import * as conf from '../app/InvoiceAdd/config';
import * as confPlan from '../app/Plans/config';
export var userEvidenceStatusLabel = function (statusID) {
    var msg = '';
    var color = '';
    switch (statusID) {
        case conf.MASTER_USER_EVIDENCE_STATUS_APPROVED:
            msg = "承認済";
            color = 'blue';
            break;
        case conf.MASTER_USER_EVIDENCE_STATUS_UNNECESSARY:
            msg = "提出不要";
            color = 'blue';
            break;
        case conf.MASTER_USER_EVIDENCE_STATUS_REJECTED:
            msg = "非承認";
            color = 'red';
            break;
        case conf.MASTER_USER_EVIDENCE_STATUS_CHECKING:
            msg = "審査中";
            color = 'orange';
            break;
        default:
    }
    return { msg: msg, color: color };
};
export var convertUsername = function (username, incorporated) {
    if (username) {
        var suffix = incorporated ? "様" : "さん";
        return "" + username + suffix;
    }
    return incorporated ? "貴社" : "あなた";
};
export var humanFileSize = function (size) {
    var i = Math.floor(Math.log(size) / Math.log(1024));
    return ((size / Math.pow(1024, i)).toFixed(2) + ["B", "KB", "MB", "GB", "TB"][i]);
};
export var getMembershipPlan = function (planID, periodID, isNonePlanHandle) {
    if (isNonePlanHandle === void 0) { isNonePlanHandle = false; }
    var conf = confPlan;
    var monthlyUnit = "円/月", yearlyUnit = "円/年";
    var planTitle = '', periodTitle = '', unit = '', price = 0, commaPrice = '0';
    if (planID === conf.MASTER_MEMBERSHIP_PLAN_FREE) {
        planTitle = conf.MEMBERSHIP_PLAN_FREE_TITLE;
        unit = "円";
        return { planTitle: planTitle, periodTitle: periodTitle, unit: unit, price: price, commaPrice: commaPrice };
    }
    switch (planID) {
        case conf.MASTER_MEMBERSHIP_PLAN_REGULAR:
            planTitle = conf.MEMBERSHIP_PLAN_REGULAR_TITLE;
            price = periodID === conf.MASTER_MEMBERSHIP_BILLING_PERIODS_MONTHLY
                ? conf.REGULAR_MONTHLY_PRICE
                : periodID === conf.MASTER_MEMBERSHIP_BILLING_PERIODS_YEARLY ? conf.REGULAR_YEARLY_PRICE : price;
            break;
        case conf.MASTER_MEMBERSHIP_PLAN_PREMIUM:
            planTitle = conf.MEMBERSHIP_PLAN_PREMIUM_TITLE;
            price = periodID === conf.MASTER_MEMBERSHIP_BILLING_PERIODS_MONTHLY
                ? conf.PREMIUM_MONTHLY_PRICE
                : periodID === conf.MASTER_MEMBERSHIP_BILLING_PERIODS_YEARLY ? conf.PREMIUM_YEARLY_PRICE : price;
            break;
        default:
            planTitle = conf.MEMBERSHIP_PLAN_FREE_TITLE;
    }
    switch (periodID) {
        case conf.MASTER_MEMBERSHIP_BILLING_PERIODS_MONTHLY:
            periodTitle = conf.MEMBERSHIP_PERIODS_MONTHLY_TITLE;
            unit = monthlyUnit;
            break;
        case conf.MASTER_MEMBERSHIP_BILLING_PERIODS_YEARLY:
            periodTitle = conf.MEMBERSHIP_PERIODS_YEARLY_TITLE;
            unit = yearlyUnit;
            break;
        default:
            unit = "円";
    }
    commaPrice = new Intl.NumberFormat("ja-JP").format(price);
    return { planTitle: planTitle, periodTitle: periodTitle, unit: unit, price: price, commaPrice: commaPrice };
};
export var serviceInvoiceAddURL = function (invoiceData) {
    var url = "/invoices/add?service_id=" + invoiceData.linking_service_id + "&service_invoice_id=" + invoiceData.linking_service_invoice_id;
    if (invoiceData.linking_service_name == 'freee') {
        url += "&company_id=" + invoiceData.company_id;
    }
    return url;
};
export var calculateApplicationAmount = function (amount, withholdingTax, maxAmountRate) {
    var applicationAmount = amount - withholdingTax;
    var maxApplicationAmount = parseInt((amount * maxAmountRate).toFixed(), 10) || 0;
    if (applicationAmount > maxApplicationAmount) {
        return maxApplicationAmount;
    }
    return applicationAmount;
};
export default {};

let idx = 0
let timers = {}

/**
 * Initialize copy to clipboard functions
 *
 * @param {HTMLElement} rootEl
 * @param {Object} clzToSwaps
 */
export function initCopyToClipboard(rootEl, clzToSwaps) {
  if (!rootEl) return;

  const btnEl = rootEl.querySelector('.btnCopy');
  if (!btnEl) return;

  // Only add event if there is no event attached
  if (rootEl.dataset.clipboard) return;
  rootEl.dataset.clipboard = true;

  // Initial object to holder timer instant
  const initId = ++idx;
  timers[initId] = null
  // Handler click event with outer function than real logics
  let clickHandler = e => {
    e.preventDefault();
    copyToClipboard(e, rootEl, clzToSwaps, initId);

    return false;
  }

  btnEl.addEventListener('click', clickHandler);
  // Create index for element
}

/**
 * Handle copy to clipboard
 *
 * @param {Event} e
 * @param {HTMLElement} rootEl
 * @param {Object} clzToSwaps
 * @param {number} idx
 * @returns {boolean}
 */
async function copyToClipboard(e, rootEl, clzToSwaps, idx) {
  // Clear previous timer if exists
  if (idx in timers && timers[idx]) {
    clearTimeout(timers[idx]);
    delete timers[idx];
  }

  const el = e.currentTarget;
  const copyEl = rootEl.querySelector('.copyText');
  var text = (copyEl.value) ? copyEl.value : copyEl.textContent.trim();

  // Do copy
  const result = await copyContentText(text);

  if (result) {
    const statusEl = el.querySelector('.copyStatus');

    if (!rootEl.dataset.clipStatusText) {
      rootEl.dataset.clipStatusText = statusEl.textContent;
    }
    const oldText = rootEl.dataset.clipStatusText;

    // Change button style
    for (let key in clzToSwaps) {
      swapClass(el, key, clzToSwaps[key]);
    }

    // Change text
    statusEl.textContent = 'コピーしました';

    // Timeout event to back to previous state
    timers[idx] = setTimeout(() => {
      for (let key in clzToSwaps) {
        swapClass(el, clzToSwaps[key], key)
      }

      statusEl.textContent = oldText;
    }, 5000);
  }
}


/**
 * Swap class for an element
 *
 * @param {HTMLElement} rootEl
 * @param {string} oldClz
 * @param {string} newClz
 */
function swapClass(rootEl, oldClz, newClz) {
    const els = rootEl.getElementsByClassName(oldClz);
    for (let el of els) {
      el.classList.remove(oldClz);
      el.classList.add(newClz);
    }
}

/**
 * Copy text from an element to clipboard
 *
 * @param {HTMLElement} el
 * @returns {boolean}
 */
function execCopy(el) {
  const range = document.createRange();
  range.selectNode(el);
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(range);

  const result = document.execCommand('copy');
  window.getSelection().removeAllRanges();

  return result;
}

async function copyContentText(text) {
  return navigator.clipboard.writeText(text)
    .then(() => {
      return true;
    })
    .catch((error) => {
      return false;
    });
}

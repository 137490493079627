<template>
  <div class="grid--2col__item">
    <dl class="itemBlock">
      <dt class="itemBlock__title">{{label}}</dt>
      <dd class="itemBlock__content">
        {{value}}
      </dd>
    </dl>
  </div>
</template>

<script>
  export default {
    name: 'item1col',
    props: [
      'label',
      'value',
    ],
  }
</script>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import RegisterForm from './components/RegisterForm.vue';
import CurrentContract from './components/CurrentContract.vue';
import PaymentHistory from './components/PaymentHistory.vue';
import * as conf from '../Plans/config';
var ANSIN_BASIC_URL = "https://freenance.net/anshin-basic";
var ANSIN_URL = "https://freenance.net/anshin";
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.contractRenewalDate = '';
        _this.insuranceRequestedAt = '';
        _this.insuranceDate = '';
        _this.isDormant = false;
        _this.isSettlementProcessing = false;
        _this.isContractEffecting = false;
        _this.simulationResult = null;
        _this.cardNumLimitExceeded = false;
        _this.paymentHistories = null;
        _this.activatingPlanID = 0; // 0: リリース前対象であり
        return _this;
    }
    App.prototype.created = function () {
        this.insuranceRequestedAt = document.INSURANCE_REQUESTED_AT;
        this.insuranceDate = document.INSURANCE_DATE;
        this.isDormant = document.IS_DORMANT;
        this.simulationResult = document.SIMULATION_RESULT;
        this.isSettlementProcessing = document.IS_SETTLEMENT_PROCESSING;
        this.isContractEffecting = document.IS_CONTRACT_EFFECTING;
        this.cardNumLimitExceeded = document.CARD_NUM_LIMIT_EXCEEDED;
        this.contractRenewalDate = document.CONTRACT_RENEWAL_DATE;
        this.paymentHistories = document.PAYMENT_HISTORIES;
        this.activatingPlanID = document.ACTIVATING_PLAN_ID;
    };
    Object.defineProperty(App.prototype, "insuranceUrl", {
        get: function () {
            return this.activatingPlanID === conf.MASTER_MEMBERSHIP_PLAN_FREE ? ANSIN_BASIC_URL : ANSIN_URL;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(App.prototype, "isPaidMembership", {
        get: function () {
            return this.activatingPlanID === conf.MASTER_MEMBERSHIP_PLAN_REGULAR ||
                this.activatingPlanID === conf.MASTER_MEMBERSHIP_PLAN_PREMIUM;
        },
        enumerable: false,
        configurable: true
    });
    App = __decorate([
        Component({
            components: {
                RegisterForm: RegisterForm,
                CurrentContract: CurrentContract,
                PaymentHistory: PaymentHistory
            }
        })
    ], App);
    return App;
}(Vue));
export default App;

import Vuex from 'vuex';
import Vue from 'vue';

import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import fileUploader from './modules/fileUploader';
import evidenceUpload from './modules/evidenceUpload';
import applyQuickPay from './modules/applyQuickPay';

Vue.use(Vuex);

let state = {
  validation: null,
  payProcessName: '',
  clientProcessName: '',
  autoDetectionMode: true,
  isProcessingDetection: false,
  detectedClientNumber: '',
  initialized: false,
  incorporated: false,
  userName: '',
  isBlockStatusPublic: false,
  isShowApplyQuickPay: false,
  status: {
    clientSpecified: false,
    agree: false,
    modeAddInvoice: false,
    howToChooseClientsinitialized: false,
    processingApiRequest: false,
    processingScreenMove: false,
    editingAction: '',
    movingPageCalled: false
  },
  page: {
    title: '',
    complete: false
  },
  dealInfo: {
    id: 0,
    clientName: '',
    commissionPrice: 0,
    corporationJudgeDone: '',
    createdAt: '',
    createdOutcomingDate: '',
    dueDate: '',
    incomingAmount: 0,
    originalAmount: 0,
    tax: 0,
    ipAddress: '',
    judgeDone: '',
    masterDealStatus: '',
    sendingDate: '',
    offerPrice: 0,
    notes: null,
    appliedAt: ''
  },
  initInvoiceInfo: {
    applicationAmount: '',
    isConfirmedPersonInCharge: false,
  },
  invoiceInfo: {
    id: 0,
    masterInvoiceStatusId: 0,
    masterDealStatusId: 0,
    client: '',
    clientNumber: '',
    personInCharge: '',
    amount: '',
    withholdingTax: '',
    applicationAmount: '',
    provingMailAddress: '',
    billingDate: '',
    date: '', // 支払期日
    memo: '',
    status: '',
    isSpecified: false,
    guaranteed: false,
    isSeleted: false,
    applicationAmountRequired: true,
    canApplyNotifiedFactoring: false,
    applyingNotifiedFactoring: false,
    codeForNotifiedFactoring: '',
    identificationID: '',
    linkingServiceId: 0,
    fileExtensionValidation: 'jpg,jpeg,png,gif,pdf',
    notifiedFactoringOptions: {
      companyNameLabel: '',
      personInChargeLabel: '',
      personInChargeRequired: true,
      issuedDateLabel: '',
      dueDateLabel: '',
      amountLabel: '',
      taxLabel: '',
      taxRequired: true,
      applicationAmountLabel: '',
      memoLabel: '',
      memoRequired: true,
      fileUploadLabel: '',
      fileUploadNote: '',
      identificationIDRequired: false,
      identificationIDLabel: '',
      amountNote: '',
      isDisplayableAmountNote: true,
      taxNote: '',
      isDisplayableTaxNote: true,
      applicationAmountNote: '',
      isDisplayableApplicationAmountNote: true,
      memoNote: '',
      isDisplayableMemoNote: true,
      fileUploadRequired: false,
      noteUser: '',
      allowedUploadFileTypes: '',
      isChoosableFrncAccount: false,
      maxApplicationAmountRate: 1,
      maxApplicationAmountPerRate: '100',
      isAutoCalculateDueDate: false,
      isDefaultIssuedDateToToday: false,
      isDisabledIssuedDate: false,
      dueDateMonthRule: 0,
      dueDateDayRule: 0,
    },
    disabledAmount: false,
    disabledWithholdingTax: false,
    disabledBillingDate: false,
    disabledDate: false,
    modifiable: true,
    isAgreePersonInCharge: false,
    isConfirmedPersonInCharge: false,
    isUsedFrncAccount: true,
  },
  invoiceDetectedData: {
    userId: '',
    invoiceId: '',
    ocrRawText: '',
    invoiceFilePath: '',
    detectedCompanyName: '',
    detectedPersonIncharge: '',
    detectedTotalAmount: '',
    detectedWithholdingAmount: '',
    detectedApplicationAmount: '',
    detectedInvoiceIssuedDate: '',
    detectedTransferDeadline: '',
    detectedTimeStart: '',
    detectedTimeEnd: '',
  },
  invoiceList: [],
  unSpecifyInvoiceList: [],
  targetUnSpecifyInvoice: {},
  clientInfo: {
    id: 0,
    number: '',
    name: '',
    representative: '',
    department: '',
    person: '',
    tel: '',
    email: '',
    address: '',
    note: '',
    notifiedFactorings: [],
    disabledClientEditing: false,
  },
  termsConditions: {
    amountBilledTotal: 0,
    withholdingTaxTotal: 0,
    transactionFee: 500,
    paymentDate: '20180801',
  },
  serviceInvoice: null,
  serviceInvoiceParam: null,
  clients: [],
  clientInfoBackup: null,
  emptyClientInfo: null,
  clientSearchTerm: '',
  tempClientId: 0,
  applyingNfCode: '',
  isInvoiceEdit: false,
  isDisplayByNf: false,
  defaultDisplayOptions: {
    companyNameLabel: '請求先会社名',
    personInChargeLabel: '請求先担当者',
    issuedDateLabel: '請求日',
    dueDateLabel: '振込期限',
    amountLabel: '請求金額',
    taxLabel: '源泉徴収税',
    amountNote: '',
    taxNote: '',
    applicationAmountNote: '',
    memoNote: '',
    applicationAmountLabel: '申込み金額',
    memoLabel: 'メモ',
    fileUploadLabel: '請求書ファイル読み取り',
    fileUploadNote: '請求書ファイルをOCRで読み取りできます。（ファイル形式：.jpg, .jpeg, .png, .pdf、ファイルサイズ：10MB 以下）'
  },
  nfCodeAppliedClientId: 0,
  isNewClientAdded: false,
  apiConsumer: {
    clientIdOfKong: '',
    // このinvoiceIdはfrnc-backendのものではなく
    // APIコンシューマー側で付与される一意のID
    invoiceId: '',
    redirectUrl: '',
  }
};

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  modules: {
    fileUploader,
    evidenceUpload,
    applyQuickPay
  }
});

<template>
  <div
    class="lineBlock"
    v-if="isDisplayable">
    <section class="section">
      <div
        class="section__inner"
        v-if="invoiceInfo.notifiedFactoringOptions.noteUser !== ''">
        <div
          class="nf-note-content"
          v-html="nfNoteContent" />
      </div>
      <div
        class="section__inner"
        v-if="invoiceInfo.notifiedFactoringOptions.identificationIDRequired">
        <dl
          class="itemBlock"
          :class="{ 'is-error': !validation.isIdentificationID.valid }">
          <dt class="itemBlock__title">
            {{ invoiceInfo.notifiedFactoringOptions.identificationIDLabel }}
          </dt>
          <dd class="itemBlock__content">
            <input
              v-model="invoiceInfo.identificationID"
              type="text"
              name="identification_id_for_notified_factoring"
              class="input">
            <p
              class="valiedTxt"
              v-if="!validation.isIdentificationID.valid">
              {{ validation.isIdentificationID.message }}
            </p>
          </dd>
        </dl>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.validCode {
  padding-left: 35px;
  position: relative;
}

.validCode:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 24px;
  height: 24px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: url(/images/icon-success.svg) center center no-repeat;
  background-size: contain;
}

.nfNote-tmp-container {
  overflow: hidden;
  margin-top: 5px;
  max-width: 100%;
}

.frnc-tpl-required {
  .unchecked {
    outline: 2px solid red;
    outline-offset: -2px;
  }

  .err-msg {
    display: none;
    color: red;
  }

  .is-err {
    color: red;
  }
}

.nf-note-content {
  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  ul,
  ol {
    padding-left: 20px;
  }

  ul ul,
  ol ol,
  ul ol,
  ol ul {
    border: none;
    margin: 2px;
    padding: 0;
    padding-left: 24px;
  }

  hr {
    display: block;
    height: 1px;
    border: none;
    border-top: 1px solid #CCC;
  }

  h1,
  h2,
  h3,
  h4 {
    color: #111;
    background: none;
    margin: 0;
    padding: 0;
    font-weight: bold;
  }

  h1 {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 15px;
  }

  h3 {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 7px;
  }

  h4 {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 7px;
  }

  img {
    max-width: 100%;
  }
}
</style>

<script>
export default {
  name: 'apply-notified-factoring',
  props: {
    'validation': Object
  },
  computed: {
    invoiceInfo() {
      return this.$store.state.invoiceInfo;
    },
    nfNoteContent() {
      const content = this.$store.state.invoiceInfo.notifiedFactoringOptions.noteUser;
      const parser = new DOMParser();
      const dom = parser.parseFromString(`${content}`, 'text/html');
      const result = dom.body ? dom.body.textContent : '';
      return result;
    },
    isDisplayable() {
      return this.invoiceInfo.applyingNotifiedFactoring
        && (this.invoiceInfo.notifiedFactoringOptions.noteUser !== ''
          || this.invoiceInfo.notifiedFactoringOptions.identificationIDRequired);
    }
  },
  methods: {
    handleNfNoteRequired() {
      const requiredSections = Array.from(document.getElementsByClassName('frnc-tpl-required'));

      requiredSections.forEach((section) => {
        const checkBox = section.querySelector('input');
        const content = section.querySelector('label');
        const errMessage = section.querySelector('.err-msg');

        checkBox.addEventListener('change', () => {
          if (checkBox.checked) {
            checkBox.classList.remove('unchecked');
            content.classList.remove('is-err');
            errMessage.style.display = 'none';
          } else {
            checkBox.classList.add('unchecked');
            content.classList.add('is-err');
            errMessage.style.display = 'block';
          }
        });
      });
    }
  },
  watch: {
    nfNoteContent() {
      this.$nextTick(() => { this.handleNfNoteRequired(); });
    }
  },
  mounted() {
    this.handleNfNoteRequired();
  }
};
</script>

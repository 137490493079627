var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Watch } from 'vue-property-decorator';
import specifyClient from './specify-client.vue';
import notifiedFactoring from './notified-factoring.vue';
import VueModal from 'vue-js-modal';
Vue.use(VueModal);
var PayProcessModal = /** @class */ (function (_super) {
    __extends(PayProcessModal, _super);
    function PayProcessModal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PayProcessModal.prototype.show = function () {
        this.$modal.show('client-modal');
    };
    PayProcessModal.prototype.hide = function () {
        this.$modal.hide('client-modal');
        this.$store.commit('updateProcessingApiRequest', false);
    };
    PayProcessModal.prototype.isOnThisStep = function (name) {
        return name === this.$store.getters.getClientProcessName;
    };
    PayProcessModal.prototype.getDetectedClientNumber = function () {
        if (this.$store.getters.getClientInfo.notifiedFactorings && this.$store.getters.getClientInfo.notifiedFactorings.length > 0) {
            this.$store.commit('updateClientProcessName', 'notifiedFactoring');
            this.show();
        }
    };
    PayProcessModal.prototype.movePage = function (nextPathName) {
        if (nextPathName === 'specifyNewClient' || nextPathName === 'specifyRegistedClient') {
            this.$store.commit('resetNotifiedFactoring');
            if (this.$store.getters.getClientInfo.notifiedFactorings && this.$store.getters.getClientInfo.notifiedFactorings.length > 0) {
                this.$store.commit('updateClientProcessName', 'notifiedFactoring');
                this.$store.commit('updateProcessingApiRequest', false);
                return;
            }
        }
        else if (nextPathName === 'notifiedFactoring') {
            this.hide();
            return;
        }
        this.$emit('nextPage', nextPathName);
    };
    __decorate([
        Watch('$store.getters.getDetectedClientNumber')
    ], PayProcessModal.prototype, "getDetectedClientNumber", null);
    PayProcessModal = __decorate([
        Component({
            components: {
                specifyClient: specifyClient,
                notifiedFactoring: notifiedFactoring
            }
        })
    ], PayProcessModal);
    return PayProcessModal;
}(Vue));
export default PayProcessModal;

import toSeparateDateByHyphen from './converter/ToSeparateDateByHyphen.js';

var moment = require('moment');

/**
 * Add Month Day For Date
 *
 * @param {string} date
 * @param {number} month
 * @param {number} day
 *
 * @return {string}
 */
export default function AddMonthDayForDate(date, month, day) {
  var convertDate = toSeparateDateByHyphen.convert(date, '/');
  let newDate = moment(convertDate).add(month, 'months');
  const dateClone = newDate.clone();
  newDate.date(day);

  if (newDate.format("M") != dateClone.format("M")) {
    newDate = dateClone.endOf('month');
  }

  if (moment(date).isAfter(newDate)) {
    return '';
  }

  return newDate.format('YYYY/MM/DD');
};

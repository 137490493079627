var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import moment from 'moment';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import Constants from '@lib/Constants';
var DealItem = /** @class */ (function (_super) {
    __extends(DealItem, _super);
    function DealItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(DealItem.prototype, "commaAmount", {
        get: function () {
            return new Intl.NumberFormat("ja-JP").format(this.deal.IncomingAmount);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DealItem.prototype, "updatedDate", {
        get: function () {
            return moment(this.deal.UpdatedAt).format("YYYY/MM/DD HH:mm:ss");
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DealItem.prototype, "operation", {
        get: function () {
            // 13(証憑追加取り寄せ), 15(受付)
            var DEAL_CANCELABLE_STATUS = [
                Constants.MASTER_DEAL_STATUS_ADDITIONAL_EVIDENCE_REQUIRE,
                Constants.MASTER_DEAL_STATUS_RECEPTION,
                Constants.MASTER_DEAL_STATUS_EDITED_AND_BEFORE_SUBMIT,
                Constants.MASTER_DEAL_STATUS_CORPORATION_CHECK
            ];
            // 8 (与信評価更新待ち), 9 (完了), 10 (遅延完了),
            //11 (不良債権を残し終了), 12 (謝絶), 19 (キャンセル)
            var DEAL_HIDDEN_STATUS = [
                Constants.MASTER_DEAL_STATUS_UPDATING_CREDIT,
                Constants.MASTER_DEAL_STATUS_DONE,
                Constants.MASTER_DEAL_STATUS_DONE_LATE,
                Constants.MASTER_DEAL_STATUS_BAD_DEBT,
                Constants.MASTER_DEAL_STATUS_DECLINED,
                Constants.MASTER_DEAL_STATUS_WITHDRAWN_BY_USER,
                Constants.MASTER_DEAL_STATUS_DONE_WITH_INSURANCE
            ];
            if (DEAL_CANCELABLE_STATUS.includes(this.deal.MasterDealStatusID)) {
                return {
                    link: "/cancelDeal/" + this.deal.ID,
                    action: 'cancelDeal',
                    dealID: this.deal.ID,
                    title: '申込みをキャンセル'
                };
            }
            else if (DEAL_HIDDEN_STATUS.includes(this.deal.MasterDealStatusID)) {
                return {
                    link: "/removeDealFromList/" + this.deal.ID,
                    action: 'removeDealFromList',
                    dealID: this.deal.ID,
                    title: 'このリストから削除'
                };
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DealItem.prototype, "statusText", {
        get: function () {
            // 受付済み
            var MASTER_DEAL_STATUS_RECEPTIONING = [
                Constants.MASTER_DEAL_STATUS_RECEPTION,
                Constants.MASTER_DEAL_STATUS_PHONE_CHECKING,
                Constants.MASTER_DEAL_STATUS_CORPORATION_CHECK
            ];
            // 金額提案
            var MASTER_DEAL_STATUS_PROPOSAL = [
                Constants.MASTER_DEAL_STATUS_BEFORE_ACCEPTING_PROPOSAL_DECREASE_APPLICATION_AMOUNT,
                Constants.MASTER_DEAL_STATUS_BEFORE_ACCEPTING_PROPOSAL_INCREASE_APPLICATION_AMOUNT
            ];
            // 入金待ち
            var MASTER_DEAL_STATUS_WAITING_INCOME = [
                Constants.MASTER_DEAL_STATUS_WAITING_INCOMING,
                Constants.MASTER_DEAL_STATUS_COLLECTED_PART
            ];
            // 完了
            var MASTER_DEAL_STATUS_COMPLETE = [
                Constants.MASTER_DEAL_STATUS_UPDATING_CREDIT,
                Constants.MASTER_DEAL_STATUS_DONE,
                Constants.MASTER_DEAL_STATUS_DONE_LATE,
                Constants.MASTER_DEAL_STATUS_BAD_DEBT,
                Constants.MASTER_DEAL_STATUS_DONE_WITH_INSURANCE
            ];
            var masterDealStatus = this.deal.MasterDealStatusID;
            if (MASTER_DEAL_STATUS_RECEPTIONING.includes(masterDealStatus)) {
                return '受付済';
            }
            if (masterDealStatus == Constants.MASTER_DEAL_STATUS_CHECK_INVOICE_EVIDENCE) {
                return '申込内容確認中';
            }
            if (masterDealStatus == Constants.MASTER_DEAL_STATUS_ADDITIONAL_EVIDENCE_REQUIRE
                || masterDealStatus == Constants.MASTER_DEAL_STATUS_EDITED_AND_BEFORE_SUBMIT) {
                return '修正・証憑追加依頼';
            }
            if (masterDealStatus == Constants.MASTER_DEAL_STATUS_ASSURANCE_CHECK) {
                return '保証確認中';
            }
            if (masterDealStatus == Constants.MASTER_DEAL_STATUS_PAYING) {
                return '振込手続き中';
            }
            if (MASTER_DEAL_STATUS_WAITING_INCOME.includes(masterDealStatus)) {
                return '入金待ち';
            }
            if (MASTER_DEAL_STATUS_PROPOSAL.includes(masterDealStatus)) {
                return '金額提案';
            }
            if (masterDealStatus == Constants.MASTER_DEAL_STATUS_DECLINED) {
                return '謝絶';
            }
            if (masterDealStatus == Constants.MASTER_DEAL_STATUS_WITHDRAWN_BY_USER) {
                return '取下げ';
            }
            if (MASTER_DEAL_STATUS_COMPLETE.includes(masterDealStatus)) {
                return '完了';
            }
            return '';
        },
        enumerable: false,
        configurable: true
    });
    DealItem.prototype.handleOperation = function (operation) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, operation];
            });
        });
    };
    __decorate([
        Prop({ required: true })
    ], DealItem.prototype, "deal", void 0);
    __decorate([
        Emit('showModal')
    ], DealItem.prototype, "handleOperation", null);
    DealItem = __decorate([
        Component({})
    ], DealItem);
    return DealItem;
}(Vue));
export default DealItem;

/**
 * Uploading-file Restriction
 *
 * この設定値はJSでのvalidation実行時に参照されるものです。
 * サーバーサイドでもファイルサイズのvalidationを行っているので、
 * 追加・変更を行う場合は・・・
 *
 * src/cakephp/config/config.php
 *
 * も合わせて確認して下さい。
 */
export default {
    MAX_FILE_UPLOAD_LOAD_INVOICE: 5,
    // file size
    FILE_SIZE_OF_UP_REGISTERING_ACCOUNT: 10 * 1024 * 1024,
    FILE_SIZE_OF_UP_ADDITIONAL_FILE_FOR_SIGN_UP: 10 * 1024 * 1024,
    FILE_SIZE_OF_UP_INVOICE_FILE: 10 * 1024 * 1024,
    FILE_SIZE_OF_UP_USER_EVIDENCE: 10 * 1024 * 1024,
    // master deal status
    MASTER_DEAL_STATUS_NOT_CREATED: 0,
    MASTER_DEAL_STATUS_GMOPG: 1,
    MASTER_DEAL_STATUS_CHECK_INVOICE_EVIDENCE: 2,
    MASTER_DEAL_STATUS_OFFERING: 3,
    MASTER_DEAL_STATUS_OFFER_APPROVING: 4,
    MASTER_DEAL_STATUS_PAYING: 5,
    MASTER_DEAL_STATUS_WAITING_INCOMING: 6,
    MASTER_DEAL_STATUS_COLLECTIONING: 7,
    MASTER_DEAL_STATUS_UPDATING_CREDIT: 8,
    MASTER_DEAL_STATUS_DONE: 9,
    MASTER_DEAL_STATUS_DONE_LATE: 10,
    MASTER_DEAL_STATUS_BAD_DEBT: 11,
    MASTER_DEAL_STATUS_DECLINED: 12,
    MASTER_DEAL_STATUS_ADDITIONAL_EVIDENCE_REQUIRE: 13,
    MASTER_DEAL_STATUS_COLLECTED_PART: 14,
    MASTER_DEAL_STATUS_RECEPTION: 15,
    MASTER_DEAL_STATUS_ASSURANCE_CHECK: 16,
    MASTER_DEAL_STATUS_EDITED_AND_BEFORE_SUBMIT: 17,
    MASTER_DEAL_STATUS_PHONE_CHECKING: 18,
    MASTER_DEAL_STATUS_WITHDRAWN_BY_USER: 19,
    MASTER_DEAL_STATUS_CORPORATION_CHECK: 20,
    MASTER_DEAL_STATUS_BEFORE_ACCEPTING_PROPOSAL_DECREASE_APPLICATION_AMOUNT: 21,
    MASTER_DEAL_STATUS_BEFORE_ACCEPTING_PROPOSAL_INCREASE_APPLICATION_AMOUNT: 22,
    MASTER_DEAL_STATUS_DONE_WITH_INSURANCE: 23,
    // master invoice status
    MASTER_INVOICE_STATUS_NOT_IMPORTED: 0,
    MASTER_INVOICE_STATUS_REGISTERED: 1,
    MASTER_INVOICE_STATUS_WAITING_EVIDENCE: 2,
    MASTER_INVOICE_STATUS_WAITING_REVIEW: 3,
    MASTER_INVOICE_STATUS_WAITING_OUTCOMING: 4,
    MASTER_INVOICE_STATUS_WAITING_COLLECTION: 5,
    MASTER_INVOICE_STATUS_DONE: 6,
    MASTER_INVOICE_STATUS_DECLINED: 7,
    MASTER_INVOICE_STATUS_WITHDRAWN: 8,
    // sort direction
    SORT_DIRECTION_DESC: 'desc',
    SORT_DIRECTION_ASC: 'asc',
    MESSAGE_OK: 'OK',
    DEFAULT_PAGE: 1,
    ACCOUNT_NAME_REGEX: /^[a-zA-Zａ-ｚＡ-Ｚ0-9０-９ァ-ワヲ-ヶｦ-ﾟ\(\)\.\-\/ 　（）．ーｰ,，]*$/,
    ACCOUNT_NAME_ERROR_MESSAGE: 'カナ・英数字・記号のみを入力してください ※ 記号は().,/-が使用可能で、独立濁点・半濁点含め40文字以内で入力してください。',
    DEFAULT_ERROR_MESSAGE: 'エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください',
};

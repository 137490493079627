import ajax from '../../../lib/Ajax';

// Add file to form data or file key to json data if key exist
const makeFileUploadData = (src, fileName, formData, jsonData) => {
  Object.values(src).forEach((value, index) => {
    if (!value.uploadedFileName) {
      formData.append(`${fileName}${index}`, value.uploadData);
    } else {
      jsonData.registrationData.uploadedFile[`${fileName}${index}`] = {
        uploadedFileName: value.uploadedFileName,
        originalName: value.fileName
      };
    }
  });
};

export default {
  async saveRegistrationData({ state, commit, getters }) {
    let isSuccess = false;

    // Set processing API request
    if (state.status.isOnAPIRequest) {
      return isSuccess;
    }
    commit('updateProcessingApiRequest', true);

    // Get user's data
    const { bankAccountNameCert, certificate, ...bankIndividualInfo } = state.bank.individual;

    const data = {
      previousStep: state.status.currentStep,
      registrationData: {
        userChoice: state.userChoice,
        userInfo: state.userInfo,
        bank: {
          individual: bankIndividualInfo,
          corporate: state.bank.corporate
        },
        uploadedFile: {},
        isFileUpdated: getters.getSaveTempFileUploadNeeded,
        selectedPlan: state.selectedPlan,
        finalRegistrationData: {}
      }
    };

    // Make upload params
    const params = new FormData();
    if (state.status.currentStep === 'bank' && state.saveTempFileUploadNeeded && !getters.incorporated) {
      makeFileUploadData(bankAccountNameCert, 'bankAccountNameCert', params, data);
      if (getters.alternatedAnshinAccountName) {
        makeFileUploadData(certificate, 'certificate', params, data);
      }
    }

    if (state.status.currentStep === state.registrationStep.REGISTRATION_STEP_CONFIRMATION) {
      data.registrationData.finalRegistrationData = getters.buildSendingParams;
    }

    params.append('registration_json_data', JSON.stringify(data));
    const csrfToken = document.CSRF_TOKEN;
    try {
      const fileUploadRes = await ajax.post(
        '/apiInternal/saveRegistrationData',
        params,
        { 'X-CSRF-Token': csrfToken }
      );

      if (fileUploadRes.data.status === 200) {
        isSuccess = true;
      } else {
        alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください');
      }
    } catch (e) {
      alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください');
    } finally {
      commit('updateSaveTempFileUploadNeeded', false);
      commit('updateProcessingApiRequest', false);
    }

    return isSuccess;
  }
};

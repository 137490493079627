import ajax from '../lib/Ajax';

export default function removeVirtualAccountSuffix() {
  const updateVirtAccBtn = document.getElementsByClassName('js-updateVirtualAccount')[0];
  const checkBox = document.getElementsByName('checkBox')[0];
  const layer02 = document.getElementsByClassName('layer02')[0];
  checkBox.addEventListener('change', () => {
    if (checkBox.checked) {
      updateVirtAccBtn.disabled = false;
    } else {
      updateVirtAccBtn.disabled = true;
    }
  });
  updateVirtAccBtn.addEventListener('click', async () => {
    const result = confirm('新しいフリーナンス口座を作成します。現在のフリーナンス口座は3ヶ月後に利用できなくなります。よろしいでしょうか。');
    const csrfToken = document.CSRF_TOKEN;
    if (result) {
      updateVirtAccBtn.disabled = true;
      try {
        const res = await ajax.post('/apiInternal/removeVirtualAccountSuffix', {}, {
          'X-CSRF-Token': csrfToken
        });
        if (res.status === 200) {
          if (res.data.status !== 200) {
            alert('新しいフリーナンス口座の作成に失敗しました。');
            updateVirtAccBtn.disabled = false;
          } else {
            document.location.href = '/';
          }
        } else {
          alert('新しいフリーナンス口座の作成に失敗しました。');
          updateVirtAccBtn.disabled = false;
        }
      } catch (e) {
        alert('新しいフリーナンス口座の作成に失敗しました。');
        updateVirtAccBtn.disabled = false;
        throw e;
      }
    }
  });
}

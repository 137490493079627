<template>
  <div>
    <step />
    <div class="layer03">
      <page-title />

      <userinfo
        :selectData="select"
        @changeCurrentStep="changeCurrentStep"
        @scrolltop="scrollTop"
        v-show="currentStep == registrationStep.REGISTRATION_STEP_GENERATE_INFO">
      </userinfo>

      <bankaccount
        :selectData="select"
        @changeCurrentStep="changeCurrentStep"
        @scrolltop="scrollTop"
        v-show="currentStep == registrationStep.REGISTRATION_STEP_BANK">
      </bankaccount>

      <confirm
        :selectData="select"
        @changeCurrentStep="changeCurrentStep"
        @scrolltop="scrollTop"
        v-show="currentStep == registrationStep.REGISTRATION_STEP_CONFIRMATION">
      </confirm>

    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import step           from './parts/step.vue';
  import pageTitle      from './parts/page-title.vue';
  import userInfo       from './userinfo/userInfo.vue';
  import bankAccount    from './bankaccount/bankAccount.vue';
  import confirm        from './confirm/confirm.vue';

  export default  {
    name: 'signup',
    beforeMount() {
      this.select.prefectures = document.PREFECTURES;
      this.select.industry = document.INDUSTRIES;
      this.select.sex = {
        '1':'男',
        '2':'女',
        '3':'その他',
      };
      this.select.accountType = {
        '1':'普通',
        '2':'定期',
        '3':'当座',
      };
      this.select.borrowingPresence = {
        '1':'無',
        '2':'有',
      };
      this.select.businessHistoryYearRange = 100;
      this.select.corporateNumberLength = 13;
      this.select.telLength = 11;
      this.select.yearLength = 4;
      this.select.amountManLength = 6;
      this.select.debtAmountLength = 7;
      this.select.postalCodeLength = 7;
      this.setCurrentStep();
    },
    data() {
      return {
        //select parts
        select: {
          prefectures: '',
          industry: '',
          sex: '',
          accountType: '',
          borrowingPresence: ''
        }
      }
    },
    computed: {
      ...mapState({
        currentStep: (state) => state.status.currentStep,
        registrationStep: (state) => state.registrationStep
      })
    },
    components: {
      'step': step,
      'page-title': pageTitle,
      'userinfo': userInfo,
      'bankaccount': bankAccount,
      'confirm': confirm
    },
    methods: {
      scrollTop: function() {
        var leftPos = 0;
        if (768 <= $(window).width()) {
          leftPos = 600 - $(window).width()/2;
        }
        window.scrollTo(leftPos, 0);
      },
      changeCurrentStep(registrationStep) {
        switch(registrationStep) {
        case this.registrationStep.REGISTRATION_STEP_GENERATE_INFO:
          window.dataLayer.push({ event: 'content-view', contentViewPath: '/signup/apply' });
          break;
        case this.registrationStep.REGISTRATION_STEP_BANK:
          window.dataLayer.push({ event: 'content-view', contentViewPath: '/signup/apply/bank-info' });
          break;
        case this.registrationStep.REGISTRATION_STEP_CONFIRMATION:
          window.dataLayer.push({ event: 'content-view', contentViewPath: '/signup/apply/confirmation' });
          break;
        default:
          break;
        }

        this.scrollTop();
        this.$store.commit('updateRegistrationStep', registrationStep);
      },
      setCurrentStep() {
        let currentStep = '';

        switch (document.PREVIOUS_REGISTRATION_STEP) {
        case this.registrationStep.REGISTRATION_STEP_GENERATE_INFO:
          currentStep = this.registrationStep.REGISTRATION_STEP_BANK;
          window.dataLayer.push({ event: 'content-view', contentViewPath: '/signup/apply/bank-info' });
          break;
        case this.registrationStep.REGISTRATION_STEP_BANK:
            currentStep = this.registrationStep.REGISTRATION_STEP_CONFIRMATION;
            window.dataLayer.push({ event: 'content-view', contentViewPath: '/signup/apply/confirmation' });
          break;
        case this.registrationStep.REGISTRATION_STEP_CONFIRMATION:
          currentStep = this.registrationStep.REGISTRATION_STEP_CONFIRMATION;
          window.dataLayer.push({ event: 'content-view', contentViewPath: '/signup/apply/confirmation' });
          break;
        default:
          currentStep = this.registrationStep.REGISTRATION_STEP_GENERATE_INFO;
          window.dataLayer.push({ event: 'content-view', contentViewPath: '/signup/apply' });
          break;
        }

        this.$store.commit('updateRegistrationStep', currentStep);
      }
    }
  };
</script>

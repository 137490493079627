<template>
  <div>
    <p class="subTitle">
      {{ invoiceInfoDisplaySetting.fileUploadLabel }}<span class="subTag">必須</span>
    </p>
    <p>
      {{ invoiceInfoDisplaySetting.fileUploadNote }}
    </p>
    <div class="upload">
      <div @dragleave.prevent @dragover.prevent @drop.prevent="selectFile" class="upload__content">
        <div class="upload__item">
          <p class="upload__txt">
            ドラッグアンドドロップでアップロードできます
          </p>
          <label class="uploadBtn--middle" :class="{ 'is-disabled': isDisableUpload || isOnScreenMove }">
            <input v-on:change="selectFile" :disabled="isDisableUpload || isOnScreenMove" type="file"
              name="invoiceCertFile" ref="fileInput" multiple>
            <span class="uploadBtn--middle__inner" tabindex="0">ファイル選択</span>
          </label>
        </div>
      </div>
      <p v-show="!validation.isFileUploaded && !err" class="valiedTxt">請求書ファイルをアップロードしてください</p>
      <p v-show="err" class="valiedTxt">{{ err }}</p>
      <FileDisplay :data="listNewUploadFile" :enable-delete="!isDisableUpload && !isOnScreenMove" @delete="deleteFile" />
    </div>
  </div>
</template>

<script>
import FileCheck from '../../../../lib/validator/File';
import Const from '../../../../lib/Constants';
import FileDisplay from './file-display.vue';
import EventBus from '../../../../component/EventBus'

export default {
  name: 'file-upload',
  props: {
    validation: Object,
    isDisableUpload: Boolean,
  },
  components: {
    FileDisplay
  },

  data() {
    return {
      err: ''
    };
  },
  created() {
    this.initEventListen();
  },
  computed: {
    sharedState() {
      return this.$store.state;
    },
    invoiceInfoDisplaySetting() {
      return this.$store.getters.getInvoiceInfoDisplaySetting;
    },
    fileTypeConfig() {
      return this.$store.getters.getUploadFileTypeConfig;
    },
    isOnScreenMove() {
      return this.$store.getters.isOnScreenMove;
    },
    listNewUploadFile() {
      return this.$store.getters['fileUploader/getNewUploadFile'];
    },
    listUploadedFile() {
      return this.$store.getters['fileUploader/getUploadedFile'];
    }
  },

  watch: {
    fileTypeConfig() {
      this.validateSelectedFiles();
    }
  },

  mounted() {
    this.validateSelectedFiles();
  },

  methods: {
    initEventListen: function () {
      EventBus.$on('deleteFile', (index) => {
        this.deleteFile(index);
      })
    },
    validateSelectedFiles: async function () {
      const fileUploadLength = this.listUploadedFile.length;
      for (let i = 0; i < fileUploadLength; i += 1) {
        const file = this.listUploadedFile[i];
        // check if file from Google Cloud
        const uploadedFileExt = file.key ? file.type : null;
        if (file.isExist) {
          continue;
        }
        const typeCheck = await FileCheck.checkInvoiceFileType(file.uploadData, uploadedFileExt, this.fileTypeConfig);

        if (!typeCheck.valid) {
          this.err = typeCheck.message;
          this.validation.isFileUploaded = false;
          return;
        }
      }

      this.err = '';
      this.validation.isFileUploaded = true;

    },
    selectFile: async function (e) {
      if (this.isDisableInvoiceUpload) {
        return;
      }

      e.preventDefault();

      // Check if file is selected
      const files = e.target.files ? e.target.files : e.dataTransfer.files;
      if (!files.length) return;

      // Check its count of currently attached
      if ((this.$store.getters.cntUploadedFiles + files.length) > Const.MAX_FILE_UPLOAD_LOAD_INVOICE) {
        e.target.value = '';
        this.err = '5ファイルのみアップロード可能です';
        return;
      }

      // Loop through each selected file
      for (const file of files) {
        // Check file type
        const typeCheck = await FileCheck.checkInvoiceFileType(file, null, this.fileTypeConfig);
        if (!typeCheck.valid) {
          this.err = typeCheck.message;
          this.validation.isFileUploaded = false;
          return;
        }

        // Check file size
        const sizeCheck = FileCheck.checkFileSize(file, Const.FILE_SIZE_OF_UP_INVOICE_FILE);
        if (!sizeCheck.valid) {
          this.err = sizeCheck.message;
          this.validation.isFileUploaded = false;
          return;
        }

        // Upload file
        this.$store.dispatch('fileUploader/upload', file);

        this.validation.isFileUploaded = true;
      }

      // Reset upload state & error message
      this.err = '';
      // Revalidate existing files
      this.validateSelectedFiles();

      // only detect when first uploading
      if (!this.sharedState.autoDetectionMode) return;
      document.getElementById('invoiceInfoWrap').scrollIntoView({ behavior: 'smooth' });
      const isAutoCheckValidation = await this.$store.dispatch('processDetectInvoice');
      if (isAutoCheckValidation) this.$emit('checkValidate');
    },

    deleteFile(index) {
      const input = this.$refs.fileInput;
      input.value = null;
      this.err = '';

      let newUploadFileIndex = null;
      let uploadedFileIndex = null;

      if (this.listNewUploadFile.length == this.listUploadedFile.length) {
        newUploadFileIndex = index;
        uploadedFileIndex = index;
      } else if (this.listUploadedFile.length > this.listNewUploadFile.length) {
        // 例：newUploadFile = [3 , 4] , uploadedFile = [1 , 2 ,3 ,4]
        // [1 , 2] : uploaded invoice when creat invoice
        // [3 , 4] : uploaded invoice when edit invoice

        // Remove [1 ,2] of newUploadFile = [1 , 2]
        // then calculate that index in uploadedFile = [1 , 2 ,3 ,4]
        let uploadedFileLength = this.uploadedFileIndex.length - this.listNewUploadFile.length;
        newUploadFileIndex = index;
        uploadedFileIndex = uploadedFileLength + index;
      }

      this.$store.dispatch('fileUploader/delete', {
        'newUploadFileIndex': newUploadFileIndex,
        'uploadedFileIndex': uploadedFileIndex
      });

      this.validateSelectedFiles();

      // stop detecting
      this.$store.dispatch('stopProcessingDetection');

      // if delete file first then clear data detected
      if (index == 0) {
        this.$store.dispatch('clearInvoiceDetectedData');
      }
    }
  }
};
</script>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import { mainModule } from "../store/main";
import moment from 'moment';
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        // ----------------------------------------
        // data
        // ----------------------------------------
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.nowUploading = false;
        _this.csrfToken = document.CSRF_TOKEN;
        return _this;
    }
    // ----------------------------------------
    // lifecycle hook
    // ----------------------------------------
    App.prototype.mounted = function () {
        var statusId = $('#telInterview').data('status');
        mainModule.load(statusId);
        mainModule.loadSchedule();
    };
    // ----------------------------------------
    // methods
    // ----------------------------------------
    App.prototype.onClickBook = function () {
        this.nowUploading = true;
        mainModule.book(this.csrfToken);
    };
    Object.defineProperty(App.prototype, "approved", {
        // ----------------------------------------
        // computed
        // ----------------------------------------
        // TODO: mainModuleをそのままtemplateに渡して
        // 以下のラップするだけのボイラープレートをなくす
        get: function () {
            return mainModule.approved;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(App.prototype, "rejected", {
        get: function () {
            return mainModule.rejected;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(App.prototype, "permanentRejected", {
        get: function () {
            return mainModule.permanentRejected;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(App.prototype, "initialized", {
        get: function () {
            return mainModule.initialized;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(App.prototype, "scheduled", {
        get: function () {
            return mainModule.scheduled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(App.prototype, "canBook", {
        get: function () {
            return mainModule.canBook;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(App.prototype, "selectBoxOptionsDate", {
        get: function () {
            return mainModule.selectBoxOptionsDate;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(App.prototype, "selectBoxOptionsTime", {
        get: function () {
            return mainModule.selectBoxOptionsTime;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(App.prototype, "selectedDate", {
        get: function () {
            return mainModule.selectedDate;
        },
        set: function (val) {
            mainModule.setSelectedDate(val);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(App.prototype, "selectedTime", {
        get: function () {
            return mainModule.selectedTime;
        },
        set: function (val) {
            mainModule.setSelectedTime(val);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(App.prototype, "interviewType", {
        get: function () {
            return mainModule.interviewType;
        },
        set: function (val) {
            mainModule.setInterviewType(val);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(App.prototype, "originalSchedule", {
        get: function () {
            return moment(mainModule.originalSchedule).format("YYYY/MM/DD(dd) HH:mm");
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(App.prototype, "isScheduledToday", {
        get: function () {
            if (mainModule.scheduled) { // only update case
                var today = new Date(Date.now());
                var scheduled = new Date(mainModule.selectedDate);
                if (moment(today).isSame(scheduled, 'day')) {
                    return true;
                }
            }
            return false;
        },
        enumerable: false,
        configurable: true
    });
    App = __decorate([
        Component({
            /** filters */
            filters: {
                convertUpperCase: function (value) {
                    if (!value) {
                        return null;
                    }
                    return value.toUpperCase();
                },
            },
        })
    ], App);
    return App;
}(Vue));
export default App;

<template>
  <div>
    <h1 class="text-center mb__59 title"><b>請求先会社情報の入力・確定</b></h1>
    <div class="card-client">
      <div class="card-header text-center">
        <tab-selector />
      </div>
      <div class="card-body">
        <div class="tab-content border__0">
          <tab-specify-new-client :no="0" @nextPage="movePage"></tab-specify-new-client>
          <tab-specify-registed-client :no="1" @nextPage="movePage"></tab-specify-registed-client>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tabSelector from './tab-selector.vue';
import tabSpecifyNewClient from './tab-specify-new-client.vue';
import tabSpecifyRegistedClient from './tab-specify-registed-client.vue';

export default {
  name: 'SpecifyClient',
  components: {
    tabSelector,
    tabSpecifyNewClient,
    tabSpecifyRegistedClient,
  },
  methods: {
    movePage(nextPathName) {
      this.$emit('nextPage', nextPathName);
    },
  }
}
</script>

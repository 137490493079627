<template>
  <div class="lineBlock">
    <section class="section">
      <h2 class="lineTitle">
        <span class="lineTitle__txt">即日払い情報</span>
      </h2>
      <div class="section__inner">
        <dl class="itemBlock">
          <dt>即日払い申請額</dt>
          <dd class="itemBlock__content">
            <span class="price__num">{{ dealInfo.incomingAmount | moneyDelimiter }}</span><span class="price">円</span>
            <p>
              <span class="price__sub">
                （
                請求金額:{{ dealInfo.originalAmount | moneyDelimiter }}円
                、源泉徴収:{{ dealInfo.tax == 0 ? 'なし' : `${taxComma}円` }}
                ）
              </span>
            </p>
          </dd>
        </dl>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'deal-info',
  props: {
    dealInfo: Object
  },
  computed: {
    taxComma() {
      return this.$options.filters.moneyDelimiter(this.dealInfo.tax);
    }
  }
};
</script>

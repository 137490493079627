<template>
  <div>
    <div class="contentBox">
      <appliable-invoice></appliable-invoice>
      <need-evidence-invoice></need-evidence-invoice>
      <template v-if="canApplyAny">
        <div class="lineBlock">
          <div class="btnUnit">
            <div class="checkLinkWrap js-checkBoxTrigger">
              <label class="checkBox--hasLink">
                <input v-model="termChecked" @click="clickAgreeCheckbox" type="checkbox" name="agree" value="1" options="1" class="checkbox" id="agree">
              </label>
              <span class="checkBox--hasLink__txt">
                <label for="agree"> 
                  上記の条件と<a href="https://freenance.net/terms-of-service" target="_blank" class="underline">利用規約</a>に同意して即日払いを利用する（必須）
                </label>
              </span>
              <p v-show="!termValid" class="valiedTxt">
                チェックをお願いします
              </p>
            </div>
            <router-link 
              :to="{name: total === '-' ? '' : 'complete'}" 
              class="actionBtn" 
              :class="{ 'is-disabled' : total === '-' || sending || isBlockStatusPublic }"
              :disabled="total === '-'"
            >
              <span class="actionBtn__inner">
                <template v-if="!sending">
                  <span class="actionBtn__txt">
                    {{ isBlockStatusPublic ? '即日払い利用制限中' : '即日払いを申込む' }}
                  </span>
                  <img src="/images/icon-cash.svg" class="actionBtn__icon" alt=""/>
                </template>
                <template v-else>
                  送信中...
                </template>
              </span>
            </router-link>
            <div v-if="!sending">
              <a href="/" class="simpleBtn">
                <span class="simpleBtn__inner">
                  <span class="simpleBtn__txt">
                    キャンセル
                  </span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="lineBlockNew mt__48">
          <template v-for="invoice in getAppliableInvoices">
            <p>・{{ invoice.offer.explain }}</p>
          </template>
          <p class="linkList__item" style="margin-top:20px;">
            <a href="https://freenance.net/faq-payment" target="_blank">即日払いに関するよくある質問はこちら</a>
          </p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appliableInvoice from './parts/appliable-invoice.vue';
import needEvidenceInvoice from './parts/need-evidence-invoice.vue';

export default {
  name: 'QuickPay',
  components: {
    'appliable-invoice': appliableInvoice,
    'need-evidence-invoice': needEvidenceInvoice,
  },
  created() {
  },
  mounted: function () {
    this.$store.dispatch('updatePageTitle', this.page);
  },
  computed: {
    ...mapGetters([
      'total',
      'canApplyAny',
      'getAppliableInvoices',
      'isBlockStatusPublic'
    ]),
  },
  beforeRouteLeave(to, from, next) {
    if (this.total === 0 || this.sending || this.isBlockStatusPublic) {
      return;
    }
    if (!this.termChecked) {
      this.termValid = false;
      return;
    }
    this.termValid = true;
    this.sending = true;
    this.$store.dispatch('apply', {
      sending_date: this.$store.getters.outcomingDate,
      callback: next,
    });
    return;
  },
  data() {
    return {
      page: {
        title: '即日払い - お取引条件の確認',
        complete: false
      },
      termsConditions: this.$store.state.termsConditions,
      termChecked: false,
      termValid: true,
      sending: false,
    }
  },
  methods: {
    clickAgreeCheckbox() {
      if (!this.termChecked) {
        this.termValid = true;
      }
    }
  },
}
</script>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
import VueCroppie from 'vue-croppie';
import VueModal from 'vue-js-modal';
import 'croppie/croppie.css';
import FileCheck from '../../../lib/validator/File';
import FileSize from '../../../lib/Constants';
import mainModule from '../store/main';
Vue.use(VueCroppie);
Vue.use(VueModal);
var DEFAULT_PHOTO_URL = '/images/profile_icon_l.svg';
var ProfileUploader = /** @class */ (function (_super) {
    __extends(ProfileUploader, _super);
    function ProfileUploader() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.inputPhoto = null;
        _this.cropedPhoto = '';
        _this.isPhotoDeleted = false;
        _this.err = '';
        return _this;
    }
    Object.defineProperty(ProfileUploader.prototype, "isExistPhotoUrl", {
        get: function () {
            if (!mainModule.profilePhotoChanged) {
                return mainModule.originPhotoDataUrl !== '';
            }
            return this.cropedPhoto !== '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProfileUploader.prototype, "displayPhotoUrl", {
        get: function () {
            if (!mainModule.profilePhotoChanged) {
                return mainModule.originPhotoDataUrl !== '' ? mainModule.originPhotoDataUrl : DEFAULT_PHOTO_URL;
            }
            return this.cropedPhoto !== '' ? this.cropedPhoto : DEFAULT_PHOTO_URL;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProfileUploader.prototype, "displayDeleteButton", {
        get: function () {
            return this.displayPhotoUrl !== DEFAULT_PHOTO_URL;
        },
        enumerable: false,
        configurable: true
    });
    ProfileUploader.prototype.openPhoto = function () {
        var image = new Image();
        image.src = this.displayPhotoUrl;
        var newWindow = window.open('');
        newWindow.document.write(image.outerHTML);
        newWindow.document.location.href = '#';
    };
    ProfileUploader.prototype.addPhotoBtnClicked = function () {
        var addBtn = document.getElementById('addPhotoBtn');
        addBtn.blur();
    };
    ProfileUploader.prototype.selectedFile = function (event) {
        event.preventDefault();
        var files = event.target.files ? event.target.files : event.dataTransfer.files;
        // Check file type
        var typeCheck = FileCheck.checkFileType(files[0], true);
        if (!typeCheck.valid) {
            this.$refs.inputFile.value = '';
            this.err = typeCheck.message;
            return;
        }
        // Check file size
        var sizeCheck = FileCheck.checkFileSize(files[0], FileSize.FILE_SIZE_OF_UP_INVOICE_FILE);
        if (!sizeCheck.valid) {
            this.$refs.inputFile.value = '';
            this.err = sizeCheck.message;
            return;
        }
        // Get input file and trigger modal
        this.inputPhoto = files.item(0);
        this.err = '';
        this.$refs.inputFile.value = '';
        this.$modal.show('imageCrop');
    };
    ProfileUploader.prototype.modalOpened = function () {
        var _this = this;
        var reader = new FileReader();
        var image = this.inputPhoto;
        this.inputPhoto = null;
        reader.readAsDataURL(image);
        reader.addEventListener('load', function (event) {
            _this.$refs.photoCroppie.bind({
                url: event.target.result
            });
        });
    };
    ProfileUploader.prototype.crop = function () {
        var _this = this;
        var options = {
            format: 'png',
            circle: true,
            type: 'base64'
        };
        this.$refs.photoCroppie.result(options, function (output) {
            _this.cropedPhoto = output;
            mainModule.addPhoto(output);
            _this.$modal.hide('imageCrop');
        });
    };
    ProfileUploader.prototype.cancel = function () {
        this.$modal.hide('imageCrop');
        this.$refs.photoCroppie.destroy();
    };
    ProfileUploader.prototype.deletePhoto = function () {
        var deleteBtn = document.getElementById('deletePhotoBtn');
        deleteBtn.blur();
        this.err = '';
        if (this.cropedPhoto !== '') {
            this.cropedPhoto = '';
            mainModule.deleteTempPhoto();
        }
        else {
            this.isPhotoDeleted = true;
            mainModule.deleteOriginPhoto();
        }
    };
    ProfileUploader.prototype.rollbackPhoto = function () {
        this.cropedPhoto = '';
        this.isPhotoDeleted = false;
        this.err = '';
        mainModule.deleteTempPhoto();
    };
    ProfileUploader = __decorate([
        Component
    ], ProfileUploader);
    return ProfileUploader;
}(Vue));
export default ProfileUploader;

export function scrollToError(errClass) {
    var listElement = Array.from(document.querySelectorAll(errClass));
    var element = listElement.find(function (item) {
        var _item = item;
        if (_item.style.display != 'none') {
            var _parent = _item.parentElement;
            if (_parent && _parent.style.display != 'none') {
                return true;
            }
        }
        return false;
    });
    if (!element) {
        return;
    }
    // add 100px align from top
    var top = element.offsetTop - 100;
    if (window.innerWidth < 768) {
        var header = document.querySelector('.header');
        if (header) {
            top -= header.offsetHeight;
        }
    }
    window.scrollTo(window.scrollX, top);
}
export default {
    scrollToError: scrollToError
};

export default  {
  check (inputStr) {
    let valid = this.isEmail(inputStr),
        msg = (!valid) ? '@を含むメールアドレスを入力してください' : '',
        result = {
          valid: valid,
          message: msg
        };

    return result
  },
  isEmail (value = '') {
    let checker = /^[a-zA-Z0-9_\-\/\?\+][\.a-zA-Z0-9_\-\/\?\+]*@([a-zA-Z0-9_\-]+\.)+([a-zA-Z0-9]+)$/;

    return checker.test(value)
  }
}

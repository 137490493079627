import axios from 'axios';
import FileCheck from '../../../lib/validator/File';
import FileSize from '../../../lib/Constants';
import { fullToHalfKana, smallToBigKana, halfToFullKana } from '../../../lib/Convert';
import util from '../util';

function loadDataURLToState(location, data) {
  let type = '';
  const fileName = data.originalName;
  if (fileName.includes('.')) type = fileName.split('.').pop().toLowerCase();
  if (data.dataURL) {
    location.push({
      displayData: data.dataURL,
      fileName: data.originalName,
      type,
      uploadedFileName: data.uploadedFileName
    });
  }
}
export default {
  loadRegistrationTempData(state, payload) {
    if (payload.userChoice || payload.userInfo) {
      state.userChoice = payload.userChoice;
      state.userInfo = payload.userInfo;
      state.industries = payload.userChoice === 1 ? payload.userInfo.individual.industry : payload.userInfo.corporate.industry;
    }

    if (payload.bank) {
      state.bank.corporate = payload.bank.corporate;
      const { individual: individualBankInfo } = payload.bank;
      state.bank.individual = Object.assign({
        certificate: [],
        bankAccountNameCert: []
      }, individualBankInfo);
    }

    if(payload.selectedPlan) {
      state.selectedPlan = payload.selectedPlan;
    }
  },

  loadRegistrationTempImage(state, payload) {
    if (!('bank' in payload)) {
      return;
    }

    for (let i = 0; i <= 1; i += 1) {
      loadDataURLToState(state.bank.individual.certificate, payload.bank[`certificate${i}`]);
      loadDataURLToState(state.bank.individual.bankAccountNameCert, payload.bank[`bankAccountNameCert${i}`]);
    }
  },
  setIsAppliedAgree(state, payload) {
    state.isAppliedAgree = payload;
  },
  setIncorporatedOrNot(state, payload) {
    state.userChoice = payload;
  },
  userInfoIndividual(state, payload) {
    state.userInfo.individual = payload;
    if (!state.bank.individual.accountName) {
      state.bank.individual.accountName = fullToHalfKana(smallToBigKana(halfToFullKana(payload.familyNameKana + payload.firstNameKana)));
      state.bank.individual.otherAccountName = state.bank.individual.accountName;
    }
  },
  userInfoIndividualTel(state, payload) {
    state.userInfo.individual.tel = payload;
  },
  userInfoCorporate(state, payload) {
    state.userInfo.corporate = payload;
  },
  bankAccountName(state, payload) {
    const incorporated = state.userChoice == 2; // TODO: userChoice type is fixed
    if (!incorporated) {
      state.bank.individual.accountName = payload;
    } else {
      state.bank.corporate.accountName = payload;
    }
  },
  bankOtherAccountName(state, payload) {
    state.bank.individual.otherAccountName = payload;
  },
  bankIndividual(state, payload) {
    state.bank.individual = payload;
  },
  bankIndividualCertificate(state, payload) {
    state.bank.individual.certificate.push(payload);
  },
  bankCorporate(state, payload) {
    state.bank.corporate = payload;
  },

  setAsOneByteChar(state, payload) {
    let s = payload.key.split('.');
    state[s[0]][s[1]][s[2]] = util.toHalfWidth(payload.value);
  },

  setFormatNumber(state, payload) {
    let s = payload.key.split('.');
    state[s[0]][s[1]][s[2]] = util.formatNumberField(payload.value);
  },

  attachBankCert(state, payload) {
    let validationObj = state['validation']['bank'][payload.sectionType][payload.fieldName];
    let storeObj = state['bank'][payload.sectionType][payload.fieldName];
    let file = payload.file;
    if (typeof file === 'undefined') {
      return;
    }

    if (storeObj.length > 1) {
      validationObj.message = '2ファイル以上はアップロードできません';
      return;
    }

    const sizeCheck  = FileCheck.checkFileSize(file, FileSize.FILE_SIZE_OF_UP_REGISTERING_ACCOUNT);
    if (!sizeCheck.valid) {
      validationObj.message = sizeCheck.message;
      return;
    }

    const typeCheck = FileCheck.checkFileType(file);
    if (!typeCheck.valid) {
      validationObj.message = typeCheck.message;
      return;
    }

    let type = '';
    const fileName = file.name;
    if (fileName.includes('.')) type = fileName.split('.').pop().toLowerCase();
    const displayData = window.URL.createObjectURL(file);
    storeObj.push({
      uploadData: file,
      displayData,
      type,
      fileName
    });

    validationObj.message = '';
    validationObj.valid = true;
  },

  resetBankValidMessenger(state) {
    state.validation.bank.individual.certificate.message = '';
    state.validation.bank.individual.bankAccountNameCert.message = '';
  },

  detachBankCert(state, payload) {
    const validationObj = state['validation']['bank'][payload.sectionType][payload.fieldName];
    const storeObj = state['bank'][payload.sectionType][payload.fieldName];
    const { index } = payload;
    storeObj.splice(index, 1);
    validationObj.valid = true;
    validationObj.message = '';
  },

  setAddressByZipCode(state, payload) { // TODO: should be an 'action'
    let zipCode = payload.zipCode;
    let incorporated = state.userChoice == 2;
    if (!(zipCode.match(/^\d{7}$/))){
      return;
    }

    const kenallToken = atob(document.KENALL_TOKEN_BASE64);
    const config = {
      headers: {
        authority: 'api.kenall.jp',
        accept: 'application/json, text/plain, */*',
        authorization: `Token ${kenallToken}`
      }
    };

    axios.get(`https://api.kenall.jp/v1/postalcode/${zipCode}`, config)
      .then((response) => {
        const prefectureResponse = response.data.data[0];
        const fetchAddress = !incorporated ? state.userInfo.individual : state.userInfo.corporate;
        const validation = !incorporated ? state.validation.userInfo.individual : state.validation.userInfo.corporate;
        if (response.status === 200) {
          // 都道府県
          let prefecturesId;
          for (const pf in document.PREFECTURES) { // TODO : All 'data' in signup.vue should be in store first, then replace
            if (document.PREFECTURES[pf] === prefectureResponse.prefecture) {
              prefecturesId = pf;
              continue;
            }
          }
          fetchAddress.prefecturesId = prefecturesId;
          fetchAddress.address = prefectureResponse.city; // 市区町村
          fetchAddress.addressKana = prefectureResponse.city_kana; // 市区町村（カナ）
          fetchAddress.areaDetail = prefectureResponse.town; // 町域・番地
          fetchAddress.areaDetailKana = prefectureResponse.town_kana; // 町域・番地（カナ）
          fetchAddress.apartmentName = prefectureResponse.building; // 建物名
          validation.postalCode.bool = true;
          validation.prefecturesId.bool = true;
          validation.address.bool = true;
          validation.addressKana.bool = true;
          validation.areaDetail.bool = true;
          validation.areaDetailKana.bool = true;
          validation.apartmentNameKana.bool = true;
        }
      })
      .catch((response) => {
        console.log(response);
      });
  },

  // API通信状態をセットする
  updateProcessingApiRequest: (state, status) => {
    state.status.isOnAPIRequest = status;
  },

  updateRegistrationStep: (state, step) => {
    state.status.currentStep = step;
  },

  updateSaveTempFileUploadNeeded: (state, isNeeded) => {
    state.saveTempFileUploadNeeded = isNeeded;
  }
};

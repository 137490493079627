export default {
  // APIリクエスト中か否か
  isOnAPIRequest: (state) => {
    return state.status.processingApiRequest;
  },
  isRedirecting: (state) => {
    return state.status.isRedirecting;
  },
  isResponseApiError: (state) => {
    return state.status.isResponseApiError;
  },
  isBlockStatusPublic: (state) => {
    return state.isBlockStatusPublic;
  },
  getPayProcessName: (state) => {
    return state.payProcessName;
  },
  getClientProcessName: (state) => {
    return state.clientProcessName;
  },
  getInvoice: (state) => {
    return state.invoiceInfo;
  },
  getValidation: (state) => {
    return state.validation;
  },
  getApplicationAmountLabel: (state) => {
    return state.invoiceInfo.applyingNotifiedFactoring
      ? state.invoiceInfo.notifiedFactoringOptions.applicationAmountLabel
      : state.defaultDisplayOptions.applicationAmountLabel;
  },
  getApplicationAmountNote: (state) => {
    return state.invoiceInfo.applyingNotifiedFactoring && state.invoiceInfo.notifiedFactoringOptions.isDisplayableApplicationAmountNote
      ? state.invoiceInfo.notifiedFactoringOptions.applicationAmountNote || state.defaultDisplayOptions.applicationAmountNote
      : '';
  },
};

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Vue, Component, Prop, Emit, Provide } from 'vue-property-decorator';
import { Validator } from 'vee-validate';
import { scrollToError } from '../../../lib/scrollToError';
import ajax from '../../../lib/Ajax';
import ClientItem from '../../InvoiceAdd/components/parts/client-item.vue';
import phoneCheck from '../../../lib/validator/PhoneNumber';
Validator.extend('isPhoneNumber', phoneCheck.isPhoneNumber);
var ClientRegisted = /** @class */ (function (_super) {
    __extends(ClientRegisted, _super);
    function ClientRegisted() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.validator = _this.$validator;
        _this.isSearching = false;
        _this.searchedClients = [];
        _this.validation = {
            searchResult: {
                status: false,
                message: '該当する項目はありません。'
            },
            selectedClient: {
                valid: false,
                message: ''
            }
        };
        _this.searchQuery = '';
        _this.clientInfo = {
            id: 0,
            number: 0,
            name: '',
            department: '',
            person: '',
            address: '',
            representative: '',
            tel: '',
            isSelected: false,
            email: '',
            notifiedFactorings: []
        };
        return _this;
    }
    ClientRegisted.prototype.mounted = function () {
        if (this.initQuery) {
            this.searchQuery = this.initQuery;
            this.getCompany();
        }
    };
    ClientRegisted.prototype.isDisabled = function () {
        return this.isOnApiRequest || this.isSearching;
    };
    ClientRegisted.prototype.getCompany = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        result = this.$validator.validate('search', this.searchQuery);
                        if (!result) {
                            return [2 /*return*/];
                        }
                        // 検索中表示
                        this.searchedClients = [];
                        this.validation.searchResult.status = false;
                        this.validation.searchResult.message = '検索中...';
                        this.isSearching = true;
                        return [4 /*yield*/, ajax.get('/apiInternal/getClients', { keyword: this.searchQuery })];
                    case 1:
                        res = _a.sent();
                        this.isSearching = false;
                        if (!res.data.result.data) {
                            this.validation.searchResult.message = '該当する項目はありません。';
                            return [2 /*return*/];
                        }
                        // status OK
                        this.validation.searchResult.status = true;
                        this.validation.searchResult.message = '';
                        if (Array.isArray(res.data.result.data)) {
                            this.searchedClients = res.data.result.data.map(function (client) {
                                var data = {
                                    id: client.id,
                                    number: Number(client.company_no),
                                    name: client.name,
                                    department: client.department,
                                    person: client.person_in_charge,
                                    address: client.address,
                                    representative: client.representative,
                                    tel: client.tel,
                                    isSelected: false,
                                    email: client.email,
                                    notifiedFactorings: client.notifiedFactorings
                                };
                                return data;
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ClientRegisted.prototype.setSelectedClient = function (id, number) {
        for (var i = 0; i < this.searchedClients.length; i += 1) {
            if (this.searchedClients[i].id === id && this.searchedClients[i].number === number) {
                this.searchedClients[i].isSelected = true;
                this.clientInfo = this.searchedClients[i];
                this.validation.selectedClient.valid = true;
            }
            else {
                this.searchedClients[i].isSelected = false;
            }
        }
    };
    ClientRegisted.prototype.selectClient = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                result = {
                    isValid: false,
                    clientInfo: this.clientInfo
                };
                if (!this.validation.selectedClient.valid) {
                    alert('会社名を入力してください');
                    scrollToError('.valiedTxt');
                    return [2 /*return*/, result];
                }
                result.isValid = true;
                return [2 /*return*/, result];
            });
        });
    };
    ClientRegisted.prototype.back = function () {
        return true;
    };
    __decorate([
        Prop(String)
    ], ClientRegisted.prototype, "initQuery", void 0);
    __decorate([
        Prop({ default: false, required: false })
    ], ClientRegisted.prototype, "isOnApiRequest", void 0);
    __decorate([
        Prop({ type: Array, required: true })
    ], ClientRegisted.prototype, "clients", void 0);
    __decorate([
        Provide()
    ], ClientRegisted.prototype, "validator", void 0);
    __decorate([
        Emit()
    ], ClientRegisted.prototype, "selectClient", null);
    __decorate([
        Emit()
    ], ClientRegisted.prototype, "back", null);
    ClientRegisted = __decorate([
        Component({
            components: {
                ClientItem: ClientItem
            }
        })
    ], ClientRegisted);
    return ClientRegisted;
}(Vue));
export default ClientRegisted;

import Vue from 'vue';
import VeeValidate, { Validator } from 'vee-validate';
// @ts-ignore: implicitly has an 'any' type.
import VeeValidateJa from '../../../node_modules/vee-validate/dist/locale/ja';
import App from './components/App.vue';
import mainStore from './store/main';
Validator.localize('ja', VeeValidateJa);
Vue.use(VeeValidate);
var domParser = new DOMParser();
function decodeHTMLEntities(txt) {
    var el = domParser.parseFromString(txt, 'text/html');
    return el.documentElement.textContent;
}
export default function page() {
    var app = new Vue({
        el: '#profileSetting',
        components: {
            App: App
        },
        created: function () {
            mainStore.loadInitialData({
                editorData: {
                    profilePageEnable: document.USER_PROFILE_INFO.profile_page_enable,
                    incorporated: document.USER_PROFILE_INFO.incorporated,
                    isSelectablePenName: document.USER_PROFILE_INFO.is_selectable_pen_name,
                    isSelectableBusinessNameOrCompanyName: document.USER_PROFILE_INFO.is_selectable_business_or_company_name,
                    profileUserNameDisplayOption: document.USER_PROFILE_INFO.profile_username_display_option,
                    profileBankInfoDisplayOption: document.USER_PROFILE_INFO.profile_bank_info_display_option,
                    profileTitle: document.USER_PROFILE_INFO.profile_title ? decodeHTMLEntities(document.USER_PROFILE_INFO.profile_title) : '',
                    twitterUsername: document.USER_PROFILE_INFO.twitter_username,
                    facebookUsername: document.USER_PROFILE_INFO.facebook_username,
                    instagramUsername: document.USER_PROFILE_INFO.instagram_username,
                    profileIntroText: document.USER_PROFILE_INFO.profile_intro_text ? decodeHTMLEntities(document.USER_PROFILE_INFO.profile_intro_text) : ''
                },
                originPhotoDataUrl: document.USER_PROFILE_INFO.profile_photo_data_url,
                profileUrl: document.PROFILE_URL
            });
        }
    });
}

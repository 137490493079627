const RELIABLE_INSURANCE_COVERAGE_TPL =
`（１）どのような業務を請け負っていましたか？
　　　①クライアント名
　　　②業務内容
　　　③業務請負期間　　　／　　~　　／
　　　④事故発生日　　／
（２）どのような事故が発生しましたか？　　

（３）発生した事故の原因は何でしょうか？

（４）誰に賠償する必要がありますか？

（５）予想される賠償額はいくらでしょうか？

（６）賠償金額の根拠資料はありますか？

（７）保険会社からの連絡先を教えて下さい。
　　　（後日、あらためて保険会社から連絡させていただく際に必要となります。）
　　　電話番号：
　　　住所：〒
　　　メールアドレス：`;

const RELIABLE_INSURANCE_QUESTION_TPL = `（１）あなたの職業を教えて下さい

（２）どのような事故事例の質問か、具体的に教えて下さい`;

const MYPAGE_GUIDELINE_TPL = `（１）どこのページでのお困りごとですか？

（２）どのようなことが起こっていますか？

（３）いつから起こっていますか？
発生日　　／　　　〜

（４）操作画面のスクリーンショットがあればアップロードしてください`;

const SERVICE_RESIGNED_TPL = `退会理由を選択してください。

1.『即日払い』を使用する機会が少ない
2.保険の必要性が低い
3.使用方法がよくわからない
4.会費が高い
5.使いたいサービスや機能が少ない
7.フリーランスとしての活動をやめる
8.その他`;

export default {
  7: RELIABLE_INSURANCE_COVERAGE_TPL,
  10: RELIABLE_INSURANCE_QUESTION_TPL,
  15: MYPAGE_GUIDELINE_TPL,
  19: SERVICE_RESIGNED_TPL
};

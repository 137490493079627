import lity from 'lity';
import pdfObject from 'pdfobject';
// TODO: FIX TEXT FOR BELOW TEMPLATE
var lightBoxTemplate = "\n<div class=\"lity\" role=\"dialog\" aria-label=\"Dialog Window (Press escape to close)\" tabindex=\"-1\">\n  <div class=\"lity-wrap\" data-lity-close role=\"document\"><div class=\"lity-loader\" aria-hidden=\"true\">\n    <div class=\"lity-loader\" aria-hidden=\"true\">\n      <div class=\"evidence-loader\"><div></div><div></div><div></div><div></div></div>\n    </div>\n  </div>\n  <div class=\"lity-container\">\n    <div class=\"lity-content\"></div>\n    <button class=\"lity-close\" type=\"button\" aria-label=\"Close (Press escape to close)\" data-lity-close>&times;</button>\n  </div>\n</div>\n";
var getImgTemplate = function (dataURL) { return "<img class=\"evidence-img-previewer\" src=" + dataURL + "></img>"; };
var getFileTemplate = function (dataURL, fileType) {
    return "\n  <div class=\"contentBox\">\n    <div class=\"section\">\n        <h2 class=\"lineTitle\"><span class=\"lineTitle__txt\">\u30D5\u30A1\u30A4\u30EB\u3092\u8868\u793A\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u305B\u3093\u3002</span></h2>\n        <div class=\"\" \"section__inner\"=\"\">\n          <div class=\"itemBlock__content\">\n            <img src=\"/images/icon-" + fileType + ".svg\" />\n          </div>\n          <div class=\"itemBlock__content\">\n          <p><a href=" + dataURL + " download=\"download." + fileType + "\" target=\"_blank\">\u3053\u3061\u3089</a>\u304B\u3089\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9\u3057\u3066\u304F\u3060\u3055\u3044\u3002</p>\n          </div>\n        </div>\n    </div>\n  </div>";
};
export default (function (params) {
    var data = '';
    switch (params.type.toLowerCase()) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
            data = getImgTemplate(params.dataURL);
            break;
        case 'pdf': {
            var dataPdf = params.dataURL.replace('charset=binary;', '');
            data = pdfObject.supportsPDFs ? '<div id="pdfPreviewBox"></div>' : getFileTemplate(dataPdf, params.type);
            break;
        }
        case 'csv':
            data = getFileTemplate(params.dataURL, params.type);
            break;
        default:
            alert('Unsupported file type');
            return;
    }
    lity(data, { template: lightBoxTemplate });
    if (params.type === 'pdf' && pdfObject.supportsPDFs)
        pdfObject.embed(params.dataURL, '#pdfPreviewBox');
});

import Vue from 'vue';
import InvoiceUpload from './components/InvoiceUpload.vue';
import mainStore from './store/main';
export default function page() {
    var vue = new Vue({
        el: '#TopPageUploadInvoice',
        components: {
            'invoice-upload': InvoiceUpload
        },
        created: function () {
            mainStore.loadInitialData();
        }
    });
}

var $ = require('jquery');

export default function AjaxSendSms(selector) {
  $(selector).on('click', function (e) {
    e.preventDefault();
    var url = '/apiInternal/resendSmsCode';
    var csrf = $('input[name=_csrfToken]').val();
    $.ajax({
        url: url,
        type: 'POST',
        beforeSend: function(xhr){
            xhr.setRequestHeader('X-CSRF-Token', csrf);
        }
    })
    // Ajaxリクエストが成功した時発動
    .done( (data) => {
      var html = '<div class="flashMessage--success">SMSを再送いたしました</div>';
      $('.flashMessage--danger, .flashMessage--success').remove();
      $('.layer02').eq(0).prepend(html);
    })
    // Ajaxリクエストが失敗した時発動
    .fail( (data) => {
      var html = '<div class="flashMessage--danger">SMSを再送に失敗しました</div>';
      $('.flashMessage--danger, .flashMessage--success').remove();
      $('.layer02').eq(0).prepend(html);
    })
    .always( (data) => {
      $(window).scrollTop(0);
    });
  });
}

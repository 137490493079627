<template>
  <div class="lineBlock">
    <div class="btnUnit" v-if="isShowCloseButton">
      <button class="generalBtn" @click="nextStep('closeInvoice')" tabindex="0" :disabled="$store.getters.isOnScreenMove">
        <span class="generalBtn__inner">
          <span class="generalBtn__txt">閉じる</span>
        </span>
      </button>
    </div>
    <div class="btnUnit" v-else>
      <button class="generalBtn" @click="nextStep('saveInvoice')" tabindex="0"
        :disabled="!modifiable || $store.getters.isOnAPIRequest || $store.getters.isOnScreenMove"
        :class="{ 'is-disabled': !modifiable || $store.getters.isOnAPIRequest || $store.getters.isOnScreenMove }">
        <span class="generalBtn__inner">
          <span class="generalBtn__txt">
            {{ $store.getters.isOnAPIRequest ? '送信中...' : lblBtn2 }}
          </span>
        </span>
      </button>
      <button class="actionBtn btnDealSave" @click="$store.getters.isBlockStatusPublic || onClickApplyButton()" tabindex="0"
        :disabled="$store.getters.isOnAPIRequest || $store.getters.isOnScreenMove || $store.getters.isBlockStatusPublic"
        :class="{ 'is-disabled': $store.getters.isOnAPIRequest || $store.getters.isOnScreenMove || $store.getters.isBlockStatusPublic }">
        <span class="actionBtn__inner">
          <span class="actionBtn__txt">
            {{ $store.getters.isOnAPIRequest ? '送信中...' : lblBtn1 }}
          </span>
          <img src="/images/icon-cash.svg" class="actionBtn__icon" alt="" />
        </span>
      </button>
      <div>
        <a @click="deleteInvoice()" class="simpleBtn" v-if="isShowDeleteButton">
          <span class="simpleBtn__inner">
            <span class="simpleBtn__txt">
              この請求書を削除する
            </span>
          </span>
        </a>
      </div>
    </div><!-- btnUnit -->
    <delete-invoice-modal ref="deleteInvoiceModal" :invoiceID="invoiceID" />
  </div><!-- lineBlock -->
</template>

<script>
import deleteInvoiceModal from './delete-invoice-modal.vue';
import { calculateApplicationAmount } from '../../../../lib/Helper';

export default {
  name: 'invoice-register-action',
  components: {
    deleteInvoiceModal
  },
  props: {
    isShowCloseButton: Boolean,
    isShowDeleteButton: Boolean
  },
  computed: {
    modifiable() {
      return this.$store.getters.getInvoiceInfo.modifiable;
    },
    invoiceInfo() {
      return this.$store.getters.getInvoiceInfo;
    }
  },
  methods: {
    nextPage(pathName) {
      this.$emit('nextPage', pathName);
    },
    onClickApplyButton() {
      if (this.$store.getters.isOnScreenMove || this.$store.getters.isOnAPIRequest) return;
      // 申請金額
      const amountInt = parseInt(this.invoiceInfo.amount, 10) || 0;

      // 請求金額レート上限
      // 普段が1(100%)で適用します、クライアントの三社間ファクタリングコードによって、1を下回る場合もあり
      const maxAmountRateFloat = parseFloat(this.invoiceInfo.notifiedFactoringOptions.maxApplicationAmountRate);

      // 源泉徴収税
      const withholdingTax = this.invoiceInfo.withholdingTax === '' || this.taxRequired ? 0 : this.invoiceInfo.withholdingTax;
      const withholdingTaxInt = parseInt(withholdingTax, 10) || 0;

      this.$store.commit('updateDefaultApplicationAmount',
        calculateApplicationAmount(amountInt, withholdingTaxInt, maxAmountRateFloat).toString());
      this.nextPage('sameDayPayment');
    },
    nextStep(pathName) {
      if (this.$store.getters.isOnScreenMove || this.$store.getters.isOnAPIRequest) return;
      this.nextPage(pathName);
    },
    deleteInvoice() {
      this.$refs.deleteInvoiceModal.show();
    }
  },
  data() {
    let lblBtn1 = '';
    if (this.$store.getters.isBlockStatusPublic) {
      lblBtn1 = '即日払い利用制限中';
    } else if (this.$store.state.invoiceInfo.modifiable) {
      lblBtn1 = '保存して即日払い申込み';
    } else {
      lblBtn1 = '即日払い申込み';
    }

    const lblBtn2 = '保存して戻る';
    const invoiceID = this.$store.state.invoiceInfo.id;
    return {
      lblBtn1,
      lblBtn2,
      invoiceID
    };
  }
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 767px) {
  .btnDealSave {
     margin-left: 12px;
  }
}
@media screen and (max-width: 767px) {
  .btnDealSave {
     margin-top: 12px;
  }
}
</style>

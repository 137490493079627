var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import QRCode from 'qrcode-svg';
import SVG from 'svg.js/dist/svg';
import axios from 'axios';
var QR_CODE_CONTAINER_WIDTH = 600;
var ICON_WIDTH = 200;
// Use for drawing qr code with label
var QR_CODE_TOP_PADDING = 20;
var LABEL_SIDE_PADDING = 60;
var axiosSVG = axios.create({
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    },
    responseType: 'text'
});
var QRCodeGenerator = /** @class */ (function () {
    function QRCodeGenerator(iconURL, labelURL, width, iconWidth, labelTopPadding, labelSidePadding, renderOptions) {
        var _this = this;
        if (iconURL === void 0) { iconURL = '/images/qrcode-icon.svg'; }
        if (labelURL === void 0) { labelURL = '/images/qrcode-label.svg'; }
        if (width === void 0) { width = QR_CODE_CONTAINER_WIDTH; }
        if (iconWidth === void 0) { iconWidth = ICON_WIDTH; }
        if (labelTopPadding === void 0) { labelTopPadding = QR_CODE_TOP_PADDING; }
        if (labelSidePadding === void 0) { labelSidePadding = LABEL_SIDE_PADDING; }
        if (renderOptions === void 0) { renderOptions = {
            content: '',
            padding: 1,
            width: width,
            height: width,
            color: '#000000',
            background: '#ffffff',
            xmlDeclaration: false,
            ecl: 'Q',
            join: true
        }; }
        this.createStdQRCodeString = function (content) {
            _this.renderOptions.content = content;
            var stdQRCode = new QRCode(_this.renderOptions).svg();
            return stdQRCode;
        };
        this.iconURL = iconURL;
        this.labelURL = labelURL;
        this.renderOptions = renderOptions;
        this.width = width;
        this.iconWidth = iconWidth;
        this.labelTopPadding = labelTopPadding;
        this.labelSidePadding = labelSidePadding;
    }
    QRCodeGenerator.prototype.setupIconQRCode = function (iconURL, qrCode, container) {
        return __awaiter(this, void 0, void 0, function () {
            var response, iconWCoordinate, iconHCoordinate, iconSVG, qrCodeSVG;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axiosSVG.get(iconURL)];
                    case 1:
                        response = _a.sent();
                        container.svg(qrCode);
                        // Add icon into container
                        container.svg(response.data);
                        iconWCoordinate = (this.width - this.iconWidth) / 2;
                        iconHCoordinate = iconWCoordinate;
                        iconSVG = container.children()[2];
                        qrCodeSVG = container.children()[1];
                        iconSVG.addTo(qrCodeSVG);
                        iconSVG.size(this.iconWidth, this.iconWidth).move(iconWCoordinate, iconHCoordinate);
                        return [2 /*return*/];
                }
            });
        });
    };
    QRCodeGenerator.prototype.setupLabelQRCode = function (labelURL, container) {
        return __awaiter(this, void 0, void 0, function () {
            var newiconQRCode, qrCodeIconWidth, qrCodeIconHeight, qrCodeIconWCoordinate, qrCodeIconHCoordinate, response, labelSVG, labelWidth, labelHeight, labelWCoordinate, labelHCoordinate, parentSVG;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        newiconQRCode = container.children()[1];
                        qrCodeIconWidth = this.width - (this.labelSidePadding * 2);
                        qrCodeIconHeight = qrCodeIconWidth;
                        qrCodeIconWCoordinate = this.labelSidePadding;
                        qrCodeIconHCoordinate = this.labelTopPadding;
                        newiconQRCode.viewbox(0, 0, newiconQRCode.width(), newiconQRCode.height())
                            .size(qrCodeIconWidth, qrCodeIconHeight)
                            .move(qrCodeIconWCoordinate, qrCodeIconHCoordinate);
                        return [4 /*yield*/, axiosSVG.get(labelURL)];
                    case 1:
                        response = _a.sent();
                        // Add label into container
                        container.svg(response.data);
                        labelSVG = container.children()[2];
                        labelWidth = qrCodeIconWidth;
                        labelHeight = labelSVG.height() * (labelWidth / labelSVG.width());
                        labelWCoordinate = qrCodeIconWCoordinate;
                        labelHCoordinate = qrCodeIconHeight + this.labelTopPadding;
                        labelSVG.viewbox(0, 0, labelSVG.width(), labelSVG.height())
                            .size(labelWidth, labelHeight)
                            .move(labelWCoordinate, labelHCoordinate);
                        parentSVG = container.nested();
                        parentSVG.size(this.width, this.width)
                            .style('overflow', 'hidden');
                        parentSVG.rect(this.width, this.width).fill('#ffffff');
                        newiconQRCode.addTo(parentSVG);
                        labelSVG.addTo(parentSVG);
                        return [2 /*return*/];
                }
            });
        });
    };
    QRCodeGenerator.prototype.createIconQRCode = function (content, container) {
        return __awaiter(this, void 0, void 0, function () {
            var standardQRCode;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        standardQRCode = this.createStdQRCodeString(content);
                        return [4 /*yield*/, this.setupIconQRCode(this.iconURL, standardQRCode, container)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    QRCodeGenerator.prototype.createLabelQRCode = function (content, container) {
        return __awaiter(this, void 0, void 0, function () {
            var standardQRCode;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        standardQRCode = this.createStdQRCodeString(content);
                        return [4 /*yield*/, this.setupIconQRCode(this.iconURL, standardQRCode, container)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.setupLabelQRCode(this.labelURL, container)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    QRCodeGenerator.prototype.drawStandardQR = function (elementId, content) {
        var standardQRCodeSVG = SVG(elementId);
        var standardQRCode = this.createStdQRCodeString(content);
        standardQRCodeSVG.viewbox(0, 0, this.width, this.width);
        standardQRCodeSVG.svg(standardQRCode);
    };
    QRCodeGenerator.prototype.drawIconQRCode = function (elementId, content) {
        return __awaiter(this, void 0, void 0, function () {
            var iconQRCodeSVG;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        iconQRCodeSVG = SVG(elementId);
                        iconQRCodeSVG.viewbox(0, 0, this.width, this.width);
                        return [4 /*yield*/, this.createIconQRCode(content, iconQRCodeSVG)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    QRCodeGenerator.prototype.drawLabelQRCode = function (elementId, content) {
        return __awaiter(this, void 0, void 0, function () {
            var labelQRCodeSVG;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        labelQRCodeSVG = SVG(elementId);
                        labelQRCodeSVG.viewbox(0, 0, this.width, this.width);
                        return [4 /*yield*/, this.createLabelQRCode(content, labelQRCodeSVG)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return QRCodeGenerator;
}());
export default QRCodeGenerator;

import Vuex from 'vuex';
import Vue from 'vue';

import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import applyQuickPay from './modules/applyQuickPay';
import moment from 'moment';

Vue.use(Vuex);
Vue.filter('moneyDelimiter', function (exp) {
  var num = new String(exp).replace(/,/g, "");
  while (num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
  return num;
});

Vue.filter('dateFormat', function (date) {
  return moment(date).format('YYYY/MM/DD');
});

let state = {
  payProcessName: '',
  clientProcessName: '',
  isBlockStatusPublic: false,
  validation: {
    applicationAmount: {
      valid: true,
      message: '',
    }
  },
  initInvoiceInfo: {
    applicationAmount: '',
    isConfirmedPersonInCharge: false,
  },
  dealInfo: {
    id: 0,
    incomingAmount: 0,
    masterDealStatusId: 0,
    originalAmount: 0,
    tax: 0,
  },
  invoiceInfo: {
    id: 0,
    amount: '',
    applicationAmount: '',
    isConfirmedPersonInCharge: false,
    isAgreePersonInCharge: false,
    withholdingTax: '',
    applyingNotifiedFactoring: false,
    notifiedFactoringOptions: {
      maxApplicationAmountRate: 1,
      maxApplicationAmountPerRate: '100',
      applicationAmountLabel: '',
      applicationAmountNote: '',
      isDisplayableApplicationAmountNote: true
    },
  },
  defaultDisplayOptions: {
    applicationAmountLabel: '申込み金額',
    applicationAmountNote: ''
  },
  status: {
    clientSpecified: false,
    agree: false,
    modeAddInvoice: false,
    howToChooseClientsinitialized: false,
    processingApiRequest: false,
    isRedirecting: false,
    isResponseApiError: false,
    editingAction: '',
    movingPageCalled: false
  },
  page: {
    title: '',
    complete: false
  },
};

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  modules: {
    applyQuickPay
  }
});

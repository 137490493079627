<template>
  <div id="individual-container" class="section__inner">
    <div class="grid--2col">
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.familyName.bool }">
          <dt class="itemBlock__title">姓<span class="tag">必須</span></dt>
          <dd class="itemBlock__content">
          <input @blur="$emit('checkvalid', 'familyName')" v-model="individual.familyName" type="text" class="input" name="family_name">
          <p v-if="hasBlackWordInFamilyName" class="warningTxt">{{ getBlackWordWarningMessage("生") }}</p>
          <p v-show="!validation.familyName.bool" class="valiedTxt">{{validation.familyName.message}}</p>
          </dd>
        </dl>
      </div>
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.firstName.bool }">
          <dt class="itemBlock__title">名<span class="tag">必須</span></dt>
          <dd class="itemBlock__content">
          <input @blur="$emit('checkvalid', 'firstName')" v-model="individual.firstName" type="text" class="input" name="first_name">
          <p v-if="hasBlackWordInFirstName" class="warningTxt">{{ getBlackWordWarningMessage("名") }}</p>
          <p v-show="!validation.firstName.bool" class="valiedTxt">{{validation.firstName.message}}</p>
          </dd>
        </dl>
      </div>
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.familyNameKana.bool }">
          <dt class="itemBlock__title">セイ<span class="tag">必須</span></dt>
          <dd class="itemBlock__content">
          <input @blur="$emit('checkvalid', 'familyNameKana')" v-model="individual.familyNameKana" type="text" class="input" name="family_name_kana">
          <p v-show="!validation.familyNameKana.bool" class="valiedTxt">{{validation.familyNameKana.message}}</p>
          </dd>
        </dl>
      </div>
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.firstNameKana.bool }">
          <dt class="itemBlock__title">メイ<span class="tag">必須</span></dt>
          <dd class="itemBlock__content">
          <input @blur="$emit('checkvalid', 'firstNameKana')" v-model="individual.firstNameKana" type="text" class="input" name="first_name_kana">
          <p v-show="!validation.firstNameKana.bool" class="valiedTxt">{{validation.firstNameKana.message}}</p>
          </dd>
        </dl>
      </div>
      <div class="grid--2col__item">
        <dl class="itemBlock">
          <dt class="itemBlock__title">ペンネームなど通り名</dt>
          <dd class="itemBlock__content">
          <input v-model="individual.nickName" type="text" class="input" name="nick_name">
          </dd>
        </dl>
      </div>
    </div>

    <div class="grid--2col">
      <div class="grid--2col__item">
        <dl class="itemBlock">
          <dt class="itemBlock__title">屋号</dt>
          <dd class="itemBlock__content">
          <input v-model="individual.storeName" type="text" class="input" name="store_name">
          </dd>
        </dl>
      </div>
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.storeNameKana.bool }">
          <dt class="itemBlock__title">屋号カナ</dt>
          <dd class="itemBlock__content">
          <input @blur="$emit('checkvalid', 'storeNameKana')" v-model="individual.storeNameKana" type="text" class="input" name="store_name_kana">
          <p v-show="!validation.storeNameKana.bool" class="valiedTxt">{{validation.storeNameKana.message}}</p>
          </dd>
        </dl>
      </div>
    </div>

    <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.birthDate.bool }">
      <dt class="itemBlock__title">生年月日<span class="tag">必須</span></dt>
      <dd class="itemBlock__content">
      <div class="grid--3col birthDate">
        <div class="grid--3col__item">
          <select @change="$emit('checkvalid', 'birthDate')" @blur="$emit('checkvalid', 'birthDate')" v-model="individual.birthDateYear" class="select" name="birth_date_year">
            <option value="" disabled selected="selected">年</option>
            <option v-for="n in 74" :value="n + (new Date().getFullYear())-90">
            {{ n + (new Date().getFullYear())-90 }}
            </option>
          </select>
        </div>
        <div class="grid--3col__item">
          <select @change="$emit('checkvalid', 'birthDate')" @blur="$emit('checkvalid', 'birthDate')" v-model="individual.birthDateMonth" class="select" name="birth_date_month">
            <option value="" disabled selected="selected">月</option>
            <option v-for="n in 12" :value="n < 10 ? '0'+n : n">
            {{ n < 10 ? '0'+n : n }}
            </option>
          </select>
        </div>
        <div class="grid--3col__item">
          <select @change="$emit('checkvalid', 'birthDate')" @blur="$emit('checkvalid', 'birthDate')" v-model="individual.birthDateDay" class="select" name="birth_date_day">
            <option value="" disabled selected="selected">日</option>
            <option v-for="n in 31" :value="n < 10 ? '0'+n : n">
            {{ n < 10 ? '0'+n : n }}
            </option>
          </select>
        </div>
      </div>
      </dd>
      <dd v-show="!validation.birthDate.bool" class="itemBlock__content valiedTxt">
      <div>{{validation.birthDate.message}}</div>
      </dd>
    </dl>

    <div class="grid--2col">
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.sex.bool }">
          <dt class="itemBlock__title">性別<span class="tag">必須</span></dt>
          <dd class="itemBlock__content">
          <select @change="$emit('checkvalid', 'sex')" @blur="$emit('checkvalid', 'sex')" v-model="individual.sex" class="select" name="sex">
            <option value="" disabled selected="selected">選択してください</option>
            <option v-for="(item, index) in select.sex" v-bind:value="index">
            {{item}}
            </option>
          </select>
          <p v-show="!validation.sex.bool" class="valiedTxt">{{validation.sex.message}}</p>
          </dd>
        </dl>
      </div>
    </div>

    <div class="grid--2col">
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.postalCode.bool }">
          <dt class="itemBlock__title">郵便番号<span class="tag">必須</span></dt>
          <dd class="itemBlock__content">
          <input
            @blur="$emit('checkvalid', 'postalCode')"
            v-model="individual.postalCode"
            type="text"
            class="input"
            name="postal_cord">
          <p v-show="!validation.postalCode.bool" class="valiedTxt">{{validation.postalCode.message}}</p>
          </dd>
        </dl>
      </div>
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.prefecturesId.bool }">
          <dt class="itemBlock__title">都道府県<span class="tag">必須</span></dt>
          <dd class="itemBlock__content">
          <select @change="$emit('checkvalid', 'prefecturesId')" @blur="$emit('checkvalid', 'prefecturesId')" v-model="individual.prefecturesId" class="select" name="prefectures_id">
            <option value="" disabled selected="selected">選択してください</option>
            <option v-for="(item, index) in select.prefectures" v-bind:value="index">
            {{item}}
            </option>
          </select>
          <p v-show="!validation.prefecturesId.bool" class="valiedTxt">{{validation.prefecturesId.message}}</p>
          </dd>
        </dl>
      </div>
    </div>

    <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.address.bool }">
      <dt class="itemBlock__title">市区町村<span class="tag">必須</span></dt>
      <dd class="itemBlock__content">
      <input @blur="$emit('checkvalid', 'address')" v-model="individual.address" type="text" class="input" name="address">
      <p v-show="!validation.address.bool" class="valiedTxt">{{validation.address.message}}</p>
      </dd>
    </dl>

    <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.addressKana.bool }">
      <dt class="itemBlock__title">市区町村（カナ）<span class="tag">必須</span></dt>
      <dd class="itemBlock__content">
      <input @blur="$emit('checkvalid', 'addressKana')" v-model="individual.addressKana" type="text" class="input" name="address_kana">
      <p v-show="!validation.addressKana.bool" class="valiedTxt">{{validation.addressKana.message}}</p>
      </dd>
    </dl>

    <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.areaDetail.bool }">
      <dt class="itemBlock__title">町域・番地<span class="tag">必須</span></dt>
      <dd class="itemBlock__content">
      <input @blur="$emit('checkvalid', 'areaDetail')" v-model="individual.areaDetail" type="text" class="input" name="area_detail">
      <p v-show="!validation.areaDetail.bool" class="valiedTxt">{{validation.areaDetail.message}}</p>
      <small>例) 桜丘町26-1</small>
      </dd>
    </dl>

    <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.areaDetailKana.bool }">
      <dt class="itemBlock__title">町域・番地カナ（カナ・数字・ハイフン）<span class="tag">必須</span></dt>
      <dd class="itemBlock__content">
      <input @blur="$emit('checkvalid', 'areaDetailKana')" v-model="individual.areaDetailKana" type="text" class="input" name="area_detail_kana">
      <p v-show="!validation.areaDetailKana.bool" class="valiedTxt">{{validation.areaDetailKana.message}}</p>
      <small>例) サクラガオカチョウ26-1</small>
      </dd>
    </dl>

    <dl class="itemBlock">
      <dt class="itemBlock__title">建物名（マンション・アパート等にお住いの場合は必須）</dt>
      <dd class="itemBlock__content">
      <input v-model="individual.apartmentName" type="text" class="input" name="apartment_name">
      <small>例) セルリアンタワーA棟C-203</small>
      </dd>
    </dl>

    <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.apartmentNameKana.bool }">
      <dt class="itemBlock__title">建物名カナ（カナ・数字）</dt>
      <dd class="itemBlock__content">
      <input @blur="$emit('checkvalid', 'apartmentNameKana')" v-model="individual.apartmentNameKana" type="text" class="input" name="apartment_name_kana">
      <p v-show="!validation.apartmentNameKana.bool" class="valiedTxt">{{validation.apartmentNameKana.message}}</p>
      <small>例) セルリアンタワーエートウシーー203</small>
      </dd>
    </dl>

    <dl class="itemBlock">
      <dt class="itemBlock__title">携帯電話番号</dt>
      <dd class="itemBlock__content">
      {{this.replacePhoneNumber($store.state.userInfo.individual.tel)}}
      </dd>
    </dl>

    <div class="grid-col-70percent">
      <div class="grid-col-70percent__item-70percent">
        <industry
          :validation="validation"
          v-on:checkbox="$emit('checkvalid', 'industry')"
          @checkvalid="$emit('checkvalid', 'amOtherEtcName')"
        ></industry>
      </div>
      <div class="grid-col-70percent__item-30percent">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.businessHistory.bool }">
          <dt class="itemBlock__title">業歴（年）<span class="tag">必須</span></dt>
          <dd class="itemBlock__content">
            <select @change="$emit('checkvalid', 'businessHistory')" @blur="$emit('checkvalid', 'businessHistory')" v-model="individual.businessHistory" class="select" name="business_history">
              <option value="" selected="selected">選択してください</option>
              <option v-for="item in select.businessHistoryYearRange" :key="item" :value="'' + item + ''">
                {{item}}
              </option>
            </select>
            <p v-show="!validation.businessHistory.bool" class="valiedTxt">{{validation.businessHistory.message}}</p>
          </dd>
        </dl>
      </div>
    </div>
    <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.averageInvoiceAmount.bool }">
      <dt class="itemBlock__title"><span class="itemBlock__requiredTxt">請求書1枚のおおよその平均請求額</span><span class="tag">必須</span></dt>
      <dd class="itemBlock__content">
        <input
          @blur="$emit('checkvalid', 'averageInvoiceAmount')"
          v-model="commaAverageInvoiceAmount"
          type="text"
          class="input sales"
          name="average_invoice_amount"
          :maxlength="select.amountManLength">万円
        <p v-show="!validation.averageInvoiceAmount.bool" class="valiedTxt">{{validation.averageInvoiceAmount.message}}</p>
      </dd>
    </dl>

    <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.monthlySalesAverage.bool }">
      <dt class="itemBlock__title"><span class="itemBlock__requiredTxt">おおよその月間平均売上</span><span class="tag">必須</span></dt>
      <dd class="itemBlock__content">
        <input
          @blur="$emit('checkvalid', 'monthlySalesAverage')"
          v-model="commaMonthlySalesAverage"
          type="text"
          class="input sales"
          name="monthly_sales_average"
          :maxlength="select.amountManLength">万円
        <p v-show="!validation.monthlySalesAverage.bool" class="valiedTxt">{{validation.monthlySalesAverage.message}}</p>
      </dd>
    </dl>

    <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.siteUrl.bool }">
      <dt class="itemBlock__title"><span class="itemBlock__requiredTxt">サイトのURL</span></dt>
      <dd class="itemBlock__content">
        <input @blur="$emit('checkvalid', 'siteUrl')" v-model="individual.siteUrl" type="text" class="input" name="site_url">
        <p v-show="!validation.siteUrl.bool" class="valiedTxt">{{validation.siteUrl.message}}</p>
      </dd>
    </dl>

  </div>
</template>

<script>
  import industry from './parts/industry';
  import util from '../../util';
  import GmoSignBlackListName from '@lib/validator/GmoSignBlackListName';

  export default {
    name: 'individual',
    data: function() {
      return {
        commaAverageInvoiceAmount : '',
        commaMonthlySalesAverage : '',
      };
    },
    props: [
      'select',
      'individual',
      'validation',
      'isAppliedAgree',
    ],
    components: {
      industry,
    },
    computed: {
      hasBlackWordInFirstName: function() {
        return this.isAppliedAgree && GmoSignBlackListName.check(this.individual.firstName);
      },
      hasBlackWordInFamilyName: function() {
        return this.isAppliedAgree && GmoSignBlackListName.check(this.individual.familyName);
      },
    },
    methods: {
      initData: function() {
        if (this.individual.averageInvoiceAmount) {
          this.commaAverageInvoiceAmount = new Intl.NumberFormat("ja-JP").format(this.individual.averageInvoiceAmount);
        }
        if (this.individual.monthlySalesAverage) {
          this.commaMonthlySalesAverage = new Intl.NumberFormat("ja-JP").format(this.individual.monthlySalesAverage);
        }
      },
      replacePhoneNumber: function(tel) {
        let len = tel.length;
        let str = tel.slice(0,5);
        for (let i = 5;i < len; i++) {
          str += '*';
        }
        return str;
      },
      setBirthDate: function() {
        if (!this.individual.birthDateYear) {
          this.individual.birthDateYear = (new Date().getFullYear()) - 89;
        }
        if (!this.individual.birthDateMonth) {
          this.individual.birthDateMonth = '01';
        }
        if (!this.individual.birthDateDay) {
          this.individual.birthDateDay = '01';
        }
        this.validation.birthDate.bool = true;
      },
      handleAmountWatch: function(val, watchField, commitField) {
        const _v = util.formatNumberField(util.toHalfWidth(val));
        if (!_v) {
          this.$store.commit('setFormatNumber', {
            key: commitField,
            value: '',
          });       
          return
        }
        
        this[watchField] = new Intl.NumberFormat("ja-JP").format(_v);
        this.$store.commit('setFormatNumber', {
          key: commitField,
          value: _v,
        });
      },
      getBlackWordWarningMessage: function(fieldName) {
        return GmoSignBlackListName.errorMessage(fieldName);
      },
    },
    mounted: async function () {
      this.initData();
    },
    watch: {
      'individual.areaDetail': function(val) {
        this.$store.commit('setAsOneByteChar', {
          key: 'userInfo.individual.areaDetail',
          value: val,
        });
      },
      'individual.areaDetailKana': function(val) {
        this.$store.commit('setAsOneByteChar', {
          key: 'userInfo.individual.areaDetailKana',
          value: val,
        });
      },
      'individual.apartmentName': function(val) {
        this.$store.commit('setAsOneByteChar', {
          key: 'userInfo.individual.apartmentName',
          value: val,
        });
      },
      'individual.apartmentNameKana': function(val) {
        this.$store.commit('setAsOneByteChar', {
          key: 'userInfo.individual.apartmentNameKana',
          value: val,
        });
      },
      'individual.businessHistory': function(val) {
        this.$store.commit('setAsOneByteChar', {
          key: 'userInfo.individual.businessHistory',
          value: val,
        });
      },
      'individual.averageInvoiceAmount': function(val) {
        if (!val || this.commaAverageInvoiceAmount) {
          return;
        }
        this.commaAverageInvoiceAmount = new Intl.NumberFormat("ja-JP").format(val);
      },
      'commaAverageInvoiceAmount': function (val) {
        this.handleAmountWatch(val, 'commaAverageInvoiceAmount', 'userInfo.individual.averageInvoiceAmount');
      },
      'individual.monthlySalesAverage': function(val) {
        if (!val || this.commaMonthlySalesAverage) {
          return;
        }
        this.commaMonthlySalesAverage = new Intl.NumberFormat("ja-JP").format(val);
      },
      'commaMonthlySalesAverage': function(val) {
        this.handleAmountWatch(val, 'commaMonthlySalesAverage', 'userInfo.individual.monthlySalesAverage');
      },
      'individual.birthDateYear': function() {
        this.setBirthDate();
      },
      'individual.birthDateMonth': function() {
        this.setBirthDate();
      },
      'individual.birthDateDay': function() {
        this.setBirthDate();
      },
      'individual.postalCode': function(val) {
        this.individual.postalCode = util.toHalfWidth(val)
        this.individual.postalCode = util.formatNumberFieldLength(this.individual.postalCode, this.select.postalCodeLength);
        this.$store.commit('setAddressByZipCode', {
          zipCode : this.individual.postalCode,
        });
      },
    },
  }
</script>

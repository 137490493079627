

export default function page () {

  $('.js-accordion').each(function () {
    var $this = $(this);
    var $trigger = $this.find('.js-accordion__trigger');

    if ($this.hasClass('accordionTable')) {
      $trigger.on('click', function () {
        if ($(this).hasClass('is-active')) {
          $(this).removeClass('is-active');
        } else {
          $(this).addClass('is-active');
        }
      }); 
    } else {
      $trigger.on('click', function () {
        if ($this.hasClass('is-active')) {
          $this.removeClass('is-active');
        } else {
          $this.addClass('is-active');
        }
      }); 
    }
  });

  //period check
  (function periodFunc() {

    /// check default
    if ($('.js-period input').prop('checked')) {
        $('.js-sortCalendar')
            .addClass('is-disabled')
            .prop('disabled', true);
    }
    
    function onChangePeriod () {
        if ($('.js-sortCalendar').hasClass('is-disabled')) {
            $('.js-sortCalendar')
            .removeClass('is-disabled')
            .prop('disabled', false);
            $('.ui-datepicker-trigger').attr('src','/images/icon-calendar.svg');
        } else {
            $('.js-sortCalendar')
            .addClass('is-disabled')
            .prop('disabled', true);
            $('.ui-datepicker-trigger').attr('src','/images/icon-calendar-disabled.svg');
        }
    }
    
    $('.js-period input').on('change', onChangePeriod);
  })();

};

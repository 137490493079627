var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { Mutation, VuexModule, getModule, Module } from 'vuex-module-decorators';
import store from '.';
var Main = /** @class */ (function (_super) {
    __extends(Main, _super);
    function Main() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.uploadFileList = [];
        _this.maxNumFilesContactUploads = 0;
        _this.maximumFileSizeOfUpContact = 0;
        return _this;
    }
    Main.prototype.addUploadFileList = function (_a) {
        var _this = this;
        var files = _a.files;
        Array.from(files).forEach(function (file) {
            var uploadData = file;
            var displayData = window.URL.createObjectURL(file);
            var fileName = file.name;
            var type = '';
            var selectedFiles = new DataTransfer();
            selectedFiles.items.add(file);
            if (fileName.includes('.')) {
                type = fileName.split('.').pop().toLowerCase();
            }
            var objFile = {
                displayData: displayData,
                type: type,
                fileName: fileName,
                uploadData: uploadData,
                selectedFiles: selectedFiles
            };
            _this.uploadFileList = __spreadArray(__spreadArray([], _this.uploadFileList), [objFile]);
        });
    };
    Main.prototype.removeUploadFileList = function (indexUploadFile) {
        this.uploadFileList = this.uploadFileList.filter(function (item, index) { return index !== indexUploadFile; });
    };
    Main.prototype.setMaxNumFilesContactUploads = function (maxNumFilesContactUploads) {
        this.maxNumFilesContactUploads = maxNumFilesContactUploads;
    };
    Main.prototype.setMaximumFileSizeOfUpContact = function (maximumFileSizeOfUpContact) {
        this.maximumFileSizeOfUpContact = maximumFileSizeOfUpContact;
    };
    __decorate([
        Mutation
    ], Main.prototype, "addUploadFileList", null);
    __decorate([
        Mutation
    ], Main.prototype, "removeUploadFileList", null);
    __decorate([
        Mutation
    ], Main.prototype, "setMaxNumFilesContactUploads", null);
    __decorate([
        Mutation
    ], Main.prototype, "setMaximumFileSizeOfUpContact", null);
    Main = __decorate([
        Module({ dynamic: true, store: store, name: 'main', namespaced: true })
    ], Main);
    return Main;
}(VuexModule));
export default getModule(Main);

import 'es6-promise/auto';
import moment from 'moment';
import Vue        from 'vue';
import Vuex       from 'vuex';
import setupInputTracking from '@lib/inputTrack';
import Common     from '../../common';
import App        from './components/App.vue';
import router     from './router';
import store      from './stores';
import $ from "jquery"

export default function page () {
  Vue.config.devtools = true;
  Vue.use(Vuex);

  Vue.filter('moneyDelimiter', function (exp) {
    var num = new String(exp).replace(/,/g, "");
    while(num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
    return num;
  });

  Vue.filter('dateFormat', function (date) {
      return moment(date).format('YYYY/MM/DD');
  });

  // eslint-disable-next-line no-new
  new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    async created() {
      if (document.SERVICE_INVOICE) {
        this.$store.commit('initServiceInvoiceData', document.SERVICE_INVOICE);
      }

      if (document.INVOICE_IMAGES && document.INVOICE_IMAGES.imageUrls !== undefined) {
        await this.showImageFromTopPage();
      }

      this.$store.commit('load', {
        'clients' : document.CLIENTS,
        'company_number_api_maintenance_start' : document.COMPANY_NUMBER_API_MAINTENANCE_START,
        'company_number_api_maintenance_end' : document.COMPANY_NUMBER_API_MAINTENANCE_END,
        'incorporated': document.INCORPORATED,
        'user_name': document.USER_NAME,
        'user_email': document.USER_EMAIL,
        'user_tel': document.USER_TEL,
        'is_block_status_public': document.IS_BLOCK_STATUS_PUBLIC,
        'deserialized_estimation_token': document.DESERIALIZED_ESTIMATION_TOKEN,
      });
      if (this.$store.state.invoiceInfo.codeForNotifiedFactoring) {
        this.$store.dispatch('loadNotifiedFactoring');
      }
      if (this.$route.name === 'edit') {
        this.$store.commit('loadIsShowApplyQuickPay', document.IS_SHOW_APPLY_QUICK_PAY);
        this.$store.commit('loadEditInvoiceInfo', document.INVOICE_INFO);
        this.$store.dispatch('setEvidenceFiles', document.DATAURLS);
        this.$store.commit('evidenceUpload/loadInvoiceEvidences', {
          evidences: document.INVOICE_INFO.additional_evidences,
          fileUrls: document.INVOICE_EVIDENCE_DATAURLS
        });
      }
      this.$store.commit('evidenceUpload/loadEvidenceContent');
      this.$store.commit('evidenceUpload/loadEvidenceContentHasClientName');
    },
    mounted() {
      // eslint-disable-next-line no-new
      new Common();

      // common.jsの複数回呼び出しで重複登録されたイベントを登録し直す
      (function sideNav() {
        function onClickSideNav() {
          var w = $(window).width();
          if (w <= 767) {
            $(this)
              .toggleClass('is-active')
              .parents('.side')
              .toggleClass('is-active');
            $('.js-sideNavContent').slideToggle(500, 'easeInOutExpo');
          }
        }
        $('.js-sideNavTrigger').off('click'); // 既存のイベントを削除
        $('.js-sideNavTrigger').on('click', onClickSideNav);
      })();
      (function headerMenufunc() {
        var $target = $('.js-headerSp'),
          $trigger = $target.find('.js-headerSp__menuBtn');

        $trigger.off('click'); // 既存のイベントを削除
        $trigger.on('click', function () {
          if ($target.hasClass('is-active')) {
            $target.removeClass('is-active');
          } else {
            $target.addClass('is-active');
          }
        });
      })();
    },
    updated() {
      // Check user input for tracking interaction
      this.$nextTick(() => {
        setupInputTracking();
      });
    },
    methods: {
      async showImageFromTopPage() {
        await this.$store.dispatch('setFileUploadFromTopPage', document.INVOICE_IMAGES);
        await this.$store.dispatch('processDetectInvoice');
        document.getElementById('invoiceInfoWrap').scrollIntoView({ behavior: 'smooth' });
      }
    }
  });
}

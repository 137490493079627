<template>
  <div class="contentBox">
    <individual
      :select="selectData"
      v-show="!$store.getters.incorporated">
    </individual>
    <corporate
      :select="selectData"
      v-show="$store.getters.incorporated">
    </corporate>
    <div class="lineBlock">
      <div class="btnUnit">
        <a v-on:click.prevent="saveFinalRegistrationData" :disabled="isOnAPIRequest" class="actionBtn" :class="{ 'is-disabled' : isOnAPIRequest }">
          <span class="actionBtn__inner">
            <span class="actionBtn__txt">{{ !isOnAPIRequest ? '確認して次へ' : '送信中..' }}</span>
          </span>
        </a>
        <div>
          <a v-on:click.prevent="stepback" :disabled="isOnAPIRequest" class="simpleBtn" :class="{ 'is-disabled' : isOnAPIRequest }">登録内容を修正する</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import individual from './individual.vue';
  import corporate  from './corporate.vue';
  import ajax from '../../../../lib/Ajax'

  export default {
    name: 'confirm',
    components: {
      'individual': individual,
      'corporate': corporate,
    },
    props: [
      'selectData'
    ],
    computed: {
      ...mapState({
        registrationStep: (state) => state.registrationStep,
        isOnAPIRequest: (state) => state.status.isOnAPIRequest
      })
    },
    data: function () {
      return {
      };
    },
    methods: {
      async saveFinalRegistrationData() {
        if (this.isOnAPIRequest) {
          return;
        }

        const isSuccess = await this.$store.dispatch('saveRegistrationData');

        if (isSuccess) {
          location.href = '/signup/uploadIdentification';
        }
      },
      stepback: function () {
        this.$emit('scrolltop');
        this.$emit('changeCurrentStep', this.registrationStep.REGISTRATION_STEP_GENERATE_INFO);
      }
    }
  }
</script>

import Vue    from 'vue';
import App    from './components/App.vue';
import store  from './store'
export default function page () {
  if ($('#uploadUserEvidence').length > 0) {
    new Vue({
      el: '#uploadUserEvidence',
      store: store,
      components: {
        'App': App 
      },
    }); 
  }
};

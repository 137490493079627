<template>
  <div class="section">
    <p>{{ desc }}<span style="color: red;">※マイナンバーカードの裏面はアップロードしないでください</span></p>
    <p v-if="onDeclined" class="valiedTxt">前回アップロードしていただいたファイルは審査の結果、非承認となりました。大変お手数ですが上記条件とファイルの内容をよくご確認の上アップロードをお願いいたします。</p>
    <div class="section__inner">
      <h2 class="lineTitle">
        <span class="lineTitle__txt">{{title}}のアップロード</span>
      </h2>
    </div>
    <template v-if="onJudging">
      <p class="subTitle">現在アップロードしていただいた書類を審査中です</p>
    </template>
    <template v-else-if="onApproved">
      <p class="subTitle">
        アップロードしていただいた書類の審査が完了しました！
        <!-- <br />有効期限：{{expiringDate}} -->
      </p>
    </template>
    <template v-else>
      <Uploader 
        :attachingData="this.attachingData" 
        :name="this.name"
      ></Uploader>
      <div class="lineBlock boxUpload">
        <div class="btnUnit">
          <a 
            v-on:click.prevent="upload" 
            v-on:keyup.enter="upload" 
            tabIndex="0" 
            :disabled="nowUploading" 
            class="generalBtn" 
            :class="{ 'is-disabled' : nowUploading }"
            >
             <span class="generalBtn__inner">
               <span class="generalBtn__txt">
                 {{ !nowUploading ? 'アップロード' : '送信中..' }}
               </span>
             </span>
          </a>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Uploader       from './Uploader'

  export default  {
    name: 'App',
    computed: {
      ...mapGetters([
      ])
    },
    components: {
      Uploader,
    },
    created() {
    },
    methods: {
      upload() {
        if (this.nowUploading) {
          return;
        }
        this.nowUploading = true;
        if (this.attachingData.files.length == 0) {
          alert("ファイルを選択してください");
          this.nowUploading = false;
          return;
        }
        if (confirm("ファイルをアップロードしてよろしいですか？")) {
          // Construct data
          let formData = new FormData();
          for (let i in this.attachingData.files) {
            formData.append(this.name + i, this.attachingData.files[i]);
          }
          formData.append('evidenceType', document.USER_EVIDENCE_TYPE);
          formData.append('evidenceID', this.evidenceID);
          this.attachingData.formData = formData;
          // Start upload
          this.$store.dispatch('upload', {
            data          : this.attachingData.formData,
            redirect      : location.href,
            callback      : function(){
              this.nowUploading = false;
            },
          });
        } else {
          this.nowUploading = false;
        }
      },
    },
    data() {
      let ue = document.USER_EVIDENCE;
      let et = document.USER_EVIDENCE_TYPE;
      let stId = ue ? ue.master_user_evidence_status_id : 0;
      let onJudging = ue &&
        (et === 'public_recepient' && stId === 1)
        || (et === 'tax_cert'      && stId === 1)
        || (et === 'fin_statement' && stId === 1);
      let onApproved = ue &&
        (et === 'public_recepient'  && stId === 2)
        || (et === 'tax_cert'       && stId === 2)
        || (et === 'fin_statement'  && stId === 2);
      let onDeclined = ue &&
        (et === 'public_recepient'  && stId === 3)
        || (et === 'tax_cert'       && stId === 3)
        || (et === 'fin_statement'  && stId === 3);
      return {
        title       : document.TITLE,
        desc        : document.DESC,
        name        : 'user_evidence',
        attachingData: {
          files : [],
          imgs  : [],
        },
        nowUploading: false,
        onJudging: onJudging,
        onApproved: onApproved,
        onDeclined: onDeclined,
        // expiringDate: ue && stId === 2 ? ue.expiring_date : '',
        evidenceID: ue ? ue.id : 0
      };
    },
  };
</script>

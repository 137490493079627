<template>
  <tr class="simpleTable__row" v-bind:class="{ 'is-checked': invoice._selected }">
    <td class="simpleTable__name">
      {{ invoice.client.name }}
    </td>
    <td>
      {{ invoice.offer.offer_price || '-' | moneyDelimiter }}
    </td>
    <td class="simpleTable__check">
      <template v-if="invoice.offer.appliable">
        <label class="checkBox js-checkBoxTrigger" v-bind:class="{ 'is-checked': invoice._selected }">
          <input type="checkbox" name="" value="1" options="1" id="" v-on:change="select(invoice.id)">
        </label>
      </template>
      <template v-else>
        {{ invoice.offer.explain }}
      </template>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'invoice-list-item-apply',
  props: {
    invoice: Object,
  },
  methods: {
    select(id) {
      this.$emit('select', id)
    }
  }
}
</script>

var blacklist = ["http"];
var check = function (name) {
    // GMOSignの/document/registerPosition APIは法人名（organize_nm）がhttpを含む場合はエラーになる
    for (var _i = 0, blacklist_1 = blacklist; _i < blacklist_1.length; _i++) {
        var word = blacklist_1[_i];
        if (name.includes(word)) {
            return true;
        }
    }
    return false;
};
var errorMessage = function (fieldName) {
    if (fieldName === void 0) { fieldName = '名'; }
    var blackWords = blacklist.join(",");
    return "\u8B66\u544A: " + blackWords + "\u3092\u542B\u3081\u308B" + fieldName + "\u306FGMO\u30B5\u30A4\u30F3\u306B\u9023\u643A\u3055\u308C\u3066\u306A\u3044\u3067\u3059\u3002";
};
export default {
    check: check,
    errorMessage: errorMessage
};

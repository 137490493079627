/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-multi-spaces */
import '../scss/common/reset.scss';
import '../scss/common/common.scss';
import '../scss/common/common_sp.scss';
import '../scss/util.scss';

/* -+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+ */

import common                         from './common';
import dialogMessages                 from './app/DialogMessages/main';
import URLLoader                      from './lib/URLLoader';
import AccordionPeriod                from './page/TransactionsIndex';
import Apply                          from './app/signup/main';
import UploadAdditionalFileForSignUp  from './app/UploadAdditionalFileForSignUp/main';
import setValidatePassword            from './component/PasswordValidator';
import InvoiceAdd                     from './app/InvoiceAdd/main';
import ApplyQuickPay                  from './app/ApplyQuickPay/main';
import AccountLoginHistory            from './page/AccountLoginHistory';
import AjaxSendSms                    from './component/AjaxSendSms';
import AccountResign                  from './page/AccountResign';
import AccountEdit                    from './page/AccountEdit';
import ProfileSetting                 from './app/ProfileSetting/main';
import MainIndustrySetting            from './app/MainIndustrySetting/main';
import UploadUserEvidence             from './app/UploadUserEvidence/main';
import TelInterview                   from './app/TelInterview/main';
import removeVirtualAccountSuffix     from './component/AjaxRemoveVirtualAccountSuffix';
import drawProfileQRCode              from './component/ProfileQRCode';
import { initModal, initOneTimeModal } from './component/Modal';
import initInstantTransferModal        from './component/InstantTransfer';
import ClientAdd                      from './app/ClientAdd/main';
import ListDeals                      from './page/ListDeals';
import TopPageUploadInvoice           from './page/TopPageUploadInvoice';
import ListInvoices                   from './page/ListInvoices';
import TopPageInvoices                from './page/TopPageInvoices';
import prohibitDblSend                from './lib/ProhibitDblSend';
import Contacts                       from './page/Contacts';
import LoginValidator                 from './component/LoginValidator';
import { scrollToError }              from './lib/scrollToError';
import EditVirtualAccountName         from './app/EditVirtualAccountName/main';
import Compensation                   from './app/Compensation/main';
import MembershipPlans                from './app/MembershipPlans/main';
import UploadIdentification           from './app/UploadIdentification/main';
import PaymentMembershipPlan          from './app/PaymentMembershipPlan/main';
import SignUp                         from './page/SignUp';
import SettingUploadUserEvidence      from './app/SettingUploadUserEvidence/main';

const $ = require('jquery');

/* -+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+ */
$(() => {
  // exec common function
  common();
  // Dialog message modal
  dialogMessages();
  // init sidebar_modal
  initModal('sidebar_modal');
  // init pg renewal failed modal
  initOneTimeModal('auto_renewal_failed_modal', 'auto_renewal_failed_url');
  // init membership campaign modal for user who registered before the release of membership
  initOneTimeModal('campaign_membership_modal', 'campaign_membership_url');
  // exec page function
  URLLoader([{
    pattern: /\/signup\/setPassword\/.*?$/,
    page: () => {
      setValidatePassword('.js-passwordValidation', '#password-confirmation', '#agree');
    }
  }, {
    pattern: /\/login\/resetPassword/,
    page: () => {
      setValidatePassword('.js-passwordValidation', '#password-confirmation', '#auth-code');
      AjaxSendSms('.js-ajaxSendSms');
    }
  }, {
    pattern: /\/signup\/verifySmsCode/,
    page: () => {
      AjaxSendSms('.js-ajaxSendSms');
    }
  }, {
    pattern: /\/signup\/verifyConfirmationCode\/*/,
    page: () => {
      prohibitDblSend('.js-prohibitDblSend');
    }
  }, {
    pattern: /\/transactions(\/)?$/,
    page: () => {
      AccordionPeriod();
      initInstantTransferModal();
    }
  }, {
    pattern: /(\/)$/,
    page: () => {
      ListDeals();
      TopPageUploadInvoice();
      TopPageInvoices();
      initModal('invoice_modal');
    }
  }, {
    pattern: /(\/invoices)$/,
    page: () => {
      ListInvoices();
      initModal('invoice_modal');
    }
  }, {
    pattern: /\/signup\/apply/,
    page: Apply
  }, {
    pattern: /\/invoices\/add/,
    page: InvoiceAdd
  },{
    pattern : /\/invoices\/edit\/[0-9]*?/,
    page : InvoiceAdd
  }, {
    pattern: /\/invoices\/applyQuickPay\/[0-9]*?$/,
    page: ApplyQuickPay
  }, {
    pattern: /\/invoices\/applyOriginalQuickPay\/[0-9]*?$/,
    page: ApplyQuickPay
  }, {
    pattern: /\/account\/loginHistory/,
    page: AccountLoginHistory
  }, {
    pattern: /\/account\/resign/,
    page: AccountResign
  }, {
    pattern: /\/account\/edit(\/)?$/,
    page: AccountEdit
  }, {
    pattern: /\/account\/profileSetting/,
    page: ProfileSetting
  }, {
    pattern: /\/account\/mainIndustrySetting/,
    page: MainIndustrySetting
  }, {
    pattern: /\/account\/editPassword/,
    page: () => {
      setValidatePassword('.js-passwordValidation', '#new-password-confirmation', '#old-password');
    }
  }, {
    pattern: /\/account\/removeVirtualAccountSuffix/,
    page: () => {
      removeVirtualAccountSuffix('.js-updateVirtualAccount');
    }
  }, {
    pattern: /\/activities/,
    page: AccordionPeriod
  }, {
    pattern: /\/credit\/timeline/,
    page: AccordionPeriod
  }, {
    pattern: /\/signup\/complete/,
    page: UploadAdditionalFileForSignUp
  }, {
    pattern: /\/credit\/tax/,
    page: UploadUserEvidence
  }, {
    pattern: /\/credit\/financialStatement/,
    page: UploadUserEvidence
  }, {
    pattern: /\/credit\/utilityCharges/,
    page: UploadUserEvidence
  }, {
    pattern: /\/credit\/telInterview/,
    page: TelInterview
  }, {
    pattern: /\/profile\/*/,
    page: () => {
      drawProfileQRCode(document.URL_PATH, 'profileQRCode');
      initModal('profile__icon');
    }
  }, {
    pattern: /\/clients\/add/,
    page: ClientAdd
  }, {
    pattern: /\/contacts/,
    page: Contacts
  }, {
    pattern: /\/login/,
    page: () => {
      LoginValidator('#email.input', '#password.input');
    }
  }, {
    pattern: /\/clients\/view/,
    page: () => {
      scrollToError('.error-message');
    }
  }, {
    pattern: /\/signup\/addUserAdditionalFile/,
    page: UploadAdditionalFileForSignUp
  }, {
    pattern: /\/account\/editVirtualAccountName/,
    page: EditVirtualAccountName
  }, {
    pattern: /\/((account)(\/|\/index|))$/,
    page: () => {
      SettingUploadUserEvidence();
    }
  }, {
    pattern: /\/compensation/,
    page: () => {
      Compensation();
      initModal('anshin_simulation_modal');
      initModal('confirm_cacel_compensation');
      initModal('delete_card_btn');
    }
  }, {
    pattern: /\/signup\/uploadIdentification/,
    page: UploadIdentification
  }, {
    pattern: /\/signup\/paymentMembershipPlan/,
    page: PaymentMembershipPlan
  }, {
    pattern: /\/plans/,
    page: MembershipPlans
  }, {
    pattern: /\/signup\/registPlan/,
    page: MembershipPlans
  }, {
    pattern: /\/signup/,
    page: SignUp
  }]);
});

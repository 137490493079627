const moment = require('moment');

export default  {
  check (inputStr1, inputStr2) {
    let valid = this.isDate1BeforeThanDate2(inputStr1, inputStr2),
        msg = (!valid) ? '振込期限が請求日より以前の日付です' : '',
        result = {
          valid: valid,
          message: msg
        };

    return result
  },
  isDate1BeforeThanDate2 (date1, date2) {
    return moment(date1).isBefore(date2);
  },
}

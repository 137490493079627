

export default function page () {
    $('.itemBlock__content').each(function () {
        var $this = $(this);
        var $trigger = $this.find('.js-radioTrigger');
        
        if ($this.hasClass('itemBlock__content')) {
            $trigger.on('click', function () {
                $('.generalBtn').prop("disabled", false);
            });
        }
    });
};

<template>
  <ul class="nav nav-tabs card-header-tabs" id="specify-client" role="tablist">
    <li class="nav-item">
      <a class="nav-link" id="client-tab0" @click="selectTab('tab0')" :class="{ 'active': isSelectedTab('tab0') }">
        <span>入力して選ぶ</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" id="client-tab1" @click="selectTab('tab1')" :class="{ 'active': isSelectedTab('tab1') }">
        <span>登録済から選ぶ</span>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'TabSelector',
  computed: {
    isSelectedTab() {
      return this.$store.getters.isSelectedTab;
    },
  },
  methods: {
    selectTab(tagName) {
      if (tagName == 'tab0') {
        document.getElementById('client-tab1').classList.remove('active');
        document.getElementById('tab1').classList.remove('active');
      } else if (tagName == 'tab1') {
        document.getElementById('client-tab0').classList.remove('active');
        document.getElementById('tab0').classList.remove('active');
      }
      document.getElementById(tagName).classList.add('active');
      document.getElementById('client-' + tagName).classList.add('active');
    },
  }
};
</script>

<style lang="scss" scoped>
.nav-link {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>

import { render, staticRenderFns } from "./Switcher.vue?vue&type=template&id=82a262fa&scoped=true&"
import script from "./Switcher.vue?vue&type=script&lang=ts&"
export * from "./Switcher.vue?vue&type=script&lang=ts&"
import style0 from "./Switcher.vue?vue&type=style&index=0&id=82a262fa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82a262fa",
  null
  
)

component.options.__file = "Switcher.vue"
export default component.exports
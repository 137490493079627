var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import ProfileUploader from './ProfileUploader.vue';
import mainStore from '../store/main';
var ProfileEditor = /** @class */ (function (_super) {
    __extends(ProfileEditor, _super);
    function ProfileEditor() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.editorData = __assign({}, mainStore.editorData);
        return _this;
    }
    ProfileEditor.prototype.updateMainStoreEditorData = function () {
        mainStore.updateEditorData(this.editorData);
    };
    ProfileEditor.prototype.previewProfile = function () {
        return __awaiter(this, void 0, void 0, function () {
            var isValid;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.$validator.validateAll()];
                    case 1:
                        isValid = _a.sent();
                        if (isValid) {
                            this.updateMainStoreEditorData();
                            this.$refs.previewForm.submit();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ProfileEditor.prototype.updateUserProfile = function () {
        return __awaiter(this, void 0, void 0, function () {
            var isValid, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.$validator.validateAll()];
                    case 1:
                        isValid = _a.sent();
                        if (!isValid) return [3 /*break*/, 5];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        this.updateMainStoreEditorData();
                        return [4 /*yield*/, mainStore.updateUserProfile()];
                    case 3:
                        _a.sent();
                        window.location.reload(true);
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        throw err_1;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ProfileEditor.prototype.onProfilePageEnableChanged = function () {
        mainStore.updateProfileUrlDisplayable(this.editorData.profilePageEnable);
        if (!this.editorData.profilePageEnable) {
            this.editorData = __assign({}, mainStore.editorData);
            this.editorData.profilePageEnable = false;
        }
    };
    Object.defineProperty(ProfileEditor.prototype, "processingApiRequest", {
        get: function () {
            return mainStore.processingApiRequest;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProfileEditor.prototype, "previewData", {
        get: function () {
            var previewData = {
                profile_username_display_option: this.editorData.profileUserNameDisplayOption,
                profile_bank_info_display_option: this.editorData.profileBankInfoDisplayOption,
                profile_title: this.editorData.profileTitle,
                twitter_username: this.editorData.twitterUsername,
                facebook_username: this.editorData.facebookUsername,
                instagram_username: this.editorData.instagramUsername,
                profile_intro_text: this.editorData.profileIntroText,
                profile_photo_data_url: mainStore.newPhotoDataUrl,
                profile_photo_changed: mainStore.profilePhotoChanged
            };
            return JSON.stringify(previewData);
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop(String)
    ], ProfileEditor.prototype, "profileUrl", void 0);
    ProfileEditor = __decorate([
        Component({
            components: {
                ProfileUploader: ProfileUploader
            }
        })
    ], ProfileEditor);
    return ProfileEditor;
}(Vue));
export default ProfileEditor;

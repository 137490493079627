<template>
  <div>
    <div class="lineBlock">
      <section class="section">
        <h2 class="lineTitle"><span class="lineTitle__txt">本譲渡債権契約の内容</span></h2>
        <div class="section__inner">
          <div class="grid--2col">
            <div class="grid--2col__item">
              <dl class="itemBlock">
                <dt class="itemBlock__title">申込者</dt>
                <dd class="itemBlock__content">
                  {{ $store.state.userName }}
                </dd>
              </dl>
            </div>
            <div class="grid--2col__item">
              <dl class="itemBlock">
                <dt class="itemBlock__title">申込日</dt>
                <dd class="itemBlock__content">
                  <time datetime="2018-08-01">
                    {{ (dealInfo.notes ? dealInfo.appliedAt : dealInfo.createdAt) | dateFormat }}
                  </time>
                </dd>
              </dl>
            </div>
            <div class="grid--2col__item">
              <dl class="itemBlock">
                <dt class="itemBlock__title">利用規約同意日</dt>
                <dd class="itemBlock__content">
                  <time datetime="2018-08-01">
                    {{ (dealInfo.notes ? dealInfo.appliedAt : dealInfo.createdAt) | dateFormat }}
                  </time>
                </dd>
              </dl>
            </div>
            <div class="grid--2col__item">
              <dl class="itemBlock">
                <dt class="itemBlock__title">申込IPアドレス</dt>
                <dd class="itemBlock__content">
                  {{ dealInfo.ipAddress || '-' }}
                </dd>
              </dl>
            </div>
            <div class="grid--2col__item">
              <dl class="itemBlock">
                <dt class="itemBlock__title">申込ステータス</dt>
                <dd class="itemBlock__content">
                  {{ dealInfo.masterDealStatus }}
                </dd>
              </dl>
            </div>
            <div class="grid--2col__item">
              <dl class="itemBlock">
                <dt class="itemBlock__title">
                  {{ invoiceInfo.masterDealStatusId === constants.MASTER_DEAL_STATUS_DECLINED ? '契約非成立日' : '譲渡契約成立日' }}
                </dt>
                <dd class="itemBlock__content">
                  <time datetime="2018-08-01"
                    v-if="invoiceInfo.masterDealStatusId === constants.MASTER_DEAL_STATUS_DECLINED && dealInfo.corporationJudgeDone !== '0001-01-01'">
                    {{ dealInfo.corporationJudgeDone | dateFormat }}
                  </time>
                  <time datetime="2018-08-01" v-else>
                    <template v-if="dealInfo.judgeDone !== '0001-01-01'">{{ dealInfo.judgeDone | dateFormat }}</template>
                    <template v-else>-</template>
                  </time>
                </dd>
              </dl>
            </div>
          </div>

          <dl class="itemBlock" v-if="dealInfo.notes">
            <dt class="itemBlock__title">
              申込時（{{ dealInfo.appliedAt | dateFormat }}）に確認いただいた注意事項
            </dt>
            <dd>
              <div v-for="note of dealInfo.notes">・{{ note }}</div>
            </dd>
          </dl>

          <hr class="line-gray">
          <dl class="itemBlock">
            <dt class="itemBlock__title">譲渡対象債権
              <i class="subTitleIcon billTable__sort billTable__sort__desc"></i>
            </dt>
            <dd class="itemBlock__content subject_transfer">
              <ul>
                <li class="subject_transfer__item">
                  <span class="label">クライアント名</span>
                  <span>
                    {{ dealInfo.clientName }}
                  </span>
                </li>
                <li class="subject_transfer__item" v-if="dataUrls.length > 0">
                  <span class="label">原契約の内容</span>
                  <span>
                    本ページ添付ファイルを参照
                  </span>
                </li>
                <li class="subject_transfer__item">
                  <span class="label">請求日</span>
                  <span>
                    <time datetime="2018-08-01">
                      {{ dealInfo.sendingDate | dateFormat }}
                    </time>
                  </span>
                </li>
                <li class="subject_transfer__item">
                  <span class="label">支払期日</span>
                  <span>
                    <time datetime="2018-08-01">
                      {{ dealInfo.dueDate | dateFormat }}
                    </time>
                  </span>
                </li>
                <li class="subject_transfer__item">
                  <span class="label">申込金額</span>
                  <span>
                    {{ dealInfo.incomingAmount | moneyDelimiter }}円
                  </span>
                </li>
                <li class="subject_transfer__item">
                  <span class="label">買取代金</span>
                  <span>
                    {{ dealInfo.offerPrice | moneyDelimiter }}円
                  </span>
                </li>
                <li class="subject_transfer__item">
                  <span class="label">手数料額</span>
                  <span>
                    {{ dealInfo.commissionPrice | moneyDelimiter }}円
                  </span>
                </li>
                <li class="subject_transfer__item">
                  <span class="label">買取代金支払日</span>
                  <span>
                    <time datetime="2018-08-01">
                      <template v-if="dealInfo.createdOutcomingDate !== '0001-01-01'">{{ dealInfo.createdOutcomingDate |
                        dateFormat }}</template>
                      <template v-else>-</template>
                    </time>
                  </span>
                </li>
              </ul>
            </dd>
          </dl>
        </div>
      </section>
    </div>
    <div class="lineBlock"
      v-if="invoiceInfo.provingMailAddress && ![constants.MASTER_DEAL_STATUS_DONE, constants.MASTER_DEAL_STATUS_DECLINED, constants.MASTER_DEAL_STATUS_DONE].includes(invoiceInfo.masterDealStatusId)">
      <div class="notice bgcf3f3f3 border-none" id="provingEmail">
        <p><b class="copyText">{{ invoiceInfo.provingMailAddress }}</b>
          宛にあらかじめ取引の確認に役立つ契約書類や、取引先ご担当者とのメールのやりとりなどを転送していただくと即日払いの審査がスムーズに進みます。</p>
        <div class="notice__btn">
          <a href="javascript:void(0)" class="localBtn en btnCopy generalBtn">
            <span class="generalBtn__bgWhite generalBtn__inner">
              <span class="copyStatus generalBtn__txt">メールアドレスをコピー</span>
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Constants from '@lib/Constants';

export default {
  name: 'transfer-credit-agreement-info',
  props: {
    'dealInfo': Object,
    'invoiceInfo': Object
  },
  data() {
    return {
      constants: Constants,
      dataUrls: document.DATAURLS || []
    };
  },
};
</script>

import Vue        from 'vue';
import Vuex       from 'vuex'
import VueRouter  from 'vue-router';
import Common     from '../../common.js';
import App        from './components/App.vue';
import router     from './router'
import store      from './stores'
import 'es6-promise/auto'
import $ from "jquery"

var moment = require('moment');

export default function page () {

  Vue.config.devtools = true;
  Vue.use(Vuex);

  Vue.filter('moneyDelimiter', function (exp) {
    var num = new String(exp).replace(/,/g, "");
    while(num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
    return num;
  });

  Vue.filter('dateFormat', function (date) {
      return moment(date).format('YYYY/MM/DD');
  });

  new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    created() {
      this.$store.commit('load', document.INVOICES);
      this.$store.commit('isBlockStatusPublic', document.IS_BLOCK_STATUS_PUBLIC);
    },
    mounted: function () {
      new Common();

       // common.jsの複数回呼び出しで重複登録されたイベントを登録し直す
       (function sideNav() {
        function onClickSideNav() {
          var w = $(window).width();
          if (w <= 767) {
            $(this)
              .toggleClass('is-active')
              .parents('.side')
              .toggleClass('is-active');
            $('.js-sideNavContent').slideToggle(500, 'easeInOutExpo');
          }
        }
        $('.js-sideNavTrigger').off('click'); // 既存のイベントを削除
        $('.js-sideNavTrigger').on('click', onClickSideNav);
      })();
      (function headerMenufunc() {
        var $target = $('.js-headerSp'),
          $trigger = $target.find('.js-headerSp__menuBtn');

        $trigger.off('click'); // 既存のイベントを削除
        $trigger.on('click', function () {
          if ($target.hasClass('is-active')) {
            $target.removeClass('is-active');
          } else {
            $target.addClass('is-active');
          }
        });
      })();
    },
  });
};

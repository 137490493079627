<template>
  <div id="corporate-container" class="section__inner">
    <p>
      法人のお申し込みの場合、<span style="font-weight:bold">代表者の写真付きの公的身分証</span>が必要です。
    </p>
    <div class="grid--2col">
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.corporateName.bool }">
          <dt class="itemBlock__title">法人名<span class="tag">必須</span></dt>
          <dd class="itemBlock__content">
            <input @blur="$emit('checkvalid', 'corporateName')" v-model="corporate.corporateName" type="text" class="input" name="corporate_name">
            <p v-if="hasBlackWordInCorporateName" class="warningTxt">{{ getBlackWordErrorWarningMessage("法人名") }}</p>
            <p v-show="!validation.corporateName.bool" class="valiedTxt">{{validation.corporateName.message}}</p>
          </dd>
        </dl>
      </div>
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.corporateNameKana.bool }">
          <dt class="itemBlock__title">法人名カナ<span class="tag">必須</span></dt>
          <dd class="itemBlock__content">
            <input @blur="$emit('checkvalid', 'corporateNameKana')" v-model="corporate.corporateNameKana" type="text" class="input" name="corporate_name_kana">
            <p v-show="!validation.corporateNameKana.bool" class="valiedTxt">{{validation.corporateNameKana.message}}</p>
          </dd>
        </dl>
      </div>
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.corporateNumber.bool }">
          <dt class="itemBlock__title">
            法人番号
            <small><a href="https://www.houjin-bangou.nta.go.jp/" target="_blank">法人番号公表サイト</a></small>
            <span class="tag">必須</span>
          </dt>
          <dd class="itemBlock__content">
            <input
              @blur="$emit('checkvalid', 'corporateNumber')"
              v-model="corporate.corporateNumber"
              type="text"
              class="input"
              name="corporate_number"
              :maxlength="this.select.corporateNumberLength">
            <p v-show="!validation.corporateNumber.bool" class="valiedTxt">{{validation.corporateNumber.message}}</p>
          </dd>
        </dl>
      </div>
    </div>
    <div class="grid--2col">
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.familyName.bool }">
          <dt class="itemBlock__title">姓<span class="tag">必須</span><span class="tag blue">代表者個人</span></dt>
          <dd class="itemBlock__content">
            <input @blur="$emit('checkvalid', 'familyName')" v-model="corporate.familyName" type="text" class="input" name="family_name">
            <p v-show="!validation.familyName.bool" class="valiedTxt">{{validation.familyName.message}}</p>
          </dd>
        </dl>
      </div>
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.firstName.bool }">
          <dt class="itemBlock__title">名<span class="tag">必須</span><span class="tag blue">代表者個人</span></dt>
          <dd class="itemBlock__content">
            <input @blur="$emit('checkvalid', 'firstName')" v-model="corporate.firstName" type="text" class="input" name="first_name">
            <p v-show="!validation.firstName.bool" class="valiedTxt">{{validation.firstName.message}}</p>
          </dd>
        </dl>
      </div>
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.familyNameKana.bool }">
          <dt class="itemBlock__title">セイ<span class="tag">必須</span><span class="tag blue">代表者個人</span></dt>
          <dd class="itemBlock__content">
            <input @blur="$emit('checkvalid', 'familyNameKana')" v-model="corporate.familyNameKana" type="text" class="input" name="family_name_kana">
            <p v-show="!validation.familyNameKana.bool" class="valiedTxt">{{validation.familyNameKana.message}}</p>
          </dd>
        </dl>
      </div>
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.firstNameKana.bool }">
          <dt class="itemBlock__title">メイ<span class="tag">必須</span><span class="tag blue">代表者個人</span></dt>
          <dd class="itemBlock__content">
            <input @blur="$emit('checkvalid', 'firstNameKana')" v-model="corporate.firstNameKana" type="text" class="input" name="first_name_kana">
            <p v-show="!validation.firstNameKana.bool" class="valiedTxt">{{validation.firstNameKana.message}}</p>
          </dd>
        </dl>
      </div>
    </div>
    <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.birthDate.bool }">
      <dt class="itemBlock__title">生年月日<span class="tag">必須</span><span class="tag blue">代表者個人</span></dt>
      <dd class="itemBlock__content">
      <div class="grid--3col birthDate">
        <div class="grid--3col__item">
          <select @change="$emit('checkvalid', 'birthDate')" @blur="$emit('checkvalid', 'birthDate')" v-model="corporate.birthDateYear" class="select" name="birth_date_year">
            <option value="" disabled selected="selected">年</option>
            <option v-for="n in 74" :value="n + (new Date().getFullYear())-90">
            {{ n + (new Date().getFullYear())-90 }}
            </option>
          </select>
        </div>
        <div class="grid--3col__item">
          <select @change="$emit('checkvalid', 'birthDate')" @blur="$emit('checkvalid', 'birthDate')" v-model="corporate.birthDateMonth" class="select" name="birth_date_month">
            <option value="" disabled selected="selected">月</option>
            <option v-for="n in 12" :value="n < 10 ? '0'+n : n">
            {{ n < 10 ? '0'+n : n }}
            </option>
          </select>
        </div>
        <div class="grid--3col__item">
          <select @change="$emit('checkvalid', 'birthDate')" @blur="$emit('checkvalid', 'birthDate')" v-model="corporate.birthDateDay" class="select" name="birth_date_day">
            <option value="" disabled selected="selected">日</option>
            <option v-for="n in 31" :value="n < 10 ? '0'+n : n">
            {{ n < 10 ? '0'+n : n }}
            </option>
          </select>
        </div>
      </div>
      </dd>
      <dd v-show="!validation.birthDate.bool" class="itemBlock__content valiedTxt">
      <div>{{validation.birthDate.message}}</div>
      </dd>
    </dl>

    <div class="grid--2col">
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.sex.bool }">
          <dt class="itemBlock__title">性別<span class="tag">必須</span><span class="tag blue">代表者個人</span></dt>
          <dd class="itemBlock__content">
          <select @change="$emit('checkvalid', 'sex')" @blur="$emit('checkvalid', 'sex')" v-model="corporate.sex" class="select" name="sex">
            <option value="" disabled selected="selected">選択してください</option>
            <option v-for="(item, index) in select.sex" v-bind:value="index">
            {{item}}
            </option>
          </select>
          <p v-show="!validation.sex.bool" class="valiedTxt">{{validation.sex.message}}</p>
          </dd>
        </dl>
      </div>
    </div>
    <div class="grid--2col">
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.postalCode.bool }">
          <dt class="itemBlock__title">郵便番号<span class="tag">必須</span><span class="tag blue">代表者個人</span></dt>
          <dd class="itemBlock__content">
            <input
              @blur="$emit('checkvalid', 'postalCode')"
              v-model="corporate.postalCode"
              type="text"
              class="input"
              name="postal_cord">
            <p v-show="!validation.postalCode.bool" class="valiedTxt">{{validation.postalCode.message}}</p>
          </dd>
        </dl>
      </div>
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.prefecturesId.bool }">
          <dt class="itemBlock__title">都道府県<span class="tag">必須</span><span class="tag blue">代表者個人</span></dt>
          <dd class="itemBlock__content">
            <select @change="$emit('checkvalid', 'prefecturesId')" @blur="$emit('checkvalid', 'prefecturesId')" v-model="corporate.prefecturesId" class="select" name="prefectures_id">
              <option value="" disabled selected="selected">選択してください</option>
              <option v-for="(item, index) in select.prefectures" v-bind:value="index">
                {{item}}
              </option>
            </select>
            <p v-show="!validation.prefecturesId.bool" class="valiedTxt">{{validation.prefecturesId.message}}</p>
          </dd>
        </dl>
      </div>
    </div>

    <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.address.bool }">
      <dt class="itemBlock__title">市区町村<span class="tag">必須</span><span class="tag blue">代表者個人</span></dt>
      <dd class="itemBlock__content">
        <input @blur="$emit('checkvalid', 'address')" v-model="corporate.address" type="text" class="input" name="address">
        <p v-show="!validation.address.bool" class="valiedTxt">{{validation.address.message}}</p>
      </dd>
    </dl>

    <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.addressKana.bool }">
      <dt class="itemBlock__title">市区町村（カナ）<span class="tag">必須</span></dt>
      <dd class="itemBlock__content">
      <input @blur="$emit('checkvalid', 'addressKana')" v-model="corporate.addressKana" type="text" class="input" name="address_kana">
      <p v-show="!validation.addressKana.bool" class="valiedTxt">{{validation.addressKana.message}}</p>
      </dd>
    </dl>

    <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.areaDetail.bool }">
      <dt class="itemBlock__title">町域・番地<span class="tag">必須</span><span class="tag blue">代表者個人</span></dt>
      <dd class="itemBlock__content">
        <input @blur="$emit('checkvalid', 'areaDetail')" v-model="corporate.areaDetail" type="text" class="input" name="area_detail">
        <p v-show="!validation.areaDetail.bool" class="valiedTxt">{{validation.areaDetail.message}}</p>
        <small>例) 桜丘町26-1</small>
      </dd>
    </dl>

    <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.areaDetailKana.bool }">
      <dt class="itemBlock__title">町域・番地カナ（カナ・数字・ハイフン）<span class="tag">必須</span></dt>
      <dd class="itemBlock__content">
      <input @blur="$emit('checkvalid', 'areaDetailKana')" v-model="corporate.areaDetailKana" type="text" class="input" name="area_detail_kana">
      <p v-show="!validation.areaDetailKana.bool" class="valiedTxt">{{validation.areaDetailKana.message}}</p>
      <small>例) サクラガオカチョウ26-1</small>
      </dd>
    </dl>

    <dl class="itemBlock">
      <dt class="itemBlock__title">建物名（マンション・アパート等にお住いの場合は必須）<span class="tag blue apartmentTag">代表者個人</span></dt>
      <dd class="itemBlock__content">
        <input v-model="corporate.apartmentName" type="text" class="input" name="apartment_name">
        <small>例) セルリアンタワーA棟C-203</small>
      </dd>
    </dl>

    <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.apartmentNameKana.bool }">
      <dt class="itemBlock__title">建物名カナ（カナ・数字）</dt>
      <dd class="itemBlock__content">
      <input @blur="$emit('checkvalid', 'apartmentNameKana')" v-model="corporate.apartmentNameKana" type="text" class="input" name="apartment_name_kana">
      <p v-show="!validation.apartmentNameKana.bool" class="valiedTxt">{{validation.apartmentNameKana.message}}</p>
      <small>例) セルリアンタワーエートウシーー203</small>
      </dd>
    </dl>

    <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.tel.bool }">
      <dt class="itemBlock__title">法人電話番号<span class="tag">必須</span></dt>
      <dd class="itemBlock__content">
        <input
          @blur="$emit('checkvalid', 'tel')"
          v-model="corporate.tel"
          type="text"
          class="input"
          name="tel"
          :maxlength="select.telLength">
        <p v-show="!validation.tel.bool" class="valiedTxt">{{validation.tel.message}}</p>
      </dd>
    </dl>

    <div class="grid-col-70percent">
      <div class="grid-col-70percent__item-70percent">
        <industry
          :validation="validation"
          v-on:checkbox="$emit('checkvalid', 'industry')"
          @checkvalid="$emit('checkvalid', 'amOtherEtcName')"
        ></industry>
      </div>
      <div class="grid-col-70percent__item-30percent">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.businessHistory.bool }">
          <dt class="itemBlock__title">業歴（年）<span class="tag">必須</span></dt>
          <dd class="itemBlock__content">
            <select @change="$emit('checkvalid', 'businessHistory')" @blur="$emit('checkvalid', 'businessHistory')" v-model="corporate.businessHistory" class="select" name="business_history">
              <option value="" selected="selected">選択してください</option>
              <option v-for="item in select.businessHistoryYearRange" :key="item" :value="'' + item + ''">
                {{item}}
              </option>
            </select>
            <p v-show="!validation.businessHistory.bool" class="valiedTxt">{{validation.businessHistory.message}}</p>
          </dd>
        </dl>
      </div>
    </div>

    <div class="grid--2col">
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.establishedYear.bool }">
          <dt class="itemBlock__title">設立年（西暦）<span class="tag">必須</span></dt>
          <dd class="itemBlock__content">
            <input
              @blur="$emit('checkvalid', 'establishedYear')"
              v-model="corporate.establishedYear"
              type="text"
              class="input"
              name="established_year"
              :placeholder="currentYear"
              :maxlength="select.yearLength">
            <p v-show="!validation.establishedYear.bool" class="valiedTxt">{{validation.establishedYear.message}}</p>
          </dd>
        </dl>
      </div>
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.employees.bool }">
          <dt class="itemBlock__title">従業員数<span class="tag">必須</span></dt>
          <dd class="itemBlock__content">
            <select @change="$emit('checkvalid', 'employees')" @blur="$emit('checkvalid', 'employees')" v-model="corporate.employees" class="select" name="employees">
              <option value="" disabled selected="selected">選択してください</option>
              <option value="1">1名</option>
              <option value="2">2〜5名</option>
              <option value="6">6〜20名</option>
              <option value="21">21〜100名</option>
              <option value="101">101名以上</option>
            </select>
            <p v-show="!validation.employees.bool" class="valiedTxt">{{validation.employees.message}}</p>
          </dd>
        </dl>
      </div>
    </div>
    <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.capital.bool }">
      <dt class="itemBlock__title">資本金<span class="tag">必須</span></dt>
      <dd class="itemBlock__content">
        <input
          @blur="$emit('checkvalid', 'capital')"
          v-model="commaCapital"
          type="text"
          class="input sales"
          name="capital"
          :maxlength="select.amountManLength">万円
        <p v-show="!validation.capital.bool" class="valiedTxt">{{validation.capital.message}}</p>
      </dd>
    </dl>
    <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.salesLastYear.bool }">
      <dt class="itemBlock__title">前年度売上<span class="tag">必須</span></dt>
      <dd class="itemBlock__content">
        <input
          @blur="$emit('checkvalid', 'salesLastYear')"
          v-model="commaSalesLastYear"
          type="text"
          class="input sales"
          name="sales_last_year"
          :maxlength="select.amountManLength">万円
        <p v-show="!validation.salesLastYear.bool" class="valiedTxt">{{validation.salesLastYear.message}}</p>
      </dd>
    </dl>
    <div class="grid--2col">
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.borrowingPresence.bool }">
          <dt class="itemBlock__title">借入有無<span class="tag">必須</span></dt>
          <dd class="itemBlock__content">
            <select @change="$emit('checkvalid', 'borrowingPresence')" @blur="$emit('checkvalid', 'borrowingPresence')" v-model="corporate.borrowingPresence" class="select" name="borrowing_presence">
              <option value="" disabled selected="selected">選択してください</option>
              <option v-for="(item, index) in select.borrowingPresence" v-bind:value="index">
                {{item}}
              </option>
            </select>
            <p v-show="!validation.borrowingPresence.bool" class="valiedTxt">{{validation.borrowingPresence.message}}</p>
          </dd>
        </dl>
      </div>
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.whichBank.bool }">
          <dt class="itemBlock__title">何行から</dt>
          <dd class="itemBlock__content">
            <select @change="$emit('checkvalid', 'whichBank')" @blur="$emit('checkvalid', 'whichBank')" v-model="corporate.whichBank" class="select" v-bind:class="{ 'is-disabled': corporate.borrowingPresence != 2 }" name="which_bank" v-bind:disabled="corporate.borrowingPresence != 2">
              <option value="" disabled selected="selected">選択してください</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
            <p v-show="!validation.whichBank.bool" class="valiedTxt">{{validation.whichBank.message}}</p>
          </dd>
        </dl>
      </div>
    </div>

    <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.debtAmount.bool }">
      <dt class="itemBlock__title">借入額合計</dt>
      <dd class="itemBlock__content">
        <input
          @blur="$emit('checkvalid', 'debtAmount')"
          v-model="commaDebtAmount"
          type="text"
          class="input sales"
          v-bind:class="{ 'is-disabled': corporate.borrowingPresence != 2 }"
          name="in_debt_amount"
          v-bind:disabled="corporate.borrowingPresence != 2"
          :maxlength="select.debtAmountLength">万円
      </dd>
      <p v-show="!validation.debtAmount.bool" class="valiedTxt">{{validation.debtAmount.message}}</p>
    </dl>

    <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.averageInvoiceAmount.bool }">
      <dt class="itemBlock__title"><span class="itemBlock__requiredTxt">請求書1枚のおおよその平均請求額</span><span class="tag">必須</span></dt>
      <dd class="itemBlock__content">
        <input
          @blur="$emit('checkvalid', 'averageInvoiceAmount')"
          v-model="commaAverageInvoiceAmount"
          type="text"
          class="input sales"
          name="average_invoice_amount"
          :maxlength="select.amountManLength">万円
        <p v-show="!validation.averageInvoiceAmount.bool" class="valiedTxt">{{validation.averageInvoiceAmount.message}}</p>
      </dd>
    </dl>

    <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.monthlySalesAverage.bool }">
      <dt class="itemBlock__title"><span class="itemBlock__requiredTxt">おおよその月間平均売上</span><span class="tag">必須</span></dt>
      <dd class="itemBlock__content">
        <input
          @blur="$emit('checkvalid', 'monthlySalesAverage')"
          v-model="commaMonthlySalesAverage"
          type="text"
          class="input sales"
          name="monthly_sales_average"
          :maxlength="select.amountManLength">万円
        <p v-show="!validation.monthlySalesAverage.bool" class="valiedTxt">{{validation.monthlySalesAverage.message}}</p>
      </dd>
    </dl>

    <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.siteUrl.bool }">
      <dt class="itemBlock__title"><span class="itemBlock__requiredTxt">サイトのURL</span></dt>
      <dd class="itemBlock__content">
        <input @blur="$emit('checkvalid', 'siteUrl')" v-model="corporate.siteUrl" type="text" class="input" name="site_url">
        <p v-show="!validation.siteUrl.bool" class="valiedTxt">{{validation.siteUrl.message}}</p>
      </dd>
    </dl>

  </div>
</template>

<script>
  import industry from './parts/industry';
  import util from '../../util';
  import GmoSignBlackListName from '@lib/validator/GmoSignBlackListName';

  export default {
    name: 'corporate',
    props: [
      'select',
      'corporate',
      'validation',
      'isAppliedAgree',
    ],
    components: {
      industry,
    },
    data() {
      return {
        currentYear: new Date().getFullYear(),
        commaDebtAmount: '',
        commaCapital: '',
        commaSalesLastYear: '',
        commaMonthlySalesAverage: '',
        commaAverageInvoiceAmount: '',

      };
    },
    computed: {
      hasBlackWordInCorporateName: function() {
        return this.isAppliedAgree && GmoSignBlackListName.check(this.corporate.corporateName);
      },
    },
    methods: {
      initData: function() {
        if (this.corporate.debtAmount) {
          this.commaDebtAmount = new Intl.NumberFormat("ja-JP").format(this.corporate.debtAmount);
        }
        if (this.corporate.capital) {
          this.commaCapital = new Intl.NumberFormat("ja-JP").format(this.corporate.capital);
        }
        if (this.corporate.salesLastYear) {
          this.commaSalesLastYear = new Intl.NumberFormat("ja-JP").format(this.corporate.salesLastYear);
        }
        if (this.corporate.monthlySalesAverage) {
          this.commaMonthlySalesAverage = new Intl.NumberFormat("ja-JP").format(this.corporate.monthlySalesAverage);
        }
        if (this.corporate.averageInvoiceAmount) {
          this.commaAverageInvoiceAmount = new Intl.NumberFormat("ja-JP").format(this.corporate.averageInvoiceAmount);
        }
      },
      setBirthDate: function() {
        if (!this.corporate.birthDateYear) {
          this.corporate.birthDateYear = (new Date().getFullYear()) - 89;
        }
        if (!this.corporate.birthDateMonth) {
          this.corporate.birthDateMonth = '01';
        }
        if (!this.corporate.birthDateDay) {
          this.corporate.birthDateDay = '01';
        }
        this.validation.birthDate.bool = true;
      },
      handleAmountWatch: function(val, watchField, commitField) {
        const _v = util.formatNumberField(util.toHalfWidth(val));
        if (!_v) {
          this.$store.commit('setFormatNumber', {
            key: commitField,
            value: '',
          });       
          return
        }
        
        this[watchField] = new Intl.NumberFormat("ja-JP").format(_v);
        this.$store.commit('setFormatNumber', {
          key: commitField,
          value: _v,
        });
      },
      getBlackWordErrorWarningMessage: function(fieldName) {
        return GmoSignBlackListName.errorMessage(fieldName);
      },
    },
    mounted: async function () {
      this.initData();
    },
    watch: {
      'corporate.corporateNumber': function(val) {
        this.$store.commit('setAsOneByteChar', {
          key: 'userInfo.corporate.corporateNumber',
          value: val,
        });

        this.$nextTick(() => {
          this.$store.commit('setFormatNumber', {
            key: 'userInfo.corporate.corporateNumber',
            value: val,
          });
        });
      },
      'corporate.tel': function(val) {
        this.$store.commit('setAsOneByteChar', {
          key: 'userInfo.corporate.tel',
          value: val,
        });

        this.$nextTick(() => {
          this.$store.commit('setFormatNumber', {
            key: 'userInfo.corporate.tel',
            value: val,
          });
        });
      },
      'corporate.areaDetail': function(val) {
        this.$store.commit('setAsOneByteChar', {
          key: 'userInfo.corporate.areaDetail',
          value: val,
        });
      },
      'corporate.apartmentName': function(val) {
        this.$store.commit('setAsOneByteChar', {
          key: 'userInfo.corporate.apartmentName',
          value: val,
        });
      },
      'corporate.establishedYear': function(val) {
        this.$store.commit('setAsOneByteChar', {
          key: 'userInfo.corporate.establishedYear',
          value: val,
        });

        this.$nextTick(() => {
          this.$store.commit('setFormatNumber', {
            key: 'userInfo.corporate.establishedYear',
            value: val,
          });
        });
      },
      'corporate.employees': function(val) {
        this.$store.commit('setAsOneByteChar', {
          key: 'userInfo.corporate.employees',
          value: val,
        });
      },
      'corporate.capital': function(val) {
        if (!val || this.commaCapital) {
          return;
        }
        this.commaCapital = new Intl.NumberFormat("ja-JP").format(val);
      },
      'commaCapital': function(val) {
        this.handleAmountWatch(val, 'commaCapital', 'userInfo.corporate.capital');
      },
      'corporate.salesLastYear': function(val) {
        if (!val || this.commaSalesLastYear) {
          return;
        }
        this.commaSalesLastYear = new Intl.NumberFormat("ja-JP").format(val);
      },
      'commaSalesLastYear': function(val) {
        this.handleAmountWatch(val, 'commaSalesLastYear', 'userInfo.corporate.salesLastYear');
      },
      'corporate.businessHistory': function(val) {
        this.$store.commit('setAsOneByteChar', {
          key: 'userInfo.corporate.businessHistory',
          value: val,
        });
      },
      'corporate.averageInvoiceAmount': function(val) {
        if (!val || this.commaAverageInvoiceAmount) {
          return;
        }
        this.commaAverageInvoiceAmount = new Intl.NumberFormat("ja-JP").format(val);
      },
      'commaAverageInvoiceAmount': function(val) {
        this.handleAmountWatch(val, 'commaAverageInvoiceAmount', 'userInfo.corporate.averageInvoiceAmount');
      },
      'corporate.monthlySalesAverage': function(val) {
        if (!val || this.commaMonthlySalesAverage) {
          return;
        }
        this.commaMonthlySalesAverage = new Intl.NumberFormat("ja-JP").format(val);
      },
      'commaMonthlySalesAverage': function(val) {
        this.handleAmountWatch(val, 'commaMonthlySalesAverage', 'userInfo.corporate.monthlySalesAverage');
      },
      'corporate.borrowingPresence': function(val) {
        if (val == '1') {
          this.corporate.whichBank = '';
          this.commaDebtAmount = '';
          this.validation.whichBank.bool = true;
          this.validation.debtAmount.bool = true;
        }
      },
      'corporate.postalCode': function(val) {
        this.corporate.postalCode = util.toHalfWidth(val)
        this.corporate.postalCode = util.formatNumberFieldLength(this.corporate.postalCode, this.select.postalCodeLength);
        this.$store.commit('setAddressByZipCode', {
          zipCode : this.corporate.postalCode,
        });
      },
      'corporate.birthDateYear': function() {
        this.setBirthDate();
      },
      'corporate.birthDateMonth': function() {
        this.setBirthDate();
      },
      'corporate.birthDateDay': function() {
        this.setBirthDate();
      },
      'corporate.debtAmount': function(val) {
        if (!val || this.commaDebtAmount) {
          return;
        }
        this.commaDebtAmount = new Intl.NumberFormat("ja-JP").format(val);
      },
      'commaDebtAmount': function(val) {
        this.handleAmountWatch(val, 'commaDebtAmount', 'userInfo.corporate.debtAmount');
      }
    },
  }
</script>

import ajax from '../../../lib/Ajax'

export default {
  updatePageTitle(context, data) {
    context.commit('updatePageTitle', data)
  },
  selectInvoice(context, id) {
    context.commit('selectInvoice', id)
  },
  apply(context, data) {
    // FIXME: 複数件の請求書の買取をまとめて行う想定で
    // 当初作られ始めたが、結局そうなることは無かったので、
    // 実態に合わせた（1件のみ扱う）実装にされるのが望ましい
    let ids = context.getters.selectedInvoces.map(iv => iv.id);
    const csrfToken = document.CSRF_TOKEN;
    const isApplyOriginalQuickPay = document.IS_APPLY_ORIGINAL_QUICK_PAY || false;
    ajax.post(
      '/apiInternal/applyQuickPay',
      {
        'ids' : ids,
        'sending_date' : data.sending_date,
        'is_apply_original_quick_pay' : isApplyOriginalQuickPay,
      },
      {
        'X-CSRF-Token': csrfToken
      }
    ).then(function (res) {
      // Check state
      if (res.data.status !== 200) {
        alert("エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください");
        console.log(res.data);
        return;
      }
      if (context.state.invoices[0].redirect_url) {
        location.href = context.state.invoices[0].redirect_url;
      } else {
        // Redirect
        data.callback();
      }
    }).catch(function(e) {
      alert("エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください");
      console.log(e);
    });
  },
}

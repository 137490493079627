/* eslint-disable no-param-reassign */

export default {
  updatePageTitle(state, data) {
    // ページタイトルセット
    state.page.title = data.title;
    state.page.complete = data.complete;
  },
  updatePayProcessName(state, data) {
    // 即日払い申込モーダル表示
    if (data === 'sameDayPayment') {
      window.dataLayer.push({ event: 'content-view', contentViewPath: '/invoices/add/application-amount' });
    // 即日払いの買取金額確認モーダル表示
    } else if (data === 'applyQuickPay') {
      window.dataLayer.push({ event: 'content-view', contentViewPath: '/invoices/add/confirmation' });
    // 即日払い申込の完了モーダル表示
    } else if (data === 'complete') {
      window.dataLayer.push({ event: 'content-view', contentViewPath: '/invoices/add/complete' });
    }
    state.payProcessName = data;
  },
  updateClientProcessName(state, data) {
    state.clientProcessName = data;
  },
  setValidation(state, data) {
    state.validationInvoice = data;
  },
  setIsAgreePersonInCharge(state, data) {
    state.invoiceInfo.isAgreePersonInCharge = data;
  },
  setIsConfirmedPersonInCharge(state, data) {
    state.invoiceInfo.isConfirmedPersonInCharge = data;
  },
  updateInitInvoiceInfo: (state, invoiceInfo) => {
    state.initInvoiceInfo.applicationAmount = invoiceInfo.applicationAmount;
    state.initInvoiceInfo.isConfirmedPersonInCharge = invoiceInfo.isConfirmedPersonInCharge;
  },
  initInvoice(state, data) {
    state.initInvoiceInfo.applicationAmount = String(data.application_amount);
    state.initInvoiceInfo.isConfirmedPersonInCharge = data.is_confirmed_person_in_charge;
    state.invoiceInfo.id = data.id;
    state.invoiceInfo.amount = String(data.amount);
    state.invoiceInfo.applicationAmount = String(data.application_amount);
    state.invoiceInfo.isConfirmedPersonInCharge = data.is_confirmed_person_in_charge;
    state.invoiceInfo.isAgreePersonInCharge = data.is_confirmed_person_in_charge;
    state.invoiceInfo.withholdingTax = data.tax_withholding;
    state.invoiceInfo.masterDealStatusId = data.master_deal_status_id;

    // deal info
    state.dealInfo.id = data.deal_info.id;
    state.dealInfo.incomingAmount = data.deal_info.incoming_amount;
    state.dealInfo.masterDealStatusId = data.deal_info.master_deal_status_id;
    state.dealInfo.originalAmount = data.deal_info.original_amount;
    state.dealInfo.tax = data.deal_info.tax;

    // 三社間
    state.invoiceInfo.notifiedFactoringOptions.maxApplicationAmountRate = data.notified_factoring.ID !== 0 ? data.notified_factoring.MaxApplicationAmountRate : 1;
    state.invoiceInfo.notifiedFactoringOptions.maxApplicationAmountPerRate = data.notified_factoring.ID !== 0 ? data.notified_factoring.MaxApplicationAmountPerRate : '100';
    state.invoiceInfo.notifiedFactoringOptions.applicationAmountLabel = data.notified_factoring.ID !== 0 ? data.notified_factoring.ApplicationAmountLabel : '';
    state.invoiceInfo.notifiedFactoringOptions.applicationAmountNote = data.notified_factoring.ID !== 0 ? data.notified_factoring.ApplicationAmountNote : '';
    state.invoiceInfo.notifiedFactoringOptions.isDisplayableApplicationAmountNote = data.notified_factoring.ID !== 0 ? data.notified_factoring.IsDisplayableApplicationAmountNote : false;
    state.invoiceInfo.applyingNotifiedFactoring = data.notified_factoring.ID !== 0;
  },
  // API通信状態をセットする
  updateProcessingApiRequest: (state, status) => {
    state.status.processingApiRequest = status;
  },
  updateIsRedirecting: (state, status) => {
    state.status.isRedirecting = status;
  },
  updateIsResponseApiError: (state, status) => {
    state.status.isResponseApiError = status;
  },
};

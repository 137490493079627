<template>
  <div class="contentBox">
    <invoice-alert
      @showModal="proposalPayment()"
      @cancelDeal="cancelDeal()"
      :screenState="screenState"
      :dealID="sharedState.dealInfo.id"
      v-if="isShowInvoiceAlert" />

    <div class="contentBox__inner">
      <section class="section">
        <p class="lineTitle">
          <span class="lineTitle__txt">
            請求書情報
          </span>
        </p>
        <file-upload
          v-if="fileUploadEnabled"
          :isDisableUpload="isDisableInput"
          @checkValidate="validate()"
          :validation="validation.invoiceInfo" />
      </section>
    </div>

    <input-invoice-info
      :invoiceInfo="sharedState.invoiceInfo"
      :validation="validation.invoiceInfo"
      :clientInfo="sharedState.clientInfo"
      :status="sharedState.status"
      :isDisableInput="isDisableInput"
      :fileUploadEnabled="fileUploadEnabled"
      @nextPage="movePage" />

    <deal-info
      v-if="hasDeal"
      :dealInfo="sharedState.dealInfo" />

    <apply-notified-factoring
      :validation="validation.invoiceInfo"
      v-if="sharedState.invoiceInfo.applyingNotifiedFactoring" />

    <invoice-evidence
      :isReadOnlyEvidenceUpload="isDisableInput || sharedState.invoiceInfo.disabledDate"
      v-if="!sharedState.invoiceInfo.applyingNotifiedFactoring" />

    <transfer-credit-agreement-info
      v-if="hasDeal"
      :dealInfo="sharedState.dealInfo"
      :invoiceInfo="sharedState.invoiceInfo" />

    <invoice-register-action
      :isShowCloseButton="isShowCloseButton"
      :isShowDeleteButton="isShowDeleteButton"
      @nextPage="movePage" />

    <pay-process-modal
      @cancelDeal="cancelDeal()"
      :validation="validation.invoiceInfo"
      ref="payProcessModal"
      @nextPage="movePage" />

    <cancel-deal-modal
      v-if="hasDeal"
      ref="cancelDealModal"
      :dealID="sharedState.dealInfo.id"
      @cancelDeal="cancelDeal()" />
  </div>
</template>

<script>
/* eslint-disable indent */
import moment from 'moment';
import pageTitle from './parts/page-title.vue';
import inputInvoiceInfo from './parts/input-invoice-info.vue';
import dealInfo from './parts/deal-info.vue';
import transferCreditAgreementInfo from './parts/transfer-credit-agreement-info.vue';
import fileUpload from './parts/file-upload.vue';
import invoiceAlert from './parts/invoice-alert.vue';
import invoiceRegisterAction from './parts/invoice-register-action.vue';
import applyNotifiedFactoring from './parts/apply-notified-factoring.vue';
import invoiceEvidence from './parts/invoice-evidence.vue';
import payProcessModal from './parts/pay-process-modal.vue';
import cancelDealModal from './parts/cancel-deal-modal.vue';
import Common from '../../../common.js';
import numberCheck from '../../../lib/validator/Number.js';
import dateCheck from '../../../lib/validator/Date.js';
import requiredCheck from '../../../lib/validator/Required.js';
import withholdingTaxCheck from '../../../lib/validator/WithholdingTax.js';
import orderOfMagnitudeDateCheck from '../../../lib/validator/OrderOfMagnitudeDate.js';
import maxLengthCheck from '../../../lib/validator/MaxLength';
import { scrollToError } from '../../../lib/scrollToError';
import * as conf from '../config';
import Constants from '@lib/Constants';
import validators from '../validators';

export default {
  name: 'app',
  data() {
    let title = '請求書詳細';
    return {
      page: {
        title,
        complete: false
      },
      invoiceInfo: this.$store.state.invoiceInfo,
      clientInfo: this.$store.state.clientInfo,
      clients: this.$store.state.clients,
      validation: {
        invoiceInfo: {
          client: {
            valid: true,
            message: ''
          },
          amount: {
            valid: true,
            message: ''
          },
          withholdingTax: {
            valid: true,
            message: ''
          },
          applicationAmount: {
            valid: true,
            message: ''
          },
          billingDate: {
            valid: true,
            message: ''
          },
          date: {
            valid: true,
            message: ''
          },
          memo: {
            valid: true,
            message: ''
          },
          isSpecified: {
            valid: true,
            message: ''
          },
          isFileUploaded: true,
          isCodeValid: {
            valid: true,
            message: ''
          },
          isIdentificationID: {
            valid: true,
            message: ''
          },
          name: {
            valid: true,
            message: ''
          },
          number: {
            valid: true,
            message: ''
          },
          address: {
            valid: true,
            message: ''
          },
          representative: {
            valid: true,
            message: ''
          },
          department: {
            valid: true,
            message: ''
          },
          person: {
            valid: true,
            message: ''
          },
          email: {
            valid: true,
            message: ''
          },
          tel: {
            valid: true,
            message: ''
          }
        },
        status: this.$store.state.status
      }
    }
  },
  computed: {
    sharedState() {
      return this.$store.state;
    },
    taxRequired() {
      return this.$store.getters.getOptionDisplaySettingByName('tax');
    },
    fileUploadEnabled() {
      return (!this.sharedState.serviceInvoice && this.sharedState.invoiceInfo.linkingServiceId === 0)
        && (!this.invoiceInfo.applyingNotifiedFactoring || this.invoiceInfo.notifiedFactoringOptions.fileUploadRequired);
    },
    screenState() {
      const masterInvoiceStatusId = this.invoiceInfo.masterInvoiceStatusId;
      const masterDealStatusId = this.invoiceInfo.masterDealStatusId;

      // 3rd invoice or invoice have not created deal or invoice was canceled
      if (this.sharedState.serviceInvoice 
       || masterDealStatusId == Constants.MASTER_DEAL_STATUS_NOT_CREATED 
       || masterDealStatusId == Constants.MASTER_DEAL_STATUS_WITHDRAWN_BY_USER) {
        return this.invoiceInfo.modifiable ? conf.SCREEN_STATE_CAN_EDIT_INVOICE : conf.SCREEN_STATE_CAN_NOT_EDIT_INVOICE;
      }
      // 即日払い申込み、審査に進んでない段階
      const MASTER_DEAL_STATUS_NOT_EDIT_INVOICE_WITH_CANCEL = [
        Constants.MASTER_DEAL_STATUS_RECEPTION, // 15
        Constants.MASTER_DEAL_STATUS_CORPORATION_CHECK // 20
      ];

      // 申請金額提案
      const MASTER_DEAL_STATUS_NOT_EDIT_INVOICE_WITH_PROPOSAL = [
        Constants.MASTER_DEAL_STATUS_BEFORE_ACCEPTING_PROPOSAL_DECREASE_APPLICATION_AMOUNT,
        Constants.MASTER_DEAL_STATUS_BEFORE_ACCEPTING_PROPOSAL_INCREASE_APPLICATION_AMOUNT
      ];

      if (masterInvoiceStatusId == Constants.MASTER_INVOICE_STATUS_WAITING_EVIDENCE
        && MASTER_DEAL_STATUS_NOT_EDIT_INVOICE_WITH_CANCEL.includes(masterDealStatusId)) {
        // 即日払い申込み中は編集できません（申込みをキャンセルする）
        return conf.SCREEN_STATE_CAN_NOT_EDIT_INVOICE_WITH_CANCEL;

      } else if ((masterInvoiceStatusId == Constants.MASTER_INVOICE_STATUS_WAITING_EVIDENCE
        && masterDealStatusId == Constants.MASTER_DEAL_STATUS_ADDITIONAL_EVIDENCE_REQUIRE)
        ||
        // 修正・証憑追加を依頼された後に、修正中
        (masterInvoiceStatusId == Constants.MASTER_INVOICE_STATUS_REGISTERED &&
           masterDealStatusId == Constants.MASTER_DEAL_STATUS_EDITED_AND_BEFORE_SUBMIT)) { // 17
        // 修正・証憑追加を依頼されています
        return conf.SCREEN_STATE_CAN_NOT_EDIT_INVOICE_WITH_ADD_EVIDENCE;

      } else if (masterInvoiceStatusId == Constants.MASTER_INVOICE_STATUS_REGISTERED
        && MASTER_DEAL_STATUS_NOT_EDIT_INVOICE_WITH_PROPOSAL.includes(masterDealStatusId)) {
        // 買取金額についての提案が届いています（提案を確認する）
        return conf.SCREEN_STATE_CAN_NOT_EDIT_INVOICE_WITH_PROPOSAL;

      } else {
        //即日払い申込み中は編集できません
        return conf.SCREEN_STATE_CAN_NOT_EDIT_INVOICE_FOR_DEALING;
      }
    },
    isDisableInput() {
      return ![conf.SCREEN_STATE_CAN_EDIT_INVOICE, conf.SCREEN_STATE_CAN_NOT_EDIT_INVOICE_WITH_ADD_EVIDENCE].includes(this.screenState);
    },
    hasDeal() {
      return this.invoiceInfo.masterDealStatusId != Constants.MASTER_DEAL_STATUS_NOT_CREATED;
    },
    isInvoiceEditScreen() {
      return this.$store.state.isInvoiceEdit;
    },
    isShowInvoiceAlert() {
      return this.isInvoiceEditScreen && this.screenState != conf.SCREEN_STATE_CAN_EDIT_INVOICE;
    },
    isShowCloseButton() {
      const DEAL_EDITABLE_STATUS = [Constants.MASTER_DEAL_STATUS_ADDITIONAL_EVIDENCE_REQUIRE ,
                                    Constants.MASTER_DEAL_STATUS_EDITED_AND_BEFORE_SUBMIT ,
                                    Constants.MASTER_DEAL_STATUS_WITHDRAWN_BY_USER]
      return this.hasDeal && !DEAL_EDITABLE_STATUS.includes(this.invoiceInfo.masterDealStatusId);
    },
    isShowDeleteButton() {
      return this.isInvoiceEditScreen && 
      (this.sharedState.serviceInvoice 
       || this.invoiceInfo.masterDealStatusId == Constants.MASTER_DEAL_STATUS_NOT_CREATED);
    }
  },
  mounted: async function () {
    const { masterInvoiceStatusId, masterDealStatusId, id } = this.sharedState.invoiceInfo;
    if (this.$route.name === 'edit' && this.sharedState.isShowApplyQuickPay && id > 0) {
      if (
        masterInvoiceStatusId == Constants.MASTER_INVOICE_STATUS_REGISTERED
        && [
          Constants.MASTER_DEAL_STATUS_BEFORE_ACCEPTING_PROPOSAL_DECREASE_APPLICATION_AMOUNT,
          Constants.MASTER_DEAL_STATUS_BEFORE_ACCEPTING_PROPOSAL_INCREASE_APPLICATION_AMOUNT
        ].includes(masterDealStatusId)
      ) {
        try {
          await this.$store.dispatch('loadApplyQuickPay');
          this.$store.commit('updatePayProcessName', 'applyQuickPay');
        } catch(e) {
          alert(e);
          return;
        }
      } else {
        this.$store.commit('updatePayProcessName', 'sameDayPayment');
      }
      if([conf.SCREEN_STATE_CAN_EDIT_INVOICE ,
          conf.SCREEN_STATE_CAN_NOT_EDIT_INVOICE ,
          conf.SCREEN_STATE_CAN_NOT_EDIT_INVOICE_WITH_PROPOSAL].includes(this.screenState)) {
            this.$refs.payProcessModal.show();
      }
    }

    new Common();
    this.$store.dispatch('updatePageTitle', this.page);
    this.$store.dispatch('updateModeAddInvoice', true);
    this.$store.commit('finishInitialization');
  },
  components: {
    'page-title': pageTitle,
    'input-invoice-info': inputInvoiceInfo,
    'deal-info': dealInfo,
    'transfer-credit-agreement-info': transferCreditAgreementInfo,
    'file-upload': fileUpload,
    'invoice-alert': invoiceAlert,
    'invoice-register-action': invoiceRegisterAction,
    'apply-notified-factoring': applyNotifiedFactoring,
    'invoice-evidence': invoiceEvidence,
    'cancel-deal-modal': cancelDealModal,
    'pay-process-modal': payProcessModal
  },
  methods: {
    validate(pathName = '') {
      let isValid = true;
      let result;
      let data = this.invoiceInfo;
      let vl = this.validation.invoiceInfo; // validation

      if (this.$store.state.invoiceInfo.isSpecified) {
        if (pathName === 'sameDayPayment') {
          this.$store.dispatch('addSelectInvoice');
        }
      }

      // 請求金額
      result = requiredCheck.check(data.amount, '請求金額');
      vl.amount.valid = result.valid;
      isValid = result.valid && isValid;
      vl.amount.message = result.message;

      if (vl.amount.valid) {
        result = numberCheck.check(data.amount);
        vl.amount.valid = result.valid;
        vl.amount.message = result.message;
        isValid = result.valid && isValid;
      }

      if (vl.amount.valid) {
        if (data.amount > 2147483647) {
          vl.amount.valid = false;
          vl.amount.message = '2147483647 以下の数値を入力して下さい。';
          isValid = isValid && vl.amount.valid;
        }
      }

      // 源泉徴収税
      if (!data.withholdingTax) {
        data.withholdingTax = "0";
      }
      result = numberCheck.check(data.withholdingTax);
      vl.withholdingTax.valid = result.valid;
      vl.withholdingTax.message = result.message;
      isValid = result.valid && isValid;

      if (vl.withholdingTax.valid) {
        if (data.withholdingTax > 2147483647) {
          vl.withholdingTax.valid = false;
          vl.withholdingTax.message = '2147483647 以下の数値を入力して下さい。';
          isValid = isValid && vl.withholdingTax.valid;
        }
      }

      // 請求金額、源泉徴収税チェック
      if (vl.amount.valid && vl.withholdingTax.valid) {
        result = withholdingTaxCheck.check(data.amount, data.withholdingTax);
        vl.withholdingTax.valid = result.valid;
        vl.withholdingTax.message = result.message;
        isValid = result.valid && isValid;
      }

      // 請求日
      result = requiredCheck.check(data.billingDate, '請求日');
      vl.billingDate.valid = result.valid;
      vl.billingDate.message = result.message;
      isValid = result.valid && isValid;

      if (vl.billingDate.valid) {
        result = dateCheck.check(data.billingDate);
        vl.billingDate.valid = result.valid;
        vl.billingDate.message = result.message;
        isValid = result.valid && isValid;
      }

      // 振込期限
      result = requiredCheck.check(data.date, '振込期限');
      vl.date.valid = result.valid;
      vl.date.message = result.message;
      isValid = result.valid && isValid;

      moment.locale('ja');
      if (vl.date.valid) {
        result = dateCheck.check(data.date, '振込期限');
        vl.date.valid = result.valid;
        vl.date.message = result.message;
        isValid = result.valid && isValid;
      }

      // 請求日、振込期限大小チェック
      if (requiredCheck.check(data.billingDate, '請求日').valid
        && vl.date.valid
        && vl.billingDate.valid
      ) {
        result = orderOfMagnitudeDateCheck.check(data.billingDate, data.date);
        vl.date.valid = result.valid;
        vl.date.message = result.message;
        isValid = result.valid && isValid;
      }

      // 請求先会社名
      result = requiredCheck.check(this.sharedState.clientInfo.name, '請求先会社名');
      vl.client.valid = result.valid;
      vl.client.message = result.message;
      isValid = result.valid && isValid;

      // 代表者名
      result = requiredCheck.check(this.sharedState.clientInfo.representative, '代表者名');
      vl.representative.valid = result.valid;
      vl.representative.message = result.message;
      isValid = result.valid && isValid;

      // 担当者名
      result = requiredCheck.check(this.sharedState.clientInfo.person, '担当者名');
      vl.person.valid = result.valid;
      vl.person.message = result.message;
      isValid = result.valid && isValid;

      // メールアドレス
      result = validators.checkClientEmail(this.sharedState.clientInfo.email, this.sharedState.userEmail);
      vl.email.valid = result.valid;
      vl.email.message = result.message;
      isValid = result.valid && isValid;

      // 電話番号
      result = validators.checkClientTel(this.sharedState.clientInfo.tel, this.sharedState.userTel);
      vl.tel.valid = result.valid;
      vl.tel.message = result.message;
      isValid = result.valid && isValid;

      // identificationID
      if (data.applyingNotifiedFactoring && data.notifiedFactoringOptions.identificationIDRequired) {
        result = requiredCheck.check(
          data.identificationID,
          this.sharedState.invoiceInfo.notifiedFactoringOptions.identificationIDLabel
        );
        vl.isIdentificationID.valid = result.valid;
        vl.isIdentificationID.message = result.message;
        isValid = result.valid && isValid;
        if (data.identificationID != "") {
          result = maxLengthCheck.check(data.identificationID, 50);
          vl.isIdentificationID.valid = result.valid;
          vl.isIdentificationID.message = result.message;
          isValid = result.valid && isValid;
        }
      }

      // 請求書メモ
      result = maxLengthCheck.check(data.memo, 1000);
      vl.memo.valid = result.valid;
      vl.memo.message = result.message;
      isValid = result.valid && isValid;

      // 請求書ファイルアップロードチェック
      if (this.fileUploadEnabled) {
        vl.isFileUploaded = !!this.$store.getters.cntUploadedFiles && vl.isFileUploaded;
        isValid = vl.isFileUploaded && isValid;
      }

      if (!data.applyingNotifiedFactoring) {
        // 請求書エビデンス
        if (!this.checkInvoiceEvidenceValidation()) {
          isValid = false;
        }
      }

      return isValid;
    },

    async proposalPayment() {
      const { masterInvoiceStatusId, masterDealStatusId } = this.$store.state.invoiceInfo;
      if (
        masterInvoiceStatusId == Constants.MASTER_INVOICE_STATUS_REGISTERED
        && [
          Constants.MASTER_DEAL_STATUS_BEFORE_ACCEPTING_PROPOSAL_DECREASE_APPLICATION_AMOUNT,
          Constants.MASTER_DEAL_STATUS_BEFORE_ACCEPTING_PROPOSAL_INCREASE_APPLICATION_AMOUNT
        ].includes(masterDealStatusId)
      ) {
        try{
          await this.$store.dispatch('loadApplyQuickPay');
        } catch(e) {
          alert(e);
          return;
        }
        this.$store.commit('updatePayProcessName', 'applyQuickPay');
        this.$refs.payProcessModal.show();
      }
    },

    cancelDeal() {
      this.$refs.cancelDealModal.show();
    },

    async movePage(nextPathName) {
      this.$store.commit('setMovingPageCalled', true);
      if (nextPathName === 'closeInvoice') {
        this.$store.commit('updateProcessingScreenMove', true);
        location.href = document.REFERER_URL;
      } else if (nextPathName === 'sameDayPayment' || nextPathName === 'saveInvoice') {
        if (this.validate(nextPathName)) {
          this.$store.dispatch('saveInvoice', { pathName: nextPathName, isUploadInvoice: true })
          .then(() => {
            if (nextPathName === 'saveInvoice') {
            this.$store.commit('updateProcessingScreenMove', true);
            this.$store.commit('updateProcessingApiRequest', false);
            location.href = document.REFERER_URL;
            } else if (nextPathName === 'sameDayPayment') {
              this.$store.commit('updateProcessingApiRequest', false);
              this.$store.commit('updatePayProcessName', 'sameDayPayment');
              this.$refs.payProcessModal.show();
            }
          })
          .catch((errMessage) => {
            alert(errMessage);
          });
        } else {
          this.$nextTick(() => {
            scrollToError('.valiedTxt');
          });
        }
      }
      else if (nextPathName === 'backToSameDayPayment') {
        this.$refs.payProcessModal.hide();
        this.$store.commit('updatePayProcessName', 'sameDayPayment');
        this.$refs.payProcessModal.show();
      } else if (nextPathName === 'home') {
        this.$store.commit('updateProcessingScreenMove', true);
        location.href = '/';
      }
    },
    isAllNfNoteRequiredChecked() {
      const requiredSections = Array.from(document.getElementsByClassName('frnc-tpl-required'));
      const result = requiredSections.every((section) => {
        const checkBox = section.querySelector('input');
        return checkBox.checked;
      });

      return result;
    },

    handleNfNoteRequiredError() {
      const requiredSections = Array.from(document.getElementsByClassName('frnc-tpl-required'));

      requiredSections.forEach((section) => {
        const checkBox = section.querySelector('input');
        const content = section.querySelector('label');
        const errMessage = section.querySelector('.err-msg');
        if (!checkBox.checked) {
          checkBox.classList.add('unchecked');
          content.classList.add('is-err');
          errMessage.style.display = 'block';
        }
      });
    },

    checkInvoiceEvidenceValidation() {
      let isValid = true;
      const listEvidenceValues = this.$store.getters['evidenceUpload/getInvoiceEvidenceUploadValues'];
      Object.keys(listEvidenceValues).forEach((key) => {
        const { selectOption, fileUploads } = listEvidenceValues[key];
        let checkEvidenceValid = true;

        // Check required evidence
        if (selectOption === null) {
          isValid = false;
          checkEvidenceValid = false;
          const validationObj = {
            key,
            evidenceType: 'invoice',
            isUploadFileError: false,
            message: 'オプションを選んでください'
          };
          this.$store.commit('evidenceUpload/setEvidenceValidation', validationObj);
        }

        if (selectOption === conf.EVIDENCE_FILEUPLOAD_OPT && fileUploads.length === 0) {
          isValid = false;
          checkEvidenceValid = false;
          const validationObj = {
            key,
            evidenceType: 'invoice',
            isUploadFileError: true,
            message: '請求書ファイルをアップロードしてください'
          };
          this.$store.commit('evidenceUpload/setEvidenceValidation', validationObj);
        }

        if (!checkEvidenceValid && listEvidenceValues[key].isCollapse) {
          this.$store.commit('evidenceUpload/toggleCollapse', {
            evidenceType: 'invoice',
            evidenceKey: key
          });
        }
      });

      return isValid;
    }
  }
};
</script>

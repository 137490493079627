var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import Free from './Free.vue';
import Regular from './Regular.vue';
import Premium from './Premium.vue';
import * as conf from '../../config';
var Plans = /** @class */ (function (_super) {
    __extends(Plans, _super);
    function Plans() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.localActivatingPlanID = _this.activatingPlanID;
        _this.localActivatingPeriodID = _this.activatingPeriodID;
        return _this;
    }
    Object.defineProperty(Plans.prototype, "conf", {
        get: function () {
            return conf;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Plans.prototype, "parentComponent", {
        get: function () {
            return this.$parent;
        },
        enumerable: false,
        configurable: true
    });
    Plans.prototype.btnTitle = function (planID, periodID) {
        if (planID === void 0) { planID = 0; }
        if (periodID === void 0) { periodID = 0; }
        // Active plan text
        if (this.checkActivePlan(planID, periodID)) {
            return this.activatingPlanBtnTitle;
        }
        return this.btnTitleByPlan(planID);
    };
    Plans.prototype.btnTitleByPlan = function (planID) {
        if (planID === void 0) { planID = 0; }
        // not active plan text
        switch (planID) {
            case conf.MASTER_MEMBERSHIP_PLAN_FREE:
                return this.freePlanTxt;
            case conf.MASTER_MEMBERSHIP_PLAN_REGULAR:
                return this.regularPlanTxt;
            case conf.MASTER_MEMBERSHIP_PLAN_PREMIUM:
                return this.premiumPlanTxt;
            default:
                return '';
        }
    };
    Plans.prototype.changeLocalPeriod = function (periodID) {
        if (periodID === void 0) { periodID = 0; }
        this.localActivatingPeriodID = periodID;
    };
    Plans.prototype.checkActivePlan = function (planID, periodID) {
        if (planID === void 0) { planID = 0; }
        if (periodID === void 0) { periodID = 0; }
        return this.activatingPlanID === planID
            && (planID === conf.MASTER_MEMBERSHIP_PLAN_FREE
                || this.activatingPeriodID === periodID);
    };
    Plans.prototype.isDisabledBtn = function (planID, periodID) {
        if (planID === void 0) { planID = 0; }
        if (periodID === void 0) { periodID = 0; }
        return this.checkActivePlan(planID, periodID);
    };
    Plans.prototype.chooseMembershipPlan = function (planID, periodID) {
        if (planID === void 0) { planID = 0; }
        if (periodID === void 0) { periodID = 0; }
        this.$emit("chooseMembershipPlan", planID, periodID);
    };
    __decorate([
        Prop({ default: true })
    ], Plans.prototype, "isAfterReleaseMembership", void 0);
    __decorate([
        Prop({ default: conf.MASTER_MEMBERSHIP_PLAN_FREE })
    ], Plans.prototype, "activatingPlanID", void 0);
    __decorate([
        Prop({ default: conf.MASTER_MEMBERSHIP_BILLING_PERIODS_YEARLY })
    ], Plans.prototype, "activatingPeriodID", void 0);
    __decorate([
        Prop({ default: false })
    ], Plans.prototype, "isOnApiRequest", void 0);
    __decorate([
        Prop({ default: false })
    ], Plans.prototype, "isFreePlanDisable", void 0);
    __decorate([
        Prop({ default: false })
    ], Plans.prototype, "isRegularPlanDisable", void 0);
    __decorate([
        Prop({ default: false })
    ], Plans.prototype, "isPremiumPlanDisable", void 0);
    __decorate([
        Prop({ default: false })
    ], Plans.prototype, "isTrialCampaign", void 0);
    __decorate([
        Prop({ default: '' })
    ], Plans.prototype, "trialCampaignTxt", void 0);
    __decorate([
        Prop({ default: '' })
    ], Plans.prototype, "trialCampaignCode", void 0);
    __decorate([
        Prop({ default: '変更する' })
    ], Plans.prototype, "freePlanTxt", void 0);
    __decorate([
        Prop({ default: '変更する' })
    ], Plans.prototype, "regularPlanTxt", void 0);
    __decorate([
        Prop({ default: '変更する' })
    ], Plans.prototype, "premiumPlanTxt", void 0);
    __decorate([
        Prop({ default: 'ご利用中' })
    ], Plans.prototype, "activatingPlanBtnTitle", void 0);
    Plans = __decorate([
        Component({
            components: {
                Free: Free,
                Regular: Regular,
                Premium: Premium,
            },
            computed: __assign({}, mapState(['payment', 'plan'])),
        })
    ], Plans);
    return Plans;
}(Vue));
export default Plans;

<template>
  <ol
    class="step"
    v-if="!this.$parent.confirmShow">
    <li class="is-finished">
      <span class="step__num">1</span>
      <span class="step__txt">パスワード設定</span>
    </li>
    <li class="is-finished">
      <span class="step__num">2</span>
      <span class="step__txt">SMS認証</span>
    </li>
    <li
      :class="{'is-current': this.currentStep == registrationStep.REGISTRATION_STEP_GENERATE_INFO,
               'is-finished': this.currentStepNumber > 1 }">
      <span class="step__num">3</span>
      <span class="step__txt">お客様情報入力</span>
    </li>
    <li
      :class="{'is-current': this.currentStep == registrationStep.REGISTRATION_STEP_BANK,
               'is-finished': this.currentStepNumber > 2 }">
      <span class="step__num">4</span>
      <span class="step__txt">口座情報登録</span>
    </li>
    <li
      :class="{'is-current': this.currentStep == registrationStep.REGISTRATION_STEP_CONFIRMATION,
               'is-finished': this.currentStepNumber > 3}">
      <span class="step__num">5</span>
      <span class="step__txt">公的身分証登録</span>
    </li>
    <li>
      <span class="step__num">6</span>
      <span class="step__txt">会員プラン支払い</span>
    </li>
  </ol>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'step',
  computed: {
    ...mapState({
      currentStep: (state) => state.status.currentStep,
      registrationStep: (state) => state.registrationStep
    }),
    incorporated() {
      return this.$store.getters.incorporated;
    },
    currentStepNumber() {
      let stepNumber = 0;

      switch (this.currentStep) {
      case this.registrationStep.REGISTRATION_STEP_GENERATE_INFO:
        stepNumber = 1;
        break;
      case this.registrationStep.REGISTRATION_STEP_BANK:
        stepNumber = 2;
        break;
      case this.registrationStep.REGISTRATION_STEP_CONFIRMATION:
        stepNumber = 3;
        break;
      default:
        break;
      }

      return stepNumber;
    }
  }
};
</script>

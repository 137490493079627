<template>
  <div class="apply-quick-pay-modal">
    <section class="section">
      <h5 class="simulation__headingNew pos__relative">即日払いの予定金額<span class="note-txt top__8">※1</span></h5>
      <div class="container">
        <appliable-invoice></appliable-invoice>
        <need-evidence-invoice></need-evidence-invoice>
        <template v-if="canApplyAny">
          <div class="agreeApplyQuickpay mt__24">
            <p class="mt__8 fz__12">
              <b>注意事項</b>  ※申込に進む前に必ず確認してください
              <template v-for="note in latestNote.note_list">
                <br>・{{ note }}
              </template>
            </p>
            <div class="checkLinkWrap js-checkBoxTrigger mt__24 mb__8">
              <label class="checkBox" :class="{ 'is-checked': notesChecked }">
                <input v-model="notesChecked" @click="clickNotesCheckbox" type="checkbox" name="agree_notes" value="1"
                  options="1" class="checkbox" id="agree_notes">
                <span class="checkBox__txt pl__24">
                  <label for="agree_notes">
                    上記の注意事項を確認しました（必須）
                  </label>
                </span>
              </label>
            </div>
            <div class="checkLinkWrap js-checkBoxTrigger">
              <label class="checkBox" :class="{ 'is-checked': termChecked }">
                <input v-model="termChecked" @click="clickAgreeCheckbox" type="checkbox" name="agree" value="1"
                  options="1" class="checkbox" id="agree">
                <span class="checkBox__txt pl__24">
                  <label for="agree">
                    上記の条件と<a href="https://freenance.net/terms-of-service" target="_blank"
                      class="underline">利用規約</a>に同意して即日払いを利用する（必須）
                  </label>
                </span>
              </label>
              <p v-show="!termValid" class="valiedTxt">
                チェックをお願いします
              </p>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="getInvoice != null && getInvoice.offer.explain != ''" class="lineBlockNew notCanApplyAny mt__48">
            <p>・{{ getInvoice.offer.explain }}</p>
            <p class="linkList__item" style="margin-top:20px;">
              <a href="https://freenance.net/faq-payment" target="_blank">即日払いに関するよくある質問はこちら</a>
            </p>
          </div>
        </template>
      </div>
    </section>
    <!-- 申請金額変更提案受諾前（引き上げ） -->
    <div v-if="isSuggestionDealIncrease" class="section__inner text-center lineBlockNew apply-proposed-amount">
      <button class="actionBtn--large pay-process-btn btnUnit mt__0"
        :class="{ 'is-disabled': !notesChecked || !termChecked || total === '-' || $store.getters.isOnAPIRequest || isBlockStatusPublic }"
        :disabled="!notesChecked || !termChecked || total === '-' || $store.getters.isOnAPIRequest || isBlockStatusPublic"
        @click="nextStep('applyOriginalQuickPay')">
        <span v-if="!isBlockStatusPublic" class="actionBtn--large__inner largeBtn price">
          <span class="fz__32">{{ getInvoice.deal.offer_price | moneyDelimiter }}</span> 円<br>
          <span>申請額で申込む</span>
        </span>
        <span v-else class="actionBtn--large__inner largeBtn">
          即日払い利用制限中
        </span>
      </button>
      <button class="actionBtn--large pay-process-btn btnUnit mt__13"
        :class="{ 'is-disabled':!notesChecked ||  !termChecked || total === '-' || $store.getters.isOnAPIRequest || isBlockStatusPublic }"
        :disabled="!notesChecked || !termChecked || total === '-' || $store.getters.isOnAPIRequest || isBlockStatusPublic"
        @click="nextStep('proposalIncreaseApplicationAmount')">
        <span v-if="!isBlockStatusPublic" class="actionBtn--large__inner largeBtn price">
          <span class="fz__32">{{ total | moneyDelimiter }}</span> 円<br>
          <span>提案額で申込む</span>
        </span>
        <span v-else class="actionBtn--large__inner largeBtn">
          即日払い利用制限中
        </span>
      </button>
    </div>
    <!-- 申請金額変更提案受諾前（引き下げ） -->
    <div v-else-if="isSuggestionDealDecrease" class="section__inner text-center lineBlockNew apply-proposed-amount">
      <button class="actionBtn--large pay-process-btn btnUnit mt__13"
        :class="{ 'is-disabled': !notesChecked || !termChecked || total === '-' || $store.getters.isOnAPIRequest || isBlockStatusPublic }"
        :disabled="!notesChecked || !termChecked || total === '-' || $store.getters.isOnAPIRequest || isBlockStatusPublic"
        @click="nextStep('proposalDecreaseApplicationAmount')">
        <span v-if="!isBlockStatusPublic" class="actionBtn--large__inner largeBtn price">
          <span class="fz__32">{{ total | moneyDelimiter }}</span> 円<br>
          <span>提案額で申込む</span>
        </span>
        <span v-else class="actionBtn--large__inner largeBtn">
          即日払い利用制限中
        </span>
      </button>
      <p v-show="this.getInvoice.deal.id > 0">
        <a @click="cancelDeal" class="simpleBtn">
          <span class="simpleBtn__inner">
            <span class="simpleBtn__txt">
              申込みをキャンセルする
            </span>
          </span>
        </a>
      </p>
      <cancel-deal-modal ref="cancelDealModal" :dealID="this.getInvoice.deal.id" />
    </div>
    <div v-else-if="canApplyAny" class="section__inner text-center lineBlockNew mt__48">
      <button class="generalBtn--large pay-process-btn btnUnit"
        :class="{ 'is-disabled': $store.getters.isOnAPIRequest }" :disabled="$store.getters.isOnAPIRequest"
        @click="backStep('backToSameDayPayment')">
        <span class="generalBtn--large__inner largeBtn">{{ $store.getters.isOnAPIRequest ? '送信中...' : '修正' }}</span>
      </button>
      <button class="actionBtn--large pay-process-btn btnUnit btnDealRegister"
        :class="{ 'is-disabled': !notesChecked || !termChecked || total === '-' || $store.getters.isOnAPIRequest || isBlockStatusPublic }"
        :disabled="!notesChecked || !termChecked || total === '-' || $store.getters.isOnAPIRequest || isBlockStatusPublic"
        @click="nextStep('complete')">
        <span v-if="!isBlockStatusPublic" class="actionBtn--large__inner largeBtn">
          {{ $store.getters.isOnAPIRequest ? '送信中...' : '即日払いを申込む' }}
        </span>
        <span v-else class="actionBtn--large__inner largeBtn">
          即日払い利用制限中
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import appliableInvoice from './appliable-invoice.vue';
import needEvidenceInvoice from './need-evidence-invoice.vue';
import cancelDealModal from './cancel-deal-modal.vue';
import Constants from '@lib/Constants';

export default {
  name: 'ApplyQuickPay',
  components: {
    'appliable-invoice': appliableInvoice,
    'need-evidence-invoice': needEvidenceInvoice,
    'cancel-deal-modal': cancelDealModal,
  },
  computed: {
    canApplyAny() {
      return this.$store.getters['applyQuickPay/canApplyAny'];
    },
    total() {
      return this.$store.getters['applyQuickPay/total'];
    },
    isBlockStatusPublic() {
      return this.$store.getters['applyQuickPay/isBlockStatusPublic'];
    },
    getInvoice() {
      return this.$store.getters['applyQuickPay/getInvoice'];
    },
    getAppliableInvoices() {
      return this.$store.getters['applyQuickPay/getAppliableInvoices'];
    },
    isSuggestionDealDecrease() {
      return this.getInvoice && this.getInvoice.masterDealStatusId == Constants.MASTER_DEAL_STATUS_BEFORE_ACCEPTING_PROPOSAL_DECREASE_APPLICATION_AMOUNT;
    },
    isSuggestionDealIncrease() {
      return this.getInvoice && this.getInvoice.masterDealStatusId == Constants.MASTER_DEAL_STATUS_BEFORE_ACCEPTING_PROPOSAL_INCREASE_APPLICATION_AMOUNT;
    },
  },
  methods: {
    clickNotesCheckbox() {
      this.notesChecked = !this.notesChecked;
    },
    clickAgreeCheckbox() {
      if (!this.termChecked) {
        this.termValid = true;
        this.termChecked = true;
      }
    },
    gotoPage(pathName) {
      this.$emit('nextPage', pathName);
    },
    nextStep(pathName) {
      if (this.total === 0 || this.sending) {
        return;
      }

      if (!this.termChecked) {
        this.termValid = false;
        return;
      }

      this.termValid = true;
      this.sending = true;
      this.$emit('nextPage', pathName, this.latestNote.version);
    },
    backStep(pathName) {
      this.$emit('nextPage', pathName);
    },
    cancelDeal() {
      if (!this.getInvoice.deal.id) return;
      this.$refs.cancelDealModal.show();
    },
  },
  data() {
    return {
      page: {
        title: '即日払い - お取引条件の確認',
        complete: false
      },
      termsConditions: this.$store.state.termsConditions,
      termChecked: false,
      termValid: true,
      sending: false,
      isConfirmedPersonInCharge: false,
      notesChecked: false,
      latestNote: document.LATEST_NOTES
    };
  },
  mounted() {
    this.$store.dispatch('updatePageTitle', this.page);
  }
}
</script>

<style lang="scss" scoped>
.apply-quick-pay-modal {
  text-align: initial !important;
}
.container {
  margin-top: 52px !important;
}
.notCanApplyAny {
  padding-top: 30px;
}
.checkBox {
  width: auto;
  height: auto;
  vertical-align: unset;
}
@media screen and (max-width: 767px) {
  .lineBlockNew {
    padding-top: 48px;
  }
  .btnDealRegister {
     margin-left: 0px;
  }
}
@media screen and (min-width: 767px) {
  .btnDealRegister {
     margin-left: 12px;
  }
}
</style>

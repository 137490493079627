var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Watch } from 'vue-property-decorator';
import VueModal from 'vue-js-modal';
Vue.use(VueModal);
var NotifiedFactoring = /** @class */ (function (_super) {
    __extends(NotifiedFactoring, _super);
    function NotifiedFactoring() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.timer = null;
        _this.applyingNfCode = _this.$store.getters.getApplyingNfCode;
        _this.canApplyNfCode = false;
        _this.nfInfo = {};
        _this.initNfCode = '';
        return _this;
    }
    NotifiedFactoring.prototype.onClientInfoChanged = function () {
        this.setInitNfCode();
    };
    NotifiedFactoring.prototype.onCodeChanged = function () {
        this.checkValidationCode(this.initNfCode);
    };
    NotifiedFactoring.prototype.created = function () {
        this.initNfCode = this.applyingNfCode;
    };
    NotifiedFactoring.prototype.show = function () {
        this.setInitNfCode();
        this.$modal.show('notified-factoring-modal');
    };
    NotifiedFactoring.prototype.hide = function () {
        this.$modal.hide('notified-factoring-modal');
    };
    NotifiedFactoring.prototype.setInitNfCode = function () {
        this.initNfCode = this.$store.getters.getClientInfo.id === this.$store.state.nfCodeAppliedClientId ? this.applyingNfCode : '';
        this.checkValidationCode(this.initNfCode);
    };
    NotifiedFactoring.prototype.applyNfCode = function () {
        this.$store.getters.getInvoiceInfo.applyingNotifiedFactoring = true;
        this.$store.getters.getValidation.isCodeValid.valid = true;
        this.$store.getters.getInvoiceInfo.codeForNotifiedFactoring = this.initNfCode;
        this.applyingNfCode = this.initNfCode;
        this.$store.commit('setNotifiedFactoringOptions', this.nfInfo);
        if (!this.$store.getters.getInvoiceInfo.notifiedFactoringOptions.fileUploadRequired) {
            this.$store.commit('clearFileUploader');
        }
        this.$store.commit('setNfApplyedClientId', this.$store.getters.getClientInfo.id);
        this.$store.commit('setIsConfirmedPersonInCharge', false);
        this.$emit('nextPage', 'notifiedFactoring');
    };
    NotifiedFactoring.prototype.cancel = function () {
        this.hide();
        this.$store.getters.getInvoiceInfo.applyingNotifiedFactoring = false;
        this.$emit('nextPage', 'notifiedFactoring');
    };
    NotifiedFactoring.prototype.checkValidationCode = function (code) {
        var found = false;
        // Check if clearing the input
        if (code === '') {
            this.$store.getters.getValidation.isCodeValid.valid = true;
            this.$store.getters.getInvoiceInfo.applyingNotifiedFactoring = false;
            this.$store.getters.getInvoiceInfo.codeForNotifiedFactoring = '';
            this.$store.getters.getInvoiceInfo.notifiedFactoringOptions.identificationIDRequired = false;
            this.$store.getters.getInvoiceInfo.notifiedFactoringOptions.fileUploadRequired = true;
            this.$store.getters.getInvoiceInfo.notifiedFactoringOptions.noteUser = '';
            this.canApplyNfCode = false;
            return;
        }
        // Check the code is valid
        if (this.$store.getters.getClientInfo.notifiedFactorings)
            this.$store.getters.getClientInfo.notifiedFactorings.forEach(function (nf) {
                if (!found && nf.code === code) {
                    found = nf;
                }
            });
        if (!found) {
            this.canApplyNfCode = false;
            this.$store.getters.getValidation.isCodeValid.valid = false;
            this.$store.getters.getInvoiceInfo.applyingNotifiedFactoring = false;
            this.$store.getters.getValidation.isCodeValid.message = '確認コードが正しくありません';
            this.$store.getters.getInvoiceInfo.codeForNotifiedFactoring = '';
            this.$store.getters.getInvoiceInfo.notifiedFactoringOptions.identificationIDRequired = false;
            this.$store.getters.getInvoiceInfo.notifiedFactoringOptions.fileUploadRequired = true;
            this.$store.getters.getInvoiceInfo.notifiedFactoringOptions.noteUser = '';
            return;
        }
        // Applying notified factoring
        this.nfInfo = found;
        this.canApplyNfCode = true;
        this.$store.getters.getValidation.isCodeValid.valid = true;
        this.$store.getters.getValidation.isCodeValid.message = '';
    };
    __decorate([
        Watch('$store.getters.getClientInfo')
    ], NotifiedFactoring.prototype, "onClientInfoChanged", null);
    __decorate([
        Watch('initNfCode')
    ], NotifiedFactoring.prototype, "onCodeChanged", null);
    NotifiedFactoring = __decorate([
        Component({
            name: 'notified-factoring-modal'
        })
    ], NotifiedFactoring);
    return NotifiedFactoring;
}(Vue));
export default NotifiedFactoring;

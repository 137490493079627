<template>
  <div class="tab-pane" :id="'tab' + no" role="tabpanel" :class="{ 'active show': isSelectedTab('tab' + no) }">
    <ClientRegisted :is-on-api-request="isOnApiRequest" :init-query="searchQuery" :clients="clients"
      @select-client="selectClient" @back="back" />
  </div>
</template>

<script>
import ClientRegisted from '../../../ClientModal/components/ClientRegisted.vue';
import ajax from '../../../../lib/Ajax';

export default {
  name: 'SpecifyRegistedClient',
  props: [
    'no',
  ],
  components: {
    ClientRegisted
  },

  computed: {
    isSelectedTab() {
      return this.$store.getters.isSelectedTab;
    },
    clients() {
      return this.$store.state.clients;
    },
    searchQuery() {
      return this.$store.state.clientInfo.name;
    },
  },

  data() {
    return {
      isOnApiRequest: false
    };
  },

  mounted() {
    // Redirect if it's not properly initialized
    if (!this.$store.getters.initialized) {
      if (location.href.includes('invoices/edit/')) {
        location.href = location.href.replace('/specifyRegistedClient', '');
        return;
      }
      location.href = '/invoices/add';
    }
  },

  methods: {
    async selectClient(result) {
      if (result.isValid) {
        const data = result.clientInfo;
        this.$store.commit('setClientInfo', data);
        data.number = String(data.number);
        if (data.notifiedFactorings != null && data.notifiedFactorings.length === 0) {
          try {
            this.isOnApiRequest = true;
            const res = await ajax.get(
              '/apiInternal/getNotifiedFactorings',
              { company_no: data.number }
            );
            data.notifiedFactorings = res.data.result.notified_factorings;
          } catch (e) {
            alert('エラーが発生しました\n大変申し訳ありませんが時間を置いてお試し下さい');
            this.isOnApiRequest = false;
            throw e;
          }
        }
        // Clear search query
        this.$store.commit('clearSearchTerm');

        this.$store.dispatch('updateInvoiceClient');
        this.$emit('nextPage', 'specifyRegistedClient');
      }
    },

    back() {
      this.$store.commit('restoreClientInfo');
      if (this.$store.state.isInvoiceEdit) {
        this.$router.push({ name: 'edit' });
        return;
      }
      this.$router.push({ name: 'add' });
    },
  }
};
</script>

import ajax from '../../../lib/Ajax';

export default {
  updatePageTitle(context, data) {
    context.commit('updatePageTitle', data);
  },
  updateProcessingApiRequest(context, data) {
    context.commit('updateProcessingApiRequest', data);
  },
  async viewApplyQuickPay({ state, commit }, invoiceId) {
    try {
      commit('updateProcessingApiRequest', true);
      const param = { invoice_id: invoiceId };
      const csrfToken = document.CSRF_TOKEN;
      const res = await ajax.post('/apiInternal/viewApplyQuickPay', param, { 'X-CSRF-Token': csrfToken });
      if (res.status !== 200 || res.data.status !== 200) {
        commit('updateProcessingApiRequest', false);
        commit('updateIsResponseApiError', true);
        throw new Error('エラーが発生しました\n大変申し訳ありませんが時間を置いてお試し下さい');
      }

      if (res.data.data && res.data.data.status !== 200) {
        commit('updateProcessingApiRequest', false);
        commit('updateIsResponseApiError', true);
        throw new Error('エラーが発生しました\n大変申し訳ありませんが時間を置いてお試し下さい');
      }

      commit('applyQuickPay/load', [res.data.result.invoice]);
      commit('applyQuickPay/isBlockStatusPublic', res.data.result.isBlockStatusPublic);
      commit('updateProcessingApiRequest', false);
    } catch (e) {
      commit('updateProcessingApiRequest', false);
      commit('updateIsResponseApiError', true);
      throw new Error('エラーが発生しました\n大変申し訳ありませんが時間を置いてお試し下さい');
    }
  },

  async saveApplicationAmount({ state, commit }) {
    try {
      commit('updateProcessingApiRequest', true);
      const { invoiceInfo, initInvoiceInfo } = state;

      if (invoiceInfo.applicationAmount == initInvoiceInfo.applicationAmount && invoiceInfo.isConfirmedPersonInCharge == initInvoiceInfo.isConfirmedPersonInCharge) {
        return true;
      }

      const invoiceId = invoiceInfo.id;
      const param = {
        id: invoiceId,
        application_amount: +invoiceInfo.applicationAmount,
        is_confirmed_person_in_charge: invoiceInfo.isConfirmedPersonInCharge
      };
      const csrfToken = document.CSRF_TOKEN;

      const res = await ajax.post('/apiInternal/saveInvoiceWithNewVersion', param, { 'X-CSRF-Token': csrfToken });
      commit('updateProcessingApiRequest', false);
      if (res.data.status !== 200) {
        if (res.data.status === 404) {
          alert('登録できる請求書は存在しません。');
        } else {
          alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください');
        }
        location.href = '/';
        return false;
      }
      commit('updateInitInvoiceInfo', invoiceInfo);

      return true;
    } catch (e) {
      commit('updateProcessingApiRequest', false);
      commit('updateIsResponseApiError', true);
      alert('エラーが発生しました\n大変申し訳ありませんが時間を置いてお試し下さい');
      return false;
    }
  },
  async cancelDeal(context, dealID) {
    const csrfToken = document.CSRF_TOKEN;
    const param = { deal_id: dealID };

    try {
      await ajax.post('/apiInternal/cancelDealByUser', param, { 'X-CSRF-Token': csrfToken });
      location.href = `/invoices`;
    } catch (e) {
      location.reload();
    }
  }
};


export default {
  toHalfWidth(val) {
    return val.replace(/[Ａ-Ｚａ-ｚ０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xFEE0));
  },
  toFullWidth(val) {
    return val.replace(/[A-Za-z0-9]/g, s => String.fromCharCode(s.charCodeAt(0) + 0xFEE0));
  },
  convertAlphabetToFullWidthUpperCase(val) {
    return this.toHalfWidth(val).toUpperCase().replace(/[A-Za-z]/g, s => String.fromCharCode(s.charCodeAt(0) + 0xFEE0));
  },
  checkValidEstablishedYear(year) {
    const yearNumber = parseInt(year);
    const currentYear = new Date().getFullYear();
    return yearNumber >= 1900 && yearNumber <= currentYear;
  },
  replaceByIndex(index, text, newValue) {
    if (index > text.length - 1) {
      return text;
    }

    return text.substring(0, index) + newValue + text.substring(index + 1)
  },
  formatNumberField(val) {
    return val.replace(/([^0-9])/g, '');
  },
  formatNumberFieldLength(val, length = 0) {
    let result = val.replace(/([^\d])/g, '');

    if (length > 0) {
      result = this.replaceByIndex(length, result, '');
    }

    return result;
  },
};

import toSeparateDateByHyphen from './converter/ToSeparateDateByHyphen.js';

var moment = require('moment');

/**
 *
 * @function 一ヶ月後の日付を返す
 * @param {string} date
 * @return {string}
 */
export default function AfterOneMonthDate (date) {
  var convertDate = toSeparateDateByHyphen.convert(date, '/');
  var afterOneWeekDate = moment(convertDate).add(1, 'months').endOf('month').format('YYYY/MM/DD');

  return afterOneWeekDate;
};

import Vue from 'vue';
import App from './components/App.vue';
import mainStore from './store/main';
export default function page() {
    var vue = new Vue({
        el: '#ListDeals',
        components: {
            App: App
        },
        created: function () {
            mainStore.loadInitialData();
        }
    });
}

export var ACTION_CHECKOUT_SESSION = 1;
export var ACTION_CANCEL_SUBSCRIPTION = 2;
export var ACTION_UPGRADE_SUBSCRIPTION = 3;
export var ACTION_DOWNGRADE_SUBSCRIPTION = 4;
export var ACTION_UPGRADE_SUBSCRIPTION_WITHOUT_CASHBACK = 5;
export var ACTION_MEMBERSHIP_TRIAL_CHECKOUT_SESSION = 8;
export var MASTER_MEMBERSHIP_PLAN_FREE = 1;
export var MASTER_MEMBERSHIP_PLAN_REGULAR = 2;
export var MASTER_MEMBERSHIP_PLAN_PREMIUM = 3;
export var MEMBERSHIP_PLAN_FREE_TITLE = 'フリー';
export var MEMBERSHIP_PLAN_REGULAR_TITLE = 'レギュラー';
export var MEMBERSHIP_PLAN_PREMIUM_TITLE = 'プレミアム';
export var MASTER_MEMBERSHIP_BILLING_PERIODS_NONE = 0;
export var MASTER_MEMBERSHIP_BILLING_PERIODS_MONTHLY = 1;
export var MASTER_MEMBERSHIP_BILLING_PERIODS_YEARLY = 2;
export var MEMBERSHIP_PERIODS_MONTHLY_TITLE = '月払い';
export var MEMBERSHIP_PERIODS_YEARLY_TITLE = '年払い';
// Plan price
export var FREE_PRICE = 0;
export var REGULAR_MONTHLY_PRICE_PER_MONTH = 590;
export var REGULAR_YEARLY_PRICE_PER_MONTH = 490;
export var PREMIUM_MONTHLY_PRICE_PER_MONTH = 1200;
export var PREMIUM_YEARLY_PRICE_PER_MONTH = 980;
export var REGULAR_MONTHLY_PRICE = 590;
export var REGULAR_YEARLY_PRICE = 5880;
export var PREMIUM_MONTHLY_PRICE = 1200;
export var PREMIUM_YEARLY_PRICE = 11760;
export var PLAN_SIGNUP_FREE_MEMBERSHIP_BTN = '無料で登録';
export var PLAN_SIGNUP_MEMBERSHIP_BTN = '30日間無料で登録';

import emailCheck from '@lib/validator/Email.js';
import phoneCheck from '@lib/validator/PhoneNumber.js';
export default {
    checkClientEmail: function (clientEmail, userEmail) {
        var result = emailCheck.check(clientEmail);
        if (result.valid && clientEmail === userEmail) {
            result.valid = false;
            result.message = '請求先担当者のメールアドレスを入力してください';
        }
        return result;
    },
    checkClientTel: function (clientTel, userTel) {
        var result = phoneCheck.check(clientTel);
        if (result.valid) {
            var telNumber = clientTel.replace(/[^0-9]/g, '');
            if (telNumber === userTel) {
                result.valid = false;
                result.message = '請求先担当者の電話番号を入力してください';
            }
        }
        return result;
    }
};

import Vue from 'vue';
import setupInputTracking from '@lib/inputTrack';
import signup from './components/signup.vue';
import store from './store';
import initBankAuto from '../../lib/BankAuto';

function setBankAuto() {
  if (document.REGISTRATION_TEMP_JSON_INFO && document.REGISTRATION_TEMP_JSON_INFO.bank) {
    const { userChoice } = document.REGISTRATION_TEMP_JSON_INFO;
    const bankInfo = userChoice === 1
      ? document.REGISTRATION_TEMP_JSON_INFO.bank.individual
      : document.REGISTRATION_TEMP_JSON_INFO.bank.corporate;
    if (window.BK && window.Bnk && bankInfo.swiftCode) {
      window.BK.bc[userChoice] = bankInfo.swiftCode; // set bank code for BankAuto when reload signUp page
      window.Bnk.c3(); // close BankAuto popup
    }
  }
}

export default function page() {
  Vue.filter('moneyLocaleString', function(num){
    return new Intl.NumberFormat("ja-JP").format(num);
  });
  // eslint-disable-next-line no-new
  new Vue({
    el: '#signup',
    store,
    beforeMount() {
      this.$store.commit('userInfoIndividualTel', document.USER_TEL);
    },
    created() {
      this.$nextTick(() => {
        setupInputTracking();
      });
      if (document.REGISTRATION_TEMP_JSON_INFO) {
        this.$store.commit('loadRegistrationTempData', document.REGISTRATION_TEMP_JSON_INFO);
      }
      if (document.REGISTRATION_TEMP_JSON_IMAGE !== '') {
        this.$store.commit('loadRegistrationTempImage', document.REGISTRATION_TEMP_JSON_IMAGE);
      }
      if (document.IS_APPLIED_AGREE != undefined) {
        this.$store.commit('setIsAppliedAgree', document.IS_APPLIED_AGREE);
      }
    },
    components: {
      'signup': signup
    },
  });
  initBankAuto();
  setBankAuto();
};

import Vue from 'vue';
import App from './components/App.vue';
import mainStore from './store/main';
export default function page() {
    var configInfo = document.DIALOG_MESSAGE_CONFIG_INFO;
    var isExclusivePage = configInfo ? configInfo.some(function (conf) { return (new RegExp(conf.link)).test(location.pathname); }) : true;
    isExclusivePage = isExclusivePage || (new RegExp(/\/(login|signup)\/.*/)).test(location.pathname);
    if (!isExclusivePage && document.getElementById('DialogMessages')) {
        var app = new Vue({
            el: '#DialogMessages',
            components: {
                App: App
            },
            created: function () {
                mainStore.loadInitialData(configInfo, document.USER_ID);
            }
        });
    }
    var modalWindow = document.getElementById('dialog-message-modal-window');
    if (modalWindow) {
        window.onclick = function (event) {
            if (event.target === modalWindow) {
                modalWindow.style.display = 'none';
            }
        };
    }
}

export default  {
  check (inputStr) {
    let valid = this.isPhoneNumber(inputStr),
        msg = (!valid) ? '携帯電話もしくは固定電話の番号を入力してください' : '',
        result = {
          valid: valid,
          message: msg
        };

    return result
  },
  isPhoneNumber (value = '') {
    let valid = false,
        results = [];

    results.push(value.match(/^[0０]{1}[0-9０-９]{1}[-ー 　]?[0-9０-９]{4}[-ー 　]?[0-9０-９]{4}$/u));
    results.push(value.match(/^[0０]{1}[0-9０-９]{2}[-ー 　]?[0-9０-９]{3}[-ー 　]?[0-9０-９]{4}$/u));
    results.push(value.match(/^[0０]{1}[0-9０-９]{3}[-ー 　]?[0-9０-９]{2}[-ー 　]?[0-9０-９]{4}$/u));
    results.push(value.match(/^[0０]{1}[5789５７８９]{1}[0０]{1}[-ー 　]?[0-9０-９]{4}[-ー 　]?[0-9０-９]{4}$/u));

    valid = results.some(function(element, index, array) {
        return element;
    });

    return valid;
  },
}

import { render, staticRenderFns } from "./ClientAdd.vue?vue&type=template&id=4d179268&scoped=true&"
import script from "./ClientAdd.vue?vue&type=script&lang=ts&"
export * from "./ClientAdd.vue?vue&type=script&lang=ts&"
import style0 from "./ClientAdd.vue?vue&type=style&index=0&id=4d179268&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d179268",
  null
  
)

component.options.__file = "ClientAdd.vue"
export default component.exports
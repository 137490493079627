var _fixHolidayObjectList = [
    { name: "年末年始", month: 1, day: 2, start: 1949, end: null },
    { name: "年末年始", month: 1, day: 3, start: 1949, end: null },
    { name: "年末年始", month: 12, day: 29, start: 1949, end: null },
    { name: "年末年始", month: 12, day: 30, start: 1949, end: null },
    { name: "年末年始", month: 12, day: 31, start: 1949, end: null },
    { name: " ", month: 5, day: 2, start: 2022, end: null },
    { name: " ", month: 5, day: 6, start: 2022, end: null },
    { name: " ", month: 6, day: 14, start: 2022, end: null },
    { name: " ", month: 7, day: 6, start: 2022, end: null },
];
export default (function (date) {
    var holidayName = "";
    if (typeof date != "object") {
        return holidayName;
    }
    try {
        date.getTime();
    }
    catch (error) {
        return holidayName;
    }
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    // 休業日の判定
    _fixHolidayObjectList.forEach(function (holiday) {
        if (holiday.end !== null) {
            if (holiday.start <= year &&
                year <= holiday.end &&
                month === holiday.month &&
                day === holiday.day) {
                holidayName = holiday.name;
            }
        }
        else {
            if (holiday.start <= year &&
                month === holiday.month &&
                day === holiday.day) {
                holidayName = holiday.name;
            }
        }
    });
    return holidayName;
});

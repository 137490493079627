var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Component, Vue, Prop } from "vue-property-decorator";
import UploadedFileList from "./UploadedFileList.vue";
import EditButton from "./EditButton.vue";
import Uploader from "./Uploader.vue";
import mainStore from "../store/main";
import FileCheck from "../../../lib/validator/File";
import Constants from "../../../lib/Constants";
import * as conf from "../../InvoiceAdd/config";
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.maxFileLength = conf.NUM_USER_EVIDENCE_UPLOADS;
        _this.displayableDefaultUploadBtn = false;
        _this.displayableFileSize = true;
        _this.inputFileInvalid = "";
        _this.nowUploading = false;
        _this.isShowUploader = false;
        _this.isEnableSubmitBtn = false;
        _this.userEvidenceFiles = [];
        _this.attachingData = {
            file: [],
            img: [],
        };
        return _this;
    }
    App.prototype.beforeMount = function () {
        this.userEvidenceFiles = this.updateUserEvidenceFiles();
        this.isShowUploader = this.userEvidenceFiles.length === 0 && this.userEvidence.status_id !== conf.MASTER_USER_EVIDENCE_STATUS_UNNECESSARY;
    };
    Object.defineProperty(App.prototype, "cntAttachedFiles", {
        get: function () {
            return this.attachingData.file.length;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(App.prototype, "isEditable", {
        get: function () {
            return (this.userEvidence.status_id === conf.MASTER_USER_EVIDENCE_STATUS_CHECKING);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(App.prototype, "isApproved", {
        get: function () {
            return (this.userEvidence.status_id === conf.MASTER_USER_EVIDENCE_STATUS_APPROVED);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(App.prototype, "existUserEvidenceFileLength", {
        get: function () {
            return this.isEditable ? this.userEvidenceFiles.length : 0;
        },
        enumerable: false,
        configurable: true
    });
    App.prototype.selectedFileLengthChange = function () {
        this.isEnableSubmitBtn =
            this.cntAttachedFiles != 0 || this.existUserEvidenceFileLength !== 0;
    };
    App.prototype.showUploader = function () {
        this.isShowUploader = true;
        this.isEnableSubmitBtn = true;
    };
    App.prototype.hideUploader = function () {
        this.isShowUploader = false;
        this.isEnableSubmitBtn = false;
        this.userEvidenceFiles = this.updateUserEvidenceFiles();
    };
    App.prototype.deleteUploadedFile = function (index) {
        this.userEvidenceFiles.splice(index, 1);
        this.isEnableSubmitBtn =
            this.cntAttachedFiles != 0 || this.existUserEvidenceFileLength !== 0;
    };
    App.prototype.updateUserEvidenceFiles = function () {
        return this.userEvidence.upload_files.slice();
    };
    App.prototype.attachFile = function (file) {
        this.attachingData.file.push(file);
        this.attachingData.img.push(window.URL.createObjectURL(file));
    };
    App.prototype.selectedFile = function (e) {
        e.preventDefault();
        this.inputFileInvalid = "";
        var files = e.target.files ? e.target.files : e.dataTransfer.files;
        if (files.length === 0) {
            return;
        }
        if (files.length + this.cntAttachedFiles + this.existUserEvidenceFileLength > this.maxFileLength) {
            this.inputFileInvalid = this.maxFileLength + "\u30D5\u30A1\u30A4\u30EB\u306E\u307F\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9\u53EF\u80FD\u3067\u3059";
            if (e.target.files) {
                e.target.files = null;
            }
            if (e.dataTransfer) {
                e.dataTransfer.files = null;
            }
            return;
        }
        // Loop all files to check type and size
        for (var _i = 0, files_1 = files; _i < files_1.length; _i++) {
            var file = files_1[_i];
            var typeCheck = FileCheck.checkFileType(file);
            if (!typeCheck.valid) {
                this.inputFileInvalid = typeCheck.message;
                return;
            }
            var sizeCheck = FileCheck.checkFileSize(file, Constants.FILE_SIZE_OF_UP_ADDITIONAL_FILE_FOR_SIGN_UP);
            if (!sizeCheck.valid) {
                this.inputFileInvalid = sizeCheck.message;
                return;
            }
            this.attachFile(file);
            this.selectedFileLengthChange();
        }
    };
    App.prototype.deleteFile = function (idx) {
        this.attachingData.img.splice(idx, 1);
        this.attachingData.file.splice(idx, 1);
        this.selectedFileLengthChange();
    };
    App.prototype.setInputFileInvalid = function (val) {
        this.inputFileInvalid = val;
    };
    App.prototype.upload = function () {
        return __awaiter(this, void 0, void 0, function () {
            var errMsg, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.nowUploading) {
                            return [2 /*return*/];
                        }
                        this.nowUploading = true;
                        errMsg = "";
                        if (this.cntAttachedFiles + this.existUserEvidenceFileLength === 0) {
                            errMsg = "ファイルを選択してください";
                        }
                        if (this.cntAttachedFiles + this.existUserEvidenceFileLength >
                            conf.NUM_USER_EVIDENCE_UPLOADS) {
                            errMsg = conf.NUM_USER_EVIDENCE_UPLOADS + "\u30D5\u30A1\u30A4\u30EB\u306E\u307F\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9\u53EF\u80FD\u3067\u3059";
                        }
                        if (errMsg) {
                            this.setInputFileInvalid(errMsg);
                            this.nowUploading = false;
                            alert(errMsg);
                            return [2 /*return*/];
                        }
                        mainStore.setExistUserEvidenceFileKeys(this.userEvidenceFiles);
                        mainStore.setCertificate(this.attachingData);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, mainStore.upload(this.userEvidence)];
                    case 2:
                        _a.sent();
                        location.href = "/account";
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.nowUploading = false;
                        throw err_1;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        Prop(Object)
    ], App.prototype, "userEvidence", void 0);
    App = __decorate([
        Component({
            components: {
                EditButton: EditButton,
                Uploader: Uploader,
                UploadedFileList: UploadedFileList,
            },
        })
    ], App);
    return App;
}(Vue));
export default App;

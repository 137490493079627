<template>
  <div id="bank-account-container" class="contentBox">
    <div class="bank-description">
      <h2 class="lineTitle">
        <span class="lineTitle__txt">フリーナンス口座とは？</span>
      </h2>
      <p class="bank-description__summary bank-description__bold">「フリーナンス口座」は、あなた専用の収納代行用口座です。</p>
      <p class="bank-description__bold">1. 屋号やペンネームでも開設可能</p>
      <p class="bank-description__detail">
        あなたの本名だけでなく、屋号やペンネームでも口座を開設することができます。
      </p>
      <p class="bank-description__bold">2. 手数料無料でメインバンクに自動振替</p>
      <p class="bank-description__detail">
        入金された報酬は自動的にメインバンクに振り替えられ、振込手数料もかかりません。
      </p>
      <p class="bank-description__bold">3. 利用するほど与信スコアがアップ</p>
      <p class="bank-description__detail">
        使えば使うほど与信スコアが上がり、即日払いの手数料が下がります。
      </p>
      <a class="bank-description__link" href="https://freenance.net/bankaccount" target="_blank">詳しくはこちら</a>
    </div>

    <mainBank
      :select="selectData"
      :bankObj="bank.individual"
      :validation="validation.individual"
      :no="''"
      :target="'individual'"
      @checkvalid="checkValidation"
      v-show="!$store.getters.incorporated">
    </mainBank>

    <modal
      name="account-name-warning-modal"
      :adaptive="true"
      :resizable="true"
      :height="'auto'"
      :width="510"
      :pivotX="0"
      classes="modal-notification-invoice-charge">
      <div class="modal-section">
        <div class="section__inner">
          <div
            class="vue-modal-close"
            @click="hideAccountNameWarning()" />
          <span class="lineTitle__txt">ご注意</span>
          <p>口座名義が法人口座ではない可能性があります。再度、ご確認ください。</p>
        </div>
      </div>
    </modal>

    <mainBank
      :select="selectData"
      :bankObj="bank.corporate"
      :validation="validation.corporate"
      :no="2"
      :target="'corporate'"
      @checkvalid="checkValidation"
      v-show="$store.getters.incorporated">
    </mainBank>

    <anshinAccount
      :individual="bank.individual"
      @checkvalid="checkValidation"
      @banktermcheck="bankTermCheck"
      :validation="validation.individual">
    </anshinAccount>

    <div class="lineBlock">
      <div class="btnUnit">
        <button
          class="generalBtn"
          tabIndex="0"
          :disabled="isDisabled"
          @click.prevent="nextStep"
          @keyup.enter="nextStep">
          <span class="generalBtn__inner">
            <span class="generalBtn__txt">
              次へ
            </span>
          </span>
        </button>
        <div>
          <button
            class="simpleBtn"
            @click.prevent="stepback">
            お客様情報入力へ戻る
          </button>
        </div>
      </div><!-- btnUnit -->
    </div><!-- lineBlock -->
  </div><!-- contentBox -->
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import VueModal from 'vue-js-modal';
import mainBank from './mainBank.vue';
import anshinAccount from './anshinAccount.vue';
import { scrollToError } from '../../../../lib/scrollToError.ts';
import checkIncorporatedAccountName from '../../../../lib/validator/IncorporatedAccountName';
import Constants from '@lib/Constants';

Vue.use(VueModal);
  export default {
    name: 'bankAccount',
    components: {
      mainBank,
      anshinAccount,
    },
    props: [
      'selectData'
    ],
    watch: {
      'bank.individual.accountNameChoice': function(val) {
        if (!val) {
          this.validation.individual.otherAccountName['bool'] = true;
        }
      },
      bank: {
        handler() {
          this.isInfoChanged = true;
        },
        deep: true
      },
      currentStep: function(val) {
        if (val === 'bank' && this.$store.getters.incorporated && this.bank.corporate.accountName) {
          this.handleAccNameWarning(this.bank.corporate.accountName, this.$store.getters.corporateNameKana);
        }
      }
    },
    computed: {
      ...mapState({
        bank: state => state.bank,
        validation: state => state.validation.bank,
        isOnAPIRequest: state => state.status.isOnAPIRequest,
        registrationStep: state => state.registrationStep,
        currentStep: state => state.status.currentStep
      }),
      incorporated() {
        return this.$store.getters.incorporated;
      },
      isDisabled() {
        return !this.bankTermAccepted || this.isOnAPIRequest;
      }
    },
    data: function() {
      return {
        bankTermAccepted : false,
        isInfoChanged: false,
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        let that = this;
        $(document).on('click', '#bnkauto .bnk_line', function(){
          that.fatchBankData();
        });
        $(document).on('blur', '#bank_name, #branch_name, #bank_name2, #branch_name2', function(){
          that.fatchBankData();
        });
        if (this.$store.getters.incorporated && this.bank.corporate.accountName) {
          this.handleAccNameWarning(this.bank.corporate.accountName, this.$store.getters.corporateNameKana);
        }
      })
    },
    methods: {
      bankTermCheck: function(bankTermAccepted) {
        this.bankTermAccepted = bankTermAccepted;
      },
      async nextStep() {
        // Remove flash message
        const flashMessage = document.getElementsByClassName('flashMessage--danger')[0];
        if (flashMessage) {
          flashMessage.parentNode.removeChild(flashMessage);
        }

        let data = '';
        let validation = '';
        let isValid = true;
        const layer02 = document.getElementsByClassName('layer02')[0];

        data = !this.$store.getters.incorporated ? this.bank.individual : this.bank.corporate;
        validation = !this.$store.getters.incorporated ? this.validation.individual : this.validation.corporate;

        this.checkValidationAll(validation);

        for (let prop in validation) {
          if (!validation[prop]['bool']) {
            isValid = false;
          }
        }

        //validation result　check
        if (!isValid) {
          const flashElement = document.createElement('div');
          flashElement.classList.add('flashMessage--danger');
          flashElement.appendChild(document.createTextNode('フォームの内容をご確認ください'));
          layer02.insertBefore(flashElement, layer02.firstChild);
          this.$nextTick(() => {
            scrollToError('#bank-account-container .valiedTxt');
          });
          return;
        }

        //add data to store
        if (!this.$store.getters.incorporated) {
          this.$store.commit('bankIndividual', data);
        } else {
          this.$store.commit('bankCorporate', data);
        }

         // Save user information
        if (this.isInfoChanged) {
          const isTempDataSaved = await this.$store.dispatch('saveRegistrationData');
          if (!isTempDataSaved) return;
          this.isInfoChanged = false;
        }
        this.$store.commit('resetBankValidMessenger');
        this.$emit('changeCurrentStep', this.registrationStep.REGISTRATION_STEP_CONFIRMATION);
      },
      stepback: function () {
        let validation = !this.$store.getters.incorporated ? this.validation.individual : this.validation.corporate;
        for (let prop in validation) {
            if ( validation.hasOwnProperty(prop) ) {
                validation[prop]['bool'] = true;
            }
        }
        $('.flashMessage--danger').remove();
        this.$store.commit('resetBankValidMessenger');
        this.$emit('scrolltop');
        this.$emit('changeCurrentStep', this.registrationStep.REGISTRATION_STEP_GENERATE_INFO);
      },
      checkValidationAll: function(validation) {
        for (let name in validation) {
          if (!this.$store.getters.incorporated && (this.bank.individual.accountNameChoice != true) && (name == 'otherAccountName')) {
            validation[name]['bool'] = true;
            continue;
          }
          this.checkValidation(name, true);
        }
      },
      checkValidation: function(name, accWarningSkipped = false) {
        let data = !this.$store.getters.incorporated ? this.bank.individual : this.bank.corporate;
        let validation = !this.$store.getters.incorporated ? this.validation.individual : this.validation.corporate;

        if (!data[name]) {
          validation[name]['bool'] = false;
          validation[name]['message'] = validation[name]['title'] + 'を入力してください';
        } else {
          let validationTarget = false;

          if (validation[name].conditions == 'checkKana') {
            validationTarget = true;
            this.itemCheckValidation(validation[name], 'カナを入力してください', this.checkKana(data[name]));
          }

          if (validation[name].conditions == 'checkNum') {
            validationTarget = true;
            this.itemCheckValidation(validation[name], '数字を入力してください', this.checkNum(data[name]));
          }

          if (validation[name].conditions == 'checkAccountName') {
            validationTarget = true;
            this.itemCheckValidation(
              validation[name],
              Constants.ACCOUNT_NAME_ERROR_MESSAGE,
              this.checkAccountName(data[name])
            );
            validation[name]['bool'] = this.checkAccountName(data[name]);
            if (this.$store.getters.incorporated && validation[name]['bool'] && !accWarningSkipped) {
              this.handleAccNameWarning(data[name], this.$store.getters.corporateNameKana);
            }
          }

          if (name  === 'accountNumber' || name === 'swiftCode') {
            if(validation['swiftCode']['bool'] === true && data['swiftCode'] !== '') {
              validationTarget = true;
              this.itemCheckValidation(
                validation['accountNumber'],
                '口座番号の形式が間違っています。',
                this.checkAccountNumber(data['swiftCode'], data['accountNumber'])
              );
            }
          }

          if (!validationTarget) {
            validation[name]['bool'] = true;
          }
        }
      },
      itemCheckValidation: function(validation, message, result) {
        validation['bool'] = result;
        if (!validation['bool']) {
          validation['message'] = message;
          validation['bool'] = false;
        } else {
          validation['bool'] = true;
        }
      },
      checkKana: function(data) {
        if (data.match(/^[ァ-ヶｦ-ﾟー\ｰ]*$/)) {
          return true;
        } else {
          return false;
        }
      },
      checkNum: function(data) {
        if (data.match(/^[0-9０-９]*$/)) {
          return true;
        } else {
          return false;
        }
      },
      checkAccountName: function(data) {
        if (data.match(Constants.ACCOUNT_NAME_REGEX)) {
          let b = divideCharacter(data);
          return b.length <= 40;
        } else {
          return false;
        }
      },
      handleAccNameWarning: function(incorporatedAccountName, companyNameKana) {
        if (!checkIncorporatedAccountName(incorporatedAccountName, companyNameKana)) this.showAccountNameWarning();
      },
      checkAccountNumber: function(swiftCode, accountNumber) {
        if (swiftCode === '9900') { //ゆうちょ銀行
          return accountNumber.match(/^[0-9０-９]{7}(1|１)$|^[0-9０-９]{1,7}$/);
        } else {
          return accountNumber.match(/^[0-9０-９]{7}$/);
        }
      },
      fatchBankData: function() {
        let data = !this.$store.getters.incorporated ? this.bank.individual : this.bank.corporate;
        if (!this.$store.getters.incorporated) {
          data.bankName = $('#bank_name').val();
          data.swiftCode = $('#bank_code').val();
          data.branchName = $('#branch_name').val();
          data.branchCord = $('#branch_code').val();
        } else {
          data.bankName = $('#bank_name2').val();
          data.swiftCode = $('#bank_code2').val();
          data.branchName = $('#branch_name2').val();
          data.branchCord = $('#branch_code2').val();
        }
      },
      hideAccountNameWarning() {
        this.$modal.hide('account-name-warning-modal');
      },
      showAccountNameWarning() {
        this.$modal.show('account-name-warning-modal');
      }
    }
  }
  function divideCharacter(rawTxt) {
    const arrReplaceData = [
      ['ガ', 'カ゛'],
      ['ギ', 'キ゛'],
      ['グ', 'ク゛'],
      ['ゲ', 'ケ゛'],
      ['ゴ', 'コ゛'],
      ['ザ', 'サ゛'],
      ['ジ', 'シ゛'],
      ['ズ', 'ス゛'],
      ['ゼ', 'セ゛'],
      ['ゾ', 'ソ゛'],
      ['ダ', 'タ゛'],
      ['ヂ', 'チ゛'],
      ['ヅ', 'ツ゛'],
      ['デ', 'テ゛'],
      ['ド', 'ト゛'],
      ['バ', 'ハ゛'],
      ['ビ', 'ヒ゛'],
      ['ブ', 'フ゛'],
      ['ベ', 'ヘ゛'],
      ['ボ', 'ホ゛'],
      ['パ', 'ハ゜'],
      ['ピ', 'ヒ゜'],
      ['プ', 'フ゜'],
      ['ペ', 'ヘ゜'],
      ['ポ', 'ホ゜'],
      ['ヴ', 'ウ゛']
    ];

    if (rawTxt.length > 0) {
      for (let i = 0; i < arrReplaceData.length; i++) {
        let replaceData = arrReplaceData[i];
        rawTxt = rawTxt.replace(new RegExp(replaceData[0], 'g'), function() {
          return replaceData[1];
        });
      }
    }
    return rawTxt;
  }
</script>

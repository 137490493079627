var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Constants from '@lib/Constants';
import EventBus from '../../../component/EventBus';
import { serviceInvoiceAddURL } from "../../../lib/Helper";
export var TYPE_TEXT = 'TEXT';
export var TYPE_BUTTON = 'BUTTON';
var InvoiceItem = /** @class */ (function (_super) {
    __extends(InvoiceItem, _super);
    function InvoiceItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(InvoiceItem.prototype, "commaAmount", {
        get: function () {
            return new Intl.NumberFormat("ja-JP").format(this.invoice.amount);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InvoiceItem.prototype, "dateToShow", {
        get: function () {
            return this.invoice.sort_date ? moment(this.invoice.sort_date).format("YYYY/MM/DD HH:mm:ss") : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InvoiceItem.prototype, "operation", {
        get: function () {
            var title = '';
            var type = TYPE_TEXT;
            var isDisabled = false;
            var masterInvoiceStatus = this.invoice.master_invoice_status_id;
            var masterDealStatus = this.invoice.master_deal_status_id;
            // 即日払いがある
            if (masterDealStatus && masterDealStatus != Constants.MASTER_DEAL_STATUS_NOT_CREATED
                && masterDealStatus != Constants.MASTER_DEAL_STATUS_EDITED_AND_BEFORE_SUBMIT) {
                title = '即日払い申請済';
                type = TYPE_BUTTON;
                isDisabled = true;
                return { title: title, type: type, isDisabled: isDisabled };
            }
            // 即日払いがない
            if (masterInvoiceStatus == Constants.MASTER_INVOICE_STATUS_NOT_IMPORTED) {
                title = '即日払い申込み';
                type = TYPE_BUTTON;
                isDisabled = false;
            }
            else if (masterInvoiceStatus == Constants.MASTER_INVOICE_STATUS_REGISTERED) {
                title = '即日払い申込み';
                type = TYPE_BUTTON;
                isDisabled = false;
                // block register deal
                if (this.isBlockStatusPublic) {
                    title = '即日払い利用制限中';
                    isDisabled = true;
                }
                // now > due_date - 1day
                var currentDate = new Date();
                var nextOneDayDate = currentDate.setDate(currentDate.getDate() + 1);
                var lastHourDueDate = new Date(this.invoice.due_date).getTime() + 60 * 60 * 24 * 1000 - 1;
                if (nextOneDayDate > lastHourDueDate) {
                    title = '処理済み・期限切れ';
                    type = TYPE_TEXT;
                }
            }
            else if (masterInvoiceStatus == Constants.MASTER_INVOICE_STATUS_DONE ||
                masterInvoiceStatus == Constants.MASTER_INVOICE_STATUS_WITHDRAWN ||
                masterInvoiceStatus == Constants.MASTER_INVOICE_STATUS_DECLINED) {
                title = '処理済み・期限切れ';
                type = TYPE_TEXT;
            }
            return { title: title, type: type, isDisabled: isDisabled };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InvoiceItem.prototype, "statusText", {
        get: function () {
            var masterInvoiceStatus = this.invoice.master_invoice_status_id;
            var masterDealStatus = this.invoice.master_deal_status_id;
            // 受付済み
            var MASTER_DEAL_STATUS_RECEPTIONING = [
                Constants.MASTER_DEAL_STATUS_RECEPTION,
                Constants.MASTER_DEAL_STATUS_PHONE_CHECKING,
                Constants.MASTER_DEAL_STATUS_CORPORATION_CHECK
            ];
            // 金額提案
            var MASTER_DEAL_STATUS_PROPOSAL = [
                Constants.MASTER_DEAL_STATUS_BEFORE_ACCEPTING_PROPOSAL_DECREASE_APPLICATION_AMOUNT,
                Constants.MASTER_DEAL_STATUS_BEFORE_ACCEPTING_PROPOSAL_INCREASE_APPLICATION_AMOUNT
            ];
            // 入金待ち
            var MASTER_DEAL_STATUS_WAITING_INCOME = [
                Constants.MASTER_DEAL_STATUS_WAITING_INCOMING,
                Constants.MASTER_DEAL_STATUS_COLLECTED_PART
            ];
            // 完了
            var MASTER_DEAL_STATUS_COMPLETE = [
                Constants.MASTER_DEAL_STATUS_UPDATING_CREDIT,
                Constants.MASTER_DEAL_STATUS_DONE,
                Constants.MASTER_DEAL_STATUS_DONE_LATE,
                Constants.MASTER_DEAL_STATUS_BAD_DEBT,
                Constants.MASTER_DEAL_STATUS_DONE_WITH_INSURANCE
            ];
            if (masterInvoiceStatus == Constants.MASTER_INVOICE_STATUS_NOT_IMPORTED) {
                return '';
            }
            if (masterInvoiceStatus == Constants.MASTER_INVOICE_STATUS_REGISTERED
                && masterDealStatus == Constants.MASTER_DEAL_STATUS_NOT_CREATED) {
                return '';
            }
            if (masterInvoiceStatus == Constants.MASTER_INVOICE_STATUS_WAITING_EVIDENCE
                && MASTER_DEAL_STATUS_RECEPTIONING.includes(masterDealStatus)) {
                return '受付済';
            }
            if (masterInvoiceStatus == Constants.MASTER_INVOICE_STATUS_WAITING_EVIDENCE
                && masterDealStatus == Constants.MASTER_DEAL_STATUS_PHONE_CHECKING) {
                return '受付済';
            }
            if (masterInvoiceStatus == Constants.MASTER_INVOICE_STATUS_WAITING_EVIDENCE
                && masterDealStatus == Constants.MASTER_DEAL_STATUS_CHECK_INVOICE_EVIDENCE) {
                return '申込内容確認中';
            }
            if ((masterInvoiceStatus == Constants.MASTER_INVOICE_STATUS_WAITING_EVIDENCE
                && masterDealStatus == Constants.MASTER_DEAL_STATUS_ADDITIONAL_EVIDENCE_REQUIRE)
                ||
                    (masterInvoiceStatus == Constants.MASTER_INVOICE_STATUS_REGISTERED
                        && masterDealStatus == Constants.MASTER_DEAL_STATUS_EDITED_AND_BEFORE_SUBMIT)) {
                return '修正・証憑追加依頼';
            }
            if (masterInvoiceStatus == Constants.MASTER_INVOICE_STATUS_WAITING_EVIDENCE
                && masterDealStatus == Constants.MASTER_DEAL_STATUS_ASSURANCE_CHECK) {
                return '保証確認中';
            }
            if (masterInvoiceStatus == Constants.MASTER_INVOICE_STATUS_WAITING_OUTCOMING
                && masterDealStatus == Constants.MASTER_DEAL_STATUS_PAYING) {
                return '振込手続き中';
            }
            if (masterInvoiceStatus == Constants.MASTER_INVOICE_STATUS_WAITING_COLLECTION
                && MASTER_DEAL_STATUS_WAITING_INCOME.includes(masterDealStatus)) {
                return '入金待ち';
            }
            if (masterInvoiceStatus == Constants.MASTER_INVOICE_STATUS_REGISTERED
                && MASTER_DEAL_STATUS_PROPOSAL.includes(masterDealStatus)) {
                return '金額提案';
            }
            if (masterInvoiceStatus == Constants.MASTER_INVOICE_STATUS_DECLINED
                && masterDealStatus == Constants.MASTER_DEAL_STATUS_DECLINED) {
                return '謝絶';
            }
            if (masterInvoiceStatus == Constants.MASTER_INVOICE_STATUS_WITHDRAWN
                && masterDealStatus == Constants.MASTER_DEAL_STATUS_WITHDRAWN_BY_USER) {
                return '取下げ';
            }
            if (masterInvoiceStatus == Constants.MASTER_INVOICE_STATUS_DONE
                && MASTER_DEAL_STATUS_COMPLETE.includes(masterDealStatus)) {
                return '完了';
            }
            return '';
        },
        enumerable: false,
        configurable: true
    });
    InvoiceItem.prototype.isText = function (type) {
        return type == TYPE_TEXT;
    };
    InvoiceItem.prototype.handleAction = function () {
        if (this.invoice.master_invoice_status_id == Constants.MASTER_INVOICE_STATUS_NOT_IMPORTED) {
            window.location.replace(serviceInvoiceAddURL(this.invoice));
            return;
        }
        EventBus.$emit('showModalInvoice', this.invoice);
    };
    __decorate([
        Prop({ required: true })
    ], InvoiceItem.prototype, "invoice", void 0);
    __decorate([
        Prop({ required: true })
    ], InvoiceItem.prototype, "isBlockStatusPublic", void 0);
    InvoiceItem = __decorate([
        Component({})
    ], InvoiceItem);
    return InvoiceItem;
}(Vue));
export default InvoiceItem;

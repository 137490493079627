// ---------------------------------
// Resolve dependencies
// ---------------------------------
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
// Vue / Vuex
import { Mutation, Action, VuexModule, getModule, Module, } from "vuex-module-decorators";
import store from "./store";
// Axios
import * as Axios from "axios";
var AXIOS = Axios.default.create({
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
    },
});
// Moment.js
import moment from "moment";
moment.locale("ja");
// Etc
import getHolidayName from "../../../lib/Holiday";
import getBusinessHolidayName from "../../../lib/BusinessHoliday";
var INTERVIEW_DURATION = 30;
var INTERVIEW_MAX_SLOT = 1;
var AVAILABLE_PADDING = 2; // n営業日後から予約可能
var EXCEPTIONAL_DATES = [];
// 休・祝日以外で電話インタビュー予約を受け付けない日付をハードコード
// DEPRECATED: はApplyQuickPayの方でもHoliday.jsを使っているので、
// 振込予定日の計算にも反映されるように、休業日はHoliday.jsの方で指定する
var Main = /** @class */ (function (_super) {
    __extends(Main, _super);
    function Main() {
        // ----------------------------------------
        // state
        // ----------------------------------------
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.statusId = 0;
        _this.originalSchedule = "";
        _this.mine = null;
        _this.all = null;
        _this.selectedDate = "";
        _this.selectedTime = "";
        _this.interviewType = 1; // 1 = tel, 2 = facetime
        _this.scheduled = false;
        _this.initialized = false;
        return _this;
    }
    Object.defineProperty(Main.prototype, "canBook", {
        // ----------------------------------------
        // getters
        // ----------------------------------------
        get: function () {
            return this.selectedDate != "" && this.selectedTime != "";
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "selectBoxOptionsDate", {
        // 日付の選択肢を返すgetter
        get: function () {
            var ahead = 21, // 3週間先まで
            d = moment(), // 本日
            result = [], padding = AVAILABLE_PADDING + 1;
            for (var i = 0; i <= ahead; i++) {
                // 祝日の名前を取得
                var holName = getHolidayName(new Date(d.format("YYYY/MM/DD")));
                if (holName == "") {
                    holName = getBusinessHolidayName(new Date(d.format("YYYY/MM/DD")));
                }
                // 休・祝日以外で電話インタビュー予約を受け付けない日付かチェック
                var exceptional = EXCEPTIONAL_DATES.find(function (ed) {
                    return d.isSame(ed, "day");
                }), isExceptionalDate = exceptional ? exceptional.length > 0 : false;
                // インタビュー予約可能日か判定
                var wd = d.isoWeekday(), disabled = holName !== "" ||
                    wd === 6 || // Saturday
                    wd === 7; // Sunday
                // 予約可能日ならば"n営業日後から予約可能"のnを消費
                if (!disabled && padding > 0) {
                    padding--;
                }
                // 結果データをプッシュ
                result.push({
                    label: d.format("YYYY/MM/DD(dd)") + (holName ? " - " + holName : ""),
                    value: d.format("YYYY/MM/DD"),
                    disabled: true, // 2023年まで休止予定
                    // disabled || // 休・祝日
                    // padding > 0 || // "n営業日後から予約可能"のnを消費しきていない
                    // isExceptionalDate, // 特別に電話インタビューの実施をさせない日付
                });
                // 日付をインクリメント
                d = d.add(1, "days");
            }
            return result;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "selectBoxOptionsTime", {
        // 時間の選択肢を返すgetter
        get: function () {
            var that = this;
            function isAppliableTime(time) {
                var cnt = 0, d = moment(that.selectedDate), exceptional = EXCEPTIONAL_DATES.find(function (ed) {
                    return d.isSame(ed, "day");
                }), isExceptionalDate = exceptional ? exceptional.length > 0 : false;
                if (isExceptionalDate) {
                    return false;
                }
                if (!that.all) {
                    return true;
                }
                that.all.forEach(function (el) {
                    var scheduled_str = el.scheduled.replace(/-/g, "/"), scheduled = new Date(scheduled_str).getTime(), candidate = new Date(that.selectedDate + " " + time).getTime();
                    if (scheduled == candidate) {
                        cnt++;
                    }
                });
                return cnt < INTERVIEW_MAX_SLOT;
            }
            if (!this.selectedDate) {
                return [];
            }
            var end = (18 - 11) * (60 / INTERVIEW_DURATION), // 11AM - 18PM
            d = moment("2019/04/01 11:00:00"), // ※'2019/04/01'の日付に意味はない
            result = [];
            for (var i = 0; i < end; i++) {
                result.push({
                    value: d.format("HH:mm"),
                    disabled: !isAppliableTime(d.format("HH:mm")),
                    label: d.format("HH:mm") +
                        " ~ " +
                        d.add(INTERVIEW_DURATION, "minutes").format("HH:mm"),
                });
            }
            return result;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "approved", {
        get: function () {
            return (this.userEvidence && this.userEvidence.master_user_evidence_status_id == 2);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "rejected", {
        get: function () {
            return (this.userEvidence &&
                this.userEvidence.master_user_evidence_status_id == 3 &&
                !this.scheduled);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "permanentRejected", {
        get: function () {
            return (this.userEvidence && this.userEvidence.master_user_evidence_status_id == 5);
        },
        enumerable: false,
        configurable: true
    });
    // ----------------------------------------
    // mutation
    // ----------------------------------------
    Main.prototype.load = function (userEvidence) {
        this.userEvidence = userEvidence;
    };
    Main.prototype.setSchedule = function (res) {
        this.initialized = true;
        this.originalSchedule = res.data.result.mine.scheduled;
        this.mine = res.data.result.mine;
        this.all = res.data.result.all;
        if (this.mine.scheduled !== "") {
            this.scheduled = true;
            var d = moment(this.mine.scheduled);
            this.selectedDate = d.format("YYYY/MM/DD");
            this.selectedTime = d.format("HH:mm");
            this.interviewType = this.mine.by_facetime ? 2 : 1;
        }
    };
    Main.prototype.setSelectedDate = function (date) {
        this.selectedDate = date;
        this.selectedTime = "";
    };
    Main.prototype.setSelectedTime = function (time) {
        this.selectedTime = time;
    };
    Main.prototype.setInterviewType = function (val) {
        this.interviewType = val;
    };
    // ----------------------------------------
    // actions
    // ----------------------------------------
    Main.prototype.loadSchedule = function () {
        var _this = this;
        AXIOS.get("/apiInternal/getTelInterviewSchedules", {})
            .then(function (res) {
            _this.setSchedule(res);
        })
            .catch(function (error) {
            alert("エラーが発生しました、申し訳ありませんが時間をあけてお試しください");
            console.log(error);
        });
    };
    Main.prototype.book = function (csrfToken) {
        AXIOS.post("/apiInternal/saveTelInterview", {
            by_facetime: this.interviewType == 2,
            selected_datetime: this.selectedDate + " " + this.selectedTime + ":00",
        }, {
            headers: { "X-CSRF-Token": csrfToken },
        })
            .then(function (res) {
            location.reload();
        })
            .catch(function (error) {
            alert("エラーが発生しました、申し訳ありませんが時間をあけてお試しください");
            console.log(error);
        });
    };
    __decorate([
        Mutation
    ], Main.prototype, "load", null);
    __decorate([
        Mutation
    ], Main.prototype, "setSchedule", null);
    __decorate([
        Mutation
    ], Main.prototype, "setSelectedDate", null);
    __decorate([
        Mutation
    ], Main.prototype, "setSelectedTime", null);
    __decorate([
        Mutation
    ], Main.prototype, "setInterviewType", null);
    __decorate([
        Action({})
    ], Main.prototype, "loadSchedule", null);
    __decorate([
        Action({})
    ], Main.prototype, "book", null);
    Main = __decorate([
        Module({ dynamic: true, store: store, name: "main", namespaced: true })
    ], Main);
    return Main;
}(VuexModule));
export var mainModule = getModule(Main);

import getHolidayName from '../../../lib/Holiday';
import getBusinessHolidayName from '../../../lib/BusinessHoliday';
var moment = require('moment');
var BANK_FEE = 350;

export default {
  // 選択した請求書を取得
  selectedInvoces: state => {
    return state.invoices.filter(invoice => invoice._selected);
  },
  // 選択した請求書の取得の数
  selectedInvocesCount: (state, getters) => {
    return getters.selectedInvoces.length;
  },
  // 買取実行可能な請求書を取得
  getAppliableInvoices: (state) => {
    let invoices = state.invoices.filter((inv, index, array) => {
      return inv.master_invoice_status_id == state.constrains.MASTER_INVOICE_STATUS_REGISTERED
          || inv.master_invoice_status_id == state.constrains.MASTER_INVOICE_STATUS_WITHDRAWN;
    });
    return invoices;
  },
  // エビデンスが必要な請求書を取得
  getNeedEvidencialInvoices: (state) => {
    let invoices = state.invoices.filter((inv, index, array) => {
      return inv.need_evidence;
    });
    return invoices;
  },
  // 買取請求書の合計金額
  total: (state, getters) => {
    let invoices = getters.getAppliableInvoices;
    let total = 0;
    for (let i = 0; i < invoices.length; i++) {
      if (invoices[i]._selected) {
        total += (invoices[i].offer.offer_price || 0);
      }
    }
    return total === 0 ? '-' : total;
  },
  // 買取可能な請求書がある場合true
  canApplyAny: (state, getters) => {
    let invoices = getters.getAppliableInvoices;
    let can = false;
    for (let i = 0; i < invoices.length; i++) {
      if (invoices[i].offer.appliable) {
        can = true;
        break;
      }
    }
    return can;
  },
  outcomingDate: (state) => {
    moment.locale('ja');

    // 土日祝日をスキップして平日のmomentを返却する
    function retUsualDay(dt) {
      let sd = dt;
      // formatして元の日付を保存. ※ momentにすると参照渡しになったため
      let sdFormat = dt.format('YYYY/MM/DD');

      if (dt.format('dddd') === '土曜日') {
        // if Saturday tomorrow, transfer 2 days later
        dt.add(2, 'days');
      } else if (dt.format('dddd') === '日曜日') {
        // if Sunday tomorrow, transfer 1 days later
        dt.add(1, 'days');
      } else if (getHolidayName(new Date(dt.format('YYYY/MM/DD'))) !== '') {
        // if Holiday tomorrow, transfer 1 days later
        dt.add(1, 'days');
      } else if (getBusinessHolidayName(new Date(dt.format('YYYY/MM/DD'))) !== '') {
        // if BusinessHoliday tomorrow, transfer 1 days later
        dt.add(1, 'days');
      }

      if (dt.format('YYYY/MM/DD') !== sdFormat) {
        // スキップされていたら再度土日祝日をチェックしてスキップする
        return retUsualDay(dt);
      } else if (parseInt(moment().format('k'), 10) < 10) {
        // スキップされずに当日10時以前なら当日
        // if before 10AM, transfer today
        sd = moment();
      }

      return sd;
    }

    // 翌日の土日祝かチェックしてスキップして平日にする
    // debug
    //let sdTomorrow = moment('20191116', 'YYYYMMDD');
    let sdTomorrow = moment().add(1, 'days');
    let ret = retUsualDay(sdTomorrow);

    return ret.format('YYYY-MM-DD');
  },
  isBlockStatusPublic: (state) => {
    return state.isBlockStatusPublic;
  },
}

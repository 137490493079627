<template>
  <div class="lineBlock">
    <div class="section">
      <div class="section__inner">
        <h2 class="lineTitle">
          <span class="lineTitle__txt">フリーナンス口座の名義登録</span>
        </h2>
        <dl
          class="itemBlock"
          v-if="$store.getters.incorporated">
          <dt class="itemBlock__title">フリーナンス口座名義（メインバンクの口座名義と同じになります）</dt>
          <dd class="itemBlock__content">
            <p class="sectionTitle">
              {{ anshinAccountName }}
            </p>
          </dd>
        </dl>
      </div>

      <div
        class="section__inner"
        :class="$store.getters.incorporated ? 'transfer-account-registration__corporation' : 'transfer-account-registration__individual'">
        <dl
          class="itemBlock"
          v-if="!$store.getters.incorporated"
          :class="{ 'is-error': !validation.otherAccountName.bool }">
          <dt class="itemBlock__title">
            フリーナンス口座名義<br>屋号やペンネームでも口座を開設できます。※個人事業主開業届の写しの提出が必要です
          </dt>
          <dd class="itemBlock__content">
            <input
              @blur="$emit('checkvalid', 'otherAccountName')"
              :value="individual.otherAccountName"
              @change="convertOtherAccName($event.target.value)"
              type="text"
              class="input"
              name="other_account_name">
            <p
              v-show="!validation.otherAccountName.bool"
              class="valiedTxt">
              {{ validation.otherAccountName.message }}
            </p>
          </dd>
          <dd
            class="itemBlock__content"
            v-show="isNewAccountName">
            <bankCertUploader
              :sectionType="'individual'"
              :fieldName="'certificate'">
              <p>
                メインバンクの口座名義とフリーナンス口座名義が異なるため、この口座がお客様の口座であることを証明する追加資料が必要です。<br>
                なお、この操作は後から行うこともできます。<br>
                （ファイル形式：.jpg, .jpeg, .png, .gif, .pdf / ファイルサイズ：10MB以下）
              </p>
            </bankCertUploader>
          </dd>
        </dl>

        <dl class="itemBlock">
          <label
            for="checkbox"
            class="checkBox--hasLink"
            :class="{'is-checked': bankTermAccepted}">
            <input
              id="checkbox"
              name="bankTermAccepted"
              type="checkbox"
              v-model="bankTermAccepted">
            <span class="checkBox__txt">お客様に関する情報をＧＭＯあおぞらネット銀行に提供すること及びＧＭＯあおぞらネット銀行が利用する場合があることに同意します。<br>詳しくは「<a href="https://freenance.net/terms-of-service" target="_blank">利用者情報のお取り扱いについて</a>」の１（１）及び２⑨をご覧ください。
            </span>
          </label>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import bankCertUploader from './bankCertUploader';
import { halfToFullKana, smallToBigKana, fullToHalfKana } from '../../../../lib/Convert';

export default {
  name: 'anshinAccount',
  components: {
    bankCertUploader
  },
  data() {
    return {
      bankTermAccepted: false
    };
  },
  props: [
    'individual',
    'validation'
  ],
  computed: {
    anshinAccountName() {
      return this.$store.getters.anshinAccountName;
    },
    isNewAccountName() {
      return this.isNewAccountNameCheck(this.individual.accountName, this.individual.otherAccountName);
    }
  },
  methods: {
    convertKana(value) {
      return fullToHalfKana(smallToBigKana(halfToFullKana(value)));
    },
    isNewAccountNameCheck(accountName, otherAccountName) {
      return !(this.convertKana(accountName) === this.convertKana(otherAccountName));
    },
    convertOtherAccName(otherAccName) {
      const convertedName = this.convertKana(otherAccName);
      this.$store.commit('bankOtherAccountName', convertedName);
    }
  },
  watch: {
    bankTermAccepted(value) {
      this.$emit('banktermcheck', value)
    },
    'individual.accountName': function (accountName) {
      if (accountName) {
        this.individual.accountNameChoice = this.isNewAccountNameCheck(accountName, this.individual.otherAccountName);
      }
    },
    'individual.otherAccountName': function (otherAccountName) {
      if (otherAccountName) {
        this.individual.accountNameChoice = this.isNewAccountNameCheck(this.individual.accountName, otherAccountName);
      }
    }
  }
};
</script>

import Vue from 'vue';
import VueRouter from 'vue-router';
import Anshin from './components/Anshin.vue';
import Root from './components/Root.vue';
import AccountName from './components/AccountName.vue';
import AddAdditionalFile from './components/AddAdditionalFile.vue';
import mainStoreModule from './store/main';
Vue.use(VueRouter);
export default function page() {
    if (document.getElementById('uploadAdditionalFiles')) {
        var waitingAnshinFile_1 = document.WAITING_ANSHIN_FILE;
        var waitingAccountNameFile_1 = document.WAITING_ACCOUNT_NAME_FILE;
        var routes = [
            { path: '/', component: Root }
        ];
        if (waitingAnshinFile_1) {
            routes.push({ path: '/anshin', component: Anshin });
        }
        if (waitingAccountNameFile_1) {
            routes.push({ path: '/account-name', component: AccountName });
        }
        var router = new VueRouter({ routes: routes });
        router.afterEach(function (to, from) {
            if (from.matched.length) {
                Vue.nextTick(function () {
                    window.dataLayer.push({ event: 'content-view' });
                });
            }
        });
        var app = new Vue({
            el: '#uploadAdditionalFiles',
            router: router,
            computed: {
                sectionTitle: function () { return mainStoreModule.sectionTitle; }
            },
            created: function () {
                mainStoreModule.loadInitialData({
                    accountNameMainBank: document.ACCOUNT_NAME_MAIN_BANK,
                    accountNameAltered: document.ACCOUNT_NAME_ALTERED,
                    defaultSectionTitle: document.DEFAULT_TITLE,
                    userAdditionalFileBtnDisplayable: document.USER_ADDITIONAL_FILE_BTN_DISPLAYABLE,
                    waitingAccountNameFile: waitingAccountNameFile_1,
                    waitingAnshinFile: waitingAnshinFile_1
                });
            }
        });
    }
    else if (document.getElementById('addAdditionalFiles')) {
        var app = new Vue({
            el: '#addAdditionalFiles',
            components: {
                AddAdditionalFile: AddAdditionalFile
            },
            created: function () {
                mainStoreModule.setMaxNumAdditionalFileUploads(document.MAX_NUM_ADDITIONAL_FILE_UPLOADS);
            }
        });
    }
}

<template>
  <div class="section">
    <div id="certificateUpload" class="upload" v-bind:class="{ 'is-error': false }">
      <div @dragleave.prevent @dragover.prevent @drop.prevent="attach" class="upload__content">
        <div class="upload__item">
          <p class="upload__txt">ドラッグアンドドロップでアップロードできます</p>
          <label class="uploadBtn--middle">
            <input v-on:change="attach" type="file" name="certificate" ref="fileInput">
            <span class="uploadBtn--middle__inner">ファイル選択</span>
          </label>
        </div>
      </div>
      <ul class="fileList">
        <li v-for="(item, index) in attachingData.files">
          <a class="fileList__name" v-bind:href="attachingData.imgs[index]" target="_blank">
            {{item.name}}
          </a>
          <div class="fileList__btn">
            <img 
              v-on:click="detach(index)" 
              class="fileList__icon" 
              src="/images/icon-close-black.svg" alt="削除" />
          </div>
        </li>
      </ul>
      <p v-show="invalidMessage" class="valiedTxt">{{invalidMessage}}</p>
    </div>
  </div>
</template>

<style scoped>
.boxUpload {
  margin-top:60px;
}
@media screen and (max-width: 767px) {
  .boxUpload {
    margin-top:30px;
  }
}
</style>

<script>
  import { mapGetters } from 'vuex'
  import FileCheck from '../../../lib/validator/File';
  import FileSize from '../../../lib/Constants';

  export default  {
    name: 'Uploader',
    props: [
      'attachingData',
      'name',
    ],
    computed: {
      ...mapGetters([
      ])
    },
    methods: {
      attach(e) {
        e.preventDefault();
        this.invalidMessage = "";
        let files = e.target.files ? e.target.files : e.dataTransfer.files;
        if (files.length === 0) {
          return;
        }

        if (this.attachingData.files.length > 4) {
          this.invalidMessage = "5ファイルのみアップロード可能です";
          if (e.target.files) {
            e.target.files = null;
          }
          if (e.dataTransfer) {
            e.dataTransfer.files = null;
          }
          return;
        }

        // Check filetype
        const typeCheck = FileCheck.checkFileType (files[0]);
        if (!typeCheck.valid) {
          this.invalidMessage = typeCheck.message;
          return;
        }

        // Check file size
        const sizeCheck  = FileCheck.checkFileSize(files[0], FileSize.FILE_SIZE_OF_UP_REGISTERING_ACCOUNT);
        if (!sizeCheck.valid) {
          this.invalidMessage = sizeCheck.message;
          return;
        }

        this.attachingData.files.push(files[0]);
        this.attachingData.imgs.push(window.URL.createObjectURL(files[0]));
      },
      detach(index) {
        const input = this.$refs.fileInput;
        input.value = null;
        this.attachingData.imgs.splice(index, 1);
        this.attachingData.files.splice(index, 1);
        this.invalidMessage = "";
      },
    },
    data() {
      return {
        invalidMessage : "",
      };
    },
  };
</script>

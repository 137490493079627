var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Constants from '@lib/Constants';
import EventBus from '../../../component/EventBus';
import { serviceInvoiceAddURL } from "../../../lib/Helper";
export var TYPE_TEXT = 'TEXT';
export var TYPE_BUTTON = 'BUTTON';
var InvoiceItem = /** @class */ (function (_super) {
    __extends(InvoiceItem, _super);
    function InvoiceItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(InvoiceItem.prototype, "commaAmount", {
        get: function () {
            return new Intl.NumberFormat("ja-JP").format(this.invoice.amount);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InvoiceItem.prototype, "dateToShow", {
        get: function () {
            return this.invoice.sort_date ? moment(this.invoice.sort_date).format("YYYY/MM/DD HH:mm:ss") : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InvoiceItem.prototype, "operation", {
        get: function () {
            var title = '';
            var type = TYPE_TEXT;
            var isDisabled = false;
            var masterInvoiceStatus = this.invoice.master_invoice_status_id;
            var masterDealStatus = this.invoice.master_deal_status_id;
            // 即日払いがある
            if (masterDealStatus && masterDealStatus != Constants.MASTER_DEAL_STATUS_NOT_CREATED
                && masterDealStatus != Constants.MASTER_DEAL_STATUS_EDITED_AND_BEFORE_SUBMIT) {
                title = '即日払い申請済';
                type = TYPE_BUTTON;
                isDisabled = true;
                return { title: title, type: type, isDisabled: isDisabled };
            }
            // 即日払いがない
            if (masterInvoiceStatus == Constants.MASTER_INVOICE_STATUS_NOT_IMPORTED) {
                title = '即日払い申込み';
                type = TYPE_BUTTON;
                isDisabled = false;
            }
            else if (masterInvoiceStatus == Constants.MASTER_INVOICE_STATUS_REGISTERED) {
                title = '即日払い申込み';
                type = TYPE_BUTTON;
                isDisabled = false;
                // block register deal
                if (this.isBlockStatusPublic) {
                    title = '即日払い利用制限中';
                    isDisabled = true;
                }
                // now > due_date - 1day
                var currentDate = new Date();
                var nextOneDayDate = currentDate.setDate(currentDate.getDate() + 1);
                var lastHourDueDate = new Date(this.invoice.due_date).getTime() + 60 * 60 * 24 * 1000 - 1;
                if (nextOneDayDate > lastHourDueDate) {
                    title = '処理済み・期限切れ';
                    type = TYPE_TEXT;
                }
            }
            else if (masterInvoiceStatus == Constants.MASTER_INVOICE_STATUS_DONE ||
                masterInvoiceStatus == Constants.MASTER_INVOICE_STATUS_WITHDRAWN ||
                masterInvoiceStatus == Constants.MASTER_INVOICE_STATUS_DECLINED) {
                title = '処理済み・期限切れ';
                type = TYPE_TEXT;
            }
            return { title: title, type: type, isDisabled: isDisabled };
        },
        enumerable: false,
        configurable: true
    });
    InvoiceItem.prototype.isText = function (type) {
        return type == TYPE_TEXT;
    };
    InvoiceItem.prototype.handleAction = function () {
        if (this.invoice.master_invoice_status_id == Constants.MASTER_INVOICE_STATUS_NOT_IMPORTED) {
            window.location.replace(serviceInvoiceAddURL(this.invoice));
            return;
        }
        EventBus.$emit('showModalInvoice', this.invoice);
    };
    __decorate([
        Prop({ required: true })
    ], InvoiceItem.prototype, "invoice", void 0);
    __decorate([
        Prop({ required: true })
    ], InvoiceItem.prototype, "isBlockStatusPublic", void 0);
    InvoiceItem = __decorate([
        Component({
            components: {}
        })
    ], InvoiceItem);
    return InvoiceItem;
}(Vue));
export default InvoiceItem;

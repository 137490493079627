<template>
  <div class="contentBox">
    <div class="section">
      <div class="is-pc signup-tips">
        <table>
          <tbody>
            <tr>
              <td>
                <img class="signup-tips__icon" src="/images/icon_note.svg">
              </td>
              <td class="signup-tips__text-container">
                <span class="signup-tips__text-main">スムーズにご登録いただくため、以下の書類をご用意ください。</span>
                <div class="signup-tips__text-details">
                  1. メインバンクの口座情報 <br>
                  2. 運転免許証、マイナンバーカード、住民基本台帳カード（顔写真のあるもの）の<b>いずれか1点</b>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="is-sp signup-tips">
        <div class="signup-tips__container">
          <div>
            <img class="signup-tips__icon" src="/images/icon_note.svg">
          </div>
          <div class="signup-tips__text-main">
            スムーズにご登録いただくため、以下の書類をご用意ください。
          </div>
        </div>
        <div class="signup-tips__text-details">
          1. メインバンクの口座情報 <br />
          2. 運転免許証、マイナンバーカード、住民基本台帳カード（顔写真のあるもの）の<b>いずれか1点</b>
        </div>
      </div>
      <h2 class="lineTitle"><span class="lineTitle__txt">お客様情報入力</span></h2>
      <ul class="inputBlock--border">
        <li class="inputBlock--border__item">
          <label class="inputBlock--border__radioBtn js-radioTrigger js-userInfoTabTrigger" v-bind:class="{ 'is-checked': !incorporated }">
            <input v-model="incorporated" class="input" type="radio" name="user_choice" value="1">
            個人として利用
          </label>
        </li>
        <li class="inputBlock--border__item">
          <label class="inputBlock--border__radioBtn js-radioTrigger js-userInfoTabTrigger" v-bind:class="{ 'is-checked': incorporated }">
            <input v-model="incorporated" class="input" type="radio" name="user_choice" value="2">
            法人として利用
          </label>
        </li>
      </ul>

      <individual
        :select="selectData"
        :individual="userInfo.individual"
        :validation="validation.individual"
        :isAppliedAgree="isAppliedAgree"
        @checkvalid="checkValidation"
        v-show="!incorporated" />
      <corporate
        :select="selectData"
        :corporate="userInfo.corporate"
        :validation="validation.corporate"
        :isAppliedAgree="isAppliedAgree"
        @checkvalid="checkValidation"
        v-show="incorporated" />
    </div>

    <div class="lineBlock">
      <div class="btnUnit">
        <button
          class="generalBtn"
          tabIndex="0"
          :disabled="isOnAPIRequest"
          @click.prevent="nextStep"
          @keyup.enter="nextStep">
          <span class="generalBtn__inner">
            <span class="generalBtn__txt">次へ</span>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import VModal from 'vue-js-modal';
  import individual from './individual.vue';
  import corporate  from './corporate.vue';
  import util from '../../util';
  import { scrollToError } from '@lib/scrollToError.ts';
  import phoneValidator from '@lib/validator/UserPhoneNumber';
  import { containsCommonChars } from '@lib/validator/CommonChars';

  export default {
    name: 'userInfo',
    components: {
      'individual': individual,
      'corporate': corporate
    },
    props: [
      'selectData'
    ],
    data() {
      return {
        isInfoChanged: false
      };
    },
    computed: {
      ...mapState({
        validation: state => state.validation.userInfo,
        userInfo: state => state.userInfo,
        industries: state => state.industries,
        isAppliedAgree: state => state.isAppliedAgree,
        isOnAPIRequest: state => state.status.isOnAPIRequest,
        registrationStep: (state) => state.registrationStep
      }),
      incorporated: {
        get () {
          $('.flashMessage--danger').remove();
          return this.$store.getters.incorporated
        },
        set (val) {
          this.$store.commit('setIncorporatedOrNot', parseInt(val));
        }
      }
    },
    watch: {
      'userInfo.corporate.tel': function(val) {
        val = util.toHalfWidth(val);
        this.userInfo.corporate.tel = val.replace(/([^0-9])/g, '');
      },
      incorporated() {
        this.isInfoChanged = true;
      },
      userInfo: {
        handler() {
          this.isInfoChanged = true;
        },
        deep: true
      },
      industries: {
        handler() {
          this.isInfoChanged = true;
        },
        deep: true
      }
    },
    methods: {
      async nextStep() {
        // Remove flash message
        $('.flashMessage, .flashMessage--danger').remove();

        let data = '';
        let validation = '';
        let isValid = true;

        data = !this.incorporated ? this.userInfo.individual : this.userInfo.corporate;
        data = Object.assign(data, {'industry':this.$store.state.industries});
        validation = !this.incorporated ? this.validation.individual : this.validation.corporate;

        this.checkAllValidationRules(validation);

        for (let prop in validation) {
          if (!validation[prop]['bool']) {
            isValid = false;
          }
        }

        //validation result　check
        if(!isValid) {
          $('.layer02').eq(0).prepend('<div class="flashMessage--danger">フォームの内容をご確認ください</div>');
          this.$nextTick(() => {
            if (this.incorporated) {
              scrollToError('#corporate-container .valiedTxt');
            } else {
              scrollToError('#individual-container .valiedTxt');
            }
          });
          return;
        }

        data.averageInvoiceAmount = this.toZeroTrim(data.averageInvoiceAmount);
        data.businessHistory = this.toZeroTrim(data.businessHistory);
        data.monthlySalesAverage = this.toZeroTrim(data.monthlySalesAverage);
        data.areaDetailKana = data.areaDetailKana ? data.areaDetailKana.trim() : data.areaDetailKana;
        data.address = data.address ? data.address.trim() : data.address;

        // Add data to store
        if (!this.incorporated) {
          this.$store.commit('userInfoIndividual', data);
        } else {
          data.corporateNumber = util.toHalfWidth(data.corporateNumber);
          data.tel = util.toHalfWidth(data.tel);
          data.establishedYear = this.toZeroTrim(data.establishedYear);
          data.employees = this.toZeroTrim(data.employees);
          data.capital = this.toZeroTrim(data.capital);
          data.salesLastYear = this.toZeroTrim(data.salesLastYear);
          if (data.debtAmount) {
            data.debtAmount = this.toZeroTrim(data.debtAmount);
          }
          this.$store.commit('userInfoCorporate', data);
        }

        // Save user information
        if (this.isInfoChanged) {
          const isTempDataSaved = await this.$store.dispatch('saveRegistrationData');
          if (!isTempDataSaved) return;
          this.isInfoChanged = false;
        }

        // Next
        this.$emit('changeCurrentStep', this.registrationStep.REGISTRATION_STEP_BANK);
      },
      checkAllValidationRules: function(validation){
        for (let name in validation) {
          if (this.incorporated) {
            if (this.userInfo.corporate.borrowingPresence == 1 || !this.userInfo.corporate.borrowingPresence) {
              if (name == 'whichBank') {
                validation[name]['bool'] = true;
                continue;
              }
              if (name == 'debtAmount') {
                validation[name]['bool'] = true;
                continue;
              }
            }
          }
          this.checkValidation(name);
        }
      },
      checkValidation: function(name) {
        let data = !this.incorporated ? this.userInfo.individual : this.userInfo.corporate;
        let validation = !this.incorporated ? this.validation.individual : this.validation.corporate;

        if (!data[name] || name == 'industry' || (data[name] && !data[name].trim())) {
          validation[name]['bool'] = false;
          validation[name]['message'] = validation[name]['title'] + 'を入力してください';

          if (['storeNameKana', 'siteUrl', 'apartmentNameKana'].includes(name)) {
            validation[name]['bool'] = true;
          }
          //birthDate check
          if (name == 'birthDate') {
            if (data.birthDateYear && data.birthDateMonth && data.birthDateDay) {
              validation.birthDate.bool = true;
            } else {
              validation.birthDate.bool = false;
            }
          }

          if (name == 'industry' || name == 'amOtherEtcName') {
            validation.industry.bool = true;
            validation.amOtherEtcName.bool = true;
            if (this.$store.getters.checkedAnyIndustries) {
              //no input check, if am_other_etc_name was checked in industry checkbox
              if (this.$store.state.industries['am_other_etc'] && this.$store.state.industries['am_other_etc_name'] == "") {
                validation.amOtherEtcName.message = validation.amOtherEtcName.title + 'を入力してください';
                validation.amOtherEtcName.bool = false;
              }
            } else {
              validation.industry.message = validation.industry.title + 'を選択してください';
              validation.industry.bool = false;
            }
          }

        } else {

          let invalid = false;
          if (validation[name].conditions == 'checkCommonChars') {
            invalid = true;
            this.itemCheckValidation(validation[name], '常用漢字を入力してください', this.checkCommonChars(data[name]));
          }
          if (validation[name].conditions == 'checkKanaFull') {
            invalid = true;
            this.itemCheckValidation(validation[name], '全角カナを入力してください', this.checkKanaFull(data[name]));
          }

          if (validation[name].conditions == 'checkKanaNumberHyphen') {
            invalid = true;
            this.itemCheckValidation(validation[name], 'カナか数字を入力してください', this.checkKanaNumberHyphen(data[name]));
          }

          if (validation[name].conditions == 'checkNum') {
            invalid = true;
            this.itemCheckValidation(validation[name], '数字を入力してください', this.checkNum(data[name]));
          }
          if (validation[name].conditions == 'checkPhoneNumber') {
            const phoneValidationResult = phoneValidator.check(data[name]);
            invalid = true;
            this.itemCheckValidation(validation[name], phoneValidationResult.message, phoneValidationResult.valid);
          }
          if (['averageInvoiceAmount', 'monthlySalesAverage', 'capital'].includes(name)) {
            invalid = true;
            this.itemCheckValidation(validation[name], '1以上10000以下の数値を入力選択して下さい', this.checkInRange(data[name], 1, 10000));
          }

          if (validation[name].conditions === 'checkPostalCode') {
            invalid = true;
            this.itemCheckValidation(validation[name], '正しい郵便番号を入力してください', this.checkPostalCode(data[name]));
          }

          if (['salesLastYear', 'debtAmount'].includes(name)) {
            invalid = true;
            this.itemCheckValidation(validation[name], '0以上100000以下の数値を入力選択して下さい', this.checkInRange(data[name], 0, 100000));
          }
          if (validation[name].conditions == 'checkUrl' && data[name] != "") {
            invalid = true;
            this.itemCheckValidation(validation[name], 'URLの形式で入力してください', this.checkUrl(data[name]));
            if (this.checkUrl(data[name])) {
              this.itemCheckValidation(validation[name], 'URLは255文字以下で入力してください', this.checkUrlMaxlen(data[name]));
            }
          }

          if (name === 'corporateNumber' && data[name].length !== this.selectData.corporateNumberLength) {
            invalid = true;
            this.itemCheckValidation(validation[name], `${this.selectData.corporateNumberLength}桁を入力して下さい`, this.checkUrl(data[name]));
          }

          if (name === 'establishedYear' && !util.checkValidEstablishedYear(data[name])) {
            invalid = true;
            this.itemCheckValidation(validation[name], `正しい年を入力してください`, this.checkUrl(data[name]));
          }

          if (validation[name].conditions == 'checkFormatAreaDetailKana') {
            invalid = true;
            const errorMsg = '許可文字列を入力ください。';
            this.itemCheckValidation(validation[name], errorMsg, this.checkFormatAreaDetailKana(data[name]));
          }

          if (!invalid) {
            validation[name]['bool'] = true;
          }
        }
      },
      itemCheckValidation: function(validation, message, result) {
        validation['bool'] = result;
        if (!validation['bool']) {
          validation['message'] = message;
          validation['bool'] = false;
        } else {
          validation['bool'] = true;
        }
      },
      checkInRange: function(data, min, max) {
        return min <= data && data <= max;
      },
      checkCommonChars: function(data) {
        return containsCommonChars(data);
      },
      checkKanaFull: function(data) {
        if (data.match(/^[ァ-ヶーﾟ]+$/)) {
          return true;
        } else {
          return false;
        }
      },
      checkKanaNumberHyphen: function(data) {
        if (data.match(/^[ァ-ヶｦ-ﾟ0-9０-９ー]+$/)) {
          return true;
        } else {
          return false;
        }
      },
      checkNum: function(data) {
        if (data.match(/^[0-9０-９]*$/)) {
          return true;
        } else {
          return false;
        }
      },
      checkPostalCode: function(data) {
        if (data.match(/^[0-9]{7}$/)) {
          return true;
        } else {
          return false;
        }
      },
      checkUrl: function(data) {
        return data.match(/^(https?):\/\/.+$/)
      },
      checkUrlMaxlen: function(data) {
        return data.length < 256
      },
      toZeroTrim: function(val) {
        return String(Number(util.toHalfWidth(val)));
      },
      checkFormatAreaDetailKana: function(data) {
        if (data.match(/^[ァ-ヶｦ-ﾟ0-9０-９\s\u30FC\u2010-\u2015\u2212\uFF70\uFF0D-]+$/gu)) {
          return true;
        } else {
          return false;
        }
      },
    }
  }
</script>

export default  {
  check (inputStr) {
    let valid = this.isNumber(inputStr + ""),
        msg = (!valid) ? '数字を入力してください' : '',
        result = {
          valid: valid,
          message: msg
        };

    return result
  },
  isNumber (value = '') {
    if (value.match(/^[0-9]+$/)) {
      return true;
    } else {
      return false;
    }
  }
}


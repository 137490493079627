import Vue from 'vue';
import App from './components/App.vue';
import mainStore from './store/main';
export default function page() {
    new Vue({
        el: '#settingUploadUserEvidence',
        components: {
            App: App
        },
        created: function () {
            mainStore.loadInitialData({
                userEvidences: document.USER_EVIDENCES,
                expiredUserEvidences: document.EXPIRED_USER_EVIDENCES,
                userName: document.USER_NAME,
                incorporated: document.INCORPORATED
            });
        }
    });
}

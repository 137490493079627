import Vue from 'vue';
import template from '../lib/template/Contact';
import { scrollToError } from '../lib/scrollToError';
import fileUpload from '../app/Contact/components/FileUpload.vue';

export default () => {
  scrollToError('.error-message');

  const trigger = document.getElementById('master-contact-type-id');
  const message = document.getElementById('message');

  if (trigger && message) {
    const currentMessage = message.value || '';
    message.value = currentMessage || (template[parseInt(trigger.value, 10)] || '');
    trigger.addEventListener('change', () => {
      message.value = template[parseInt(trigger.value, 10)] || currentMessage;
    });
  }

  if (document.getElementById('uploadFile')) {
    const app = new Vue({
      el: '#uploadFile',
      components: {
        fileUpload
      }
    });
  }
};

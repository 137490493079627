<template>
  <div>
    <div class="contentBox">
      <p>
        申し込みを頂きましてありがとうございました。<br/>現在審査・入金手続き中です。入金まで今しばらくお待ちください。
      </p>
      <div class="notice" id="provingMail" v-if="provingMailAddress">
          <p><b class="copyText">{{ provingMailAddress}}</b> 宛にあらかじめ取引の確認に役立つ契約書類や、取引先ご担当者とのメールのやりとりなどを転送していただくと即日払いの審査がスムーズに進みます。</p>
          <div class="notice__btn">
            <a href="javascript:void(0)" class="localBtn en btnCopy">
              <span class="generalBtn__inner">
                <span class="generalBtn__txt copyStatus">メールアドレスをコピー</span>
              </span>
            </a>
          </div>
      </div>
      <div class="btnUnit">
        <a href="/" class="generalBtn">
          <span class="generalBtn__inner">
            <span class="generalBtn__txt">
              ホームへ戻る
            </span>
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import { initCopyToClipboard } from '../../../../lib/CopyToClipboard'

  export default {
    name: 'Complete',
    data() {
      return {
        page:{
          title:'お申し込み完了',
          complete: true,
        },
        provingMailAddress: document.INVOICES[0]['proving_mail_address']
      }
    },
    mounted() {
      initCopyToClipboard(document.getElementById('provingMail'),
          {'generalBtn__inner':  'localBtn__inner', 'generalBtn__txt': 'localBtn__txt'});
      this.$store.dispatch('updatePageTitle', this.page);
    },
  }
</script>

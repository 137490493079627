import Vue from 'vue';
import App from './components/App.vue';
export default function page() {
    var vue = new Vue({
        el: '#paymentMembershipPlanApp',
        components: {
            App: App
        }
    });
}

import Vue from 'vue';
import App from './components/App.vue';
import mainStore from './store/main';
export default function page() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var app = new Vue({
        el: '#mainIndustrySetting',
        components: {
            App: App
        },
        created: function () {
            var _a;
            mainStore.loadInitialData({
                masterIndustries: document.MASTER_INDUSTRIES,
                masterIndustryID: document.MASTER_INDUSTRY_ID,
                amOtherEtcName: (_a = document.AM_OTHER_ETC_NAME) !== null && _a !== void 0 ? _a : ''
            });
        }
    });
}

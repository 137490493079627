<template>
  <section class="section">
    <div class="section__inner">
      <div v-if="isAdminSuggestionDeal" class="admin-suggestion-deal">
        <div class="grid--2col">
          <div class="grid--2col__item">
            <dl class="itemBlock">
              <dt class="itemBlock__title">フリーナンスからの提案額</dt>
              <dd class="itemBlock__content pos__relative">
                <span class="price">
                  <p class="price__num fz__32 mt__0">
                    {{ total | moneyDelimiter }}
                    <span class="fz__16"> 円</span>
                  </p>
                  <p class="fz__14">(手数料引き前 
                    <span class="fz__20">{{ applicationAmount | moneyDelimiter }}</span>円)
                  </p>
                </span>
              </dd>
            </dl>
          </div>
          <div class="grid--2col__item">
            <dl class="itemBlock">
              <dt class="itemBlock__title">振込予定日<span class="note-txt top__2">※2</span></dt>
              <dd class="itemBlock__content pos__relative mt__0">
                <template v-if="canApplyAny">
                  <span class="num fz__32">
                    <time datetime="2018-08-01">
                      {{ outcomingDate | dateFormat }}
                    </time>
                  </span>
                </template>
                <template v-else>
                  <span class="num">-</span>
                </template>
              </dd>
            </dl>
          </div>
        </div>
        <div class="grid--2col mt__0">
          <div class="grid--2col__item">
            <dl class="itemBlock your-request-amount">
              <dt class="itemBlock__title fz__12">あなたの申請額</dt>
              <dd class="itemBlock__content pos__relative mt__0">
                <span class="price">
                  <span class="price__num  fz__24">
                    {{ getInvoice.deal.offer_price | moneyDelimiter }}
                  </span>
                  <span class="fz__12"> 円</span>
                  <br>(手数料引き前 {{ getInvoice.deal.incoming_amount | moneyDelimiter }}円)
                </span>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="grid--2col">
          <div class="grid--2col__item">
            <dl class="itemBlock">
              <dt class="itemBlock__title">あなたの申請額</dt>
              <dd class="itemBlock__content mt__0">
                <span class="price">
                  <span class="price__num fz__32">
                    {{ applicationAmount | moneyDelimiter }}
                  </span>
                  <span class="fz__16"> 円</span>
                </span>
              </dd>
            </dl>
          </div>
          <div class="grid--2col__item">
            <dl class="itemBlock">
              <dt class="itemBlock__title">振込予定日<span class="note-txt top__2">※2</span></dt>
              <dd class="itemBlock__content pos__relative mt__0">
                <template v-if="canApplyAny">
                  <span class="num fz__32">
                    <time datetime="2018-08-01">
                      {{ outcomingDate | dateFormat }}
                    </time>
                  </span>
                </template>
                <template v-else>
                  <span class="num">-</span>
                </template>
              </dd>
            </dl>
          </div>
        </div>
        <div class="grid--2col mt__10">
          <div class="grid--2col__item">
            <dl class="itemBlock">
              <dt class="itemBlock__title fz__12">即日払い手数料差引後の振込金額</dt>
              <dd class="mt__0 itemBlock__content">
                <span class="price">
                  <span class="price--small fz__24">
                    {{ total | moneyDelimiter }}
                  </span>
                  <span class="fz__12">円</span>
                </span>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <p v-if="canApplyAny" class="mt__8 fz__12">
        ※1: 実際の送金額は振込手数料350円を差し引いた金額となります。
        <br />※2: 振込の実行は審査状況や金融機関の稼働状況により遅延する場合があります。
      </p>
    </div>
  </section>
</template>

<script>
import invoiceListItemApply from './invoice-list-item-apply.vue';
import Constants from '@lib/Constants';

export default {
  name: 'AppliableInvoice',
  components: {
    'invoice-list-item-apply': invoiceListItemApply,
  },
  computed: {
    canApplyAny() {
      return this.$store.getters['applyQuickPay/canApplyAny'];
    },
    amount() {
      return this.$store.getters['applyQuickPay/amount'];
    },
    total() {
      return this.$store.getters['applyQuickPay/total'];
    },
    applicationAmount() {
      return this.$store.getters['applyQuickPay/applicationAmount'];
    },
    getAppliableInvoices() {
      return this.$store.getters['applyQuickPay/getAppliableInvoices'];
    },
    outcomingDate() {
      return this.$store.getters['applyQuickPay/outcomingDate'];
    },
    getInvoice() {
      return this.$store.getters['applyQuickPay/getInvoice'];
    },
    isAdminSuggestionDeal() {
      return this.getInvoice != null
        && [Constants.MASTER_DEAL_STATUS_BEFORE_ACCEPTING_PROPOSAL_DECREASE_APPLICATION_AMOUNT,
        Constants.MASTER_DEAL_STATUS_BEFORE_ACCEPTING_PROPOSAL_INCREASE_APPLICATION_AMOUNT].includes(this.getInvoice.master_deal_status_id);
    },
  },
  methods: {
    select(id) {
      this.$store.dispatch('applyQuickPay/selectInvoice', id);
    },
  },
  data() {
    return {
      isConfirmedPersonInCharge: false
    };
  },
  mounted() {
    if (this.getAppliableInvoices.length > 0) {
      this.isConfirmedPersonInCharge = this.getAppliableInvoices[0].is_confirmed_person_in_charge;
    }
  }
}
</script>

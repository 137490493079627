/* eslint-disable no-param-reassign */
import getHolidayName from '../../../../lib/Holiday';
import getBusinessHolidayName from '../../../../lib/BusinessHoliday';
import ajax from '../../../../lib/Ajax';
var moment = require('moment');

// state
var state = {
  status: {
    agree: false,
    processingApiRequest: false
  },
  page: {
    title: '',
    complete: false
  },
  invoices: [],
  unSpecifyInvoiceList: [],

  isBlockStatusPublic: false,

  termsConditions: {
    amountBilledTotal: 0,
    withholdingTaxTotal: 0,
    transactionFee: 500,
    paymentDate: '20180801',
  },
  constrains: {
    MASTER_INVOICE_STATUS_REGISTERED: 1,
    MASTER_INVOICE_STATUS_WITHDRAWN: 8
  }
};

// getters
const getters = {
  // 選択した請求書を取得
  selectedInvoces: state => {
    return state.invoices.filter(invoice => invoice._selected);
  },
  // 選択した請求書の取得の数
  selectedInvocesCount: (state, getters) => {
    return getters.selectedInvoces.length;
  },
  // 買取実行可能な請求書を取得
  getAppliableInvoices: (state) => {
    let invoices = state.invoices.filter((inv, index, array) => {
      return inv.master_invoice_status_id == state.constrains.MASTER_INVOICE_STATUS_REGISTERED
          || inv.master_invoice_status_id == state.constrains.MASTER_INVOICE_STATUS_WITHDRAWN;
    });
    return invoices;
  },
  // エビデンスが必要な請求書を取得
  getNeedEvidencialInvoices: (state) => {
    let invoices = state.invoices.filter((inv, index, array) => {
      return inv.need_evidence;
    });
    return invoices;
  },
  // 買取請求書の合計金額
  total: (state, getters) => {
    let invoices = getters.getAppliableInvoices;
    let total = 0;
    for (let i = 0; i < invoices.length; i++) {
      if (invoices[i]._selected) {
        total += (invoices[i].offer.offer_price || 0);
      }
    }
    return total === 0 ? '-' : total;
  },
  amount: (state, getters) => {
    let invoices = getters.getAppliableInvoices;
    let amount = 0;
    for (let i = 0; i < invoices.length; i++) {
      if (invoices[i]._selected) {
        amount += (invoices[i].amount || 0);
      }
    }
    return amount === 0 ? '-' : amount;
  },
  applicationAmount: (state, getters) => {
    let invoices = getters.getAppliableInvoices;
    let applicationAmount = 0;
    for (let i = 0; i < invoices.length; i++) {
      if (invoices[i]._selected) {
        applicationAmount += (invoices[i].application_amount || 0);
      }
    }
    return applicationAmount === 0 ? '-' : applicationAmount;
  },
  // 買取可能な請求書がある場合true
  canApplyAny: (state, getters) => {
    let invoices = getters.getAppliableInvoices;
    let can = false;
    for (let i = 0; i < invoices.length; i++) {
      if (invoices[i].offer.appliable) {
        can = true;
        break;
      }
    }
    return can;
  },
  getInvoice: (state, getters) => {
    let invoices = getters.getAppliableInvoices;
    return invoices.length > 0 ? invoices[0] : null;
  },
  outcomingDate: (state) => {
    moment.locale('ja');

    // 土日祝日をスキップして平日のmomentを返却する
    function retUsualDay(dt) {
      let sd = dt;
      // formatして元の日付を保存. ※ momentにすると参照渡しになったため
      let sdFormat = dt.format('YYYY/MM/DD');

      if (dt.format('dddd') === '土曜日') {
        // if Saturday tomorrow, transfer 2 days later
        dt.add(2, 'days');
      } else if (dt.format('dddd') === '日曜日') {
        // if Sunday tomorrow, transfer 1 days later
        dt.add(1, 'days');
      } else if (getHolidayName(new Date(dt.format('YYYY/MM/DD'))) !== '') {
        // if Holiday tomorrow, transfer 1 days later
        dt.add(1, 'days');
      } else if (getBusinessHolidayName(new Date(dt.format('YYYY/MM/DD'))) !== '') {
        // if BusinessHoliday tomorrow, transfer 1 days later
        dt.add(1, 'days');
      }

      if (dt.format('YYYY/MM/DD') !== sdFormat) {
        // スキップされていたら再度土日祝日をチェックしてスキップする
        return retUsualDay(dt);
      } else if (parseInt(moment().format('k'), 10) < 10) {
        // スキップされずに当日10時以前なら当日
        // if before 10AM, transfer today
        sd = moment();
      }

      return sd;
    }

    // 翌日の土日祝かチェックしてスキップして平日にする
    let sdTomorrow = moment().add(1, 'days');
    let ret = retUsualDay(sdTomorrow);

    return ret.format('YYYY-MM-DD');
  },
  isBlockStatusPublic: (state) => {
    return state.isBlockStatusPublic;
  },
};

// mutations
const mutations = {
  // Load data from inline-embeded doc on HTML
  load(state, invoices) {
    let _invoices = invoices.map((inv) => {
      inv._selected = true;
      return inv;
    });
    state.invoices = _invoices;
  },
  updatePageTitle(state, data) {
    // ページタイトルセット
    state.page.title = data.title;
    state.page.complete = data.complete;
  },
  // 選択した請求書にフラグ立てる
  selectInvoice(state, id) {
    state.invoices.forEach((inv) => {
      if (inv.id == id) {
        inv._selected = inv._selected ? false : true;
      }
    });
  },
  // API通信状態をセットする
  updateProcessingApiRequest: (state, status) => {
    state.status.processingApiRequest = status;
  },
  isBlockStatusPublic: (state, value) => {
    state.isBlockStatusPublic = value;
  },
};

// actions
const actions = {
  updatePageTitle(context, data) {
    context.commit('updatePageTitle', data)
  },
  selectInvoice(context, id) {
    context.commit('selectInvoice', id)
  },
  async apply(context, data) {
    // FIXME: 複数件の請求書の買取をまとめて行う想定で
    // 当初作られ始めたが、結局そうなることは無かったので、
    // 実態に合わせた（1件のみ扱う）実装にされるのが望ましい
    let ids = context.getters.selectedInvoces.map(iv => iv.id);
    const csrfToken = document.CSRF_TOKEN;
    const res = await ajax.post(
      '/apiInternal/applyQuickPay',
      {
        'ids': ids,
        'sending_date': data.sending_date,
        'is_apply_original_quick_pay': data.is_apply_original_quick_pay,
        'notes_version': data.notes_version
      },
      {
        'X-CSRF-Token': csrfToken
      }
    );
    // Check status
    if (res.status !== 200 || res.data.status !== 200) {
      throw new Error("エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください");
    }

    if (res.data.data && res.data.data.status !== 200) {
      throw new Error("エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください");
    }

    if (context.state.invoices[0].redirect_url) {
      location.href = context.state.invoices[0].redirect_url;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import VueModal from 'vue-js-modal';
import mainStore from '../../store/main';
Vue.use(VueModal);
var DealConfirmModal = /** @class */ (function (_super) {
    __extends(DealConfirmModal, _super);
    function DealConfirmModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.content = '';
        _this.modalName = 'deal-confirm-modal';
        return _this;
    }
    DealConfirmModal.prototype.show = function () {
        this.$modal.show(this.modalName);
    };
    DealConfirmModal.prototype.hide = function () {
        this.$modal.hide(this.modalName);
    };
    DealConfirmModal.prototype.handleShowModal = function (operation) {
        switch (operation.action) {
            case 'cancelDeal':
                this.content = '即日払い申込みをキャンセルしてもよろしいでしょうか？';
                break;
            case 'removeDealFromList':
                this.content = '即日払い申込みを非表示してもよろしいでしょうか？';
                break;
        }
        if (this.content) {
            this.operation = operation;
            this.show();
        }
    };
    DealConfirmModal.prototype.handleAction = function (operation) {
        this.hide();
        switch (operation.action) {
            case 'cancelDeal':
                return mainStore.cancelDeal(operation.dealID);
            case 'removeDealFromList':
                return mainStore.removeDealFromList(operation.dealID);
        }
    };
    DealConfirmModal = __decorate([
        Component({})
    ], DealConfirmModal);
    return DealConfirmModal;
}(Vue));
export default DealConfirmModal;

import * as conf from '../app/InvoiceAdd/config';
import * as confPlan from '../app/MembershipPlans/config';
export var userEvidenceStatusLabel = function (statusID) {
    var msg = '';
    var color = '';
    switch (statusID) {
        case conf.MASTER_USER_EVIDENCE_STATUS_APPROVED:
            msg = '承認済';
            color = 'blue';
            break;
        case conf.MASTER_USER_EVIDENCE_STATUS_UNNECESSARY:
            msg = '提出不要';
            color = 'blue';
            break;
        case conf.MASTER_USER_EVIDENCE_STATUS_REJECTED:
            msg = '非承認';
            color = 'red';
            break;
        case conf.MASTER_USER_EVIDENCE_STATUS_CHECKING:
            msg = '審査中';
            color = 'orange';
            break;
        default:
    }
    return { msg: msg, color: color };
};
export var convertUsername = function (username, incorporated) {
    if (username) {
        var suffix = incorporated ? '様' : 'さん';
        return "" + username + suffix;
    }
    return incorporated ? '貴社' : 'あなた';
};
export var humanFileSize = function (size) {
    var i = Math.floor(Math.log(size) / Math.log(1024));
    return ((size / (Math.pow(1024, i))).toFixed(2) + ['B', 'KB', 'MB', 'GB', 'TB'][i]);
};
export var getMembershipPlan = function (planID, periodID) {
    var monthlyUnit = '円/月';
    var yearlyUnit = '円/年';
    var planTitle = '';
    var periodTitle = '';
    var unit = '';
    var price = 0;
    var commaPrice = '0';
    if (planID === confPlan.MASTER_MEMBERSHIP_PLAN_FREE) {
        planTitle = confPlan.MEMBERSHIP_PLAN_FREE_TITLE;
        unit = '円';
        return { planTitle: planTitle, periodTitle: periodTitle, unit: unit, price: price, commaPrice: commaPrice };
    }
    switch (planID) {
        case confPlan.MASTER_MEMBERSHIP_PLAN_REGULAR:
            planTitle = confPlan.MEMBERSHIP_PLAN_REGULAR_TITLE;
            if (periodID === confPlan.MASTER_MEMBERSHIP_BILLING_PERIODS_MONTHLY) {
                price = confPlan.REGULAR_MONTHLY_PRICE;
            }
            else if (periodID === confPlan.MASTER_MEMBERSHIP_BILLING_PERIODS_YEARLY) {
                price = confPlan.REGULAR_YEARLY_PRICE;
            }
            break;
        case confPlan.MASTER_MEMBERSHIP_PLAN_PREMIUM:
            planTitle = confPlan.MEMBERSHIP_PLAN_PREMIUM_TITLE;
            if (periodID === confPlan.MASTER_MEMBERSHIP_BILLING_PERIODS_MONTHLY) {
                price = confPlan.PREMIUM_MONTHLY_PRICE;
            }
            else if (periodID === confPlan.MASTER_MEMBERSHIP_BILLING_PERIODS_YEARLY) {
                price = confPlan.PREMIUM_YEARLY_PRICE;
            }
            break;
        default:
            planTitle = confPlan.MEMBERSHIP_PLAN_FREE_TITLE;
    }
    switch (periodID) {
        case confPlan.MASTER_MEMBERSHIP_BILLING_PERIODS_MONTHLY:
            periodTitle = confPlan.MEMBERSHIP_PERIODS_MONTHLY_TITLE;
            unit = monthlyUnit;
            break;
        case confPlan.MASTER_MEMBERSHIP_BILLING_PERIODS_YEARLY:
            periodTitle = confPlan.MEMBERSHIP_PERIODS_YEARLY_TITLE;
            unit = yearlyUnit;
            break;
        default:
            unit = '円';
    }
    commaPrice = new Intl.NumberFormat('ja-JP').format(price);
    return { planTitle: planTitle, periodTitle: periodTitle, unit: unit, price: price, commaPrice: commaPrice };
};
export var serviceInvoiceAddURL = function (invoiceData) {
    var url = "/invoices/add?service_id=" + invoiceData.linking_service_id
        + ("&service_invoice_id=" + invoiceData.linking_service_invoice_id);
    if (invoiceData.linking_service_name === 'freee') {
        url += "&company_id=" + invoiceData.company_id;
    }
    return url;
};
export var calculateApplicationAmount = function (amount, withholdingTax, maxAmountRate) {
    var applicationAmount = amount - withholdingTax;
    var maxApplicationAmount = parseInt((amount * maxAmountRate).toFixed(), 10) || 0;
    if (applicationAmount > maxApplicationAmount) {
        return maxApplicationAmount;
    }
    return applicationAmount;
};
export default {};

import { render, staticRenderFns } from "./AddAdditionalFile.vue?vue&type=template&id=7a17ace8&"
import script from "./AddAdditionalFile.vue?vue&type=script&lang=ts&"
export * from "./AddAdditionalFile.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "AddAdditionalFile.vue"
export default component.exports
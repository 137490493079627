<template>
<div class="section">
  <div class="section__inner">
    <slot></slot>
  </div>
  <div class="upload" v-bind:class="{ 'is-error': !validationObj.valid }">
    <div @dragleave.prevent @dragover.prevent @drop.prevent="attachFile" class="upload__content">
      <div class="upload__item">
        <p class="upload__txt">ドラッグアンドドロップでアップロードできます</p>
        <label class="uploadBtn--middle">
          <input v-on:change="attachFile" type="file" :name="inputFieldName" ref="fileInput">
          <span class="uploadBtn--middle__inner">ファイル選択</span>
        </label>
      </div>
    </div>
    <FileDisplay
      :data="storeObj"
      :enable-delete="true"
      @delete="detachFile" />
  </div>
  <p v-show="validationObj.message" class="valiedTxt">{{validationObj.message}}</p>
</div>
</template>

<script>
import { mapState } from 'vuex';
import FileDisplay from '../../../InvoiceAdd/components/parts/file-display.vue';

  export default {
    name: 'bankCertUploader',
    props: [
      'sectionType',
      'fieldName'
    ],
    components: {
      FileDisplay
    },
    computed: {
      inputFieldName() {
        const fieldName = this.fieldName === 'certificate' ? 'anshinBankCert' : this.fieldName;
        return `${fieldName}File`;
      },
      ...mapState({
        saveTempFileUploadNeeded: state => state.saveTempFileUploadNeeded
      }),
      validationObj() {
        return this.$store.getters.getBankCertValidation(this.sectionType, this.fieldName);
      },
      storeObj() {
        return this.$store.getters.getStoreObj(
          'bank',
          this.sectionType,
          this.fieldName
        );
      },
    },
    methods: {
      attachFile: function(e) {
        e.preventDefault();
        let files = e.target.files ? e.target.files : e.dataTransfer.files;
        this.$store.commit('attachBankCert', {
          sectionType : this.sectionType,
          fieldName   : this.fieldName,
          file        : files[0],
        });
        this.$store.commit('updateSaveTempFileUploadNeeded', true)
      },
      detachFile: function(index) {
        const input = this.$refs.fileInput;
        input.value = null;
        this.$store.commit('detachBankCert', {
          sectionType : this.sectionType,
          fieldName   : this.fieldName,
          index       : index,
        });
        this.$store.commit('updateSaveTempFileUploadNeeded', true)
      }
    }
  }
</script>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Vue, Component } from 'vue-property-decorator';
import FileCheck from '../../../lib/validator/File';
import mainStore from '../store/main';
import FileDisplay from '../../InvoiceAdd/components/parts/file-display.vue';
var FileUpload = /** @class */ (function (_super) {
    __extends(FileUpload, _super);
    function FileUpload() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.errUpload = '';
        return _this;
    }
    FileUpload.prototype.created = function () {
        mainStore.setMaxNumFilesContactUploads(document.MAX_NUM_FILES_CONTACT_UPLOADS);
        mainStore.setMaximumFileSizeOfUpContact(document.MAXIMUM_FILE_SIZE_OF_UP_CONTACT);
        var contactForm = document.getElementById('contact-form');
        contactForm.addEventListener('submit', function (e) {
            var hiddenEl = document.getElementById('contact_element_hidden');
            if (hiddenEl) {
                // create (5) input type file to upload file
                mainStore.uploadFileList.forEach(function (item, index) {
                    var key = "uploaded_file_key" + (index + 1);
                    var inputEl = document.createElement('input');
                    inputEl.type = 'file';
                    inputEl.name = key;
                    inputEl.files = item.selectedFiles.files;
                    hiddenEl.insertBefore(inputEl, hiddenEl.firstChild);
                });
            }
            // disable button submit when form submit
            var btnSendContact = document.getElementById('btn-send-contact');
            if (btnSendContact) {
                btnSendContact.disabled = true;
            }
        });
    };
    Object.defineProperty(FileUpload.prototype, "uploadFileList", {
        get: function () {
            return mainStore.uploadFileList;
        },
        enumerable: false,
        configurable: true
    });
    FileUpload.prototype.selectedFile = function (e) {
        return __awaiter(this, void 0, void 0, function () {
            var target, files, _i, files_1, fileUpload, typeCheck, sizeCheck;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.preventDefault();
                        this.errUpload = '';
                        target = e.target;
                        files = target.files ? target.files : e.dataTransfer.files;
                        if (!files[0])
                            return [2 /*return*/];
                        if (mainStore.uploadFileList.length + files.length > mainStore.maxNumFilesContactUploads) {
                            target.value = '';
                            this.errUpload = mainStore.maxNumFilesContactUploads + "\u30D5\u30A1\u30A4\u30EB\u306E\u307F\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9\u53EF\u80FD\u3067\u3059";
                            return [2 /*return*/];
                        }
                        _i = 0, files_1 = files;
                        _a.label = 1;
                    case 1:
                        if (!(_i < files_1.length)) return [3 /*break*/, 4];
                        fileUpload = files_1[_i];
                        return [4 /*yield*/, FileCheck.checkFileType(fileUpload)];
                    case 2:
                        typeCheck = _a.sent();
                        if (!typeCheck.valid) {
                            this.errUpload = typeCheck.message;
                            return [2 /*return*/];
                        }
                        sizeCheck = FileCheck.checkFileSize(fileUpload, mainStore.maximumFileSizeOfUpContact);
                        if (!sizeCheck.valid) {
                            this.errUpload = sizeCheck.message;
                            return [2 /*return*/];
                        }
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        mainStore.addUploadFileList({ files: files });
                        return [2 /*return*/];
                }
            });
        });
    };
    FileUpload.prototype.deleteUploadFile = function (index) {
        this.errUpload = '';
        mainStore.removeUploadFileList(index);
    };
    FileUpload = __decorate([
        Component({
            components: {
                FileDisplay: FileDisplay
            }
        })
    ], FileUpload);
    return FileUpload;
}(Vue));
export default FileUpload;

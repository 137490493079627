var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import ajax from '../../../lib/Ajax';
var RegisterForm = /** @class */ (function (_super) {
    __extends(RegisterForm, _super);
    function RegisterForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.chargeMonthly = _this.simulationResult ? _this.simulationResult.chargeMonthly : '0';
        _this.chargeAnnually = _this.simulationResult ? _this.simulationResult.chargeAnnually : '0';
        _this.ageGroup = _this.simulationResult ? _this.simulationResult.ageGroup : '';
        _this.moneyGroup = _this.simulationResult ? _this.simulationResult.moneyGroup : '';
        _this.jobType = _this.simulationResult ? _this.simulationResult.jobType : '';
        _this.ageGroupTitle = _this.simulationResult ? _this.simulationResult.ageGroupTitle : '';
        _this.moneyGroupTitle = _this.simulationResult ? _this.simulationResult.moneyGroupTitle : '';
        _this.jobTypeTitle = _this.simulationResult ? _this.simulationResult.jobTypeTitle : '';
        _this.jobTypeValue = _this.simulationResult ? _this.simulationResult.jobTypeValue : null;
        _this.willSaveResult = false;
        _this.agreeAWS = false;
        _this.isProcessing = _this.isSettlementProcessing;
        return _this;
    }
    RegisterForm.prototype.mounted = function () {
        var checkBoxes = document.querySelectorAll('.js-checkBoxTrigger');
        checkBoxes.forEach(function (checkBox) {
            var input = checkBox.querySelector('input');
            input.addEventListener('change', function () {
                if (input.checked) {
                    checkBox.classList.add('is-checked');
                }
                else {
                    checkBox.classList.remove('is-checked');
                }
            });
        });
    };
    RegisterForm.prototype.calculate = function (_a) {
        var target = _a.target;
        /* 価格表 */
        /* タイプ1の15-19歳は priceTable[0][0] と表現できて、値は24 */
        var priceTable = [
            [32, 47, 53, 65, 81, 101, 121, 140, 150, 158],
            [37, 54, 60, 75, 93, 116, 139, 161, 172, 181],
            [44, 63, 71, 88, 109, 136, 163, 189, 202, 213]
        ];
        /* タイプを代入する変数 */
        /* 1級 -> 0, 2級 -> 1, 3級 -> 2 */
        var selectedOption = target.options[target.selectedIndex];
        switch (target.id) {
            case 'jobType':
                this.jobTypeValue = parseInt(selectedOption.getAttribute('data-value'), 10) - 1;
                this.jobTypeTitle = selectedOption.text;
                break;
            case 'ageGroup':
                this.ageGroupTitle = selectedOption.text;
                break;
            case 'moneyGroup':
                this.moneyGroupTitle = selectedOption.text;
                break;
            default:
                alert('Invalid option');
                return;
        }
        /* 年齢を代入する変数 */
        /* 15-19 -> 0, 20-24 -> 1, 25-29 -> 2, 30-34 -> 3, 35-39 -> 4 */
        /* 40-44 -> 5, 45-49 -> 6, 50-54 -> 7, 55-59 -> 8, 60-64 -> 9 */
        var age = parseInt(this.ageGroup, 10);
        /* 何万円受け取りたいか。 */
        var money = parseInt(this.moneyGroup, 10);
        if (this.jobTypeValue === null || isNaN(age) || isNaN(money)) {
            return;
        }
        /* 月額保険料 */
        var monthly = priceTable[this.jobTypeValue][age] * money;
        this.chargeMonthly = monthly.toLocaleString();
        /* 年額保険料 */
        var annualy = monthly * 12;
        this.chargeAnnually = annualy.toLocaleString();
    };
    RegisterForm.prototype.registAnshin = function () {
        return __awaiter(this, void 0, void 0, function () {
            var params, csrfToken, res, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = {
                            ageGroup: this.ageGroup,
                            ageGroupTitle: this.ageGroupTitle,
                            moneyGroup: this.moneyGroup,
                            moneyGroupTitle: this.moneyGroupTitle,
                            jobType: this.jobType,
                            jobTypeValue: this.jobTypeValue,
                            jobTypeTitle: this.jobTypeTitle,
                            chargeMonthly: this.chargeMonthly,
                            chargeAnnually: this.chargeAnnually
                        };
                        csrfToken = document.CSRF_TOKEN;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.isProcessing = true;
                        return [4 /*yield*/, ajax.post('/apiInternal/registerAnshinPlus', this.willSaveResult ? params : {}, {
                                'Content-Type': 'application/json',
                                'X-CSRF-Token': csrfToken
                            })];
                    case 2:
                        res = _a.sent();
                        if (res.data.status !== 200) {
                            alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください');
                            this.isProcessing = false;
                        }
                        else {
                            location.href = res.data.data;
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください');
                        this.isProcessing = false;
                        throw err_1;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        Prop()
    ], RegisterForm.prototype, "simulationResult", void 0);
    __decorate([
        Prop(Boolean)
    ], RegisterForm.prototype, "isSettlementProcessing", void 0);
    __decorate([
        Prop(Boolean)
    ], RegisterForm.prototype, "cardNumLimitExceeded", void 0);
    RegisterForm = __decorate([
        Component
    ], RegisterForm);
    return RegisterForm;
}(Vue));
export default RegisterForm;

<template>
  <div :key="evidenceKey" class="block-evidence" :class="{ 'pr__40': this.evidenceKey !== 'invoiceEvidence3' }">
    <div class="title-evidence" :class="{ clickable: displayFormInput, hideBottomBorder: hideBottomBorder }">
      <div class="subTitle">
        <div class="subTitleText">
          {{ evidenceItem.title }}
          <i class="subTitleIcon billTable__sort" :class="sortIcon"></i>
        </div>
      </div>
    </div>
    <div v-if="displayFormInput" class="container-content-evidence">
      <div class="content-evidence">
        <p v-html="evidenceItem.content"></p>
      </div>
      <div class="container-form-evidence">
        <div v-for="(itemOption, idx) in evidenceItem.options" :key="idx" class="option-item">
          <label v-if="radioOptionShow(itemOption.id)" class="profileSettingOption__item__label
              radioUnit__radioBtn
              style-evidence-radio is-disabled" :class="{
                'is-checked': evidenceUploadValues[evidenceKey].selectOption === itemOption.id
              }">
            <input type="radio" :name="`${evidenceKey}Radio`" :value="itemOption.id">
            {{ itemOption.content }}
          </label>
          <div v-if="itemOption.isUploadFile" v-show="evidenceUploadValues[evidenceKey].selectOption === itemOption.id"
            class="upload">
            <FileDisplay :data="evidenceUploadValues[evidenceKey].fileUploads" :enable-delete="false" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileDisplay from './file-display.vue';
import * as conf from '../../config';

export default {
  components: {
    FileDisplay
  },

  props: {
    evidenceKey: String,
    evidenceItem: Object,
    evidenceType: String
  },
  computed: {
    evidenceUploadValues() {
      return this.$store.getters['evidenceUpload/getInvoiceEvidenceUploadValues'];
    },

    evidenceItemUploadValue() {
      return this.evidenceUploadValues[this.evidenceKey];
    },

    evidenceValidation() {
      return this.$store.getters['evidenceUpload/getInvoiceEvidenceValidation'];
    },

    displayFormInput() {
      return ![
        conf.MASTER_USER_EVIDENCE_STATUS_UNNECESSARY,
        conf.MASTER_USER_EVIDENCE_STATUS_APPROVED
      ].includes(this.evidenceItemUploadValue.status);
    },

    hideBottomBorder() {
      return this.evidenceKey !== 'invoiceEvidence3' && (!this.displayFormInput || this.evidenceItemUploadValue.isCollapse);
    },

    sortIcon() {
      return (!this.displayFormInput || this.evidenceItemUploadValue.isCollapse) ? 'billTable__sort__desc' : 'billTable__sort__asc';
    },
  },
  methods: {
    toggleEvidenceEditMark(selectOpt) {
      this.$store.commit('evidenceUpload/toggleEvidenceEditMark', {
        key: this.evidenceKey,
        evidenceType: this.evidenceType,
        selectOpt
      });
    },

    radioOptionShow(selectOpt) {
      // If status is reject, only show upload option radio and none option
      if (this.evidenceItemUploadValue.status === conf.MASTER_USER_EVIDENCE_STATUS_REJECTED
        && selectOpt !== conf.EVIDENCE_USER_SEND_ABOVE_OPT) {
        return true;
      }

      // If image is already uploaded, only show image upload option
      if (this.evidenceItemUploadValue.isUploaded && selectOpt !== conf.EVIDENCE_FILEUPLOAD_OPT) {
        return false;
      }

      return true;
    },

    toggleCollapse() {
      if (!this.displayFormInput) {
        return;
      }
      this.$store.commit('evidenceUpload/toggleCollapse', {
        evidenceType: this.evidenceType,
        evidenceKey: this.evidenceKey
      });
    }
  }
};
</script>

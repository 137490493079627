import Vue from 'vue';
import Invoice from './components/Invoice.vue';
import mainStore from './store/main';
import store from '../InvoiceApplyQuickPayModal/store';
var mixinInvoiceModal = {
    store: store
};
export default function page() {
    var vue = new Vue({
        el: '#ListInvoices',
        mixins: [
            mixinInvoiceModal
        ],
        components: {
            'list-invoice': Invoice
        },
        created: function () {
            mainStore.loadInitialData();
        }
    });
}

/**
 * 渡された日付の形式を変換する
 *
 * toSeparateDateByHyphen
 * @see https://zukucode.com/2017/04/javascript-string-date.html
 * @function 日付をハイフン区切りにする
 * @param    {string} str 日付文字列（yyyy-MM-dd, yyyy/MM/dd）
 * @param    {string} delim: 変換する区切り文字（"-", "/"など）
 * @return   {string}
 */
export default {
  convert (str, delim) {
    let arr = str.split(delim)
    return new Date(arr[0], arr[1] - 1, arr[2]);
  }
}

import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';

Vue.use(Vuex);

const state = {
  userChoice: 1,
  isAppliedAgree: false,
  userInfo: {
    individual: {
      familyName: '',
      firstName: '',
      familyNameKana: '',
      firstNameKana: '',
      nickName: '',
      storeName: '',
      storeNameKana: '',
      birthDateYear: '',
      birthDateMonth: '',
      birthDateDay: '',
      sex: '',
      postalCode: '',
      prefecturesId: '',
      address: '',
      addressKana: '',
      areaDetail: '',
      areaDetailKana: '',
      apartmentName: '',
      apartmentNameKana: '',
      tel: '',
      industry: '',
      businessHistory: '',
      averageInvoiceAmount: '',
      monthlySalesAverage: '',
      siteUrl: ''
    },
    corporate: {
      corporateName: '',
      corporateNameKana: '',
      corporateNumber: '',
      familyName: '',
      firstName: '',
      familyNameKana: '',
      firstNameKana: '',
      birthDateYear: '',
      birthDateMonth: '',
      birthDateDay: '',
      sex: '',
      postalCode: '',
      prefecturesId: '',
      address: '',
      addressKana: '',
      areaDetail: '',
      areaDetailKana: '',
      apartmentName: '',
      apartmentNameKana: '',
      tel: '',
      industry: '',
      businessHistory: '',
      establishedYear: '',
      employees: '',
      capital: '',
      salesLastYear: '',
      borrowingPresence: '',
      whichBank: '',
      debtAmount: '',
      averageInvoiceAmount: '',
      monthlySalesAverage: '',
      siteUrl: ''
    }
  },
  bank: {
    individual: {
      bankName: '',
      swiftCode: '',
      branchName: '',
      branchCord: '',
      accountType: '',
      accountNumber: '',
      accountName: '',
      alternatedBankAccountName: false,
      accountNameChoice: false,
      otherAccountName: '',
      certificate: [],
      bankAccountNameCert: []
    },
    corporate: {
      bankName: '',
      swiftCode: '',
      branchName: '',
      branchCord: '',
      accountType: '',
      accountNumber: '',
      accountName: ''
    }
  },
  status: {
    isOnAPIRequest: false,
    currentStep: ''
  },
  saveTempFileUploadNeeded: false,
  validation: {
    userInfo: {
      individual: {
        familyName: {
          bool: true,
          title: '姓',
          conditions: 'checkCommonChars'
        },
        firstName: {
          bool: true,
          title: '名',
          conditions: 'checkCommonChars'
        },
        familyNameKana: {
          bool: true,
          title: 'セイ',
          conditions: 'checkKanaFull'
        },
        firstNameKana: {
          bool: true,
          title: 'メイ',
          conditions: 'checkKanaFull'
        },
        storeNameKana: {
          bool: true,
          title: '屋号カナ',
          conditions: 'checkKanaFull'
        },
        birthDate: {
          bool: true,
          title: '生年月日'
        },
        sex: {
          bool: true,
          title: '性別'
        },
        postalCode: {
          bool: true,
          title: '郵便番号',
          conditions: 'checkPostalCode'
        },
        prefecturesId: {
          bool: true,
          title: '都道府県'
        },
        address: {
          bool: true,
          title: '市区町村',
          conditions: 'checkCommonChars'
        },
        addressKana: {
          bool: true,
          title: '市区町村（カナ）',
          conditions: 'checkKanaFull'
        },
        areaDetail: {
          bool: true,
          title: '町域・番地',
          conditions: 'checkCommonChars'
        },
        areaDetailKana: {
          bool: true,
          title: '町域・番地（カナ）',
          conditions: 'checkFormatAreaDetailKana'
        },
        apartmentNameKana: {
          bool: true,
          title: '建物名（カナ）',
          conditions: 'checkKanaNumberHyphen'
        },
        industry: {
          bool: true,
          title: '業種'
        },
        amOtherEtcName: {
          bool: true,
          title: 'その他の業種名'
        },
        businessHistory: {
          bool: true,
          title: '業歴',
          conditions: 'checkNum'
        },
        averageInvoiceAmount: {
          bool: true,
          title: '請求書1枚の平均請求額（円）',
          conditions: 'checkNum'
        },
        monthlySalesAverage: {
          bool: true,
          title: '月間売上平均（円）',
          conditions: 'checkNum'
        },
        siteUrl: {
          bool: true,
          title: 'サイトのURL',
          conditions: 'checkUrl'
        }
      },
      corporate: {
        corporateName: {
          bool: true,
          title: '法人名',
          conditions: 'checkCommonChars'
        },
        corporateNameKana: {
          bool: true,
          title: '法人名カナ',
          conditions: 'checkKanaFull'
        },
        corporateNumber: {
          bool: true,
          title: '法人番号',
          conditions: 'checkNum'
        },
        familyName: {
          bool: true,
          title: '姓',
          conditions: 'checkCommonChars'
        },
        firstName: {
          bool: true,
          title: '名',
          conditions: 'checkCommonChars'
        },
        familyNameKana: {
          bool: true,
          title: 'セイ',
          conditions: 'checkKanaFull'
        },
        firstNameKana: {
          bool: true,
          title: 'メイ',
          conditions: 'checkKanaFull'
        },
        birthDate: {
          bool: true,
          title: '生年月日'
        },
        sex: {
          bool: true,
          title: '性別'
        },
        postalCode: {
          bool: true,
          title: '郵便番号',
          conditions: 'checkPostalCode'
        },
        prefecturesId: {
          bool: true,
          title: '都道府県'
        },
        address: {
          bool: true,
          title: '市区町村',
          conditions: 'checkCommonChars'
        },
        addressKana: {
          bool: true,
          title: '市区町村（カナ）',
          conditions: 'checkKanaFull'
        },
        areaDetail: {
          bool: true,
          title: '町域・番地',
          conditions: 'checkCommonChars'
        },
        areaDetailKana: {
          bool: true,
          title: '町域・番地（カナ）',
          conditions: 'checkFormatAreaDetailKana'
        },
        apartmentNameKana: {
          bool: true,
          title: '建物名（カナ）',
          conditions: 'checkKanaNumberHyphen'
        },
        tel: {
          bool: true,
          title: '電話番号',
          conditions: 'checkPhoneNumber'
        },
        industry: {
          bool: true,
          title: '業種'
        },
        amOtherEtcName: {
          bool: true,
          title: 'その他の業種名'
        },
        businessHistory: {
          bool: true,
          title: '業歴',
          conditions: 'checkNum'
        },
        establishedYear: {
          bool: true,
          title: '設立年',
          conditions: 'checkNum'
        },
        employees: {
          bool: true,
          title: '従業員数',
          conditions: 'checkNum'
        },
        capital: {
          bool: true,
          title: '資本金',
          conditions: 'checkNum'
        },
        salesLastYear: {
          bool: true,
          title: '前年度売上',
          conditions: 'checkNum'
        },
        borrowingPresence: {
          bool: true,
          title: '借入有無'
        },
        whichBank: {
          bool: true,
          title: '何行から'
        },
        debtAmount: {
          bool: true,
          title: '借入額合計',
          conditions: ''
        },
        averageInvoiceAmount: {
          bool: true,
          title: '請求書1枚の平均請求額（円）',
          conditions: 'checkNum'
        },
        monthlySalesAverage: {
          bool: true,
          title: '月間売上平均（円）',
          conditions: 'checkNum'
        },
        siteUrl: {
          bool: true,
          title: 'サイトのURL',
          conditions: 'checkUrl'
        }
      }
    },
    bank: {
      individual: {
        bankName: {
          bool: true,
          title: '銀行名'
        },
        swiftCode: {
          bool: true,
          title: '銀行コード',
          conditions: 'checkNum'
        },
        branchName: {
          bool: true,
          title: '支店名'
        },
        branchCord: {
          bool: true,
          title: '支店コード',
          conditions: 'checkNum'
        },
        accountType: {
          bool: true,
          title: '口座種別'
        },
        accountNumber: {
          bool: true,
          title: '口座番号',
          conditions: 'checkNum'
        },
        accountName: {
          bool: true,
          title: '口座名義',
          conditions: 'checkAccountName'
        },
        otherAccountName: {
          bool: true,
          title: 'メインバンクとは異なる名義',
          conditions: 'checkAccountName'
        },
        certificate: {
          valid: true,
          message: ''
        },
        bankAccountNameCert: {
          valid: true,
          message: ''
        }
      },
      corporate: {
        bankName: {
          bool: true,
          title: '銀行名'
        },
        swiftCode: {
          bool: true,
          title: '銀行コード',
          conditions: 'checkNum'
        },
        branchName: {
          bool: true,
          title: '支店名'
        },
        branchCord: {
          bool: true,
          title: '支店コード',
          conditions: 'checkNum'
        },
        accountType: {
          bool: true,
          title: '口座種別'
        },
        accountNumber: {
          bool: true,
          title: '口座番号',
          conditions: 'checkNum'
        },
        accountName: {
          bool: true,
          title: '口座名義',
          conditions: 'checkAccountName'
        }
      }
    }
  },
  industries: {
    am_writer_1: false,
    am_writer_2: false,
    am_writer_3: false,
    am_writer_4: false,
    am_writer_5: false,
    am_writer_6: false,
    am_writer_7: false,
    am_writer_8: false,
    am_writer_etc: false,
    am_creator_1: false,
    am_creator_2: false,
    am_creator_3: false,
    am_creator_4: false,
    am_creator_5: false,
    am_creator_etc: false,
    am_designer_1: false,
    am_designer_2: false,
    am_designer_3: false,
    am_designer_4: false,
    am_designer_5: false,
    am_designer_etc: false,
    am_engineer_1: false,
    am_engineer_2: false,
    am_engineer_3: false,
    am_engineer_4: false,
    am_engineer_5: false,
    am_engineer_6: false,
    am_engineer_7: false,
    am_engineer_8: false,
    am_engineer_etc: false,
    am_entertainer_1: false,
    am_entertainer_2: false,
    am_entertainer_3: false,
    am_entertainer_4: false,
    am_entertainer_5: false,
    am_entertainer_etc: false,
    am_other_1: false,
    am_other_2: false,
    am_other_3: false,
    am_other_4: false,
    am_other_5: false,
    am_other_6: false,
    am_other_7: false,
    am_other_8: false,
    am_other_9: false,
    am_other_10: false,
    am_other_11: false,
    am_other_12: false,
    am_other_13: false,
    am_other_etc: false,
    am_other_etc_name: ''
  },
  master_industries: [
    { name: 'am_writer_1', label: 'ライター・記者' },
    { name: 'am_writer_2', label: '脚本家' },
    { name: 'am_writer_3', label: '作家・小説家' },
    { name: 'am_writer_4', label: '翻訳家' },
    { name: 'am_writer_5', label: 'コピーライター' },
    { name: 'am_writer_6', label: 'ウェブ編集者' },
    { name: 'am_writer_7', label: '雑誌・書籍編集者' },
    { name: 'am_writer_8', label: '校正・校閲' },
    { name: 'am_writer_etc', label: 'その他文筆業' },
    { name: 'am_creator_1', label: '画家・イラストレーター' },
    { name: 'am_creator_2', label: '漫画家' },
    { name: 'am_creator_3', label: 'カメラマン' },
    { name: 'am_creator_4', label: '映像クリエイター' },
    { name: 'am_creator_5', label: '音楽クリエイター' },
    { name: 'am_creator_etc', label: 'その他クリエイター' },
    { name: 'am_designer_1', label: 'グラフィックデザイナー' },
    { name: 'am_designer_2', label: 'Webデザイナー' },
    { name: 'am_designer_3', label: 'エディトリアル・デザイナー' },
    { name: 'am_designer_4', label: 'DTPオペレーター' },
    { name: 'am_designer_5', label: 'UI/UXデザイナー' },
    { name: 'am_designer_etc', label: 'その他デザイナー' },
    { name: 'am_engineer_1', label: 'サーバエンジニア' },
    { name: 'am_engineer_2', label: 'フロントエンドエンジニア' },
    { name: 'am_engineer_3', label: 'ゲームプログラマ' },
    { name: 'am_engineer_4', label: 'ITコンサルタント' },
    { name: 'am_engineer_5', label: 'アプリ・エンジニア' },
    { name: 'am_engineer_6', label: 'DBエンジニア' },
    { name: 'am_engineer_7', label: 'データアナリスト' },
    { name: 'am_engineer_8', label: 'ネットワーク・エンジニア' },
    { name: 'am_engineer_etc', label: 'その他エンジニア・プログラマー' },
    { name: 'am_entertainer_1', label: '広報・PR' },
    { name: 'am_entertainer_2', label: '広告・マーケティング' },
    { name: 'am_entertainer_3', label: '演出家' },
    { name: 'am_entertainer_4', label: '俳優・パフォーマー' },
    { name: 'am_entertainer_5', label: '声優・ナレーター' },
    { name: 'am_entertainer_etc', label: 'その他エンターテイメント' },
    { name: 'am_other_1', label: 'スタイリスト' },
    { name: 'am_other_2', label: '理容師・美容師・ヘアメイク' },
    { name: 'am_other_3', label: '研究者・リサーチャー' },
    { name: 'am_other_4', label: '経営コンサルタント' },
    { name: 'am_other_5', label: '金融・保険' },
    { name: 'am_other_6', label: '建築・不動産' },
    { name: 'am_other_13', label: '配送・運送' },
    { name: 'am_other_7', label: '医療・健康' },
    { name: 'am_other_8', label: '法務' },
    { name: 'am_other_9', label: '会計・財務・経理' },
    { name: 'am_other_10', label: '人事・労務' },
    { name: 'am_other_11', label: 'ファッション・アパレル' },
    { name: 'am_other_12', label: '飲食' },
    { name: 'am_other_etc', label: 'その他' }
  ],
  mainBanks: {
    isDisplayable: true,
    col: 3, // 2 or 3
    list: [
      { bnkCode: '0310', bnkName: 'ＧＭＯあおぞらネット銀行' },
      { bnkCode: '0005', bnkName: '三菱ＵＦＪ銀行' },
      { bnkCode: '0009', bnkName: '三井住友銀行' },
      { bnkCode: '0001', bnkName: 'みずほ銀行' },
      { bnkCode: '0036', bnkName: '楽天銀行' },
      { bnkCode: '9900', bnkName: 'ゆうちょ銀行' }
    ]
  },
  registrationStep: {
    REGISTRATION_STEP_GENERATE_INFO: 'generate-info',
    REGISTRATION_STEP_BANK: 'bank',
    REGISTRATION_STEP_CONFIRMATION: 'confirmation'
  },
  selectedPlan: {
    planID: 0,
    periodID: 0
  }
};

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions
});

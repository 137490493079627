import Vuex       from 'vuex'
import Vue        from 'vue'
import mutations  from './mutations'
import actions    from './actions'
import getters    from './getters'

Vue.use(Vuex)

var state = {
  status: {
    agree: false,
    processingApiRequest: false
  },
  page: {
    title: '',
    complete: false
  },
  invoices: [],
  unSpecifyInvoiceList: [],

  isBlockStatusPublic: false,

  termsConditions: {
    amountBilledTotal: 0,
    withholdingTaxTotal: 0,
    transactionFee: 500,
    paymentDate: '20180801',
  },
  constrains: {
    MASTER_INVOICE_STATUS_REGISTERED : 1,
  }
}

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  modules: {
  }
})

import Vue from 'vue';
import App from './App.vue';
export default (function () {
    var app = new Vue({
        el: '#compensation',
        components: {
            App: App
        }
    });
    // disable button 解約する when submit
    var btnCancelContract = document.getElementById('btn-cancel-contract');
    var cacelContractForm = document.getElementById('cancelContractForm');
    if (btnCancelContract && cacelContractForm) {
        btnCancelContract.addEventListener('click', function () {
            btnCancelContract.disabled = true;
            btnCancelContract.querySelector('.actionBtn--middle__txt').innerHTML = '処理中...';
            cacelContractForm.submit();
        });
    }
    var btnCloseModal = document.getElementById('btn-cancel-contract__close');
    var cancelContractModal = document.getElementById('modal_confirm_cacel_compensation');
    if (btnCloseModal && cancelContractModal) {
        btnCloseModal.addEventListener('click', function () {
            cancelContractModal.style.display = 'none';
        });
    }
});

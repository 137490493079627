const moment = require('moment');

export default  {
  check (inputStr) {
    let valid = this.isDate(inputStr),
        msg = (!valid) ? '日付はYYYY/MM/DDの形式で入力してください' : '',
        result = {
          valid: valid,
          message: msg
        };

    return result
  },
  isDate (value = '') {
    return moment(value, 'YYYY/MM/DD', true).isValid()
  },
}

import Vue      from 'vue'
import Router   from 'vue-router'
import App      from '../components/App'
import Complete from '../components/containers/Complete'

Vue.use(Router);
const router = new Router({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path      : '/invoices/applyQuickPay/:id',
      component : App,
      name      : 'quickPay'
    },
    {
      path      : '/invoices/applyOriginalQuickPay/:id',
      component : App,
      name      : 'originQuickPay'
    },
    {
      path      : '/invoices/add/complete',
      component : Complete,
      name      : 'complete'
    },
  ]
});
router.afterEach((to, from) => {
  if (from.matched.length) {
    Vue.nextTick(() => {
      window.dataLayer.push({ event: 'content-view' });
    });
  }
});
export default router;

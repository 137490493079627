var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import * as conf from '../../config';
var Free = /** @class */ (function (_super) {
    __extends(Free, _super);
    function Free() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Free.prototype, "conf", {
        get: function () {
            return conf;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Free.prototype, "parentComponent", {
        get: function () {
            return this.$parent;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Free.prototype, "isDisabledBtn", {
        get: function () {
            return this.parentComponent.isFreePlanDisable || this.parentComponent.isOnApiRequest || this.parentComponent.isDisabledBtn(conf.MASTER_MEMBERSHIP_PLAN_FREE);
        },
        enumerable: false,
        configurable: true
    });
    Free = __decorate([
        Component
    ], Free);
    return Free;
}(Vue));
export default Free;

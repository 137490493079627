const state = {
  uploadedFile: [],
  newUploadFile: []
};

// actions
const actions = {
  upload(context, payload) {
    context.commit('upload', payload);
  },
  delete(context, payload) {
    context.commit('delete', payload);
  }
};

// mutations
const mutations = {
  upload(state, payload) {
    let type = '';
    const fileName = payload.name;
    if (fileName.includes('.')) type = fileName.split('.').pop().toLowerCase();
    const displayData = window.URL.createObjectURL(payload);
    const uploadData = payload;
    state.uploadedFile.push({ uploadData, displayData, type, fileName });
    state.newUploadFile.push({ uploadData, displayData, type, fileName });
  },
  delete(state, payload) {
    if (payload.newUploadFileIndex != null) {
      state.newUploadFile.splice(payload.newUploadFileIndex, 1);
    }

    if (payload.uploadedFileIndex != null) {
      state.uploadedFile.splice(payload.uploadedFileIndex, 1);
    }
  }
};

// getter
const getters = {
  getUploadedFile(fileUploaderState) {
    return fileUploaderState.uploadedFile;
  },

  getNewUploadFile(fileUploaderState) {
    return fileUploaderState.newUploadFile;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

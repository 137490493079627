var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Mutation, VuexModule, getModule, Module, Action } from 'vuex-module-decorators';
import store from '.';
import ajax from '../../../lib/Ajax';
import FileCheck from '../../../lib/validator/File';
import Constant from '@lib/Constants';
function validateFile(file) {
    return __awaiter(this, void 0, void 0, function () {
        var typeCheck, sizeCheck;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, FileCheck.checkInvoiceFileType(file, '', 'jpg,jpeg,png,gif,pdf')];
                case 1:
                    typeCheck = _a.sent();
                    // Check file type
                    if (!typeCheck.valid) {
                        return [2 /*return*/, '.jpg / .jpeg / .png / .gif / .pdf 以外のファイル形式には対応してません。'];
                    }
                    sizeCheck = FileCheck.checkFileSize(file, Constant.FILE_SIZE_OF_UP_INVOICE_FILE);
                    if (!sizeCheck.valid) {
                        return [2 /*return*/, 'ファイルサイズは10MB以内にしてください。'];
                    }
                    return [2 /*return*/, ''];
            }
        });
    });
}
var Main = /** @class */ (function (_super) {
    __extends(Main, _super);
    function Main() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.files = [];
        _this.warnMessage = '';
        return _this;
    }
    Object.defineProperty(Main.prototype, "isShowButton", {
        get: function () {
            return this.files.length > 0 && !this.isError;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "isError", {
        get: function () {
            for (var _i = 0, _a = this.files; _i < _a.length; _i++) {
                var file = _a[_i];
                if (file.errMessage) {
                    return true;
                }
            }
            return false;
        },
        enumerable: false,
        configurable: true
    });
    Main.prototype.loadInitialData = function () {
        // Retrieve the object from storage
        localStorage.removeItem('invoiceUploads');
        var retrievedObject = localStorage.getItem('invoiceUploads');
        if (retrievedObject) {
            this.files = JSON.parse(retrievedObject);
        }
    };
    Main.prototype.addFile = function (file) {
        return __awaiter(this, void 0, void 0, function () {
            var type, fileName, errMessage;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        type = '';
                        fileName = file.name;
                        return [4 /*yield*/, validateFile(file)];
                    case 1:
                        errMessage = _a.sent();
                        if (fileName.includes('.'))
                            type = fileName.split('.').pop().toLowerCase();
                        this.files.push({
                            uploadData: file,
                            displayUrl: window.URL.createObjectURL(file),
                            fileName: fileName,
                            type: type,
                            errMessage: errMessage
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    Main.prototype.removeFile = function (index) {
        this.files.splice(index, 1);
    };
    Main.prototype.setWarnMessage = function (mess) {
        this.warnMessage = mess;
    };
    Main.prototype.uploadFiles = function (uploadingParams) {
        return __awaiter(this, void 0, void 0, function () {
            var csrfToken, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        csrfToken = document.CSRF_TOKEN;
                        return [4 /*yield*/, ajax.post('/apiInternal/uploadTmpFileForInvoicesAdd', uploadingParams, { 'X-CSRF-Token': csrfToken, 'Content-Type': 'multipart/form-data' })];
                    case 1:
                        res = _a.sent();
                        if (res.data.status == 200) {
                            window.location.href = '/invoices/add'; // redirect
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Main.prototype.selectFiles = function (files) {
        return __awaiter(this, void 0, void 0, function () {
            var totalUpload, _i, files_1, fileUpload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setWarnMessage('');
                        totalUpload = this.files.length + files.length;
                        if (totalUpload > Constant.MAX_FILE_UPLOAD_LOAD_INVOICE) {
                            this.setWarnMessage(Constant.MAX_FILE_UPLOAD_LOAD_INVOICE + "\u30D5\u30A1\u30A4\u30EB\u306E\u307F\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9\u53EF\u80FD\u3067\u3059");
                            return [2 /*return*/];
                        }
                        _i = 0, files_1 = files;
                        _a.label = 1;
                    case 1:
                        if (!(_i < files_1.length)) return [3 /*break*/, 4];
                        fileUpload = files_1[_i];
                        return [4 /*yield*/, this.addFile(fileUpload)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    Main.prototype.sendToInvoicePage = function () {
        return __awaiter(this, void 0, void 0, function () {
            var uploadingParams;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        uploadingParams = new FormData();
                        this.files.forEach(function (file, index) {
                            uploadingParams.append("invoice_pdf" + (index + 1), file.uploadData);
                        });
                        return [4 /*yield*/, this.uploadFiles(uploadingParams)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Main.prototype.delete = function (index) {
        this.setWarnMessage('');
        this.removeFile(index);
    };
    __decorate([
        Mutation
    ], Main.prototype, "loadInitialData", null);
    __decorate([
        Mutation
    ], Main.prototype, "addFile", null);
    __decorate([
        Mutation
    ], Main.prototype, "removeFile", null);
    __decorate([
        Mutation
    ], Main.prototype, "setWarnMessage", null);
    __decorate([
        Mutation
    ], Main.prototype, "uploadFiles", null);
    __decorate([
        Action({ rawError: true })
    ], Main.prototype, "selectFiles", null);
    __decorate([
        Action({ rawError: true })
    ], Main.prototype, "sendToInvoicePage", null);
    __decorate([
        Action({ rawError: true })
    ], Main.prototype, "delete", null);
    Main = __decorate([
        Module({ dynamic: true, store: store, name: 'main' })
    ], Main);
    return Main;
}(VuexModule));
export default getModule(Main);

import { encode } from 'iconv-lite';
// 参考: https://mendo.work/2020/10/04/javascriptangular%E3%81%A7jis%E6%BC%A2%E5%AD%97%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E5%88%A4%E5%AE%9A%E3%81%99%E3%82%8B/
export function containsCommonChars(target) {
    var chars = Array.prototype.slice.call(target);
    return chars.reduce(function (acc, char) {
        var hex = encode(char, 'Shift_JIS');
        // シングルバイト (Shift-JISで変換できない文字は除く 例：澍)
        if (hex.length == 1 && hex[0] !== 63) {
            return acc && true;
        }
        // 非漢字 ※実際は未定義エリアが散在しているため絞り込む必要有
        //  → コード表：第1面-1区-01 ～ 第1面-8区-32
        //  → SJIS：81-41 ～ 84-BE ＝ 129-65 ～ 132-190
        if ((hex[0] == 129 && 65 <= hex[1])
            || (130 <= hex[0] && hex[0] <= 131)
            || (hex[0] == 132 && hex[1] <= 190)) {
            return acc && true;
        }
        // 第一水準
        //  → コード表：第1面-16区-01 ～ 第1面-47区-51
        //  → SJIS：88-9f ～ 98-72 ＝ 136-159 ～ 152-114
        if ((hex[0] == 136 && 159 <= hex[1])
            || (137 <= hex[0] && hex[0] <= 151)
            || (hex[0] == 152 && hex[1] <= 114)) {
            return acc && true;
        }
        // 第二水準
        //  → コード表：第1面-48区-01 ～ 第1面―84区-6
        //  → SJIS：98-9f ～ EA-A4 ＝ 152-159 ～ 234-164
        if ((hex[0] == 152 && 159 <= hex[1])
            || (153 <= hex[0] && hex[0] <= 233)
            || (hex[0] == 234 && hex[1] <= 164)) {
            return acc && true;
        }
        return false;
    }, true);
}

/* eslint-disable no-param-reassign */
import * as conf from '../../config';

const state = {
  invoiceEvidenceUploadContent: {
    invoiceEvidence1: {
      title: '請求書の送付済み確認',
      content: '',
      options: [
        {
          id: conf.EVIDENCE_FILEUPLOAD_OPT,
          content: '写しをアップロードする',
          isUploadFile: true
        },
        {
          id: conf.EVIDENCE_INVOICE_MAIL_OPT,
          content: '(shinsa@freenance.jp）に転送済'
        },
        {
          id: conf.EVIDENCE_NONE_OPT,
          content: '提出できるものがない'
        }
      ]
    },
    invoiceEvidence2: {
      title: '請求書の受領済み確認',
      content: '',
      options: [
        {
          id: conf.EVIDENCE_FILEUPLOAD_OPT,
          content: '写しをアップロードする',
          isUploadFile: true
        },
        {
          id: conf.EVIDENCE_INVOICE_MAIL_OPT,
          content: '(shinsa@freenance.jp）に転送済'
        },
        {
          id: conf.EVIDENCE_NONE_OPT,
          content: '提出できるものがない'
        }
      ]
    },
    invoiceEvidence3: {
      title: 'そのほか',
      content: '',
      options: [
        {
          id: conf.EVIDENCE_FILEUPLOAD_OPT,
          content: '写しをアップロードする',
          isUploadFile: true
        },
        {
          id: conf.EVIDENCE_INVOICE_MAIL_OPT,
          content: '(shinsa@freenance.jp）に転送済'
        },
        {
          id: conf.EVIDENCE_NONE_OPT,
          content: '提出できるものがない'
        }
      ]
    }
  },
  invoiceEvidenceValidation: {
    invoiceEvidence1: {
      isUploadFileError: false,
      message: ''
    },
    invoiceEvidence2: {
      isUploadFileError: false,
      message: ''
    },
    invoiceEvidence3: {
      isUploadFileError: false,
      message: ''
    }
  },
  invoiceEvidenceUploadValues: {
    invoiceEvidence1: {
      isEdited: false,
      isUploaded: false,
      selectOption: conf.EVIDENCE_NONE_OPT,
      fileUploads: [],
      isCollapse: true
    },
    invoiceEvidence2: {
      isEdited: false,
      isUploaded: false,
      selectOption: conf.EVIDENCE_NONE_OPT,
      fileUploads: [],
      isCollapse: true
    },
    invoiceEvidence3: {
      isEdited: false,
      isUploaded: false,
      selectOption: conf.EVIDENCE_NONE_OPT,
      fileUploads: [],
      isCollapse: true
    }
  },
};

// getter
const getters = {
  getInvoiceEvidenceUploadContent(evidenceState) {
    return evidenceState.invoiceEvidenceUploadContent;
  },

  getInvoiceEvidenceUploadValues(evidenceState) {
    return evidenceState.invoiceEvidenceUploadValues;
  },

  getInvoiceEvidenceValidation(evidenceState) {
    return evidenceState.invoiceEvidenceValidation;
  }
};

// mutations
const mutations = {
  toggleEvidenceEditMark(evidenceState, payload) {
    const { key, evidenceType, selectOpt } = payload;
    const evidenceStateValues = evidenceType === 'user'
      ? evidenceState.userEvidenceUploadValues : evidenceState.invoiceEvidenceUploadValues;
    const evidenceVal = evidenceStateValues[key];
    evidenceVal.isEdited = selectOpt !== conf.EVIDENCE_FILEUPLOAD_OPT || evidenceVal.fileUploads.length > 0;
  },

  setFileUpload(evidenceState, payload) {
    const { file, key, evidenceType } = payload;
    const fileName = file.name;

    let fileType = '';
    if (fileName.includes('.')) {
      fileType = fileName.split('.').pop().toLowerCase();
    }

    const encodeFile = window.URL.createObjectURL(file);
    const obj = {
      uploadData: file,
      displayData: encodeFile,
      type: fileType
    };

    const evidenceStateValues = evidenceType === 'user'
      ? evidenceState.userEvidenceUploadValues : evidenceState.invoiceEvidenceUploadValues;
    evidenceStateValues[key].fileUploads.push(obj);
  },

  deleteFileUpload(evidenceState, payload) {
    const { key, idx, evidenceType } = payload;
    const evidenceStateValues = evidenceType === 'user'
      ? evidenceState.userEvidenceUploadValues : evidenceState.invoiceEvidenceUploadValues;
    evidenceStateValues[key].fileUploads.splice(idx, 1);
  },

  setEvidenceValidation(evidenceState, payload) {
    const { key, evidenceType } = payload;
    const evidenceStateValidation = evidenceType === 'user'
      ? evidenceState.userEvidenceValidation : evidenceState.invoiceEvidenceValidation;
    evidenceStateValidation[key].isUploadFileError = payload.isUploadFileError;
    evidenceStateValidation[key].message = payload.message;
  },

  loadInvoiceEvidences(evidenceState, payload) {
    const { evidences, fileUrls } = payload;

    for (let i = 1; i <= conf.NUM_INVOICE_EVIDENCES; i += 1) {
      const selectOpt = evidences[`additional_evidence_${i}`].selected_option;
      // In case if checkbox is checked, reload the value
      evidenceState.invoiceEvidenceUploadValues[`invoiceEvidence${i}`].selectOption = selectOpt;
      evidenceState.invoiceEvidenceUploadValues[`invoiceEvidence${i}`].isEdited = true;

      if (selectOpt === conf.EVIDENCE_FILEUPLOAD_OPT) {
        evidenceState.invoiceEvidenceUploadValues[`invoiceEvidence${i}`].isUploaded = true;
      }

      if (!fileUrls[`additional_evidence_${i}`]) {
        continue;
      }
      const evFiles = Object.entries(fileUrls[`additional_evidence_${i}`]);
      evFiles.forEach(async (f) => {
        const [fileName, fileUrl] = f;
        const fileType = fileName.split('.').pop().toLowerCase();
        const file = {
          displayData: fileUrl, // 画像表示用のURLをリンク先に設定する
          key: fileName,
          type: fileType
        };
        evidenceState.invoiceEvidenceUploadValues[`invoiceEvidence${i}`].fileUploads.push(file);
      });
    }
  },

  loadEvidenceContent(evidenceState) {
    evidenceState.invoiceEvidenceUploadContent.invoiceEvidence3.content = '私たちが今回の取引について理解するのに役立つものがあればご提供ください。納品物や契約書、どんな役務を提供したかなどさまざまな情報が審査の役に立ちます。';
  },

  loadEvidenceContentHasClientName(evidenceState) {
    const clientName = this.state.clientInfo.name || 'クライアント';
    evidenceState.invoiceEvidenceUploadContent.invoiceEvidence1.content = `この請求書がすでに${clientName}に送付済みであることを確認するために、請求書の送付を示すエビデンスをご提供ください。<br/>もっともシンプルなのは請求書を送付する際に<br/><a href="mailto:shinsa@freenance.jp">shinsa@freenance.jp</a>をBCCにいれることです。`;
    evidenceState.invoiceEvidenceUploadContent.invoiceEvidence2.content = `この請求書を${clientName}が受領済みであることを確認するために、${clientName}からの返信などをご提供ください。<br/>私たちが重要視しているのは、<ul class="invoiceEvidence2"><li>${clientName}が請求書を確かに受領済みであること</li><li>支払い口座がフリーナンス口座であること</li><li>支払い金額と支払い期日について合意が取れていること<br>です。</li></ul>`;
  },

  toggleCollapse(evidenceState, payload) {
    if (payload.evidenceType === 'user') {
      evidenceState.userEvidenceUploadValues[payload.evidenceKey].isCollapse = !evidenceState.userEvidenceUploadValues[payload.evidenceKey].isCollapse;
    } else {
      evidenceState.invoiceEvidenceUploadValues[payload.evidenceKey].isCollapse = !evidenceState.invoiceEvidenceUploadValues[payload.evidenceKey].isCollapse;
    }
  }
};

// actions
const actions = {
  addEvidenceFileUpload({ commit }, payload) {
    commit('setFileUpload', payload);
    payload.selectOpt = conf.EVIDENCE_FILEUPLOAD_OPT;
    commit('toggleEvidenceEditMark', payload);
  },

  deleteEvidenceFileUpload({ commit }, payload) {
    commit('deleteFileUpload', payload);
    payload.selectOpt = conf.EVIDENCE_FILEUPLOAD_OPT;
    commit('toggleEvidenceEditMark', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

import Cookie from 'js-cookie';
export function initModal(activeModalClass) {
    var modalTriggers = Array.from(document.getElementsByClassName(activeModalClass));
    var _loop_1 = function (i) {
        var id = modalTriggers[i].getAttribute('data-modal');
        var modalWindow = document.getElementById(id);
        var closeIcon = modalWindow.getElementsByClassName('close_icon')[0];
        modalTriggers[i].addEventListener('click', function () {
            modalWindow.style.display = 'block';
        });
        closeIcon.addEventListener('click', function () {
            modalWindow.style.display = 'none';
        });
        window.addEventListener('click', function (event) {
            if (event.target === modalWindow) {
                modalWindow.style.display = 'none';
            }
        });
    };
    for (var i = 0; i < modalTriggers.length; i += 1) {
        _loop_1(i);
    }
}
export function initOneTimeModal(modalId, urlElementID) {
    var modalWindow = document.getElementById(modalId);
    if (!modalWindow)
        return;
    var closeIcon = modalWindow.getElementsByClassName('close_icon')[0];
    var cookieKey = modalId + "_is_hiding";
    var isAutoShow = modalWindow.getAttribute('data-auto-show') == '1';
    var expiringDateData = modalWindow.getAttribute('data-expiring-date');
    var expiringDate = expiringDateData ? new Date(expiringDateData) : undefined;
    if (!Cookie.get(cookieKey) || isAutoShow) {
        modalWindow.style.display = 'block';
    }
    closeIcon.addEventListener('click', function () {
        modalWindow.style.display = 'none';
        Cookie.set(cookieKey, 'true', { expires: expiringDate });
    });
    window.addEventListener('click', function (event) {
        if (event.target === modalWindow) {
            modalWindow.style.display = 'none';
            Cookie.set(cookieKey, 'true', { expires: expiringDate });
        }
    });
    var urlElement = document.getElementById(urlElementID);
    if (urlElement) {
        urlElement.addEventListener('click', function () {
            modalWindow.style.display = 'none';
            Cookie.set(cookieKey, 'true', { expires: expiringDate });
        });
    }
}

/**
 *  App.Util.camelize
 *  @function (スネークケースな)textを大文字キャメルケースにして返す
 *  @param    {string} text
 *  @return   {string} result
 */
function camelize (text) {
  text = text || "";
  return text.replace(/(^.)|_([a-z])/ig, function (all, letter) {
    if (letter) {
      return letter.toUpperCase();
    } else {
      return all.replace("_", "").toUpperCase();
    }
  });
}

function isArray(obj) {
  return Object.prototype.toString.call(obj) === '[object Array]';
}

/**
 * App.Page.HogeFugaと定義されるKlassを初期化
 *
 * またApp.Const.url_patternsのパターンに
 * マッチするページを初期化する
 *
 * @function templateからDOM化
 * @return  {void}
 */
export default function URLLoader (url_patterns) {
  // url_patternsに指定されたURLパターンによる初期化
  var p = location.pathname,
    pttrns = url_patterns,
    i = 0,
    l = pttrns.length,
    j = 0;
  for (; i < l; i++) {
    if (isArray(pttrns[i].pattern)) {
      var m = pttrns[i].pattern.length;
      for (j = 0; j < m; j++) {
        if (pttrns[i].pattern[j].test(p)) {
          pttrns[i].page();
          return;
        }
      }
    } else {
      if (pttrns[i].pattern.test(p)) {
        pttrns[i].page();
        return;
      }
    }
  }
}

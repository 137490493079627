import { halfToFullKana, fullToHalfKana, smallToBigKana } from '../../lib/Convert';
import Constants from '@lib/Constants';
export default {
    checkAccountName: function (accountName) {
        if (accountName.match(Constants.ACCOUNT_NAME_REGEX)) {
            var b = this.divideCharacter(accountName);
            return b.length <= 40;
        }
        return false;
    },
    divideCharacter: function (rawTxt) {
        var arrReplaceData = [
            ['ガ', 'カ゛'],
            ['ギ', 'キ゛'],
            ['グ', 'ク゛'],
            ['ゲ', 'ケ゛'],
            ['ゴ', 'コ゛'],
            ['ザ', 'サ゛'],
            ['ジ', 'シ゛'],
            ['ズ', 'ス゛'],
            ['ゼ', 'セ゛'],
            ['ゾ', 'ソ゛'],
            ['ダ', 'タ゛'],
            ['ヂ', 'チ゛'],
            ['ヅ', 'ツ゛'],
            ['デ', 'テ゛'],
            ['ド', 'ト゛'],
            ['バ', 'ハ゛'],
            ['ビ', 'ヒ゛'],
            ['ブ', 'フ゛'],
            ['ベ', 'ヘ゛'],
            ['ボ', 'ホ゛'],
            ['パ', 'ハ゜'],
            ['ピ', 'ヒ゜'],
            ['プ', 'フ゜'],
            ['ペ', 'ヘ゜'],
            ['ポ', 'ホ゜'],
            ['ヴ', 'ウ゛']
        ];
        if (rawTxt.length > 0) {
            var _loop_1 = function (i) {
                var replaceData = arrReplaceData[i];
                rawTxt = rawTxt.replace(new RegExp(replaceData[0], 'g'), function () { return replaceData[1]; });
            };
            for (var i = 0; i < arrReplaceData.length; i += 1) {
                _loop_1(i);
            }
        }
        return rawTxt;
    },
    convertAccName: function (accName) {
        return fullToHalfKana(smallToBigKana(halfToFullKana(accName)));
    }
};

export default  {
  check(amount, withholdingTax) {
    const valid = this.isValidWithholdingTax(amount, withholdingTax);
    const msg = (!valid) ? '源泉徴収税は上限値以下を入力してください' : '';
    const result = {
          valid: valid,
          message: msg
        };

    return result;
  },
  isValidWithholdingTax(amount, withholdingTax) {
    // https://github.com/freenance/frnc-frontend/issues/3369
    // 請求金額が100万を超えない場合、一般的に源泉徴収税額は10.21%としていますが、
    // ユーザの入力が困難あって、源泉徴収税の入力の問い合わせがよく来てるために、12%にしています。
    return withholdingTax <= Math.floor(Math.max(amount-1000000, 0) * 20.42/100 + Math.min(amount, 1000000) * 12/100);
  },
}

export default  {
  check (inputStr, title) {
    let valid = this.isRequired(inputStr),
        msg = (!valid) ? title + 'を入力してください' : '',
        result = {
          valid: valid,
          message: msg
        };

    return result
  },
  isRequired (value = '') {
    if (!value) {
      return false;
    }
    return (value !== '' && typeof value.match === "function" && !!value.match(/\S/g));
  }
}

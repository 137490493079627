var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Mutation, Action, VuexModule, getModule, Module } from 'vuex-module-decorators';
import store from '.';
import ajax from '../../../lib/Ajax';
var Main = /** @class */ (function (_super) {
    __extends(Main, _super);
    function Main() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.editorData = {
            profilePageEnable: false,
            incorporated: false,
            isSelectablePenName: false,
            isSelectableBusinessNameOrCompanyName: false,
            profileUserNameDisplayOption: 1,
            profileBankInfoDisplayOption: 1,
            profileTitle: '',
            twitterUsername: '',
            facebookUsername: '',
            instagramUsername: '',
            profileIntroText: ''
        };
        _this.newPhotoDataUrl = '';
        _this.originPhotoDataUrl = '';
        _this.profileUrl = '';
        _this.profileUrlDisplayable = _this.editorData.profilePageEnable;
        _this.processingApiRequest = false;
        _this.profilePhotoChanged = false;
        return _this;
    }
    Main.prototype.loadInitialData = function (state) {
        this.editorData = state.editorData;
        this.originPhotoDataUrl = state.originPhotoDataUrl;
        this.profileUrl = state.profileUrl;
        this.profileUrlDisplayable = state.editorData.profilePageEnable;
    };
    Main.prototype.addPhoto = function (payload) {
        this.newPhotoDataUrl = payload;
        this.profilePhotoChanged = true;
    };
    Main.prototype.deleteOriginPhoto = function () {
        this.newPhotoDataUrl = '';
        this.profilePhotoChanged = true;
    };
    Main.prototype.deleteTempPhoto = function () {
        this.newPhotoDataUrl = '';
        this.profilePhotoChanged = false;
    };
    Main.prototype.updateEditorData = function (data) {
        this.editorData = __assign({}, data);
    };
    Main.prototype.updateProcessingApiRequest = function (status) {
        this.processingApiRequest = status;
    };
    Main.prototype.updateProfileUrlDisplayable = function (status) {
        this.profileUrlDisplayable = status;
    };
    Main.prototype.updateUserProfile = function () {
        return __awaiter(this, void 0, void 0, function () {
            var csrfToken, response, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.updateProcessingApiRequest(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        csrfToken = document.CSRF_TOKEN;
                        return [4 /*yield*/, ajax.post('/apiInternal/updateUserProfile', {
                                profile_page_enable: this.editorData.profilePageEnable,
                                profile_username_display_option: this.editorData.profileUserNameDisplayOption,
                                profile_bank_info_display_option: this.editorData.profileBankInfoDisplayOption,
                                profile_title: this.editorData.profileTitle,
                                twitter_username: this.editorData.twitterUsername,
                                facebook_username: this.editorData.facebookUsername,
                                instagram_username: this.editorData.instagramUsername,
                                profile_intro_text: this.editorData.profileIntroText,
                                profile_photo_data_url: this.newPhotoDataUrl,
                                profile_photo_changed: this.profilePhotoChanged
                            }, {
                                'X-CSRF-Token': csrfToken
                            })];
                    case 2:
                        response = _a.sent();
                        if (response.status !== 200) {
                            alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください');
                        }
                        if (response.data.status !== 200) {
                            alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください');
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.updateProcessingApiRequest(false);
                        alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください');
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        Mutation
    ], Main.prototype, "loadInitialData", null);
    __decorate([
        Mutation
    ], Main.prototype, "addPhoto", null);
    __decorate([
        Mutation
    ], Main.prototype, "deleteOriginPhoto", null);
    __decorate([
        Mutation
    ], Main.prototype, "deleteTempPhoto", null);
    __decorate([
        Mutation
    ], Main.prototype, "updateEditorData", null);
    __decorate([
        Mutation
    ], Main.prototype, "updateProcessingApiRequest", null);
    __decorate([
        Mutation
    ], Main.prototype, "updateProfileUrlDisplayable", null);
    __decorate([
        Action
    ], Main.prototype, "updateUserProfile", null);
    Main = __decorate([
        Module({ dynamic: true, store: store, name: 'main', namespaced: true })
    ], Main);
    return Main;
}(VuexModule));
export default getModule(Main);

export default {
    checkFileSize: function (file, maxSize) {
        var valid = this.isFileSizeExceed(file, maxSize);
        var msg = (!valid) ? 'アップロードすることができるファイルサイズの上限を超えています。' : '';
        var result = { valid: valid, message: msg };
        return result;
    },
    isFileSizeExceed: function (value, maxSize) {
        var fileSize = value.size;
        if (fileSize > maxSize) {
            return false;
        }
        return true;
    },
    checkFileType: function (file, isImage) {
        if (isImage === void 0) { isImage = false; }
        if (isImage) {
            return {
                valid: new RegExp('image/jpeg|image/png').test(file.type),
                message: '画像(JPEG、PNG)ファイルのみアップロード可能です'
            };
        }
        return {
            valid: new RegExp('application/pdf|image/jpeg|image/png|image/gif').test(file.type),
            message: 'PDFまたは画像(JPEG、PNG、GIF)ファイルのみアップロード可能です'
        };
    },
    checkInvoiceFileType: function (file, fileExtension, allowedFileTypes) {
        var message = allowedFileTypes.toUpperCase().split(',').join('、') + "\u30D5\u30A1\u30A4\u30EB\u306E\u307F\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9\u53EF\u80FD\u3067\u3059";
        var types = allowedFileTypes.split(',');
        // In case, file is already uploaded
        if (fileExtension) {
            return Promise.resolve({
                valid: types.includes(fileExtension.toLowerCase()),
                message: message
            });
        }
        // When file is a CSV, we need to check type from "file.name" since "file.type" is an empty string.
        var allowedCsv = false;
        var reg = '';
        types.forEach(function (type) {
            switch (type) {
                case 'pdf':
                    reg += 'application/pdf|';
                    break;
                case 'jpg':
                case 'jpeg':
                    reg += 'image/jpeg|';
                    break;
                case 'png':
                    reg += 'image/png|';
                    break;
                case 'gif':
                    reg += 'image/gif|';
                    break;
                case 'csv':
                    allowedCsv = true;
                    break;
                default:
                    break;
            }
        });
        reg = reg.substring(0, reg.length - 1);
        return new Promise(function (resolve, reject) {
            var fileReader = new FileReader();
            fileReader.onloadend = function (e) {
                // @ts-ignore
                var arr = (new Uint8Array(e.target.result)).subarray(0, 4);
                var header = "";
                for (var i = 0; i < arr.length; i++) {
                    header += arr[i].toString(16);
                }
                var type = '';
                switch (header) {
                    case "25504446":
                        type = "application/pdf";
                        break;
                    case "89504e47":
                        type = "image/png";
                        break;
                    case "47494638":
                        type = "image/gif";
                        break;
                    case "ffd8ffe0":
                    case "ffd8ffe1":
                    case "ffd8ffe2":
                    case "ffd8ffe3":
                    case "ffd8ffe8":
                        type = "image/jpeg";
                        break;
                    default:
                        type = "unknown";
                        break;
                }
                return resolve({
                    valid: (reg ? new RegExp(reg).test(type) : false) || (allowedCsv ? file.name.endsWith('.csv') : false),
                    message: message
                });
            };
            fileReader.readAsArrayBuffer(file);
        });
    },
    checkIdentificationFileType: function (file, isImage) {
        if (isImage === void 0) { isImage = false; }
        if (isImage) {
            return {
                valid: new RegExp('image/jpeg|image/png').test(file.type),
                message: '画像(JPEG、PNG)ファイルのみアップロード可能です'
            };
        }
        return {
            valid: new RegExp('image/jpeg|image/png|image/gif').test(file.type),
            message: '画像(JPEG、PNG、GIF)ファイルのみアップロード可能です'
        };
    }
};

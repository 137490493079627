export const ACTION_CHECKOUT_SESSION = 1;
export const ACTION_CANCEL_SUBSCRIPTION = 2;
export const ACTION_UPGRADE_SUBSCRIPTION = 3;
export const ACTION_DOWNGRADE_SUBSCRIPTION = 4;
export const ACTION_UPGRADE_SUBSCRIPTION_WITHOUT_CASHBACK = 5;
export const ACTION_COMFIRM_FREE_PLAN_BY_CURRENT_USER = 6;
export const ACTION_COMFIRM_TRIAL_PLAN_BY_CURRENT_USER = 7;
export const ACTION_MEMBERSHIP_TRIAL_CHECKOUT_SESSION = 8;

export const MASTER_MEMBERSHIP_PLAN_FREE = 1;
export const MASTER_MEMBERSHIP_PLAN_REGULAR = 2;
export const MASTER_MEMBERSHIP_PLAN_PREMIUM = 3;

export const MEMBERSHIP_PLAN_FREE_TITLE = 'フリー';
export const MEMBERSHIP_PLAN_REGULAR_TITLE = 'レギュラー';
export const MEMBERSHIP_PLAN_PREMIUM_TITLE = 'プレミアム';

export const MASTER_MEMBERSHIP_BILLING_PERIODS_NONE = 0;
export const MASTER_MEMBERSHIP_BILLING_PERIODS_MONTHLY = 1;
export const MASTER_MEMBERSHIP_BILLING_PERIODS_YEARLY = 2;

export const MEMBERSHIP_PERIODS_MONTHLY_TITLE = '月払い';
export const MEMBERSHIP_PERIODS_YEARLY_TITLE = '年払い';

// Plan price
export const FREE_PRICE = 0;
export const REGULAR_MONTHLY_PRICE = 590;
export const REGULAR_YEARLY_PRICE = 5880;
export const PREMIUM_MONTHLY_PRICE = 1200;
export const PREMIUM_YEARLY_PRICE = 11760;

export const PLAN_SIGNUP_FREE_MEMBERSHIP_BTN = '無料で登録';
export const PLAN_SIGNUP_MEMBERSHIP_BTN = '30日間無料で登録';

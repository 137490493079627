import ajax from '../../../lib/Ajax';

export default {
  // Uploading attached files
  upload({ commit, state }, args) {
    const csrfToken = document.CSRF_TOKEN;
    ajax.post(
      '/apiInternal/uploadUserEvidence/',
      args.data,
      {
        'Content-Type': 'multipart/form-data',
        'X-CSRF-Token': csrfToken
      }
    ).then(function(res){
      args.callback();
      location.href = args.redirect;
    }).catch(function(e){
      console.log(e);
      alert("エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください");
    });
  },
};


<template>
  <h2 class="sectionTitle">
    <img src="/images/icon-bill.svg" alt="" class="sectionTitle__icon" />
    <span class="sectionTitle__txt">
      請求書の新規登録
    </span>
  </h2>
</template>

<script>
export default {
  name: 'page-title',
}
</script>

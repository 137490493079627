<template>
  <div class="applyConfirm">
    <div class="section">
      <h2 class="lineTitle"><span class="lineTitle__txt">お客様情報入力</span></h2>
      <div class="section__inner">
        <div class="grid--2col">
          <item2col :label="'法人名'" :value="userInfo.corporateName"></item2col>
          <item2col :label="'法人名カナ'" :value="userInfo.corporateNameKana"></item2col>
          <item2col :label="'法人番号'" :value="userInfo.corporateNumber"></item2col>
        </div>
        <div class="grid--2col">
          <item2col :label="'姓'" :value="userInfo.familyName"></item2col>
          <item2col :label="'名'" :value="userInfo.firstName"></item2col>
          <item2col :label="'セイ'" :value="userInfo.familyNameKana"></item2col>
          <item2col :label="'メイ'" :value="userInfo.firstNameKana"></item2col>
          <item2col :label="'生年月日'" :value="userInfo.birthDateYear+'年'+userInfo.birthDateMonth+'月'+userInfo.birthDateDay+'日'"></item2col>
          <item2col :label="'性別'" :value="select.sex[userInfo.sex]"></item2col>
          <item2col :label="'郵便番号'" :value="userInfo.postalCode"></item2col>
          <item2col :label="'都道府県'" :value="select.prefectures[userInfo.prefecturesId]"></item2col>
        </div>
        <div class="grid--2col">
          <item2col :label="'市区町村'" :value="userInfo.address"></item2col>
          <item2col :label="'市区町村（カナ）'" :value="userInfo.addressKana"></item2col>
          <item2col :label="'町域・番地'" :value="userInfo.areaDetail"></item2col>
          <item2col :label="'町域・番地カナ（カナ・数字・ハイフン）'" :value="userInfo.areaDetailKana"></item2col>
          <item2col :label="'建物名'" :value="userInfo.apartmentName ? userInfo.apartmentName : '-'"></item2col>
          <item2col :label="'建物名カナ（カナ・数字）'" :value="userInfo.apartmentNameKana ? userInfo.apartmentNameKana : '-'"></item2col>
          <item2col :label="'法人電話番号'" :value="userInfo.tel"></item2col>
        </div>
        <div class="grid--2col">
          <div class="grid--2col__item">
            <dl class="itemBlock">
              <dt class="itemBlock__title">業種</dt>
              <dd class="itemBlock__content">
                <div v-for="(ind, index) in listCheckedIndustries" :key="index">
                  {{ind}}
                </div>
              </dd>
            </dl>
          </div>
          <item1col :label="'その他の業種名'" :value="userInfo.industry['am_other_etc_name']"></item1col>
          <item2col :label="'業歴'" :value="userInfo.businessHistory"></item2col>
          <item2col :label="'設立年（西暦）'" :value="userInfo.establishedYear"></item2col>
          <item2col :label="'従業員数'" :value="userInfo.employees"></item2col>
          <item2col :label="'資本金（万円）'" :value="userInfo.capital | moneyLocaleString"></item2col>
          <item2col :label="'前年度売上（万円）'" :value="userInfo.salesLastYear | moneyLocaleString"></item2col>
          <item2col :label="'借入有無'" :value="select.borrowingPresence[userInfo.borrowingPresence]"></item2col>
          <item2col :label="'何行から'" :value="userInfo.whichBank ? userInfo.whichBank : '-' "></item2col>
        </div>
        <item1col :label="'借入額合計（万円）'" :value="debtAmount"></item1col>
        <div class="grid--2col">
          <item2col :label="'請求書1枚の平均請求額（万円）'" :value="userInfo.averageInvoiceAmount | moneyLocaleString"></item2col>
          <item2col :label="'月間売上平均（万円）'" :value="userInfo.monthlySalesAverage | moneyLocaleString"></item2col>
        </div>
        <item2col :label="'サイトURL'" :value="userInfo.siteUrl"></item2col>
      </div>
    </div>
    <div class="lineBlock" v-if="membershipPlan">
      <div class="section">
      <h2 class="lineTitle"><span class="lineTitle__txt">選択したプラン</span></h2>
        <div class="section__inner">
          <div class="grid--2col">
            <item2col :label="'プラン名と金額'" :value="membershipPlan"></item2col>
          </div>
        </div>
      </div>
    </div>
    <div class="lineBlock">
      <div class="section">
      <h2 class="lineTitle"><span class="lineTitle__txt">メインバンク口座登録</span></h2>
        <div class="section__inner">
          <div class="grid--2col">
            <item2col :label="'銀行名'" :value="bank.bankName"></item2col>
            <item2col :label="'銀行コード'" :value="bank.swiftCode"></item2col>
            <item2col :label="'支店名'" :value="bank.branchName"></item2col>
            <item2col :label="'支店コード'" :value="bank.branchCord"></item2col>
          </div>
          <div class="grid--2col">
            <item2col :label="'口座種別'" :value="select.accountType[bank.accountType]"></item2col>
            <item2col :label="'口座番号'" :value="bank.accountNumber"></item2col>
          </div>
        </div>
      </div>
    </div>
    <div class="lineBlock">
      <div class="section">
        <h2 class="lineTitle"><span class="lineTitle__txt">口座名義登録</span></h2>
        <div>
          <item1col :label="'口座名義'" :value="anshinAccountName"></item1col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import item2col from './item2col';
  import item1col from './item1col';
  import FileDisplay from '../../../InvoiceAdd/components/parts/file-display.vue';
  import { getMembershipPlan } from "../../../../lib/Helper";
  export default {
    name: 'corporate',
    props: [
      'select'
    ],
    components: {
      item2col,
      item1col,
      FileDisplay
    },
    computed: {
      userInfo() {
        return this.$store.getters.getStoreObj('userInfo', 'corporate');
      },
      bank() {
        return this.$store.getters.getStoreObj('bank', 'corporate');
      },
      listCheckedIndustries() {
        return this.$store.getters.listCheckedIndustries;
      },
      anshinAccountName () {
        return this.$store.getters.anshinAccountName;
      },
      debtAmount() {
        return this.userInfo.debtAmount ? this.$options.filters.moneyLocaleString(this.userInfo.debtAmount) : '-';
      },
      membershipPlan() {
        const selectedPlanID = parseInt(this.$store.getters.getStoreObj('selectedPlan','planID'));
        const selectedPeriodID = parseInt(this.$store.getters.getStoreObj('selectedPlan','periodID'));
        if (!selectedPlanID) return '';
        const { planTitle, periodTitle, unit, commaPrice } = getMembershipPlan(selectedPlanID, selectedPeriodID);
        return `${planTitle}（${periodTitle ? periodTitle + "、" : ""}${commaPrice}${unit}）`;
      },
    },
  }
</script>

import ajax from '../../../lib/Ajax';
import * as conf from '../config';
import { fileObject, base64toFile } from '../../../lib/converter/File';
import Constants from '@lib/Constants';

function focusEmptyItem() {
  setTimeout(() => {
    if (document.getElementsByClassName('multiselect__single').length === 0) {
      const clientSelectorEl = document.getElementById('inputSearch');
      if (clientSelectorEl) {
        clientSelectorEl.focus();
        return;
      }
    }

    const els = document.querySelectorAll("input[class^='input']");
    for (const el of els) {
      if (el.value === '') {
        el.focus();
        break;
      }
    }
  }, 200);
}

async function detectOcrForInvoiceFile(file) {
  const csrfToken = document.CSRF_TOKEN;

  const uploadingParams = new FormData();
  uploadingParams.append(`invoice_pdf1`, file);

  try {
    const res = await ajax.post(
      '/apiInternal/detectOcrForInvoiceFile',
      uploadingParams,
      {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'multipart/form-data'
      }
    );
    return res;
  } catch (e) {
    throw e;
  }
}

async function uploadInvoiceFile(uploadingParams) {
  const csrfToken = document.CSRF_TOKEN;
  return ajax.post(
    '/apiInternal/uploadPdfForInvoicesAdd',
    uploadingParams,
    {
      'X-CSRF-Token': csrfToken,
      'Content-Type': 'multipart/form-data'
    }
  );
}

export default {
  async setEvidenceFiles(context, payload) {
    if (payload) {
      for (const [key, value] of Object.entries(payload)) {
        const fileType = key.split('.').pop().toLowerCase();
        const fileObj = {
          uploadData: null,
          displayData: value, // 画像表示用のURLをリンク先に設定する
          type: fileType,
          fileName: key,
          isExist: true
        };
        context.commit('addFileUploader', fileObj);
      }
    }
  },
  async setFileUploadFromTopPage(context, payload) {
    if (payload.imageUrls) {
      for (const [key, value] of Object.entries(payload.imageUrls)) {
        const file = await base64toFile(value, key);
        if (file) {
          const imageName = (payload.imageNames[key]) ? payload.imageNames[key] : '';
          const fileObj = fileObject(file, imageName);
          context.commit('addFileUploaderFromTopPage', fileObj);
        }
      }
    }
  },
  updatePageTitle(context, data) {
    context.commit('updatePageTitle', data);
  },
  updatePaymentDeadline(context, date) {
    context.commit('updatePaymentDeadline', date);
  },
  selectClient(context, data) {
    context.commit('selectClient', data);
  },
  clearClient(context) {
    context.commit('clearClient');
  },
  clientSpecifyStatusUpdate(context) {
    context.commit('clientSpecifyStatusUpdate');
  },
  addSelectInvoice(context) {
    context.commit('addSelectInvoice');
  },
  addSelectFromInvoiceList(context, data) {
    context.commit('addSelectFromInvoiceList', data);
  },
  selectInvoice(context, id) {
    context.commit('selectInvoice', id);
  },
  updateInvoiceClient(context) {
    context.commit('updateInvoiceClient');
  },
  updateClientForInvoice(context, data) {
    context.commit('resetNotifiedFactoring');
    context.commit('updateClientForInvoice', data);
    context.commit('updateInvoiceClient');
  },
  clearClientInfo(context) {
    context.commit('backUpClientInfo');
    context.commit('clearClientInfo');
  },
  createNewClient(context, data) {
    context.commit('resetNotifiedFactoring');
    context.commit('createNewClient', data);
  },
  getTargetUnSpecifyInvoice(context, index = 0) {
    context.commit('getTargetUnSpecifyInvoice', index);
  },
  getUnSpecifyInvoiceList(context) {
    context.commit('getUnSpecifyInvoiceList');
  },
  updateModeAddInvoice(context, data) {
    context.commit('updateModeAddInvoice', data);
  },
  howToChooseClientsinitialized(context, data) {
    context.commit('howToChooseClientsinitialized', data);
  },
  updateProcessingApiRequest(context, data) {
    context.commit('updateProcessingApiRequest', data);
  },
  newPersonInCharge(context) {
    context.commit('backUpClientInfo');
    context.commit('newPersonInCharge');
  },
  startProcessingDetection(context) {
    context.commit('updateProcessingDetectionStatus', true);
  },
  stopProcessingDetection(context) {
    context.commit('updateProcessingDetectionStatus', false);
    context.commit('updateProcessingApiRequest', false);
  },
  stopAutoDetectionMode(context) {
    context.commit('updateAutoDetectionMode', false);
  },
  clearInvoiceDetectedData(context) {
    context.commit('clearInvoiceDetectedData');
  },
  async loadNotifiedFactoring({ state, commit }) {
    try {
      const res = await ajax.get(
        '/apiInternal/getNotifiedFactorings',
        { company_no: state.clientInfo.number }
      );
      commit('updateNotifiedFactorings', res.data.result.notified_factorings);
      res.data.result.notified_factorings.forEach((nf) => {
        if (nf.code === state.invoiceInfo.codeForNotifiedFactoring) {
          commit('setNotifiedFactoringOptions', nf);
          if (!state.invoiceInfo.notifiedFactoringOptions.fileUploadRequired) {
            commit('clearFileUploader');
          }
        }
      });
    } catch (e) {
      alert(Constants.DEFAULT_ERROR_MESSAGE);
      throw e;
    }
  },
  async processDetectInvoice(context) {
    let isAutoCheckValidation = false;
    const file = context.state.fileUploader.uploadedFile[0].uploadData;

    // Set status as on processing API request
    if (context.state.status.processingApiRequest || file === null) {
      return;
    }

    context.commit('updateProcessingApiRequest', true);
    context.dispatch('stopAutoDetectionMode');
    context.dispatch('startProcessingDetection');
    try {
      const resp = await detectOcrForInvoiceFile(file);

      // user clicked 「中止」=> stop check validate
      if (!context.state.isProcessingDetection) {
        return isAutoCheckValidation;
      }

      isAutoCheckValidation = true;

      if (resp.data.status !== 200 || resp.data.data.detectedInfo == undefined) {
        context.dispatch('stopProcessingDetection');
        context.commit('updateProcessingApiRequest', false);
        focusEmptyItem();
        return isAutoCheckValidation;
      }

      if (context.state.isProcessingDetection) {
        const detectedInfo = resp.data.data.detectedInfo;
        context.commit('updateUIFromDetectInvoice', detectedInfo);
      }

      context.dispatch('stopProcessingDetection');
      context.commit('updateProcessingApiRequest', false);
      focusEmptyItem();
      return isAutoCheckValidation;
    } catch (e) {
      context.dispatch('stopProcessingDetection');
      context.commit('updateProcessingApiRequest', false);
      focusEmptyItem();
      return isAutoCheckValidation;
    }
  },
  async saveInvoice({ state, commit }, payload) {
    // Set status as on processing API request
    if (state.status.processingApiRequest) {
      return;
    }
    commit('updateProcessingApiRequest', true);

    // skip update invoice
    if (!state.invoiceInfo.modifiable) {
      commit('updateProcessingApiRequest', false);
      return;
    }

    // File upload when there is no 3rd service invoice and file upload is required for freenance tomodachi program
    const uploadingInvoiceFiles = {};
    const existKeys = {};
    if (!state.serviceInvoice && (!state.invoiceInfo.applyingNotifiedFactoring || state.invoiceInfo.notifiedFactoringOptions.fileUploadRequired)) {
      state.fileUploader.uploadedFile.forEach((file, index) => {
        if (file.isExist) {
          // file.uploadData.name is defined when upload data from top page
          existKeys[`invoice_pdf${(index + 1)}`] = file.uploadData ? file.uploadData.name : file.fileName;
        } else {
          uploadingInvoiceFiles[`invoice_pdf${(index + 1)}`] = file.uploadData;
        }
      });
    }

    const userEvidenceData = [];
    const existUserEvidenceKeys = {};
    const invoiceEvidencesInfo = {};
    const uploadingInvoiceEvidenceFiles = {};
    const existInvoiceEvidenceKeys = {};
    if (!state.invoiceInfo.applyingNotifiedFactoring) {
      // Set select option and file uploads for invoice evidence
      const invoiceEvidenceValues = state.evidenceUpload.invoiceEvidenceUploadValues;
      for (let i = 1; i <= conf.NUM_INVOICE_EVIDENCES; i += 1) {
        invoiceEvidencesInfo[`invoice_evidence${i}_select_option`] = invoiceEvidenceValues[`invoiceEvidence${i}`].selectOption;
        invoiceEvidenceValues[`invoiceEvidence${i}`].fileUploads.forEach((file, index) => {
          if (!file.key) {
            uploadingInvoiceEvidenceFiles[`invoice_evidence${i}_file${(index + 1)}`] = file.uploadData;
          } else {
            existInvoiceEvidenceKeys[`invoice_evidence${i}_uploaded_file_key${(index + 1)}`] = file.key;
          }
        });
      }
    }

    const csrfToken = document.CSRF_TOKEN;
    const invoiceId = state.invoiceInfo.id;

    // 今回の編集以前にアップロード済の請求書ファイル情報を送信する
    if (Object.keys(existKeys).length > 0) {
      const params = new FormData();
      params.append('invoice_id', invoiceId);
      for (const existKey in existKeys) {
        params.append(`exist_keys[${existKey}]`, existKeys[existKey]);
      }
      try {
        const fileUploadRes = await uploadInvoiceFile(params);
        if (fileUploadRes.data.status !== 200) {
          commit('updateProcessingApiRequest', false);
          throw Constants.DEFAULT_ERROR_MESSAGE;
        }
      } catch (e) {
        commit('updateProcessingApiRequest', false);
        throw Constants.DEFAULT_ERROR_MESSAGE;
      }
    }
    // 請求書ファイルをアップロードする
    // ファイルを一括アップロードするとCloud Runのリクエストファイルサイズの制約(最大32Mb)に引っかかることがあるため、1件ずつアップロードを行う
    for (const key in uploadingInvoiceFiles) {
      const params = new FormData();
      params.append('invoice_id', invoiceId);
      params.append(key, uploadingInvoiceFiles[key]);
      try {
        const fileUploadRes = await uploadInvoiceFile(params);
        if (fileUploadRes.data.status !== 200) {
          commit('updateProcessingApiRequest', false);
          throw Constants.DEFAULT_ERROR_MESSAGE;
        }
      } catch (e) {
        commit('updateProcessingApiRequest', false);
        throw Constants.DEFAULT_ERROR_MESSAGE;
      }
    }
    // エビデンスファイルをアップロードする
    // ファイルを一括アップロードするとCloud Runのリクエストファイルサイズの制約(最大32Mb)に引っかかることがあるため、1件ずつアップロードを行う
    for (const key in uploadingInvoiceEvidenceFiles) {
      const params = new FormData();
      params.append('invoice_id', invoiceId);
      params.append(key, uploadingInvoiceEvidenceFiles[key]);
      try {
        const fileUploadRes = await uploadInvoiceFile(params);
        if (fileUploadRes.data.status !== 200) {
          commit('updateProcessingApiRequest', false);
          throw Constants.DEFAULT_ERROR_MESSAGE;
        }
      } catch (e) {
        commit('updateProcessingApiRequest', false);
        throw Constants.DEFAULT_ERROR_MESSAGE;
      }
    }

    // Check state
    // Secondary, POST actually
    const inv = state.invoiceInfo;
    const clt = state.clientInfo;
    // All temporary created client id will become 0
    const clientId = clt.id > 0 ? clt.id : 0;
    const invDetectedData = state.invoiceDetectedData;

    let param = {
      invoice_data: {
        id: inv.id,
        amount: +inv.amount,
        tax: +inv.withholdingTax,
        application_amount: +inv.applicationAmount,
        issued_date: inv.billingDate ? inv.billingDate.replace(/\//g, '-') : null,
        due_date: inv.date.replace(/\//g, '-'),
        client_id: clientId,
        company_name: clt.name,
        company_representative: clt.representative,
        company_person_in_charge: clt.person,
        company_no: `${clt.number}`,
        company_department: clt.department,
        company_tel: clt.tel,
        company_email: clt.email,
        company_address: clt.address,
        company_zipcode: clt.postCode,
        company_note: clt.note,
        memo: inv.memo,
        code: inv.applyingNotifiedFactoring ? inv.codeForNotifiedFactoring : '',
        applying_notified_factoring: inv.applyingNotifiedFactoring,
        identification_id: inv.identificationID,
        identification_id_required: inv.notifiedFactoringOptions.identificationIDRequired,
        file_upload_required: inv.notifiedFactoringOptions.fileUploadRequired,
        exist_keys: existKeys,
        additional_invoice_evidences: invoiceEvidencesInfo,
        exist_invoice_evidence_file_keys: existInvoiceEvidenceKeys,
        api_consumer: {
          client_id_of_kong: state.apiConsumer.clientIdOfKong,
          invoice_id: state.apiConsumer.invoiceId,
          redirect_url: state.apiConsumer.redirectUrl
        },
        modifiable: inv.modifiable,
        is_confirmed_person_in_charge: inv.isConfirmedPersonInCharge,
        is_used_frnc_account: inv.isUsedFrncAccount
      },
      invoice_detected_data: {
        user_id: parseInt(invDetectedData.userId, 10),
        invoice_id: parseInt(invDetectedData.invoiceId, 10),
        ocr_raw_text: invDetectedData.ocrRawText,
        invoice_file_path: invDetectedData.invoiceFilePath,
        detected_company_name: invDetectedData.detectedCompanyName,
        detected_person_incharge: invDetectedData.detectedPersonIncharge,
        detected_total_amount: parseInt(invDetectedData.detectedTotalAmount, 10),
        detected_withholding_amount: parseInt(invDetectedData.detectedWithholdingAmount, 10),
        detected_application_amount: parseInt(invDetectedData.detectedApplicationAmount, 10),
        detected_invoice_issued_date: invDetectedData.detectedInvoiceIssuedDate
          ? invDetectedData.detectedInvoiceIssuedDate.replace(/\//g, '-') : null,
        detected_transfer_deadline: invDetectedData.detectedTransferDeadline
          ? invDetectedData.detectedTransferDeadline.replace(/\//g, '-') : null,
        detection_started_at: invDetectedData.detectionStartedAt
          ? invDetectedData.detectionStartedAt.replace(/\//g, '-') : null,
        detection_ended_at: invDetectedData.detectionEndedAt
          ? invDetectedData.detectionEndedAt.replace(/\//g, '-') : null
      },
      evidences: userEvidenceData,
      exist_user_evidence_file_keys: existUserEvidenceKeys
    };

    // If there is 3rd party service invoice data, include necessary data
    if (state.serviceInvoice) {
      const serviceInvParams = {
        company_id: state.serviceInvoice.company_id,
        linking_service_id: state.serviceInvoice.linking_service_id,
        linking_service_invoice_id: state.serviceInvoice.linking_service_invoice_id.toString()
      };

      param.invoice_data = { ...param.invoice_data, ...serviceInvParams };
    }

    // Create invoice
    try {
      const postRes = await ajax.post('/apiInternal/saveInvoice', param, { 'X-CSRF-Token': csrfToken });

      // Check state
      if (postRes.data.status !== 200) {
        commit('updateProcessingApiRequest', false);
        if (postRes.data.status === 404) {
          throw new Error('登録できる請求書は存在しません。');
        } else {
          throw(Constants.DEFAULT_ERROR_MESSAGE);
        }
      }

      if (inv.id === 0) {
        commit('setInvoiceInfoID', postRes.data.result.id);
        commit('resetLinkingServiceID');
      }
      commit('updateInitInvoiceInfo', inv);
      commit('updateProcessingApiRequest', false);
    } catch (e) {
      commit('updateProcessingApiRequest', false);
      throw(Constants.DEFAULT_ERROR_MESSAGE);
    }
  },
  async updateInvoiceWithLastVersion({ state, commit }) {
    // Set status as on processing API request
    if (state.status.processingApiRequest) {
      return false;
    }
    commit('updateProcessingApiRequest', true);

    const { invoiceInfo, initInvoiceInfo } = state;

    if (invoiceInfo.applicationAmount == initInvoiceInfo.applicationAmount && invoiceInfo.isConfirmedPersonInCharge == initInvoiceInfo.isConfirmedPersonInCharge) {
      return true;
    }
    commit('updateInitInvoiceInfo', invoiceInfo);

    try {
      const paramUpdate = {
        id: invoiceInfo.id,
        application_amount: +invoiceInfo.applicationAmount,
        is_confirmed_person_in_charge: invoiceInfo.isConfirmedPersonInCharge,
      };
      const csrfToken = document.CSRF_TOKEN;
      const postRes = await ajax.post('/apiInternal/updateInvoiceWithLastVersion', paramUpdate, { 'X-CSRF-Token': csrfToken });

      // Check state
      if (postRes.data.status !== 200) {
        commit('updateProcessingApiRequest', false);

        if (postRes.data.status === 404) {
          alert('登録できる請求書は存在しません。');
          location.href = '/';
        } else {
          alert(Constants.DEFAULT_ERROR_MESSAGE);
        }
        return false;
      }
      return true;
    } catch (e) {
      commit('updateProcessingApiRequest', false);
      alert(Constants.DEFAULT_ERROR_MESSAGE);
    }
    return false;
  },
  async loadApplyQuickPay({ state, commit }) {
    if (!state.invoiceInfo.id) return;
    const csrfToken = document.CSRF_TOKEN;
    try {
      const param = { invoice_id: state.invoiceInfo.id };
      const res = await ajax.post('/apiInternal/viewApplyQuickPay', param, { 'X-CSRF-Token': csrfToken });

      if (res.status !== 200 || res.data.status !== 200) {
        throw new Error(Constants.DEFAULT_ERROR_MESSAGE);
      }

      if (res.data.data && res.data.data.status !== 200) {
        throw new Error(Constants.DEFAULT_ERROR_MESSAGE);
      }

      commit('applyQuickPay/load', [res.data.result.invoice]);
      commit('applyQuickPay/isBlockStatusPublic', res.data.result.isBlockStatusPublic);
    } catch (e) {
      throw new Error(Constants.DEFAULT_ERROR_MESSAGE);
    }
  },
  async deleteInvoice(context, invoiceID) {
    const csrfToken = document.CSRF_TOKEN;
    const param = { invoice_id: invoiceID };
    try {
      await ajax.post('/apiInternal/deleteInvoice', param, { 'X-CSRF-Token': csrfToken });
      location.href = `/invoices`;
    } catch (e) {
      location.reload();
    }
  },
  async cancelDeal(state, dealID) {
    const csrfToken = document.CSRF_TOKEN;
    const param = { deal_id: dealID };

    try {
      await ajax.post('/apiInternal/cancelDealByUser', param, { 'X-CSRF-Token': csrfToken });
      location.href = `/invoices/edit/${state.invoiceInfo.id}`;
    } catch (e) {
      location.reload();
    }
  }
};

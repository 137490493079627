import Vue, { ComponentOptions } from 'vue';
import App from './components/App.vue';
import $ from "jquery";
import { mainModule } from "./store/main";

export default function page () {
    if ($('#telInterview').length > 0) {
        new Vue({
            el: '#telInterview',
            created() {
              mainModule.load(document.USER_EVIDENCE);
            },
            components: {
                'App': App 
            },
        }); 
    }
};

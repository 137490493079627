import { render, staticRenderFns } from "./specify-client.vue?vue&type=template&id=53880eae&"
import script from "./specify-client.vue?vue&type=script&lang=js&"
export * from "./specify-client.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "specify-client.vue"
export default component.exports
import { render, staticRenderFns } from "./mainBank.vue?vue&type=template&id=13400b02&scoped=true&"
import script from "./mainBank.vue?vue&type=script&lang=js&"
export * from "./mainBank.vue?vue&type=script&lang=js&"
import style0 from "./mainBank.vue?vue&type=style&index=0&id=13400b02&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13400b02",
  null
  
)

component.options.__file = "mainBank.vue"
export default component.exports
<template>
  <div class="contentBox__inner">
    <h2 class="lineTitle"><span class="lineTitle__txt">メインバンク口座の登録</span></h2>
    <p>
      フリーナンス口座への入金を振り替える、お客様のメインバンク口座をご登録ください。<br>
      ※旧姓名義のメインバンク口座を登録する場合、追加の資料が必要となります。
    </p>

    <div class="grid--2col grid--2to1">
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.bankName.bool }">
          <dt class="itemBlock__title">銀行名</dt>
          <dd class="itemBlock__content">
          <input @blur="$emit('checkvalid', 'bankName')" v-model="bankObj.bankName" type="text" class="input" name="bank_name" :id="'bank_name'+no">
          <p v-show="!validation.bankName.bool" class="valiedTxt">{{validation.bankName.message}}</p>
          </dd>
        </dl>
        <dl class="itemBlock bnk-wrap" v-if="mainBanks.isDisplayable && mainBanks.list.length > 0">
          <p>以下の金融機関の場合、クリックすることで自動入力できます</p>
          <ul class="bnk-ul" >
            <li class="bnk-li" :class="`li_${mainBanks.col}col`" v-for="(item, index) in mainBanks.list" :key="index" v-bind:value="index">
              <button @click="selectMainBank($event)" :value="item.bnkCode" type="button" class="bnk-btn" :class="{active:selectedBnkCode == item.bnkCode}">{{ item.bnkName }}</button>
            </li>
          </ul>
        </dl>
      </div>
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.swiftCode.bool }">
          <dt class="itemBlock__title">銀行コード</dt>
          <dd class="itemBlock__content">
          <input @blur="$emit('checkvalid', 'swiftCode')" v-model="bankObj.swiftCode" type="text" class="input" name="swift_code" :id="'bank_code'+no">
          <p v-show="!validation.swiftCode.bool" class="valiedTxt">{{validation.swiftCode.message}}</p>
          </dd>
        </dl>
      </div>
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.branchName.bool }">
          <dt class="itemBlock__title">支店名</dt>
          <dd class="itemBlock__content">
          <input @blur="$emit('checkvalid', 'branchName')" v-model="bankObj.branchName" type="text" class="input" name="branch_name" :id="'branch_name'+no">
          <p v-show="!validation.branchName.bool" class="valiedTxt">{{validation.branchName.message}}</p>
          </dd>
        </dl>
      </div>
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.branchCord.bool }">
          <dt class="itemBlock__title">支店コード</dt>
          <dd class="itemBlock__content">
          <input @blur="$emit('checkvalid', 'branchCord')" v-model="bankObj.branchCord" type="text" class="input" name="branch_cord" :id="'branch_code'+no">
          <p v-show="!validation.branchCord.bool" class="valiedTxt">{{validation.branchCord.message}}</p>
          </dd>
        </dl>
      </div>
    </div>

    <div class="grid--2col">
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.accountType.bool }">
          <dt class="itemBlock__title">口座種別</dt>
          <dd class="itemBlock__content">
          <select @change="$emit('checkvalid', 'accountType')" @blur="$emit('checkvalid', 'accountType')" v-model="bankObj.accountType" class="select" name="account_type">
            <option value="" disabled selected="selected">選択してください</option>
            <option v-for="(item, index) in select.accountType" :key="index" v-bind:value="index">
            {{item}}
            </option>
          </select>
          <p v-show="!validation.accountType.bool" class="valiedTxt">{{validation.accountType.message}}</p>
          </dd>
        </dl>
      </div>
      <div class="grid--2col__item">
        <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.accountNumber.bool }">
          <dt class="itemBlock__title">口座番号</dt>
          <dd class="itemBlock__content">
          <input @blur="$emit('checkvalid', 'accountNumber')" v-model="bankObj.accountNumber" type="text" class="input" name="account_number">
          <p v-show="!validation.accountNumber.bool" class="valiedTxt">{{validation.accountNumber.message}}</p>
          </dd>
        </dl>
      </div>
    </div>
    
    <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.accountName.bool }">
      <dt class="itemBlock__title">口座名義</dt>
      <dd class="itemBlock__content">
      <input @blur="$emit('checkvalid', 'accountName')" :value="bankObj.accountName" @change="convertAccName($event.target.value)" type="text" class="input" name="account_family_name">
      <p v-show="!validation.accountName.bool" class="valiedTxt">{{validation.accountName.message}}</p>
      </dd>
    </dl>
    <bankCertUploader
      v-show="bankObj.alternatedBankAccountName && !$store.getters.incorporated"
      :sectionType="'individual'"
      :fieldName="'bankAccountNameCert'">
      <p>
        登録氏名とメインバンクの口座名義が異なるため、この口座がお客様の口座であることを証明する追加資料が必要です。<br>
        なお、この操作は後から行うこともできます。<br>
        （ファイル形式：.jpg, .jpeg, .png, .gif, .pdf / ファイルサイズ：10MB以下）
      </p>
    </bankCertUploader>
  </div>
</template>

<script>
import FileCheck from '../../../../lib/validator/File';
import FileSize from '../../../../lib/Constants';
import bankCertUploader from './bankCertUploader';
import { halfToFullKana, smallToBigKana, fullToHalfKana } from '../../../../lib/Convert';

export default {
  name: 'mainBank',
  components: {
      bankCertUploader,
    },
  props: [
    'select',
    'bankObj',
    'validation',
    'no',
    'target',
  ],
  data() {
    return {
      selectedBnkCode: this.bankObj.swiftCode
    };
  },
  computed: {
    mainBanks() {
      return this.$store.getters.mainBanks;
    }
  },
  watch: {
    'bankObj.bankName': function(val) {
      const mainBnkObj = this.mainBanks.list.find(x => x.bnkCode === this.selectedBnkCode);
      if (mainBnkObj && mainBnkObj.bnkName !== this.bankObj.bankName) {
        this.selectedBnkCode = '';
      }
      if (val) {
        this.validation.bankName['bool'] = true;
      }
    },
    'bankObj.swiftCode': function(val) {
      if (this.bankObj.swiftCode !== this.selectedBnkCode) {
        this.selectedBnkCode = '';
      }
      if (val) {
        this.validation.swiftCode['bool'] = true;
      }
      this.$store.commit('setAsOneByteChar', {
        key: 'bank.' + this.target + '.swiftCode',
        value: val,
      });
    },
    'bankObj.branchName': function(val) {
      if (val) {
        this.validation.branchName['bool'] = true;
      }
    },
    'bankObj.branchCord': function(val) {
      if (val) {
        this.validation.branchCord['bool'] = true;
      }
      this.$store.commit('setAsOneByteChar', {
        key: 'bank.' + this.target + '.branchCord',
        value: val,
      });
    },
    'bankObj.accountNumber': function(val) {
      this.$store.commit('setAsOneByteChar', {
        key: 'bank.' + this.target + '.accountNumber',
        value: val,
      });
    },
    'bankObj.accountName': function(val) {
      if (val) {
        if (!this.$store.getters.incorporated) {
          const defaultAccountName = this.$store.getters.defaultIndividualAccountName;
          this.bankObj.alternatedBankAccountName = fullToHalfKana(smallToBigKana(halfToFullKana(val))) !== defaultAccountName;
        }
      }
      },
  },
  methods: {
    convertAccName(accName) {
      const convertedName = fullToHalfKana(smallToBigKana(halfToFullKana(accName)));
      this.$store.commit('bankAccountName', convertedName);
    },
    selectMainBank(e) {
      if (this.selectedBnkCode === e.target.value) return;
      if (e.target.value) {
        this.selectedBnkCode = e.target.value;
        const mainBnkObj = this.mainBanks.list.find(x => x.bnkCode === this.selectedBnkCode);
        this.bankObj.bankName = mainBnkObj ? mainBnkObj.bnkName : '';
        this.bankObj.swiftCode = mainBnkObj ? mainBnkObj.bnkCode : '';
        this.bankObj.branchName = '';
        this.bankObj.branchCord = '';
        if (window.BK && window.Bnk) {
          const idx = this.$store.getters.incorporated ? 2 : 1;
          window.BK.bc[idx] = this.bankObj.swiftCode; // set bank code for BankAuto
          window.Bnk.c3(); // close BankAuto popup
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bnk-wrap {
  width: 100%;
  p {
    font-size: 10px;
    margin: 10px 0 1px;
  }
  .bnk-ul {
    margin: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 0;
    list-style: none;
    .bnk-li {
      margin-bottom: 4px;
      padding: 0;
      height: 23px;
      .bnk-btn {
        width: 100%;
        height: 23px;
        line-height: 23px;
        overflow: hidden;
        background: none;
        border: 1px solid #3c3c3c;
        border-radius: 12px;
        color: #3c3c3c;
        cursor: pointer;
        font-weight: bold;
        &:hover {
          background-color: #3c3c3c;
          color: #ffffff;
          box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
        }
      }
      .active {
        background: #3c3c3c;
        color: #fff;
      }
    }
    .li_2col {
      width: 49%;
      .bnk-btn {
        font-size: 10px;
      }
    }
    .li_3col {
      width: 32.5%;
      .bnk-btn {
        font-size: 9px;
      }
    }
  }
}
@media (max-width: 375px) {
  .li_2col {
    .bnk-btn {
      font-size: 8px!important;
    }
  }
  .li_3col {
    .bnk-btn {
      font-size: 7px!important;
    }
  }
}
</style>

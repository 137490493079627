<template>
  <div id="invoiceInfoWrap" class="contentBox__inner">
    <div class="grid--2col flex-container">
      <div class="flex-item paymentInfo">
        <div class="grid--2colNew__item flex-items" :class="{ 'is-error': !validation.amount.valid }">
          <dl class="itemBlock" :class="{ 'is-error': !validation.amount.valid }">
            <dt class="itemBlock__title">
              {{ invoiceInfoDisplaySetting.amountLabel }}<span class="tag">必須</span>
            </dt>
            <dd class="itemBlock__content">
              <input v-show="!isProcessingDetection" v-model="invoiceInfo.amount"
                :disabled="$store.getters.isOnScreenMove || isDisableInput || invoiceInfo.disabledAmount" type="text"
                name="amount" class="input" @change="onChangeAmount" @blur="updateDefaultApplicationAmount">
              <div v-show="isProcessingDetection" readonly class="input color-read-only">
                <small>読み取り中···<a class="cancelBtn" v-on:click="cancelDetectInvoice()">(中止)</a></small>
              </div>
              <p v-show="!validation.amount.valid" class="valiedTxt">
                {{ validation.amount.message }}
              </p>
              <small>{{ invoiceInfoDisplaySetting.amountNote }}</small>
            </dd>
          </dl>
        </div>
        <div class="grid--2colNew__item flex-items">
          <dl class="itemBlock" :class="{ 'is-error': !validation.withholdingTax.valid }"
            v-if="getOptionDisplaySettingByName('tax')">
            <dt class="itemBlock__title">
              {{ invoiceInfoDisplaySetting.taxLabel }}<span class="tag">必須</span>
            </dt>
            <dd class="itemBlock__content">
              <input v-show="!isProcessingDetection" v-model="invoiceInfo.withholdingTax"
                :disabled="$store.getters.isOnScreenMove || isDisableInput || invoiceInfo.disabledWithholdingTax"
                type="text" name="withholdingTax" class="input" @change="onChangeWithholdingTax"
                @blur="updateDefaultApplicationAmount">
              <div v-show="isProcessingDetection" readonly class="input color-read-only">
                <small>読み取り中···<a class="cancelBtn" v-on:click="cancelDetectInvoice()">(中止)</a></small>
              </div>
              <p v-show="!validation.withholdingTax.valid" class="valiedTxt">
                {{ validation.withholdingTax.message }}
              </p>
              <small>{{ invoiceInfoDisplaySetting.taxNote }}</small>
            </dd>
          </dl>
        </div>
        <div class="grid--2colNew__item flex-items">
          <dl class="itemBlock" :class="{ 'is-error': !validation.billingDate.valid }">
            <dt class="itemBlock__title">
              {{ invoiceInfoDisplaySetting.issuedDateLabel }}<span class="tag">必須</span>
            </dt>
            <dd class="itemBlock__content">
              <template v-if="$store.getters.isOnScreenMove || isDisableInput || invoiceInfo.disabledBillingDate">
                <div class="calendar billingDate">
                  <input v-model="invoiceInfo.billingDate" readonly type="text" name="billingDate" class="input disabled">
                </div>
              </template>
              <template v-else>
                <div class="calendar billingDate">
                  <input v-show="!isProcessingDetection" v-model="invoiceInfo.billingDate"
                    :disabled="$store.getters.isOnScreenMove || isDisableInput || invoiceInfo.disabledBillingDate"
                    type="text" name="billingDate" class="input"
                    :class="{ 'js-calendar': !invoiceInfo.disabledBillingDate }">
                </div>
                <div v-show="isProcessingDetection" readonly class="input color-read-only">
                  <small>読み取り中···<a class="cancelBtn" v-on:click="cancelDetectInvoice()">(中止)</a></small>
                </div>
              </template>
              <p v-show="!validation.billingDate.valid" class="valiedTxt">
                {{ validation.billingDate.message }}
              </p>
            </dd>
          </dl>
        </div>
        <div class="grid--2colNew__item flex-items">
          <dl class="itemBlock" :class="{ 'is-error': !validation.date.valid }">
            <dt class="itemBlock__title">
              {{ invoiceInfoDisplaySetting.dueDateLabel }}<span class="tag">必須</span>
            </dt>
            <dd class="itemBlock__content">
              <template v-if="$store.getters.isOnScreenMove || isDisableInput || invoiceInfo.disabledBillingDate">
                <input v-model="invoiceInfo.date" readonly type="text" name="dueDate" class="input disabled">
              </template>
              <template v-else>
                <div class="calendar">
                  <input v-show="!isProcessingDetection" v-model="invoiceInfo.date"
                    v-on:focusout="autoFillPaymentDeadline"
                    :disabled="$store.getters.isOnScreenMove || isDisableInput || invoiceInfo.disabledDate" type="text"
                    name="dueDate" class="input" :class="{ 'js-calendar': !invoiceInfo.disabledDate }">
                </div>
                <div v-show="isProcessingDetection" readonly class="input color-read-only">
                  <small>読み取り中···<a class="cancelBtn" v-on:click="cancelDetectInvoice()">(中止)</a></small>
                </div>
              </template>
              <p v-show="!validation.date.valid" class="valiedTxt">
                {{ validation.date.message }}
              </p>
              <small v-if="!(isDisableInput || invoiceInfo.disabledDate)">パターンから入力:<a class="poiter"
                  @click="setDueDate('30日後')">30日後</a> | <a class="poiter" @click="setDueDate('翌月末')">翌月末</a> | <a
                  class="poiter" @click="setDueDate('翌々月末')">翌々月末</a></small>
            </dd>
          </dl>
        </div>
        <div class="grid--2colNew__item flex-items">
          <dl class="itemBlock" v-if="getOptionDisplaySettingByName('memo')">
            <dt class="itemBlock__title">
              {{ invoiceInfoDisplaySetting.memoLabel }}
            </dt>
            <dd class="itemBlock__content">
              <input type="text" :disabled="$store.getters.isOnScreenMove || isDisableInput" name="memo"
                class="input memo-section" maxlength="1000" v-model="invoiceInfo.memo" />
              <p v-show="!validation.memo.valid" class="valiedTxt">
                {{ validation.memo.message }}
              </p>
              <small>{{ invoiceInfoDisplaySetting.memoNote }}</small>
            </dd>
          </dl>
        </div>
        <div class="grid--2colNew__item flex-items">
          <dl class="itemBlock" v-if="getOptionDisplaySettingByName('isChoosableFrncAccount')">
            <dt class="itemBlock__title">
              振込先口座
            </dt>
            <dd class="itemBlock__content">
              <div>
                <label class="radioBtn modal-notification freenance-bank-account"
                  :class="{ 'is-checked': isUsedFrncAccount, 'is-disabled': $store.getters.isOnScreenMove || isDisableInput || linkingServiceName !== '' }"
                  for="freenance-bank-account">
                  <input type="radio" v-model="isUsedFrncAccount" name="bank-account-choice" :value="true"
                    id="freenance-bank-account" checked="checked"
                    :disabled="$store.getters.isOnScreenMove || isDisableInput || linkingServiceName !== ''">
                  <span class="radioBtn__txt">フリーナンス口座</span>
                </label>
                <label id="other-bank-account-label" class="radioBtn modal-notification"
                  :class="{ 'is-checked': !isUsedFrncAccount, 'is-disabled': isDisableInput || $store.getters.isOnScreenMove || linkingServiceName !== '' }"
                  for="other-bank-account">
                  <input type="radio" name="bank-account-choice" v-model="isUsedFrncAccount" :value="false"
                    id="other-bank-account"
                    :disabled="isDisableInput || $store.getters.isOnScreenMove || linkingServiceName !== ''">
                  <span class="radioBtn__txt">フリーナンス口座以外</span>
                </label>
              </div>
              <small v-if="linkingServiceName !== ''">
                連携元の{{ linkingServiceName }}の請求書にフリーナンス口座の{{ isUsedFrncAccount ? '記載があるため' : '記載がないため' }}この項目は変更できません
              </small>
            </dd>
          </dl>
        </div>
        <div v-if="fileUploadEnabled || linkingServiceName != ''" class="grid--2colNew__item flex-items">
          <dl class="itemBlock">
            <dt class="itemBlock__title">
              請求書ファイル<span class="tag" v-if="linkingServiceName == ''">必須</span>
            </dt>
            <dd v-if="linkingServiceName !== ''" class="itemBlock__content">
              <small>{{ linkingServiceName }}連携</small>
            </dd>
            <dd v-else>
              <small>請求書をアップロードしてください。（ファイル形式：.jpg, .jpeg, .png, .pdf、ファイルサイズ：10MB 以下）</small>
              <FileDisplay :data="listUploadedFile" :enable-delete="!isDisableInput && !$store.getters.isOnScreenMove"
                @delete="deleteFile" />
            </dd>
          </dl>
        </div>
      </div>
      <div class="flex-item clientInfo">
        <div class="grid--2colNew__item flex-items" :class="{ 'is-error': !validation.client.valid }">
          <dl class="itemBlock itemClientWrap" :class="{ 'is-error': !validation.client.valid }">
            <dt class="itemBlock__title">
              請求先会社名から法人番号と所在地を特定します。下の「検索して入力」ボタンを押してください。<span class="tag">必須</span>
            </dt>
            <dd class="itemBlock__content">
              <div class="btnUnit">
                <button class="actionBtn"
                  :disabled="$store.getters.isOnAPIRequest || $store.getters.isOnScreenMove || isDisableInput"
                  @click="searchClient('specifyClient')"
                  :class="{ 'is-disabled': $store.getters.isOnAPIRequest || $store.getters.isOnScreenMove || isDisableInput }">
                  <span class="actionBtn__inner">
                    <span class="actionBtn__txt">
                      {{ $store.getters.isOnAPIRequest ? '送信中...' : '請求先会社名を検索して入力する' }}
                    </span>
                  </span>
                </button>
              </div>
            </dd>
          </dl>
        </div>
        <div class="grid--2colNew__item flex-items">
          <dl class="itemBlock" :class="{ 'is-error': !validation.name.valid }">
            <dt class="itemBlock__title">
              {{ invoiceInfoDisplaySetting.companyNameLabel }}
            </dt>
            <dd class="itemBlock__content">
              <input v-model="clientInfo.name" type="text" name="companyName" class="input color-read-only" readonly>
              <template v-if="!(isDisableInput || invoiceInfo.disabledDate)">
                <small v-show="!isProcessingDetection">
                  検索候補：
                  <a v-if="clientInfo.name" @click="searchClient('specifyClient')">{{ clientInfo.name }}</a>
                  <span v-else>-</span>
                </small>
                <small v-show="isProcessingDetection">
                  検索候補：読み取り中···<a class="cancelBtn" v-on:click="cancelDetectInvoice()">(中止)</a>
                </small>
              </template>
              <p v-show="!validation.name.valid" class="valiedTxt">
                {{ validation.name.message }}
              </p>
            </dd>
          </dl>
        </div>
        <div class="grid--2colNew__item flex-items">
          <dl class="itemBlock" :class="{ 'is-error': !validation.number.valid }">
            <dt class="itemBlock__title">
              法人番号
            </dt>
            <dd class="itemBlock__content">
              <input v-model="clientInfo.number" type="text" name="corporateNumber" class="input color-read-only"
                readonly>
              <p v-show="!validation.number.valid" class="valiedTxt">
                {{ validation.number.message }}
              </p>
            </dd>
          </dl>
        </div>
        <div class="grid--2colNew__item flex-items">
          <dl class="itemBlock" :class="{ 'is-error': !validation.address.valid }">
            <dt class="itemBlock__title">
              所在地
            </dt>
            <dd class="itemBlock__content">
              <input v-model="clientInfo.address" type="text" name="address" class="input color-read-only" readonly>
              <template v-if="!(isDisableInput || invoiceInfo.disabledDate)">
                <small v-show="!isProcessingDetection">
                  検索候補：
                  <a v-if="clientInfo.address" @click="searchClient('specifyClient')">{{ clientInfo.address }}</a>
                  <span v-else>-</span>
                </small>
                <small v-show="isProcessingDetection">
                  検索候補：読み取り中···<a class="cancelBtn" v-on:click="cancelDetectInvoice()">(中止)</a>
                </small>
              </template>
              <p v-show="!validation.address.valid" class="valiedTxt">
                {{ validation.address.message }}
              </p>
            </dd>
          </dl>
        </div>
        <div class="grid--2colNew__item flex-items">
          <dl class="itemBlock" :class="{ 'is-error': !validation.representative.valid }">
            <dt class="itemBlock__title">
              代表者名<span class="tag">必須</span>
            </dt>
            <dd class="itemBlock__content">
              <input v-model="clientInfo.representative" :disabled="$store.getters.isOnScreenMove || isDisableInput"
                type="text" name="representative" @change="checkClientInfoValid('representative', '代表者名')" class="input"
                placeholder="代表者名">
              <p v-show="!validation.representative.valid" class="valiedTxt">
                {{ validation.representative.message }}
              </p>
            </dd>
          </dl>
        </div>
        <div class="grid--2colNew__item flex-items">
          <dl class="itemBlock" :class="{ 'is-error': !validation.department.valid }">
            <dt class="itemBlock__title">
              担当者部署
            </dt>
            <dd class="itemBlock__content">
              <input v-model="clientInfo.department" :disabled="$store.getters.isOnScreenMove || isDisableInput"
                type="text" name="department" class="input" placeholder="担当者部署">
              <p v-show="!validation.client.valid" class="valiedTxt">
                {{ validation.department.message }}
              </p>
            </dd>
          </dl>
        </div>
        <div class="grid--2colNew__item flex-items">
          <dl class="itemBlock" :class="{ 'is-error': !validation.person.valid }">
            <dt class="itemBlock__title">
              {{ invoiceInfoDisplaySetting.personInChargeLabel }}<span class="tag">必須</span>
            </dt>
            <dd class="itemBlock__content">
              <input v-model="clientInfo.person" :disabled="$store.getters.isOnScreenMove || isDisableInput" type="text"
                name="personInCharge" @change="checkClientInfoValid('person', '担当者名')" class="input" placeholder="担当者名">
              <p v-show="!validation.person.valid" class="valiedTxt">
                {{ validation.person.message }}
              </p>
            </dd>
          </dl>
        </div>
        <div class="grid--2colNew__item flex-items">
          <dl class="itemBlock" :class="{ 'is-error': !validation.email.valid }">
            <dt class="itemBlock__title">
              担当者メールアドレス<span class="tag">必須</span>
            </dt>
            <dd class="itemBlock__content">
              <input v-model="clientInfo.email" :disabled="$store.getters.isOnScreenMove || isDisableInput" type="text"
                name="email" @change="checkClientInfoValid('email')" class="input" placeholder="担当者メールアドレス">
              <p v-show="!validation.email.valid" class="valiedTxt">
                {{ validation.email.message }}
              </p>
            </dd>
          </dl>
        </div>
        <div class="grid--2colNew__item flex-items">
          <dl class="itemBlock" :class="{ 'is-error': !validation.tel.valid }">
            <dt class="itemBlock__title">
              担当者電話番号<span class="tag">必須</span>
            </dt>
            <dd class="itemBlock__content">
              <input v-model="clientInfo.tel" :disabled="$store.getters.isOnScreenMove || isDisableInput" type="tel"
                name="tel" @change="checkClientInfoValid('tel')" class="input" placeholder="担当者電話番号">
              <p v-show="!validation.tel.valid" class="valiedTxt">
                {{ validation.tel.message }}
              </p>
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <client-modal ref="clientModal" @nextPage="movePage" />
  </div>
</template>

<script>
import requiredCheck from '@lib/validator/Required';
import dateCheck from '@lib/validator/Date';
import { toNumberHalfWidth } from '@lib/Convert';
import memo from './memo.vue';
import withholdingTaxCheck from '@lib/validator/WithholdingTax';
import applicationAmountCheck from '@lib/validator/ApplicationAmount';
import numberCheck from '@lib/validator/Number';
import orderOfMagnitudeDateCheck from '@lib/validator/OrderOfMagnitudeDate';
import clientModal from './client-modal.vue';
import FileDisplay from './file-display.vue';
import AddMonthDayForDate from '@lib/AddMonthDayForDate';
import moment from 'moment';
import { calculateApplicationAmount } from '../../../../lib/Helper';
import validators from '../../validators';

export default {
  name: 'input-invoice-info',
  props: {
    'invoiceInfo': Object,
    'validation': Object,
    'clientInfo': Object,
    'screenState': Number,
    'isDisableInput': Boolean,
    'status': Object,
    'fileUploadEnabled': Boolean
  },
  components: {
    memo,
    clientModal,
    FileDisplay,
  },
  data() {
    return {
      typingApplicationAmount: false
    };
  },
  computed: {
    taxRequired() {
      return this.$store.getters.getOptionDisplaySettingByName('tax');
    },
    isProcessingDetection() {
      return this.$store.getters.getDetectingStatus;
    },
    invoiceInfoDisplaySetting() {
      return this.$store.getters.getInvoiceInfoDisplaySetting;
    },
    isUsedFrncAccount: {
      get() {
        return this.$store.getters.getIsUsedFrncAccount;
      },
      set(value) {
        this.$store.commit('setIsUsedFrncAccount', value);
      }
    },
    linkingServiceName() {
      return this.$store.getters.getLinkingServiceName;
    },
    sharedState() {
      return this.$store.state;
    },
    listUploadedFile() {
      return this.$store.getters['fileUploader/getUploadedFile'];
    },
    listNewUploadFile() {
      return this.$store.getters['fileUploader/getNewUploadFile'];
    },
  },
  watch: {
    'invoiceInfo.disabledBillingDate': function (val) {
      var btnDatepicker = $('.billingDate .ui-datepicker-trigger');
      if (val) {
        btnDatepicker.hide();
      } else {
        btnDatepicker.show();
      }
    },
    'invoiceInfo.applyingNotifiedFactoring': function (val) {
      if (val) {
        this.$store.commit('setIsUsedFrncAccount', true);
        this.autoFillBillingDate();
      } else {
        this.$store.commit('setDisableBillingDate', false);
      }
    },
    'invoiceInfo.billingDate': function (billingDate) {
      this.checkValid('billingDate', '請求日');
      this.autoFillPaymentDeadline();
    },
    'invoiceInfo.date': function (date) {
      this.checkValid('date', '振込期限');
    },
    'clientInfo': function (val) {
      this.checkClientInfoValid('representative', '代表者名');
      this.checkClientInfoValid('person', '担当者名');
      this.checkClientInfoValid('email');
      this.checkClientInfoValid('tel');
    },
    taxRequired() {
      this.updateDefaultApplicationAmount();
    }
  },
  methods: {
    cancelDetectInvoice() {
      this.$store.dispatch('stopProcessingDetection');
      this.$store.commit('updateProcessingApiRequest', false);
    },
    updatePaymentDeadline(e) {
      this.$store.dispatch('updatePaymentDeadline', e.target.value);
    },
    autoFillBillingDate() {
      const { billingDate } = this.invoiceInfo;
      const { isAutoCalculateDueDate, isDefaultIssuedDateToToday, isDisabledIssuedDate } = this.invoiceInfo.notifiedFactoringOptions;
      if (isDefaultIssuedDateToToday && isDisabledIssuedDate) {
        this.$store.commit('setDisableBillingDate', true);
      } else {
        this.$store.commit('setDisableBillingDate', false);
      }
      if (isAutoCalculateDueDate && isDefaultIssuedDateToToday && (billingDate == '' || isDisabledIssuedDate)) {
        let today = moment().format('YYYY/MM/DD');
        this.$store.commit('setBillingDate', today);
      }
      if (billingDate != '') {
        this.autoFillPaymentDeadline();
      }
    },
    autoFillPaymentDeadline() {
      const { applyingNotifiedFactoring, date, billingDate } = this.invoiceInfo;
      const { isAutoCalculateDueDate, dueDateDayRule, dueDateMonthRule } = this.invoiceInfo.notifiedFactoringOptions;
      if (!applyingNotifiedFactoring
        || !isAutoCalculateDueDate
        || !dateCheck.check(billingDate).valid
      ) {
        return;
      }
      const month = dueDateMonthRule - 1;
      const fillDate = AddMonthDayForDate(billingDate, month, dueDateDayRule);
      this.$store.commit('setPaymentDeadline', fillDate);
    },
    updateDefaultApplicationAmount() {
      if (!this.validation.amount.valid || !this.validation.withholdingTax.valid) {
        return;
      }

      // 申請金額
      const amountInt = parseInt(this.invoiceInfo.amount, 10) || 0;

      // 請求金額レート上限
      // 普段が1(100%)で適用します、クライアントの三社間ファクタリングコードによって、1を下回る場合もあり

      const maxAmountRateFloat = parseFloat(this.invoiceInfo.notifiedFactoringOptions.maxApplicationAmountRate);
      // 源泉徴収税
      const withholdingTax = this.invoiceInfo.withholdingTax === '' || !this.taxRequired ? 0 : this.invoiceInfo.withholdingTax;
      const withholdingTaxInt = parseInt(withholdingTax, 10) || 0;

      // 申込金額計算
      const defaultApplicationAmountInt = calculateApplicationAmount(amountInt, withholdingTaxInt, maxAmountRateFloat);

      this.$store.commit('updateDefaultApplicationAmount', defaultApplicationAmountInt.toString());
    },
    newPersonInCharge() {
      this.$store.dispatch('newPersonInCharge');
      if (this.$store.state.isInvoiceEdit) {
        this.$router.push({ name: 'newPersonInChargeEditPage' });
        return;
      }
      this.$router.push({ name: 'newPersonInCharge' });
    },
    onChangeAmount() {
      if (this.invoiceInfo.amount !== '') {
        this.invoiceInfo.amount = toNumberHalfWidth(this.invoiceInfo.amount);
      }
      this.checkValid('amount', '即日払い金額');
    },
    onChangeWithholdingTax() {
      if (this.invoiceInfo.withholdingTax !== '') {
        this.invoiceInfo.withholdingTax = toNumberHalfWidth(this.invoiceInfo.withholdingTax);
      }
      this.checkValid('withholdingTax', '源泉徴収税');
    },
    checkValid(field, title) {
      if (field !== 'withholdingTax') {
        this.validation[field].valid = requiredCheck.check(this.invoiceInfo[field], title).valid;
        this.validation[field].message = requiredCheck.check(this.invoiceInfo[field], title).message;
      }
      switch (field) {
        case 'amount':
          this.checkAmount();
          break;
        case 'withholdingTax':
          this.checkWithholdingTax();
          break;
        case 'applicationAmount':
          this.checkApplicationAmount();
          break;
        case 'billingDate':
          this.checkbillingDate();
          break;
        case 'date':
          this.checkDate();
          break;
        default:
          break;
      }
    },
    checkClientInfoValid(field, title = '') {
      let validator;
      switch (field) {
        case 'email':
          validator = validators.checkClientEmail(this.clientInfo[field], this.$store.state.userEmail);
          break;
        case 'tel':
          validator = validators.checkClientTel(this.clientInfo[field], this.$store.state.userTel);
          break;
        default:
          validator = this.checkRequiredValid(this.clientInfo[field], title);
          break;
      }
      this.validation[field].valid = validator.valid;
      this.validation[field].message = validator.message;
    },
    checkRequiredValid(field, title) {
      return requiredCheck.check(field, title);
    },
    checkAmount() {
      if (this.validation.amount.valid) {
        const result = numberCheck.check(this.invoiceInfo.amount);
        this.validation.amount.valid = result.valid;
        this.validation.amount.message = result.message;
      }
      if (this.validation.amount.valid) {
        if (this.invoiceInfo.amount > 2147483647) {
          this.validation.amount.valid = false;
          this.validation.amount.message = '2147483647 以下の数値を入力して下さい。';
        }
      }
    },
    checkWithholdingTax() {
      if (this.invoiceInfo.withholdingTax === '') {
        this.validation.withholdingTax.valid = true;
        this.validation.withholdingTax.message = '';
      } else {
        let result = numberCheck.check(this.invoiceInfo.withholdingTax);
        this.validation.withholdingTax.valid = result.valid;
        this.validation.withholdingTax.message = result.message;
        if (this.validation.withholdingTax.valid) {
          if (this.invoiceInfo.withholdingTax > 2147483647) {
            this.validation.withholdingTax.valid = false;
            this.validation.withholdingTax.message = '2147483647 以下の数値を入力して下さい。';
          }
        }
        if (this.validation.amount.valid && this.validation.withholdingTax.valid) {
          result = withholdingTaxCheck.check(this.invoiceInfo.amount, this.invoiceInfo.withholdingTax);
          this.validation.withholdingTax.valid = result.valid;
          this.validation.withholdingTax.message = result.message;
        }
      }
    },
    checkApplicationAmount() {
      if (this.invoiceInfo.amount === '') {
        if (this.invoiceInfo.applicationAmount !== '') {
          const result = numberCheck.check(this.invoiceInfo.applicationAmount);
          this.validation.applicationAmount.valid = result.valid;
          this.validation.applicationAmount.message = result.message;
          return;
        }

        this.validation.applicationAmount.valid = true;
        this.validation.applicationAmount.message = '';
        return;
      }

      if (this.invoiceInfo.applicationAmount !== '') {
        let result = numberCheck.check(this.invoiceInfo.applicationAmount);
        this.validation.applicationAmount.valid = result.valid;
        this.validation.applicationAmount.message = result.message;

        if (this.validation.applicationAmount.valid && this.validation.amount.valid && this.validation.withholdingTax.valid) {
          const withholdingTax = this.taxRequired ? this.invoiceInfo.withholdingTax : 0;
          const maxApplicationAmountRate = this.invoiceInfo.applyingNotifiedFactoring ?
            parseFloat(this.invoiceInfo.notifiedFactoringOptions.maxApplicationAmountRate) || 1 : 1;

          result = applicationAmountCheck.check(
            parseInt(this.invoiceInfo.applicationAmount, 10) || 0,
            parseInt(this.invoiceInfo.amount, 10) || 0,
            parseInt(withholdingTax, 10) || 0,
            maxApplicationAmountRate,
            this.invoiceInfo.notifiedFactoringOptions.maxApplicationAmountPerRate
          );
          this.validation.applicationAmount.valid = result.valid;
          this.validation.applicationAmount.message = result.message;
        }
      }
    },
    checkbillingDate() {
      const result = dateCheck.check(this.invoiceInfo.billingDate);
      this.validation.billingDate.valid = result.valid;
      this.validation.billingDate.message = result.message;
      this.checkDate();
    },
    checkDate() {
      const result = dateCheck.check(this.invoiceInfo.date);
      this.validation.date.valid = result.valid;
      this.validation.date.message = result.message;
      if (this.validation.billingDate.valid && this.validation.date.valid) {
        const result = orderOfMagnitudeDateCheck.check(this.invoiceInfo.billingDate, this.invoiceInfo.date);
        this.validation.date.valid = result.valid;
        this.validation.date.message = result.message;
      }
    },
    getOptionDisplaySettingByName(optionName) {
      const result = this.$store.getters.getOptionDisplaySettingByName(optionName);
      if (!result && optionName === 'tax') {
        this.$store.commit('resetTax');
      } else if (!result && optionName === 'memo') {
        this.$store.commit('resetMemo');
      }
      return result;
    },
    searchClient(pathName) {
      if (this.$store.getters.isOnAPIRequest || this.$store.getters.isOnScreenMove || this.isDisableInput) return;
      if (pathName === 'specifyClient') {
        this.$store.commit('updateClientProcessName', 'specifyClient');
        this.$refs.clientModal.show();
      }
    },
    movePage(nextPathName) {
      this.$refs.clientModal.hide();
    },
    setDueDate(txtTime) {
      const options = {
        timeZone: "Asia/Tokyo",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
      const JP_FORMAT = "ja-JP";

      let dt = null;
      if (this.invoiceInfo.billingDate !== '') {
        const result = dateCheck.check(this.invoiceInfo.billingDate);
        if (result.valid) {
          dt = new Date(this.invoiceInfo.billingDate);
        }
      }
      if (!dt) return;
      if (txtTime === '30日後') {
        dt.setDate(dt.getDate() + 30);
      } else if (txtTime === '翌月末') {
        const date = moment(dt).add(1, 'M').endOf('month').format('YYYY/MM/DD');
        dt = new Date(date);
      } else if (txtTime === '翌々月末') {
        const date = moment(dt).add(2, 'M').endOf('month').format('YYYY/MM/DD');
        dt = new Date(date);
      }
      this.invoiceInfo.date = dt.toLocaleString(JP_FORMAT, options);
    },
    deleteFile(index) {
      document.getElementsByName('invoiceCertFile')[0].value = null;

      let newUploadFileIndex = null;
      let uploadedFileIndex = null;

      if (this.listNewUploadFile.length == this.listUploadedFile.length) {
        newUploadFileIndex = index;
        uploadedFileIndex = index;
      } else if (this.listUploadedFile.length > this.listNewUploadFile.length) {

        let uploadedFileLength = this.listUploadedFile.length - this.listNewUploadFile.length;
        // 例：newUploadFile = [3 , 4] , uploadedFile = [1 , 2 ,3 ,4]
        // [1 , 2] : uploaded invoice when creat invoice
        // [3 , 4] : uploaded invoice when edit invoice
        if (index <= uploadedFileLength - 1) {
          // Remove [1 ,2] of uploadedFile = [1 , 2 ,3 ,4]
          // then not remove newUploadFile
          newUploadFileIndex = null;
          uploadedFileIndex = index;
        } else {
          // Remove [3 ,4] of uploadedFile = [1 , 2 ,3 ,4]
          // then calculate that index in  newUploadFile = [3 , 4]
          newUploadFileIndex = index - uploadedFileLength;
          uploadedFileIndex = index;
        }
      }

      this.$store.dispatch('fileUploader/delete', {
        'newUploadFileIndex': newUploadFileIndex,
        'uploadedFileIndex': uploadedFileIndex
      });

      // stop detecting
      this.$store.dispatch('stopProcessingDetection');

      // if delete file first then clear data detected
      if (index == 0) {
        this.$store.dispatch('clearInvoiceDetectedData');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cancelBtn {
  cursor: pointer;
}

.btnUnit {
  height: 42px;
}

.actionBtn {
  [class*="__inner"] {
    height: 40px !important;
    width: 250px;
  }

  [class*="__txt"] {
    font-size: 12px;
  }
}

.itemClientWrap {
  margin-bottom: 0px;

  .itemBlock__title {
    font-weight: normal;
  }

  .itemBlock__content {
    margin-top: 24px;
  }

  .actionBtn {
    width: auto;
  }
}

.tag {
  float: none;
  position: unset;
  font-size: 12px;
  padding: 2px 10px;
  margin-left: 6px;
}

small {
  a {
    text-decoration: underline;
    cursor: pointer;
  }
}

.radioBtn.is-disabled {
  cursor: default;

  &:before {
    border: 1.5px solid #cccccc;
  }
}
</style>

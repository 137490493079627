<template>
  <div class="clientItem" v-bind:class="{ 'is-checked': client.isSelected }">
    <div class="radioUnit">
      <label class="radioUnit__radioBtn" v-on:click="select(client.id, client.number)"
        v-bind:class="{ 'is-checked': client.isSelected }">
        <input type="hnumberden" name="client-item" value="" />
        <span class="radioUnit__title">{{ client.name }}</span>

        <span v-if="isShowPerson" class="radioUnit__detail">
          <span class="radioUnit__infoName">担当者</span>
          <span class="radioUnit__infoContent">{{ client.person }}</span>
        </span>
        <span v-else class="radioUnit__detail">
          <span class="radioUnit__infoName">法人番号</span>
          <span class="radioUnit__infoContent">{{ client.number }}</span>
          <span class="radioUnit__infoName">所在地</span>
          <span class="radioUnit__infoContent">{{ client.address || client.addressOutside || '-' }}</span>
        </span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'client-item',
  props: {
    client: Object,
    isShowPerson: true
  },
  methods: {
    select(id, number) {
      this.$emit('select', id, number)
    },
  },
}
</script>

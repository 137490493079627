var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import Canvg from 'canvg';
import QRCode from '../../../lib/QRCode';
var QRCodeGenerator = new QRCode();
var ProfileURL = /** @class */ (function (_super) {
    __extends(ProfileURL, _super);
    function ProfileURL() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProfileURL.prototype.mounted = function () {
        this.getQRCode();
    };
    ProfileURL.prototype.getQRCode = function () {
        var _this = this;
        // Run QR code generation process asynchronously for processes that need load external svg file
        (function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, QRCodeGenerator.drawLabelQRCode('labelQR', this.profileUrl)];
                    case 1:
                        _a.sent();
                        this.makePNGSource('labelQR');
                        this.makeSVGSource('labelQR');
                        return [2 /*return*/];
                }
            });
        }); })();
        (function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, QRCodeGenerator.drawIconQRCode('iconQR', this.profileUrl)];
                    case 1:
                        _a.sent();
                        this.makePNGSource('iconQR');
                        this.makeSVGSource('iconQR');
                        return [2 /*return*/];
                }
            });
        }); })();
        QRCodeGenerator.drawStandardQR('standardQR', this.profileUrl);
        this.makePNGSource('standardQR');
        this.makeSVGSource('standardQR');
    };
    ProfileURL.prototype.makeSVGSource = function (elementId) {
        var fileName = elementId + ".svg";
        var svgStr = this.getSvgString(elementId);
        var dataURI = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svgStr);
        var containerId = elementId + "-SVG";
        this.appendDownload(containerId, dataURI, fileName);
    };
    ProfileURL.prototype.makePNGSource = function (elementId) {
        var fileName = elementId + ".png";
        var qrCode = this.getSvgString(elementId);
        var canvas = document.createElement('canvas');
        Canvg(canvas, qrCode);
        var source = canvas.toDataURL('image/png');
        var containerId = elementId + "-PNG";
        this.appendDownload(containerId, source, fileName);
    };
    ProfileURL.prototype.appendDownload = function (elementId, source, fileName) {
        var downloadElement = document.getElementById(elementId);
        var isEdge = window.navigator.userAgent.indexOf('Edge') > -1;
        // Edge does not support download attribute with data uri
        if (downloadElement.download !== undefined && !isEdge) {
            downloadElement.href = source;
            downloadElement.download = fileName;
        }
        else {
            // For browser that does not support download attribute, we will open
            // the image in a new tab
            downloadElement.addEventListener('click', function () {
                var image = new Image();
                var newWindow = window.open('');
                image.src = source;
                newWindow.document.write(image.outerHTML);
                newWindow.document.location.href = '#';
                newWindow.document.close();
            });
        }
    };
    ProfileURL.prototype.getSvgString = function (containerID) {
        var container = document.getElementById(containerID);
        var svgEl = container.getElementsByTagName('svg')[1];
        var svgStr = new XMLSerializer().serializeToString(svgEl);
        return svgStr;
    };
    ProfileURL.prototype.copyURL = function (e) {
        return __awaiter(this, void 0, void 0, function () {
            var originText, copiedText;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        originText = e.target.innerText;
                        copiedText = 'コピーしました';
                        if (!(originText !== copiedText)) return [3 /*break*/, 2];
                        return [4 /*yield*/, navigator.clipboard.writeText(this.profileUrl)];
                    case 1:
                        _a.sent();
                        e.target.innerText = copiedText;
                        setTimeout(function () {
                            e.target.innerText = originText;
                        }, 2000);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        Prop(String)
    ], ProfileURL.prototype, "profileUrl", void 0);
    ProfileURL = __decorate([
        Component
    ], ProfileURL);
    return ProfileURL;
}(Vue));
export default ProfileURL;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import pdfObject from 'pdfobject';
import lity from '../../../lib/Lity';
var FileDisplay = /** @class */ (function (_super) {
    __extends(FileDisplay, _super);
    function FileDisplay() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.supportsPDFs = pdfObject.supportsPDFs;
        return _this;
    }
    FileDisplay.prototype.openLightBox = function (item) {
        var lityParams = {
            dataURL: item.displayUrl,
            type: item.type
        };
        lity(lityParams);
    };
    FileDisplay.prototype.deleteFile = function (index) {
        return index;
    };
    __decorate([
        Prop()
    ], FileDisplay.prototype, "data", void 0);
    __decorate([
        Prop()
    ], FileDisplay.prototype, "enableDelete", void 0);
    __decorate([
        Emit('delete')
    ], FileDisplay.prototype, "deleteFile", null);
    FileDisplay = __decorate([
        Component
    ], FileDisplay);
    return FileDisplay;
}(Vue));
export default FileDisplay;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import mainStore from '../store/main';
var Content = /** @class */ (function (_super) {
    __extends(Content, _super);
    function Content() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Content.prototype, "dialogMessage", {
        get: function () {
            return mainStore.dialogMessage;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Content.prototype, "dialogMessageTitle", {
        get: function () {
            return this.dialogMessage.title;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Content.prototype, "dialogMessageID", {
        get: function () {
            return this.dialogMessage.id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Content.prototype, "dialogMessageLink", {
        get: function () {
            return this.dialogMessage.link;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Content.prototype, "dialogMessageActionText", {
        get: function () {
            return this.dialogMessage.action_text;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Content.prototype, "dialogMessageRedisplayCheckboxPeriod", {
        get: function () {
            var _a;
            return (_a = this.dialogMessage.redisplay_checkbox_period) !== null && _a !== void 0 ? _a : 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Content.prototype, "dialogMessageContent", {
        get: function () {
            var _a;
            if (!this.dialogMessage)
                return '';
            var doc = new DOMParser().parseFromString(this.dialogMessage.content, 'text/html');
            return (_a = doc.documentElement.textContent) !== null && _a !== void 0 ? _a : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Content.prototype, "classSetting", {
        get: function () {
            var type = this.dialogMessage.type;
            if (type) {
                return {
                    dialogBorder: "dialog-message-" + type,
                    dialogTypeIcon: "dialog-message-" + type + "__" + type + "-icon",
                    dialogCloseIcon: "dialog-message-" + type + "__" + type + "-close"
                };
            }
            return {
                dialogBorder: 'dialog-message-info',
                dialogTypeIcon: '',
                dialogCloseIcon: 'dialog-message-info__info-close'
            };
        },
        enumerable: false,
        configurable: true
    });
    Content.prototype.setOmissionPeriod = function (isChecked) {
        mainStore.setOmissionPeriod(isChecked);
    };
    Content.prototype.processDialogMessageOmission = function () {
        mainStore.processDialogMessageOmission();
    };
    Content = __decorate([
        Component({
            components: {}
        })
    ], Content);
    return Content;
}(Vue));
export default Content;

<template>
  <dl class="itemBlock" v-bind:class="{ 'is-error': !validation.industry.bool }">
    <dt class="itemBlock__title">業種<span class="tag">必須</span></dt>
    <dd class="itemBlock__content">
    <div class="scrollBlock--spvVrtical">
      <div class="radioUnitWrap--scroll">
        <div class="radioUnit" v-for="ind in masterIndustries">
          <template v-if="ind.name != 'am_other_etc'">
            <label class="radioUnit__checkboxBtn checkBox" :class="{ 'is-checked is-click' : industries[ind.name] }">
              <input type="checkbox" :name="ind.name" v-model="industries[ind.name]" value="1" @change="onChangeIndustry">
              <span class="radioUnit__title">{{ind.label}}</span>
            </label>
          </template>
          <template v-else>
            <label class="radioUnit__checkboxBtn checkBox" :class="{ 'is-checked is-click' : industries[ind.name] }" style="width:auto !important">
              <input type="checkbox" :name="ind.name" v-model="industries[ind.name]" value="1" @change="onChangeIndustry">
              <span class="radioUnit__title">{{ind.label}}</span>
            </label>
            <input
            @blur="$emit('checkvalid', 'amOtherEtcName')"
            v-model="industries['am_other_etc_name']"
            type="text" class="input" name="am_other_etc_name"
            v-bind:class="{ 'is-disabled': !industries['am_other_etc'] }"
            v-bind:disabled="!industries['am_other_etc']"
            style="margin:10px; width:70% !important">
          </template>
        </div>
      </div>
    </div>
    <p v-show="!validation.industry.bool" class="valiedTxt">{{validation.industry.message}}</p>
    <p v-show="!validation.amOtherEtcName.bool" class="valiedTxt">{{validation.amOtherEtcName.message}}</p>
    </dd>
  </dl>
</template>

<style scoped>
  .radioUnitWrap--scroll {
    display: block;
    margin-top: 0px;
    min-height:280px;
    max-height:280px;
  }
</style>

<script>
  export default {
    name: 'industry',
    props: [
      'validation',
    ],
    computed: {
      masterIndustries () {
        return this.$store.getters.masterIndustries;
      },
    },
    methods: {
      onChangeIndustry() {
        if(!this.$store.state.industries['am_other_etc']){
          this.$store.state.industries['am_other_etc_name'] = '';
        }
        this.$emit('checkbox')
      },
    },
    data() {
      return {
        industries: this.$store.state.industries,
      };
    },
  }
</script>

export default {
  // 選択した請求書を取得
  selectedInvoces: state => {
    return state.invoiceList.filter(invoice => invoice.isSelected);
  },
  // 選択した請求書の取得の数
  selectedInvocesCount: (state, getters) => {
    return getters.selectedInvoces.length
  },
  // アップロードしたファイルの数
  cntUploadedFiles: (state) => {
    return state.fileUploader.uploadedFile.length;
  },
  // 取引先特定が必要な請求書を取得
  getUnSpecifyInvoiceList: (state) => {
    let invoices = state.invoiceList.filter(function (element, index, array) {
      return !element.isSpecified
    });

    return invoices
  },
  // 買取実行可能な請求書を取得
  getPaydInvoiceList: (state) => {
    let invoices = state.invoiceList.filter(function (element, index, array) {
      return element.status == 'paid'
    });

    return invoices
  },
  // エビデンスが必要な請求書を取得
  getEvidenceInvoiceList: (state) => {
    let invoices = state.invoiceList.filter(function (element, index, array) {
      return element.status == 'evidence'
    });

    return invoices
  },
  // 日付の形式を変更した請求書情報を返す（そのまま登録するとエラーになるため）
  changeDateFormatInvoice: (state) => {
    let invoice = state.invoiceInfo;

    invoice.billingDate = state.invoiceInfo.billingDate.replace(/\//g, '-');
    invoice.date = state.invoiceInfo.date.replace(/\//g, '-');

    return invoice;
  },
  // 初期化が完了しているか否か
  initialized: (state) => {
    return state.initialized;
  },
  // APIリクエスト中か否か
  isOnAPIRequest: (state) => {
    return state.status.processingApiRequest;
  },
  // 画面遷移中
  isOnScreenMove: (state) => {
    return state.status.processingScreenMove;
  },
  isBlockStatusPublic: (state) => {
    return state.isBlockStatusPublic;
  },
  getPayProcessName: (state) => {
    return state.payProcessName;
  },
  getValidation: (state) => {
    return state.validation;
  },
  // 法人番号APIがメンテナンス中か否か
  underMaintenanceCompanyNumberApi: (state) => {
    return state.under_maintenance_company_number_api;
  },

  editingAction: (state) => {
    return state.status.editingAction;
  },

  getInvoiceInfoDisplaySetting: (state) => {
    if (state.invoiceInfo.applyingNotifiedFactoring) {
      const nfOptions = state.invoiceInfo.notifiedFactoringOptions;

      return {
        companyNameLabel: nfOptions.companyNameLabel,
        personInChargeLabel: nfOptions.personInChargeLabel,
        issuedDateLabel: nfOptions.issuedDateLabel,
        dueDateLabel: nfOptions.dueDateLabel,
        amountLabel: nfOptions.amountLabel,
        taxLabel: nfOptions.taxLabel,
        amountNote: nfOptions.amountNote,
        taxNote: nfOptions.taxNote,
        applicationAmountNote: nfOptions.applicationAmountNote,
        memoNote: nfOptions.memoNote,
        applicationAmountLabel: nfOptions.applicationAmountLabel,
        memoLabel: nfOptions.memoLabel,
        fileUploadLabel: nfOptions.fileUploadLabel,
        fileUploadNote: nfOptions.fileUploadNote
      };
    }

    return state.defaultDisplayOptions;
  },

  getUploadFileTypeConfig: (state) => {
    return state.invoiceInfo.fileExtensionValidation;
  },

  getOptionDisplaySettingByName: (state) => (optionName) => {
    if (!state.invoiceInfo.applyingNotifiedFactoring) return true;

    const { notifiedFactoringOptions } = state.invoiceInfo;

    switch (optionName) {
      case 'personInCharge': return notifiedFactoringOptions.personInChargeRequired;
      case 'tax': return notifiedFactoringOptions.taxRequired;
      case 'memo': return notifiedFactoringOptions.memoRequired;
      case 'isChoosableFrncAccount': return notifiedFactoringOptions.isChoosableFrncAccount;
      default: return false;
    }
  },

  getApplyingNfCode: (state) => state.applyingNfCode,

  getClientInfo: (state) => {
    return state.clientInfo;
  },

  getInvoiceInfo: (state) => {
    return state.invoiceInfo;
  },

  getDetectingStatus: (state) => {
    return state.isProcessingDetection;
  },

  getDetectedClientNumber: (state) => {
    return state.detectedClientNumber;
  },

  getIsUsedFrncAccount: (state) => {
    return state.invoiceInfo.isUsedFrncAccount
  },

  getLinkingServiceName: (state) => {
    if (!state.serviceInvoice) {
      return '';
    }
    return state.serviceInvoice.linking_service_name || '';
  },

  isSelectedTab: (state) => (tabName) => {
    if (!location.hash) {
      return tabName === 'tab0';
    }
    return location.hash.replace('#', '') === tabName;
  },

  getClientProcessName: (state) => {
    return state.clientProcessName;
  },

};

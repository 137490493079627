
export default function page () {

  $('.js-accordion').each(function () {
    var $this = $(this);
    var $trigger = $this.find('.js-accordion__trigger');

    if ($this.hasClass('accordionTable')) {
      $trigger.on('click', function () {
        if ($(this).hasClass('is-active')) {
          $(this).removeClass('is-active');
        } else {
          $(this).addClass('is-active');
        }
      }); 
    } else {
      $trigger.on('click', function () {
        if ($this.hasClass('is-active')) {
          $this.removeClass('is-active');
        } else {
          $this.addClass('is-active');
        }
      }); 
    }
  });

};

import Vue from 'vue';
import Vuex from 'vuex';
export var UploadType;
(function (UploadType) {
    UploadType[UploadType["ANSHIN"] = 1] = "ANSHIN";
    UploadType[UploadType["ACCOUNT_NAME"] = 2] = "ACCOUNT_NAME";
    UploadType[UploadType["ADDITIONAL_FILE"] = 3] = "ADDITIONAL_FILE";
})(UploadType || (UploadType = {}));
Vue.use(Vuex);
export default new Vuex.Store({});

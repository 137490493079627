import { fullToHalfKana, smallToBigKana, halfToFullKana } from '../Convert';
var abbreviationRegexConfig = {
    カブシキガイシャ: '(（カ）|カ）|（カ)',
    カブシキカイシャ: '(（カ）|カ）|（カ)',
    ユウゲンガイシャ: '(（ユ）|ユ）|（ユ)',
    ユウゲンカイシャ: '(（ユ）|ユ）|（ユ)',
    ゴウメイガイシャ: '(（メ）|メ）|（メ)',
    ゴウメイカイシャ: '(（メ）|メ）|（メ)',
    ゴウシガイシャ: '(（シ）|シ）|（シ)',
    ゴウシカイシャ: '(（シ）|シ）|（シ)',
    ゴウドウガイシャ: '(（ド）|ド）|（ド)',
    ゴウドウカイシャ: '(（ド）|ド）|（ド)',
    イリョウホウジン: '(（イ）|イ）|（イ)',
    イリョウホウジンザイダン: '(（イ）|イ）|（イ)',
    イリョウホウジンシャダン: '(（イ）|イ）|（イ)',
    ギョウセイショシホウジン: '(（ギョ）|ギョ）|（ギョ)',
    コクリツダイガクホウジン: '(（ダイ）|ダイ）|（ダイ)',
    シショホウジン: '(（シホウ）|シホウ）|（シホウ)',
    ザイダンホウジン: '(（ザイ）|ザイ）|（ザイ)',
    シャダンホウジン: '(（シャ）|シャ）|（シャ)',
    エイギョウショ: '(（エイ）|エイ）|（エイ)',
    シュウキョウホウジン: '(（シュウ）|シュウ）|（シュウ)',
    ゼイリシホウジン: '(（ゼイ）|ゼイ）|（ゼイ)',
    ガッコウホウジン: '(（ガク）|ガク）|（ガク)',
    シャカイフクシホウジン: '(（フク）|フク）|（フク)',
    ソウゴガイシャ: '(（ソ）|ソ）|（ソ)',
    ソウゴカイシャ: '(（ソ）|ソ）|（ソ)',
    ユウゲンセキニンチュウカンホウジン: '(（チュウ）|チュウ）|（チュウ)',
    ムゲンセキニンチュウカンホウジン: '(（チュウ）|チュウ）|（チュウ)',
    コウセイホゴホウジン: '(（ホゴ）|ホゴ）|（ホゴ)',
    ベンゴシホウジン: '(（ベン）|ベン）|（ベン)',
    トクテイヒエイリカツドウホウジン: '(（トクヒ）|トクヒ）|（トクヒ)',
    ドクリツギョウセイホウジン: '(（ドク）|ドク）|（ドク)',
    ノウジクミアイホウジン: '(（ノウ）|ノウ）|（ノウ)',
    シュッチョウジョ: '(（シュツ）|シュツ）|（シュツ)'
};
var getRequiredRegex = function () {
    var requiredStrings = [];
    Object.entries(abbreviationRegexConfig).forEach(function (_a) {
        var key = _a[0], value = _a[1];
        requiredStrings.push(key, value);
    });
    return requiredStrings.join('|');
};
var getIncorporatedAccountNameRegex = function (companyNameKana) {
    // Sort terms by length in descending order
    // Otherwise the shorter terms will be match first and the longer terms which contains the shorter terms will never be matched
    var termsArray = Object.keys(abbreviationRegexConfig);
    termsArray.sort(function (a, b) { return b.length - a.length; });
    var regex = new RegExp(termsArray.join('|'));
    return companyNameKana.replace(regex, function (matched) { return (abbreviationRegexConfig[matched]); });
};
var postProcessAccountName = function (name) {
    var result = name;
    var replaceFrom = [
        ' ', '　', /\(/, /\)/, 'ー', '．', 'ｰ', '，'
    ];
    var replaceTo = [
        '', '', '（', '）', '-', '.', '-', ','
    ];
    replaceFrom.forEach(function (fm, idx) {
        result = result.replace(new RegExp(fm, 'g'), replaceTo[idx]);
    });
    return result;
};
// Equivalent to the convertAccountName function in UsersTable.php
// However parentheses are not included in order to work with regex
var convertAccountName = function (name) {
    var result = name;
    var replaceFrom = [
        'ー', '．', 'ｰ', '，'
    ];
    var replaceTo = [
        '-', '.', '-', ','
    ];
    replaceFrom.forEach(function (fm, idx) {
        result = result.replace(new RegExp(fm, 'g'), replaceTo[idx]);
    });
    return fullToHalfKana(smallToBigKana(halfToFullKana(result)));
};
var check = function (incorporatedAccountName, companyNameKana) {
    var accountName = postProcessAccountName(incorporatedAccountName);
    var companyName = postProcessAccountName(companyNameKana);
    var regex = new RegExp("" + convertAccountName(getRequiredRegex()));
    if (!regex.test(accountName)) {
        return false;
    }
    if (accountName.includes(convertAccountName(companyName))) {
        return true;
    }
    regex = new RegExp("" + convertAccountName(getIncorporatedAccountNameRegex(companyName)));
    return regex.test(accountName);
};
export default check;

<template>
  <dl class="itemBlock">
    <dt class="itemBlock__title">{{label}}</dt>
    <dd class="itemBlock__content">
      {{value}}
    </dd>
  </dl>
</template>

<script>
  export default {
    name: 'item2col',
    props: [
      'label',
      'value',
    ],
  }
</script>

<template>
  <div class="lineBlock">
    <section class="section">
      <p class="lineTitle">
        <span class="lineTitle__txt subTitle">
          エビデンス
          <span class="profile__icon modal_evidence" data-modal="modal_evidence"></span>
        </span><br><br>
        <span style="font-weight:bold">　※請求書の登録のみの場合は不要です</span>
      </p>
      <p style="margin-top: 0">請求書だけでお申し込みいただくこともできますが、下記のエビデンスアップロードにより審査の通過率が大きく上がります。</p>
      <div class="container-evidence">
        <dl class="itemBlock">
          <dd class="itemBlock__content">
            <div class="grid--3col" v-if="isReadOnlyEvidenceUpload">
              <EvidenceUploadReadonly v-for="(item, key) in listInvoiceEvidenceUploadContent" :key="key"
                :evidence-key="key" :evidence-item="item" evidence-type="invoice" />
            </div>
            <div class="grid--3col" v-else>
              <EvidenceUpload v-for="(item, key) in listInvoiceEvidenceUploadContent" :key="key" :evidence-key="key"
                :evidence-item="item" evidence-type="invoice" />
            </div>
          </dd>
          <dd class="itemBlock__content valiedTxt">
          </dd>
        </dl>
      </div>
    </section>
    <div class="trigger_company_no">{{ companyNo }}</div>
    <div id="modal_evidence" class="modal_window modal">
      <div class="modal-content">
        <div class="section__inner">
          <span class="close_icon close" id="close_icon"></span>
          <span class="lineTitle__txt">エビデンスアップロード</span>
          <p>取引の内容を確認するためにさらにいくつかの情報が必要です。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EvidenceUpload from './evidence-upload.vue';
import EvidenceUploadReadonly from './evidence-upload-readonly.vue';
import { initModal } from '../../../../component/Modal';

export default {
  name: 'InvoiceEvidence',
  components: {
    EvidenceUpload,
    EvidenceUploadReadonly
  },
  props: {
    isReadOnlyEvidenceUpload: {
      default: false,
      type: Boolean
    },
  },
  data() {
    return {
      validation: Object
    };
  },
  mounted() {
    initModal('modal_evidence');
  },
  computed: {
    listInvoiceEvidenceUploadContent() {
      return this.$store.getters['evidenceUpload/getInvoiceEvidenceUploadContent'];
    },

    companyNo() {
      return this.$store.getters.getClientInfo.number;
    },
  },
  watch: {
    companyNo: {
      async handler(newCompanyNo, oldCompanyNo) {
        if (newCompanyNo && newCompanyNo !== oldCompanyNo) {
          this.$store.commit('evidenceUpload/loadEvidenceContentHasClientName');
        }
      },
      immediate: true
    },
  },
};
</script>

var check = function (inputStr, maxLength) {
    var valid = inputStr.length <= maxLength;
    var message = valid ? '' : maxLength + "\u6587\u5B57\u4EE5\u5185\u3067\u5165\u529B\u3057\u3066\u4E0B\u3055\u3044";
    return {
        valid: valid,
        message: message
    };
};
export default {
    check: check
};

<template>
  <div :key="evidenceKey" class="block-evidence" :class="{ 'pr__40': this.evidenceKey !== 'invoiceEvidence3' }">
    <div class="title-evidence" :class="{ clickable: displayFormInput, hideBottomBorder: hideBottomBorder }"
      @click="toggleCollapse()">
      <div class="subTitle">
        <div class="subTitleText">
          {{ evidenceItem.title }}
          <i class="subTitleIcon billTable__sort" :class="sortIcon"></i>
        </div>
      </div>
    </div>
    <div v-if="displayFormInput" v-show="evidenceItemUploadValue.isCollapse" class="container-content-evidence">
      <div class="content-evidence">
        <p v-html="evidenceItem.content"></p>
      </div>
      <p v-show="errMessage && !evidenceItemUploadValue.isEdited && !isUploadFileError" class="valiedTxt">
        {{ errMessage }}
      </p>
      <div class="container-form-evidence">
        <div v-for="itemOption in evidenceItem.options" :key="itemOption.id" class="option-item">
          <label v-if="radioOptionShow(itemOption.id)" class="profileSettingOption__item__label
              radioUnit__radioBtn
              style-evidence-radio" :class="{
                'disable-input-evidence': radioOptionDisabled(itemOption.id),
                'is-checked': evidenceUploadValues[evidenceKey].selectOption === itemOption.id
              }">
            <input type="radio" v-model="evidenceUploadValues[evidenceKey].selectOption"
              @change="toggleEvidenceEditMark(itemOption.id)" :name="`${evidenceKey}Radio`" :value="itemOption.id"
              :disabled="isOnScreenMove || radioOptionDisabled(itemOption.id)">
            {{ itemOption.content }}
          </label>
          <div v-if="itemOption.isUploadFile" v-show="evidenceUploadValues[evidenceKey].selectOption === itemOption.id"
            class="upload">
            <div @dragleave.prevent @dragover.prevent @drop.prevent="setEvidenceUploadFile" class="upload__content">
              <div class="upload__item">
                <p class="upload__txt">
                  ドラッグアンドドロップでアップロードできます
                </p>
                <label class="uploadBtn--middle">
                  <input @change="setEvidenceUploadFile" multiple="multiple" type="file" :name="`${evidenceKey}File`">
                  <span class="uploadBtn--middle__inner" tabindex="0">ファイル選択</span>
                </label>
              </div>
            </div>
            <p v-show="errMessage && isUploadFileError" class="valiedTxt">
              {{ errMessage }}
            </p>
            <FileDisplay :data="evidenceUploadValues[evidenceKey].fileUploads" :enable-delete="true"
              @delete="deleteFile" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileCheck from '../../../../lib/validator/File';
import Const from '../../../../lib/Constants';
import FileDisplay from './file-display.vue';
import * as conf from '../../config';

export default {
  components: {
    FileDisplay
  },

  props: {
    evidenceKey: String,
    evidenceItem: Object,
    evidenceType: String
  },
  computed: {
    evidenceUploadValues() {
      return this.$store.getters['evidenceUpload/getInvoiceEvidenceUploadValues'];
    },

    isOnScreenMove() {
      return this.$store.getters.isOnScreenMove;
    },

    evidenceItemUploadValue() {
      return this.evidenceUploadValues[this.evidenceKey];
    },

    evidenceValidation() {
      return this.$store.getters['evidenceUpload/getInvoiceEvidenceValidation'];
    },

    clientInfo() {
      return this.$store.getters.getClientInfo;
    },
    errMessage() {
      return this.evidenceValidation[this.evidenceKey].message;
    },

    displayFormInput() {
      return ![
        conf.MASTER_USER_EVIDENCE_STATUS_UNNECESSARY,
        conf.MASTER_USER_EVIDENCE_STATUS_APPROVED
      ].includes(this.evidenceItemUploadValue.status);
    },

    hideBottomBorder() {
      return this.evidenceKey !== 'invoiceEvidence3' && (!this.displayFormInput || this.evidenceItemUploadValue.isCollapse);
    },

    sortIcon() {
      return (!this.displayFormInput || this.evidenceItemUploadValue.isCollapse) ? 'billTable__sort__desc' : 'billTable__sort__asc';
    },

    isUploadFileError() {
      return this.evidenceValidation[this.evidenceKey].isUploadFileError;
    }
  },
  methods: {
    toggleEvidenceEditMark(selectOpt) {
      this.$store.commit('evidenceUpload/toggleEvidenceEditMark', {
        key: this.evidenceKey,
        evidenceType: this.evidenceType,
        selectOpt
      });
    },

    setEvidenceUploadFile: async function (e) {
      e.preventDefault();
      this.setValidateUploadEnvidence();

      let files = e.target.files ? e.target.files : e.dataTransfer.files;

      if (files.length === 0) {
        return;
      }

      // Check its count of currently attached
      if (this.evidenceUploadValues[this.evidenceKey].fileUploads.length + files.length > Const.MAX_FILE_UPLOAD_LOAD_INVOICE) {
        e.target.value = '';
        this.setValidateUploadEnvidence(true, '5ファイルのみアップロード可能です');
        return;
      }

      for (const fileUpload of files) {
        await this.addEnvidenceUploadFile(fileUpload);
      }
    },

    async addEnvidenceUploadFile(file) {
      const typeCheck = await FileCheck.checkInvoiceFileType(file, null, this.$store.getters.getUploadFileTypeConfig);
      if (!typeCheck.valid) {
        this.setValidateUploadEnvidence(true, typeCheck.message);
        return;
      }

      // Check file size
      const sizeCheck = FileCheck.checkFileSize(file, Const.FILE_SIZE_OF_UP_INVOICE_FILE);
      if (!sizeCheck.valid) {
        this.setValidateUploadEnvidence(true, sizeCheck.message);
        return;
      }


      const uploadEvidenceObj = {
        file,
        key: this.evidenceKey,
        evidenceType: this.evidenceType
      };

      this.$store.dispatch('evidenceUpload/addEvidenceFileUpload', uploadEvidenceObj);
    },
    setValidateUploadEnvidence(isUploadFileError = false, message = '') {
      this.$store.commit('evidenceUpload/setEvidenceValidation', {
          key: this.evidenceKey,
          evidenceType: this.evidenceType,
          isUploadFileError,
          message
      });
    },
    deleteFile(index) {
      this.setValidateUploadEnvidence();

      const deleteObj = {
        evidenceType: this.evidenceType,
        key: this.evidenceKey,
        idx: index
      };

      this.$store.dispatch('evidenceUpload/deleteEvidenceFileUpload', deleteObj);
    },

    radioOptionShow(selectOpt) {
      // If status is reject, only show upload option radio and none option
      if (this.evidenceItemUploadValue.status === conf.MASTER_USER_EVIDENCE_STATUS_REJECTED
        && selectOpt !== conf.EVIDENCE_USER_SEND_ABOVE_OPT) {
        return true;
      }

      // If image is already uploaded, only show image upload option
      if (this.evidenceItemUploadValue.isUploaded && selectOpt !== conf.EVIDENCE_FILEUPLOAD_OPT) {
        return false;
      }

      return true;
    },

    radioOptionDisabled(selectOpt) {
      return false;
    },

    toggleCollapse() {
      if (!this.displayFormInput) {
        return;
      }
      this.$store.commit('evidenceUpload/toggleCollapse', {
        evidenceType: this.evidenceType,
        evidenceKey: this.evidenceKey
      });
    }
  }
};
</script>

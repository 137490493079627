import Vue from 'vue';
import App from './components/App.vue';
export default function page() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var vue = new Vue({
        el: '#paymentMembershipPlanApp',
        components: {
            App: App
        }
    });
}

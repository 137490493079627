var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Mutation, Action, VuexModule, getModule, Module } from 'vuex-module-decorators';
import store from '.';
import ajax from '../../../lib/Ajax';
import * as conf from '../../MembershipPlans/config';
var Main = /** @class */ (function (_super) {
    __extends(Main, _super);
    function Main() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isOnApiRequest = false;
        _this.isWaitingPayMembershipPlan = false;
        _this.selectedPlan = {
            planID: conf.MASTER_MEMBERSHIP_PLAN_FREE,
            periodID: conf.MASTER_MEMBERSHIP_BILLING_PERIODS_YEARLY
        };
        return _this;
    }
    Main.prototype.loadInitialData = function (payload) {
        this.selectedPlan = typeof payload.selectedPlan !== 'undefined' && payload.selectedPlan.planID
            ? payload.selectedPlan
            : this.selectedPlan;
        if (this.selectedPlan.periodID === conf.MASTER_MEMBERSHIP_BILLING_PERIODS_NONE) {
            this.selectedPlan.periodID = conf.MASTER_MEMBERSHIP_BILLING_PERIODS_YEARLY;
        }
        this.isWaitingPayMembershipPlan = payload.isWaitingPayMembershipPlan;
    };
    Main.prototype.updateSelectedPlanData = function (payload) {
        this.selectedPlan.planID = payload.planID;
        this.selectedPlan.periodID = payload.periodID;
    };
    Main.prototype.updateApiProcess = function (isOnApiRequest) {
        this.isOnApiRequest = isOnApiRequest;
    };
    Main.prototype.processConfirmedFreePlan = function () {
        return __awaiter(this, void 0, void 0, function () {
            var csrfToken, res, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        csrfToken = document.CSRF_TOKEN;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.updateApiProcess(true);
                        return [4 /*yield*/, ajax.post('/apiInternal/processConfirmedFreePlan', {}, { 'X-CSRF-Token': csrfToken })];
                    case 2:
                        res = _a.sent();
                        if (res.status !== 200) {
                            alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください');
                            this.updateApiProcess(false);
                            return [2 /*return*/];
                        }
                        location.href = '/signup/complete?planID=0';
                        this.updateApiProcess(false);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください');
                        this.updateApiProcess(false);
                        throw err_1;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    Main.prototype.createStripeCheckoutSession = function () {
        return __awaiter(this, void 0, void 0, function () {
            var csrfToken, res, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        csrfToken = document.CSRF_TOKEN;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.updateApiProcess(true);
                        return [4 /*yield*/, ajax.post('/apiInternal/createStripeCheckoutSession', { planID: this.selectedPlan.planID, periodID: this.selectedPlan.periodID }, { 'X-CSRF-Token': csrfToken })];
                    case 2:
                        res = _a.sent();
                        if (res.status !== 200) {
                            alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください');
                            this.updateApiProcess(false);
                            return [2 /*return*/];
                        }
                        location.href = res.data.data.url;
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください');
                        this.updateApiProcess(false);
                        throw err_2;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        Mutation
    ], Main.prototype, "loadInitialData", null);
    __decorate([
        Mutation
    ], Main.prototype, "updateSelectedPlanData", null);
    __decorate([
        Mutation
    ], Main.prototype, "updateApiProcess", null);
    __decorate([
        Action({ rawError: true })
    ], Main.prototype, "processConfirmedFreePlan", null);
    __decorate([
        Action({ rawError: true })
    ], Main.prototype, "createStripeCheckoutSession", null);
    Main = __decorate([
        Module({ dynamic: true, store: store, name: 'main' })
    ], Main);
    return Main;
}(VuexModule));
export default getModule(Main);

<template>
  <section class="section">
    <p class="lineTitle"><span class="lineTitle__txt">買取可能な請求書</span></p>
    <div class="section__inner">
      <div class="grid--2col">
        <div class="grid--2col__item">
          <dl class="itemBlock">
            <dt class="itemBlock__title">即日払い合計</dt>
            <dd class="itemBlock__content">
              <span class="price--xl">
                <span class="price--xl__num">
                  {{ total | moneyDelimiter }}
                </span>円
              </span>
              ※1
            </dd>
          </dl>
        </div>
        <div class="grid--2col__item">
          <dl class="itemBlock">
            <dt class="itemBlock__title">振込予定日</dt>
            <dd class="itemBlock__content mt__0">
            <template v-if="canApplyAny">
              <span class="num">
                <time datetime="2018-08-01">
                  {{outcomingDate | dateFormat}}
                </time>
              </span>
              <span class="note-txt">※2</span>
            </template>
            <template v-else>
              <span class="num">-</span>
            </template>
            </dd>
          </dl>
        </div>
      </div>
      <p v-if="canApplyAny">
        ※1: 実際の送金額は振込手数料350円を差し引いた金額となります
        <br />※2: 振込の実行は審査状況や金融機関の稼働状況により遅延する場合があります。
      </p>
      <table class="simpleTable">
        <thead>
          <tr>
            <th>取引先</th>
            <th>買取金額（円/税込）</th>
            <th>買取実行</th>
          </tr>
        </thead>
        <tbody>
          <invoice-list-item
             v-for="(invoice, index) in getAppliableInvoices"
             :key="`appliableInvoice${index}`"
             :invoice="invoice"
             @select="select"
             >
          </invoice-list-item>
        </tbody>
      </table>
      <div
        class="section__inner"
        v-show="isConfirmedPersonInCharge">
        <label
          class="checkBox is_disabled_checkbox"
          :class="{ 'is-checked': isConfirmedPersonInCharge }">
          <input
            v-model="isConfirmedPersonInCharge"
            type="checkbox"
            disabled="disabled"
            value="1">
          <span class="checkBox__txt">フリーナンスが請求先企業に連絡することを同意する</span>
        </label>
      </div>
    </div>
  </section>
</template>

<script>

  import { mapGetters } from 'vuex'
  import invoiceListItem  from './invoice-list-item.vue';

  export default {
    name: 'AppliableInvoice',
    components: {
      'invoice-list-item': invoiceListItem,
    },
    computed: {
      ...mapGetters([
        'total',
        'getAppliableInvoices',
        'outcomingDate',
        'canApplyAny',
      ]),
    },
    methods: {
      select(id) {
        this.$store.dispatch('selectInvoice', id);
      },
    },
    data() {
      return {
        isConfirmedPersonInCharge: false
      };
    },
    mounted() {
      if (this.getAppliableInvoices.length > 0) {
        this.isConfirmedPersonInCharge = this.getAppliableInvoices[0].is_confirmed_person_in_charge;
      }
    }
  }
</script>

<template>
    <h1 class="sectionTitle">
        <img src="/images/icon-profile.svg" alt="" class="sectionTitle__icon">
        <span v-if="currentStep == registrationStep.REGISTRATION_STEP_GENERATE_INFO" class="sectionTitle__txt">
          お客様情報入力
        </span>
        <span v-if="currentStep == registrationStep.REGISTRATION_STEP_BANK" class="sectionTitle__txt">
          フリーナンス口座の開設
        </span>
        <span v-if="currentStep == registrationStep.REGISTRATION_STEP_CONFIRMATION" class="sectionTitle__txt">
            登録内容確認
        </span>
    </h1>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'page-title',
  computed: {
    ...mapState({
      currentStep: (state) => state.status.currentStep,
      registrationStep: (state) => state.registrationStep
    }),
    incorporated() {
      return this.$store.getters.incorporated;
    }
  },
}
</script>

function sendEvent(e) {
    window.dataLayer.push({
        event: 'ChangingInput',
        eventAction: 'input',
        eventLabel: e.target.getAttribute('name')
    });
}
export default function setupInputTracking(parentDiv) {
    var rootEl = parentDiv || document;
    var inputsNodeList = rootEl.querySelectorAll('input:not([type="hidden"]), select');
    inputsNodeList.forEach(function (el) {
        var eventType = el.tagName === 'SELECT' || ['radio', 'file', 'checkbox'].includes(el.getAttribute('type')) ? 'change' : 'focusin';
        el.removeEventListener(eventType, sendEvent);
        el.addEventListener(eventType, sendEvent);
    });
}

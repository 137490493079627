var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Mutation, VuexModule, getModule, Module, Action } from 'vuex-module-decorators';
import store from '.';
var Main = /** @class */ (function (_super) {
    __extends(Main, _super);
    function Main() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.invoices = [];
        _this.isBlockStatusPublic = false;
        _this.flagSortDesc = 1; // -1 : asc, 1: desc
        return _this;
    }
    Object.defineProperty(Main.prototype, "isShowTable", {
        get: function () {
            return this.invoices.length > 0;
        },
        enumerable: false,
        configurable: true
    });
    Main.prototype.setSortDirection = function () {
        this.flagSortDesc = -1 * this.flagSortDesc;
    };
    Main.prototype.setInvoice = function (invoices) {
        this.invoices = invoices;
    };
    Main.prototype.loadInitialData = function () {
        var _a, _b;
        var doc = document;
        this.invoices = (_a = doc.INVOICES) !== null && _a !== void 0 ? _a : [];
        this.isBlockStatusPublic = (_b = doc.IS_BLOCK_STATUS_PUBLIC) !== null && _b !== void 0 ? _b : false;
    };
    Main.prototype.sortDate = function () {
        var flagSortDesc = this.flagSortDesc;
        var newInvoices = this.invoices.sort(function (a, b) {
            if (a.sort_date < b.sort_date)
                return -1 * flagSortDesc;
            if (a.sort_date > b.sort_date)
                return 1 * flagSortDesc;
            return 0;
        });
        this.setSortDirection();
        this.setInvoice(newInvoices);
    };
    __decorate([
        Mutation
    ], Main.prototype, "setSortDirection", null);
    __decorate([
        Mutation
    ], Main.prototype, "setInvoice", null);
    __decorate([
        Mutation
    ], Main.prototype, "loadInitialData", null);
    __decorate([
        Action({ rawError: true })
    ], Main.prototype, "sortDate", null);
    Main = __decorate([
        Module({ dynamic: true, store: store, name: 'main' })
    ], Main);
    return Main;
}(VuexModule));
export default getModule(Main);

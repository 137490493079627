import util from '../app/signup/util';

export default function initBankAuto() {
function Bnk(){
/*
 *	■金融機関（銀行コード等）情報の自動入力( bankauto0.js Ver1.11 )
 *
 *	The use is free of charge. / ご利用は無料です。
 *	@demo    https://bank-auto.com/
 *	@link    https://www.pierre-soft.com/
 *	@author  Tatsuro, Terunuma <info@pierre-soft.com>
 *
 *	[使い方：headタグ内に次の1行を定義して下さい]
 *	<script src="https://zipaddr.github.io/bankauto0.js" charset="UTF-8"></script>
 */
/*	<-↓ 以下は変更が可能な箇所です-> */
this.pm= new Array();
//            銀行コード、       支店コード
this.pm[1]= {"bkc":"bank_code", "bkn":"bank_name", "brc":"branch_code", "brn":"branch_name" };
this.pm[2]= {"bkc":"bank_code2","bkn":"bank_name2","brc":"branch_code2","brn":"branch_name2"};
this.pm[3]= {"bkc":"bank_code3","bkn":"bank_name3","brc":"branch_code3","brn":"branch_name3"};
this.bankmax=3;
this.bgc="#fafafa"; // guide_bgc
this.ovr="transparent"; // deepskyblue
this.lnc="#ffffcc"; // link color
this.lgc="#333333"; // link guide
this.family="ヒラギノ角ゴ Pro W3,Hiragino Kaku Gothic Pro,メイリオ,Meiryo,ＭＳ Ｐゴシック,sans-serif";
this.sel= "10";     // 拡張用(selectc)
this.left=22;       // offsetLeft、ガイダンス画面表示位置の補正
this.top= 18;       // offsetTop
this.pfon="12";     // pc:font-size
this.phig="1.4";    // pc:height
this.sfon="16";     // sPhone
this.shig="1.6";
this.rtrv="0";      // 1:曖昧検索,0:上方一致
this.rtrv0="";      // 1:検索wait
this.sphone="";     // 1:jQuery-mobile,2:etc(SmartPhone)
this.clear="_clear";
this.debug="";      // 1:debug-mode
/*	<-↑ 以上が変更可能です-> */

this.ver="1";
this.rev=".11";
this.com=".com";
this.goo=".git";
this.sv= "";
this.pad= 0;
this.url;
this.ul= new Array();
this.uls=new Array();
this.b= new Array();// bank_code
this.n= new Array();// bank_name
this.r= new Array();// branch_code
this.m= new Array();// branch_name
this.tb=new Array();// table
this.at=new Array();// guide
this.bc=new Array();// 銀行コード
this.xb="";         // code
this.xn="";         // name
this.xi="";         // number
this.xp="";         // sequence
this.xk="";
this.apad="";       // module追加
this.xlisten= "";   // 1:ｷIE,2:IE
this.elid="bnkauto";// ガイダンスid
this.zc="bnk_close";// 閉じるid
this.cls="click";   // or mouseover
this.contract="ymGTGwr9NXCv"; // 契約コード(c)
this.Cache=[];      // キャッシュ
}

// HACK Modification to add variable to global window (solve Bnk not defined error)
window.Bnk = Bnk;

let K = new Bnk;

// HACK Modification to add object to global window for using in Freenance:
// For automatically update bank info for bank-auto when select from predefined list or when reload from saved data
window.BK = K;
Bnk.a5 = function() {
  Bnk.aa(5)
};
Bnk.m5 = function() {
  Bnk.mv(5)
};
Bnk.v5 = function() {
  Bnk.ot(5)
};
Bnk.au = function(r) {
  let s = "https:";
  let x = K.uls[r];
  if (location.protocol == s || K.ul[r] == "") {} else {
    s = "http:";
    x = K.ul[r]
  }
  x = Ban.pr(unescape(x));
  x = s + '/' + '/' + x;
  return x
};
Bnk.a8 = function() {
  Bnk.aa(8)
};
Bnk.m8 = function() {
  Bnk.mv(8)
};
Bnk.v8 = function() {
  Bnk.ot(8)
};
Bnk.ib3 = function() {
  Bnk.g(3, 1)
};
Bnk.ir3 = function() {
  Bnk.g(3, 3)
};
Bnk.in2 = function() {
  Bnk.g(2, 2)
};
Bnk.im2 = function() {
  Bnk.g(2, 4)
};
Bnk.a3 = function() {
  Bnk.aa(3)
};
Bnk.m3 = function() {
  Bnk.mv(3)
};
Bnk.v3 = function() {
  Bnk.ot(3)
};
Bnk.a9 = function() {
  Bnk.aa(9)
};
Bnk.m9 = function() {
  Bnk.mv(9)
};
Bnk.v9 = function() {
  Bnk.ot(9)
};
Bnk.ib2 = function() {
  Bnk.g(2, 1)
};
Bnk.ir2 = function() {
  Bnk.g(2, 3)
};
Bnk.ipo = function() {
  if (typeof bankauto_ownb === "function") bankauto_ownb();
  Bnk.u();
  if (K.sphone == "") {
    const t = navigator.userAgent;
    if ((t.indexOf('iPhone') > 0 && t.indexOf('iPad') == -1) || t.indexOf('Android') > 0) {
      K.sphone = "2"
    }
  }
  if (typeof bankauto_own === "function") bankauto_own();
  for (let r = 1; r <= K.bankmax; r++) {
    const u = K.pm[r];
    K.b[r] = (typeof u.bkc != "undefined") ? u.bkc : "";
    K.n[r] = (typeof u.bkn != "undefined") ? u.bkn : "";
    K.r[r] = (typeof u.brc != "undefined") ? u.brc : "";
    K.m[r] = (typeof u.brn != "undefined") ? u.brn : ""
  }
  Bnk.h();
  K.tb["0"] = new Array();
  K.tb["1"] = new Array();
  K.tb["2"] = new Array();
  for (let d = 1; d <= K.bankmax; d++) {
    const b = K.b[d];
    const y = K.n[d];
    const e = K.r[d];
    const w = K.m[d];
    Ban.gi(b);
    Ban.gi(y);
    Ban.gi(e);
    Ban.gi(w);
    Bnk.s(b, y, e, w, d);
    Bnk.t(b, y, e, w, d)
  }
  Bnk.v();
  if (typeof bankauto_owna === "function") bankauto_owna()
};
Bnk.a2 = function() {
  Bnk.aa(2)
};
Bnk.m2 = function() {
  Bnk.mv(2)
};
Bnk.v2 = function() {
  Bnk.ot(2)
};
Bnk.t = function(b, h, f, a, k) {
  if (b != "") {
    K.tb["0"][b] = k;
    K.tb["1"][b] = 1;
    K.tb["2"][b] = h
  }
  if (h != "") {
    K.tb["0"][h] = k;
    K.tb["1"][h] = 2;
    K.tb["2"][h] = b
  }
  if (f != "") {
    K.tb["0"][f] = k;
    K.tb["1"][f] = 3;
    K.tb["2"][f] = a
  }
  if (a != "") {
    K.tb["0"][a] = k;
    K.tb["1"][a] = 4;
    K.tb["2"][a] = f
  }
};
Bnk.a1 = function() {
  Bnk.aa(1)
};
Bnk.m1 = function() {
  Bnk.mv(1)
};
Bnk.v1 = function() {
  Bnk.ot(1)
};
Bnk.a6 = function() {
  Bnk.aa(6)
};
Bnk.m6 = function() {
  Bnk.mv(6)
};
Bnk.v6 = function() {
  Bnk.ot(6)
};
Bnk.ib1 = function() {
  Bnk.g(1, 1)
};
Bnk.ir1 = function() {
  Bnk.g(1, 3)
};
Bnk.s = function(h, w, d, g, x) {
  const y = 'keyup';
  const y2 = 'compositionend';
  let n = "";
  if (h != "" && document.getElementById(h)) {
    n = document.getElementById(h);
    if (x == 1) {
      Ban.av(n, y, Bnk.ib1);
      Ban.av(n, y2, Bnk.ib1)
    } else if (x == 2) {
      Ban.av(n, y, Bnk.ib2);
      Ban.av(n, y2, Bnk.ib2)
    } else if (x == 3) {
      Ban.av(n, y, Bnk.ib3);
      Ban.av(n, y2, Bnk.ib3)
    }
  }
  if (w != "" && document.getElementById(w)) {
    n = document.getElementById(w);
    if (x == 1) {
      Ban.av(n, y, Bnk.in1);
      Ban.av(n, y2, Bnk.in1)
    } else if (x == 2) {
      Ban.av(n, y, Bnk.in2);
      Ban.av(n, y2, Bnk.in2)
    } else if (x == 3) {
      Ban.av(n, y, Bnk.in3);
      Ban.av(n, y2, Bnk.in3)
    }
  }
  if (d != "" && document.getElementById(d)) {
    n = document.getElementById(d);
    if (x == 1) {
      Ban.av(n, y, Bnk.ir1);
      Ban.av(n, y2, Bnk.ir1)
    } else if (x == 2) {
      Ban.av(n, y, Bnk.ir2);
      Ban.av(n, y2, Bnk.ir2)
    } else if (x == 3) {
      Ban.av(n, y, Bnk.ir3);
      Ban.av(n, y2, Bnk.ir3)
    }
  }
  if (g != "" && document.getElementById(g)) {
    n = document.getElementById(g);
    if (x == 1) {
      Ban.av(n, y, Bnk.im1);
      Ban.av(n, y2, Bnk.im1)
    } else if (x == 2) {
      Ban.av(n, y, Bnk.im2);
      Ban.av(n, y2, Bnk.im2)
    } else if (x == 3) {
      Ban.av(n, y, Bnk.im3);
      Ban.av(n, y2, Bnk.im3)
    }
  }
};
Bnk.in3 = function() {
  Bnk.g(3, 2)
};
Bnk.im3 = function() {
  Bnk.g(3, 4)
};
Bnk.h = function() {
  const e = "bankauto_param";
  if (document.getElementById(e)) {
    const t = document.getElementById(e).value;
    const w = t.split(",");
    for (let k = 0; k < w.length; k++) {
      const g = w[k].replace(/(^\s+)|(\s+$)/g, "");
      const q = g.split("=");
      if (q.length == 2) {
        const f = q[0];
        const r = q[1];
        if (f == "bkc") K.b[1] = r;
        else if (f == "bkn") K.n[1] = r;
        else if (f == "brc") K.r[1] = r;
        else if (f == "brn") K.m[1] = r;
        else if (f == "bkc2") K.b[2] = r;
        else if (f == "bkn2") K.n[2] = r;
        else if (f == "brc2") K.r[2] = r;
        else if (f == "brn2") K.m[2] = r;
        else if (f == "bkc3") K.b[3] = r;
        else if (f == "bkn3") K.n[3] = r;
        else if (f == "brc3") K.r[3] = r;
        else if (f == "brn3") K.m[3] = r;
        else if (f == "sel") K.sel = r;
        else if (f == "left") K.left = r;
        else if (f == "top") K.top = r;
        else if (f == "pfon") K.pfon = r;
        else if (f == "phig") K.phig = r;
        else if (f == "rtrv") K.rtrv = r;
        else if (f == "rtrv0") K.rtrv0 = r;
        else if (f == "bankmax") K.bankmax = r
      }
    }
  }
};
Bnk.u = function() {
  K.ul[0] = "";
  K.ul[1] = "";
  K.uls[0] = "b%u3042a%u3046nk%u3044a%u3042u%u3046t%u3042o%u3046a" + K.com;
  K.uls[1] = "%u3044b%u3046an%u3044k%u3042a%u3046ut%u3042o%u3042b" + K.com;
  if (K.sv == "") {
    const x = Math.floor(Math.random() * 10);
    if (x >= 5) K.sv = "1";
    else K.sv = "0"
  }
};
Bnk.in1 = function() {
  Bnk.g(1, 2)
};
Bnk.im1 = function() {
  Bnk.g(1, 4)
};
Bnk.p1 = function(w, h) {
  if (!document.getElementById(w)) return 0;
  let m;
  const y = document.getElementById(w);
  if (y.currentStyle) m = y.currentStyle[h];
  else if (getComputedStyle) {
    m = document.defaultView.getComputedStyle(y, '').getPropertyValue(h)
  } else m = "0";
  if (typeof m === "undefined") m = "1";
  let x = m;
  x = x.replace(/rem/g, '');
  x = x.replace(/em/g, '');
  if (m != x) m = (K.sphone != "") ? parseInt(x * 24) : parseInt(x * K.pfon);
  return m
};
Bnk.mv = function(u) {
  const obj = document.getElementById("zlin_" + u);
  Bnk.u9(obj, 1)
};
Bnk.aa = function(t) {
  Bnk.anp(K.at[t])
};
Bnk.l2 = function(u, s) {
  if (document.getElementById(u)) {
    const k = 'click';
    const v = 'mouseover';
    const a = 'mouseout';
    const q = document.getElementById(u);
    if (s == 1) {
      Ban.av(q, k, Bnk.a1);
      if (K.sphone == "") {
        Ban.av(q, v, Bnk.m1);
        Ban.av(q, a, Bnk.v1)
      }
    } else if (s == 2) {
      Ban.av(q, k, Bnk.a2);
      if (K.sphone == "") {
        Ban.av(q, v, Bnk.m2);
        Ban.av(q, a, Bnk.v2)
      }
    } else if (s == 3) {
      Ban.av(q, k, Bnk.a3);
      if (K.sphone == "") {
        Ban.av(q, v, Bnk.m3);
        Ban.av(q, a, Bnk.v3)
      }
    } else if (s == 4) {
      Ban.av(q, k, Bnk.a4);
      if (K.sphone == "") {
        Ban.av(q, v, Bnk.m4);
        Ban.av(q, a, Bnk.v4)
      }
    } else if (s == 5) {
      Ban.av(q, k, Bnk.a5);
      if (K.sphone == "") {
        Ban.av(q, v, Bnk.m5);
        Ban.av(q, a, Bnk.v5)
      }
    } else if (s == 6) {
      Ban.av(q, k, Bnk.a6);
      if (K.sphone == "") {
        Ban.av(q, v, Bnk.m6);
        Ban.av(q, a, Bnk.v6)
      }
    } else if (s == 7) {
      Ban.av(q, k, Bnk.a7);
      if (K.sphone == "") {
        Ban.av(q, v, Bnk.m7);
        Ban.av(q, a, Bnk.v7)
      }
    } else if (s == 8) {
      Ban.av(q, k, Bnk.a8);
      if (K.sphone == "") {
        Ban.av(q, v, Bnk.m8);
        Ban.av(q, a, Bnk.v8)
      }
    } else if (s == 9) {
      Ban.av(q, k, Bnk.a9);
      if (K.sphone == "") {
        Ban.av(q, v, Bnk.m9);
        Ban.av(q, a, Bnk.v9)
      }
    } else if (s == 10) {
      Ban.av(q, k, Bnk.a10);
      if (K.sphone == "") {
        Ban.av(q, v, Bnk.m10);
        Ban.av(q, a, Bnk.v10)
      }
    }
  }
};
Bnk.g = function(e, b) {
  K.xi = e;
  K.xp = b;
  let r = "";
  if (b == 1 || b == 2) {
    K.xb = K.b[e];
    K.xn = K.n[e]
  } else {
    K.xb = K.r[e];
    K.xn = K.m[e];
    if (!K.bc[e] || K.bc[e] == "") return
  }
  if (b == 1 || b == 3) {
    r = document.getElementById(K.xb).value;
    r = Ban.cg(r);
    if (0 < r.length && K.rtrv0 == "") {
      Bnk.pie(r, K.xb)
    }
  } else {
    r = document.getElementById(K.xn).value;
    r = Ban.cg(r);
    if (0 < r.length && K.rtrv0 == "") {
      Bnk.pie(r, K.xn)
    }
  }
};
Bnk.a10 = function() {
  Bnk.aa(10)
};
Bnk.m10 = function() {
  Bnk.mv(10)
};
Bnk.v10 = function() {
  Bnk.ot(10)
};
Bnk.a7 = function() {
  Bnk.aa(7)
};
Bnk.m7 = function() {
  Bnk.mv(7)
};
Bnk.v7 = function() {
  Bnk.ot(7)
};
Bnk.c3 = function() {
  Ban.es(K.elid)
};
Bnk.ot = function(w) {
  const obj = document.getElementById("zlin_" + w);
  Bnk.u9(obj, 0)
};
Bnk.a4 = function() {
  Bnk.aa(4)
};
Bnk.m4 = function() {
  Bnk.mv(4)
};
Bnk.v4 = function() {
  Bnk.ot(4)
};
Bnk.v = function() {
  const y = "11";
  let w = Bnk.au(1) + "/js/bankauto_x02.php?v=" + y;
  if (K.apad != "") w += "&m=" + K.apad;
  Ban.ca(w)
};
if (window.addEventListener) {
  window.addEventListener('load', Bnk.ipo, true)
} else if (window.attachEvent) {
  window.attachEvent('onload', Bnk.ipo, true)
}
try {
  $(document).on('pageinit', function(e) {
    K.sphone = "1";
    Bnk.ipo()
  })
} catch (e) {}
Ban.pr = function(d) {
  let r = d.replace(/う/g, '');
  r = r.replace(/あ/g, '');
  r = r.replace(/い/g, '');
  r = r.replace(/え/g, '');
  return r
};
Ban.bv = function(c, m, r) {
  if (c.addEventListener) {
    c.addEventListener(m, r, false)
  } else if (c.attachEvent) {
    c.attachEvent('on' + m, r)
  }
};
Ban.fc = function(y) {
  const x = y.value.length;
  y.focus();
  if (y.createTextRange) {
    const e = y.createTextRange();
    e.move('character', x);
    e.select()
  } else if (y.setSelectionRange) {
    y.setSelectionRange(x, x)
  }
};
Ban.er = function(m, g) {
  let w;
  if (document.getElementById(m)) {
    w = document.getElementById(m)
  } else {
    w = document.createElement('div');
    w.id = m;
    let x = g;
    if (x == "") x = document.getElementsByTagName("body").item(0);
    x.appendChild(w)
  }
  return w
};
Ban.br = function() {
  K.ua = window.navigator.userAgent.toLowerCase();
  const c = window.navigator.appVersion.toLowerCase();
  let k;
  if (K.ua.indexOf("msie") > -1) {
    if (c.indexOf("msie 6.") > -1) {
      k = "IE6"
    } else if (c.indexOf("msie 7.") > -1) {
      k = "IE7"
    } else if (c.indexOf("msie 8.") > -1) {
      k = "IE8"
    } else if (c.indexOf("msie 9.") > -1) {
      k = "IE9"
    } else if (c.indexOf("msie 10.") > -1) {
      k = "IE10"
    } else {
      k = "IE"
    }
  } else if (K.ua.indexOf("trident/7") > -1) {
    k = "IE11"
  } else if (K.ua.indexOf("edge") > -1) {
    k = "Edge"
  } else if (K.ua.indexOf("firefox") > -1) {
    k = "Firefox"
  } else if (K.ua.indexOf("opera") > -1) {
    k = "Opera"
  } else if (K.ua.indexOf("chrome") > -1) {
    k = "Chrome"
  } else if (K.ua.indexOf("safari") > -1) {
    k = "Safari"
  } else if (K.ua.indexOf("gecko") > -1) {
    k = "Gecko"
  } else {
    k = "Unknown"
  }
  K.bro = k;
  return k
};
Ban.av = function(e, d, h) {
  if (e.addEventListener) {
    e.addEventListener(d, h, false);
    K.xlisten = "1"
  } else if (e.attachEvent) {
    e.attachEvent('on' + d, h);
    K.xlisten = "2"
  }
};
Ban.sp = function(b) {
  if (K.woo == '1') {} else {
    const r = b.getAttribute("type").toLowerCase();
    if (r != "hidden") b.type = "tel"
  }
};
Ban.gi = function(a) {
  let c = a;
  if (a == "" || document.getElementById(a)) {} else {
    const t = document.getElementsByName(a);
    if (t.length == 1 && (t[0].id == "undefined" || t[0].id == "")) {
      c = c.replace(/\[/g, "");
      c = c.replace(/\]/g, "");
      t[0].id = c
    } else if (t.length == 1) c = t[0].id
  }
  return c
};
Ban.ca = function(e) {
  if (K.debug == "T") alert(e);
  Ban.es(K.elid);
  const y = document.createElement("script");
  y.id = K.elid;
  y.setAttribute("type", "text/javascript");
  y.setAttribute("src", e);
  y.setAttribute("charset", "UTF-8");
  document.body.appendChild(y)
};
Ban.ol = function(e) {
  let f = 0;
  while (e) {
    f += e.offsetLeft;
    e = e.offsetParent
  }
  return f
};
Ban.st = function(c) {
  c.style.imeMode = "disabled";
  if (c.value != "") Ban.fc(c)
};

// HACK Modified for display offset correctly in mobile
Ban.ot = function(r) {
  let v = 0;
  if (!r) return v;

  const t = Math.floor((r.offsetHeight - 18) / 2) - 3;
  if (t >= 2) v += t;

  while (r) {
    v += r.offsetTop;
    r = r.offsetParent
  }

  return v;
};
Ban.zh = function(y) {
  const w = "０１２３４５６７８９ー－‐―" + decodeURI("%E2%88%92");
  const n = "0123456789-----";
  let g = "";
  for (let x = 0; x < y.length; x++) {
    let r = y.charAt(x);
    const b = w.indexOf(r, 0);
    if (b >= 0) r = n.charAt(b);
    g += r
  }
  return g
};
Ban.th = function(n) {
  return n.replace(/[！-～]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0)
  })
};
Ban.sc = function(r) {
  if (r.length < 14) return false;
  const n = r.slice(2, -2);
  let k = n.length;
  if (k < 10) return false;
  const s = n.substr(1, 1);
  const y = n.substr(-3, 1);
  const t = n.substr(-1, 1);
  let m = n.substr(2, k - 6);
  m = Ban.pr(unescape(m));
  k = (m.length + 65) % 100;
  k = ("00" + k.toString(10)).slice(-2);
  if (s != k.substr(0, 1)) return false;
  if (y != k.substr(1, 1)) return false;
  if (t != m.split(".").length) return false;
  if (m != location.hostname) return false;
  return true
};
Ban.cs = function(q) {
  if (q != "") {
    const v = document.getElementsByClassName(q);
    if (v.length == 1 && !document.getElementById(q)) {
      if (v[0].id == "") v[0].id = q
    }
  }
};

function Ban() {
  this.ver = 1.10
}
Ban.cg = function(e) {
  let v = Ban.zh(e);
  v = v.replace(/-/g, '');
  v = v.replace(/\s/g, '');
  return v
};
Ban.es = function(p) {
  if (document.getElementById(p)) {
    const v = document.getElementById(p);
    const f = document.getElementsByTagName("body").item(0);
    f.removeChild(v)
  }
};

// HACK Modification to add variable to global window (so that variable not defined would not happend)
window.Banis_mole = "1";
Bnk.r = function(q, h) {
  if (document.getElementById(h)) {
    const u = document.getElementById(h);
    u.style.display = 'none';
    Bnk.y(q, "");
    if (K.tb["2"][q]) {
      Bnk.y(K.tb["2"][q], "");
      const r = K.tb["0"][q];
      const d = K.tb["1"][q];
      if (d == 1 || d == 2) K.bc[r] = ""
    }
  }
};

function Sub() {
  this.ver = "1.11";
  this.ip = "";
  this.keyc = "";
  this.remote = "";
  this.hostmei = "";
  this.keynams = "";
  this.owner = "@bank-auto";
  this.url = "/bank-auto.com/";
  this.ul = "%u3044b%u3044%u3042a%u3044n%u3044%u3046kd%u3048at%u3042a3"
}
let SS = new Sub;
Bnk.x = function(u, b) {
  const x = u + K.clear;
  if (b != "" && document.getElementById(x)) {
    const f = document.getElementById(x);
    f.style.display = 'inline-block';
    f.addEventListener("click", function() {
      Bnk.r(u, x)
    }, false)
  }
};
Bnk.u9 = function(p, m) {
  if (m == 1) {
    p.style.backgroundColor = K.ovr;
    p.style.fontSize = 120 + '%'
  } else {
    p.style.backgroundColor = K.bgc;
    p.style.fontSize = 100 + '%'
  }
};
Bnk.back = function(s) {
  Ban.es(K.elid);
  K.Cache[K.url] = s;
  const f = K.xi;
  const a = K.xp;
  let t = [];
  let x = 0;
  let n = 0;
  const k = K.xk.length;
  let m = "";
  let r = "";
  let h = "";
  let y;
  let g;
  let v;
  for (let e = 0; e < s.s; e++) {
    if (typeof s.bnk[e]['c'] == "undefined") y = m;
    else if (a == 1 || a == 2) y = ("0000" + s.bnk[e]['c']).slice(-4);
    else y = ("0000" + s.bnk[e]['c']).slice(-3);
    if (typeof s.bnk[e]['b'] == "undefined") v = h;
    else v = ("0000" + s.bnk[e]['b']).slice(-4);
    if (e == 0) {
      g = r = s.bnk[e]['n']
    } else if (typeof s.bnk[e]['n'] == "undefined") g = r;
    else g = Bnk.unpress(s.bnk[e]['n'], r);
    const z = {
      "c": y,
      "n": g,
      "b": v
    };
    if ((a == 3 || a == 4) && K.bc[f] != v) {} else if (isFinite(K.xk) && K.xk === y.substr(0, k)) {
      if (x < K.sel) {
        t[x] = z;
        x++
      }
      n++
    } else if (!isFinite(K.xk) && K.xk === g.substr(0, k)) {
      if (x < K.sel) {
        t[x] = z;
        x++
      }
      n++
    }
    m = y;
    h = v;
    r = g
  }
  const u = {
    "bnk": t,
    "s": n
  };
  Bnk.p(u)
};
Bnk.anp = function(x) {
  const e = K.xi;
  const w = K.xp;
  Bnk.c3();
  const t = x.split(" ");
  if (t.length != 2) return;
  Bnk.y(K.xb, t[0]);
  Bnk.y(K.xn, t[1]);
  if (w == 1 || w == 2) {
    K.bc[e] = t[0];
    Bnk.y(K.r[e], "");
    Bnk.y(K.m[e], "")
  }
  if (K.sphone != "") {
    let n = "";
    if (w == 1 && K.b[e] != "") n = document.getElementById(K.b[e]);
    else if (w == 2 && K.n[e] != "") n = document.getElementById(K.n[e]);
    else if (w == 3 && K.r[e] != "") n = document.getElementById(K.r[e]);
    else if (w == 4 && K.m[e] != "") n = document.getElementById(K.m[e]);
    if (n != "") n.blur()
  }
  return false
};
Bnk.z = function(p) {
  let c = [];
  if (p == null) return "";
  for (let a = 0; a < p.length; a++) {
    const g = p.charCodeAt(a);
    if (g <= 0x7f) c.push(g);
    else if (g <= 0x07ff) {
      c.push(((g >> 6) & 0x1F) | 0xC0);
      c.push((g & 0x3F) | 0x80)
    } else {
      c.push(((g >> 12) & 0x0F) | 0xE0);
      c.push(((g >> 6) & 0x3F) | 0x80);
      c.push((g & 0x3F) | 0x80)
    }
  }
  let z = "";
  for (let u = 0; u < c.length; u++) {
    let q = (c[u]).toString(16);
    if (c[u] < 16) q = '0' + q;
    z += q
  }
  return z
};
Bnk.unpress = function(u, t) {
  const a = u.indexOf("\t");
  if (a < 0) return u;
  const c = Number(u.substr(a + 1, 1)) + 2;
  const f = u.split("\t");
  return f[0] + t.substr(-c)
};
Bnk.y = function(b, m) {
  if (b != "" && document.getElementById(b)) {
    const v = document.getElementById(b);
    const z = v.tagName.toLowerCase();
    const e = (z == "input") ? v.getAttribute("type").toLowerCase() : "";
    if (e == "hidden") {} else if (z == "input") {
      v.value = m;
      Bnk.x(b, m)
    } else {
      v.innerHTML = m;
      Bnk.x(b, m)
    }
  }
};
Bnk.pie = function(s, h) {
  // HACK Modification to allow completion for lower case and english alphabet
  // Solve https://github.com/freenance/frnc-frontend/pull/1584#issuecomment-618846401
  s = util.convertAlphabetToFullWidthUpperCase(s);

  K.xk = s;
  K.pad = Bnk.p1(h, "padding-top");
  let q = s.substr(0, 1);
  if (!isFinite(K.xk)) q = Bnk.z(q);
  if (K.xp == "1" || K.xp == "2") q += ".bank";
  else q += ".bran";
  const v = Ban.pr(unescape(SS.ul));
  K.url = 'https:' + '/' + '/' + 'zipaddr' + K.goo + 'hub.io/' + v + '/' + q;
  const f = K.Cache[K.url];
  if (f) return Bnk.back(f);
  Ban.ca(K.url, K.elid)
};
Bnk.p = function(g) {
  const e = K.xi;
  const c = K.xp;
  const w = (K.sv == "0") ? "" : ".";
  const v = '<span id="bnk_count">' + g.s + '件ヒット</span>';
  let y = '<span id="bnk_close">[閉じる]</span> ';
  y += '<a href="https:/' + SS.url + '" style="text-decoration:none;color:' + K.lgc + '" target="_blank">';
  y += '<font size="2">' + SS.owner + w + '</font></a>';
  let h = Bnk.w(g, c);
  h += (h == "") ? "" : "<br />";
  h += v + "　" + y;
  let t;
  if (c == 1 || c == 3) t = document.getElementById(K.xb);
  else t = document.getElementById(K.xn);
  t.style.position = "relative";
  let u = Ban.ol(t) + K.left;
  const p = Ban.ot(t, K.xb) + K.top + parseInt(K.pad) - 1;
  if (K.sphone != "") {
    u -= 20
  }
  let z = Bnk.e();
  z.style.left = u + "px";
  z.style.top = p + "px";
  z.innerHTML = h;
  const k = (K.zc != "" && document.getElementById(K.zc)) ? document.getElementById(K.zc) : "";
  for (let a = 1; a <= g.bnk.length; a++) {
    Bnk.l2('zlin_' + a, a)
  }
  if (document.getElementById(K.zc)) {
    Ban.bv(k, K.cls, Bnk.c3)
  }
};
Bnk.w = function(h, n) {
  let d = "";
  let g;
  K.at = new Array();
  let z = "";
  let p = "";
  let m;
  let q;
  for (let b = 0; b < h.bnk.length; b++) {
    const u = b + 1;
    if (typeof h.bnk[b]['c'] == "undefined") m = z;
    else if (n == 1 || n == 2) m = ("0000" + h.bnk[b]['c']).slice(-4);
    else m = ("0000" + h.bnk[b]['c']).slice(-3);
    if (b == 0) {
      q = p = h.bnk[b]['n']
    } else if (typeof h.bnk[b]['n'] == "undefined") q = p;
    else q = Bnk.unpress(h.bnk[b]['n'], p);
    g = '<font size="2">' + m + "</font>";
    g += (K.sphone == "") ? "&nbsp;" : "";
    g += q;
    d += '<div class="bnk_line" id="zlin_' + u + '">' + g + '</div>';
    K.at[u] = m + " " + q;
    z = m;
    p = q
  }
  return d
};
Bnk.e = function() {
  const p = (K.sphone == "") ? K.pfon : K.sfon;
  const w = (K.sphone == "") ? K.phig : K.shig;
  let n = Ban.er(K.elid, "");
  n.style.position = 'absolute';
  n.style.display = "block";
  n.style.zIndex = "999999";
  n.style.fontFamily = K.family;
  n.style.fontSize = p + 'px';
  n.style.lineHeight = w;
  n.style.padding = "5px 8px";
  n.style.borderRadius = "8px";
  n.style.backgroundColor = K.bgc;
  n.style.textAlign = 'left';
  return n
};
}

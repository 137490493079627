<template>
  <div class="lineBlock" v-if="needEvidencialInvoices.length > 0">
    <section class="section">
      <div class="section__inner">
        <p class="lineTitle">
          <span class="lineTitle__txt">追加エビデンス登録後に買取可能な請求書</span>
        </p>
        <p>
          以下の請求書を書いとるには追加エビデンスが必要です。<br>請求書詳細画面からエビデンスをメールで送信してください。
        </p>
      </div>
      <div class="section__inner">
        <div class="grid--2col">
          <div class="grid--2col__item">
            <dl class="itemBlock">
              <dt class="itemBlock__title">買取合計額</dt>
              <dd class="itemBlock__content">
                <span class="price--xl">
                  <span class="price--xl__num">
                    {{ total | moneyDelimiter }}
                  </span>円
                </span>
              </dd>
            </dl>
          </div>
          <div class="grid--2col__item">
            <dl class="itemBlock">
              <dt class="itemBlock__title">振込予定日</dt>
              <dd class="itemBlock__content">追加エビデンスアップロードから2営業日</dd>
            </dl>
          </div>
        </div>
        <table class="simpleTable">
          <thead>
            <tr>
              <th>取引先</th>
              <th>買取金額（円/税込）</th>
            </tr>
          </thead>
          <tbody>
            <invoice-list-item-apply v-for="(invoice, index) in needEvidencialInvoices" :invoice="invoice"
              :key="`evidencialInvoice${index}`" @select="select">
            </invoice-list-item-apply>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
import invoiceListItemApply from './invoice-list-item-apply.vue';
export default {
  name: 'NeedEvidenceInvoice',
  components: {
    'invoice-list-item-apply': invoiceListItemApply,
  },
  computed: {
    total: function () {
      return this.$store.getters['applyQuickPay/total'];
    },
    needEvidencialInvoices: function () {
      return this.$store.getters['applyQuickPay/getNeedEvidencialInvoices'];
    },
  },
  methods: {
    select(id) {
      this.$store.dispatch('selectInvoice', id);
    },
  },
  data() {
    return {
      termsConditions: this.$store.state.termsConditions,
    }
  },
}
</script>

export const EVIDENCE_FILEUPLOAD_OPT = 1;
export const EVIDENCE_INVOICE_MAIL_OPT = 2;
export const EVIDENCE_USER_SEND_ABOVE_OPT = 3;
export const EVIDENCE_NONE_OPT = 0;

export const NUM_INVOICE_FILE_UPLOADS = 5;
export const NUM_INVOICE_EVIDENCES = 3;
export const NUM_INVOICE_EVIDENCE_UPLOADS = 5;
export const NUM_USER_EVIDENCE_UPLOADS = 5;

export const MASTER_USER_EVIDENCE_STATUS_CHECKING = 1;
export const MASTER_USER_EVIDENCE_STATUS_APPROVED = 2;
export const MASTER_USER_EVIDENCE_STATUS_REJECTED = 3;
export const MASTER_USER_EVIDENCE_STATUS_UNNECESSARY = 6;
export const MASTER_USER_EVIDENCE_STATUS_REQUIRED = 7;

// edit invoice screen state
export const SCREEN_STATE_CAN_EDIT_INVOICE = 0; //画面完全に編集可能
export const SCREEN_STATE_CAN_NOT_EDIT_INVOICE_FOR_DEALING = 1; //即日払い申込み中は編集できません
export const SCREEN_STATE_CAN_NOT_EDIT_INVOICE_WITH_CANCEL = 2; // 即日払い申込み中は編集できません（申込みをキャンセルする）
export const SCREEN_STATE_CAN_NOT_EDIT_INVOICE_WITH_ADD_EVIDENCE = 3; //修正・証憑追加を依頼されています
export const SCREEN_STATE_CAN_NOT_EDIT_INVOICE_WITH_PROPOSAL = 4; // 買取金額についての提案が届いています（提案を確認する）
export const SCREEN_STATE_CAN_NOT_EDIT_INVOICE = 5; //即日払い未申込、請求書編集できません

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { VuexModule, getModule, Module, Mutation, Action } from 'vuex-module-decorators';
import ajax from '@lib/Ajax';
import store from '.';
import * as conf from '../config';
var Main = /** @class */ (function (_super) {
    __extends(Main, _super);
    function Main() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.csrfToken = '';
        _this.isSignup = false;
        _this.isTrialCampaign = false;
        _this.isTrialCampaignCodeInvalid = false;
        _this.activatingPlanID = 0;
        _this.activatingPeriodID = conf.MASTER_MEMBERSHIP_BILLING_PERIODS_YEARLY;
        _this.periodEnd = '';
        _this.isDisplayableCustomerPortalLink = false;
        _this.mediaCode = {
            invitingCode: '',
            trackingCode: ''
        };
        _this.status = {
            processingApiRequest: false,
            processingCustomerPortalSessionApiRequest: false
        };
        _this.trialCampaignCode = '';
        _this.trialCampaignStartDate = '';
        _this.trialCampaignEndDate = '';
        _this.trialCampaignPeriod = '';
        _this.changePlanInfo = {
            action: 0,
            selectedPlanID: 0,
            selectedPeriodID: 0,
            subID: '',
            checkoutUrl: ''
        };
        return _this;
    }
    Object.defineProperty(Main.prototype, "selectedPlan", {
        get: function () {
            return {
                selectedPlanID: this.changePlanInfo.selectedPlanID,
                selectedPeriodID: this.changePlanInfo.selectedPeriodID
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "isOnApiRequest", {
        get: function () {
            return this.status.processingApiRequest;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "isOnCustomerPortalSessionApiRequest", {
        get: function () {
            return this.status.processingCustomerPortalSessionApiRequest;
        },
        enumerable: false,
        configurable: true
    });
    Main.prototype.loadInitialData = function () {
        var _a, _b, _c;
        var doc = document;
        this.csrfToken = doc.CSRF_TOKEN;
        var invitingCode = '';
        var trackingCode = '';
        var isSignup = (_a = doc.IS_SIGNUP) !== null && _a !== void 0 ? _a : false;
        if (isSignup) {
            invitingCode = doc.INVITING_CODE;
            trackingCode = doc.TRACKING_CODE;
        }
        this.isSignup = isSignup;
        this.isTrialCampaign = !!doc.TRIAL_CAMPAIGN_CODE;
        this.isTrialCampaignCodeInvalid = (_b = doc.IS_TRIAL_CAMPAIGN_CODE_INVALID) !== null && _b !== void 0 ? _b : false;
        this.activatingPlanID = doc.TRIAL_CAMPAIGN_CODE
            ? conf.MASTER_MEMBERSHIP_PLAN_FREE
            : (_c = doc.ACTIVATING_PLAN_ID) !== null && _c !== void 0 ? _c : this.activatingPlanID;
        this.activatingPeriodID = (doc.TRIAL_CAMPAIGN_CODE
            || doc.ACTIVATING_PERIOD_ID === conf.MASTER_MEMBERSHIP_BILLING_PERIODS_NONE)
            ? conf.MASTER_MEMBERSHIP_BILLING_PERIODS_YEARLY
            : doc.ACTIVATING_PERIOD_ID;
        this.periodEnd = doc.PERIOD_END;
        this.isDisplayableCustomerPortalLink = doc.IS_DISPLAYABLE_CUSTOMER_PORTAL_LINK;
        this.mediaCode = {
            invitingCode: invitingCode,
            trackingCode: trackingCode
        };
        // trial campaign data
        this.trialCampaignCode = doc.TRIAL_CAMPAIGN_CODE;
        this.trialCampaignStartDate = doc.TRIAL_CAMPAIGN_START_DATE;
        this.trialCampaignEndDate = doc.TRIAL_CAMPAIGN_END_DATE;
        this.trialCampaignPeriod = doc.TRIAL_CAMPAIGN_PERIOD;
    };
    // API通信状態をセットする
    Main.prototype.updateProcessingApiRequest = function (status) {
        this.status.processingApiRequest = status;
    };
    // CustomerPortalSessionAPI通信状態をセットする
    Main.prototype.updateProcessingCustomerPortalSessionApiRequest = function (status) {
        this.status.processingCustomerPortalSessionApiRequest = status;
    };
    Main.prototype.updateChangePlanInfo = function (data) {
        this.changePlanInfo.action = data.action || this.changePlanInfo.action;
        this.changePlanInfo.checkoutUrl = data.checkoutUrl || this.changePlanInfo.checkoutUrl;
        this.changePlanInfo.subID = data.subID || this.changePlanInfo.subID;
        this.changePlanInfo.selectedPlanID = data.selectedPlanID || this.changePlanInfo.selectedPlanID;
        this.changePlanInfo.selectedPeriodID = data.selectedPeriodID || this.changePlanInfo.selectedPeriodID;
    };
    // process change to new plan
    Main.prototype.processChangePlan = function () {
        return __awaiter(this, void 0, void 0, function () {
            var params, res, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.status.processingApiRequest)
                            return [2 /*return*/];
                        this.updateProcessingApiRequest(true);
                        params = this.changePlanInfo;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, ajax.post('/apiInternal/processChangePlan', params, { 'X-CSRF-Token': this.csrfToken })];
                    case 2:
                        res = _a.sent();
                        this.updateProcessingApiRequest(false);
                        if (res.data.status !== 200) {
                            alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください');
                            return [2 /*return*/];
                        }
                        location.href = '/account';
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください');
                        this.updateProcessingApiRequest(false);
                        throw err_1;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    // estimate info when user choose new plan
    Main.prototype.chooseMembershipPlan = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var trialCampaignCode, params, res, reData, result, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.status.processingApiRequest)
                            return [2 /*return*/];
                        this.updateProcessingApiRequest(true);
                        trialCampaignCode = data.trialCampaignCode ? data.trialCampaignCode : '';
                        params = { selectedPlanID: data.planID, selectedPeriodID: data.periodID, trialCampaignCode: trialCampaignCode };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, ajax.post('/apiInternal/chooseMembershipPlan', params, { 'X-CSRF-Token': this.csrfToken })];
                    case 2:
                        res = _a.sent();
                        reData = res.data.data;
                        this.updateProcessingApiRequest(false);
                        if (res.data.status !== 200) {
                            alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください');
                            return [2 /*return*/];
                        }
                        result = 'OK';
                        switch (reData.action) {
                            case conf.ACTION_CHECKOUT_SESSION:
                            case conf.ACTION_CANCEL_SUBSCRIPTION:
                            case conf.ACTION_UPGRADE_SUBSCRIPTION:
                            case conf.ACTION_DOWNGRADE_SUBSCRIPTION:
                            case conf.ACTION_UPGRADE_SUBSCRIPTION_WITHOUT_CASHBACK:
                            case conf.ACTION_MEMBERSHIP_TRIAL_CHECKOUT_SESSION:
                                this.updateChangePlanInfo(__assign(__assign({}, params), reData));
                                break;
                            default:
                                result = 'NG';
                        }
                        // eslint-disable-next-line consistent-return
                        return [2 /*return*/, result];
                    case 3:
                        e_1 = _a.sent();
                        this.updateProcessingApiRequest(false);
                        alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください');
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    // Return URL of the session that gives customers access to the customer portal
    Main.prototype.createStripeCustomerPortalSession = function () {
        return __awaiter(this, void 0, void 0, function () {
            var res, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.status.processingCustomerPortalSessionApiRequest)
                            return [2 /*return*/];
                        this.updateProcessingCustomerPortalSessionApiRequest(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, ajax.post('/apiInternal/createStripeCustomerPortalSession', {}, { 'X-CSRF-Token': this.csrfToken })];
                    case 2:
                        res = _a.sent();
                        if (res.status !== 200 || res.data.data === undefined || res.data.data === '') {
                            alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください');
                            this.updateProcessingCustomerPortalSessionApiRequest(false);
                            return [2 /*return*/];
                        }
                        location.href = res.data.data;
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください');
                        this.updateProcessingCustomerPortalSessionApiRequest(false);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        Mutation
    ], Main.prototype, "loadInitialData", null);
    __decorate([
        Mutation
    ], Main.prototype, "updateProcessingApiRequest", null);
    __decorate([
        Mutation
    ], Main.prototype, "updateProcessingCustomerPortalSessionApiRequest", null);
    __decorate([
        Mutation
    ], Main.prototype, "updateChangePlanInfo", null);
    __decorate([
        Action({ rawError: true })
    ], Main.prototype, "processChangePlan", null);
    __decorate([
        Action({ rawError: true })
    ], Main.prototype, "chooseMembershipPlan", null);
    __decorate([
        Action({ rawError: true })
    ], Main.prototype, "createStripeCustomerPortalSession", null);
    Main = __decorate([
        Module({ dynamic: true, store: store, name: 'main', namespaced: true })
    ], Main);
    return Main;
}(VuexModule));
export default getModule(Main);

import Vue from 'vue'
import Router from 'vue-router'
import App from '../components/App'

Vue.use(Router);
const router = new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/invoices/add',
      component: App,
      name: 'add'
    },
    {
      path: '/invoices/edit/:id',
      component: App,
      name: 'edit'
    },
  ]
});

router.afterEach((to, from) => {
  if (from.matched.length) {
    Vue.nextTick(() => {
      window.dataLayer.push({ event: 'content-view' });
    });
  }
});

export default router;

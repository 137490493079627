import { initCopyToClipboard } from './lib/CopyToClipboard';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import ajax from './lib/Ajax';
import QRCodeGenerator from './lib/QRCode';
import { datadogRum } from '@datadog/browser-rum';

var $ = require('jquery');
require('jquery-ui/ui/widgets/datepicker');
require('jquery-easing');

export default function common () {

  (function sideNav() {
    function onClickSideNav() {
      var w = $(window).width();
      if (w <= 767) {
        $(this)
          .toggleClass('is-active')
          .parents('.side')
          .toggleClass('is-active');
        $('.js-sideNavContent').slideToggle(500, 'easeInOutExpo');
      }
    }
    $('.js-sideNavTrigger').on('click', onClickSideNav);
  })();

  (function headerMenufunc() {
    var $target = $('.js-headerSp'), 
      $trigger = $target.find('.js-headerSp__menuBtn');

    $trigger.on('click', function () {
      if ($target.hasClass('is-active')) {
        $target.removeClass('is-active');
      } else {
        $target.addClass('is-active');
      }
    });
  })();


  //radio btn
  (function radioFunc() {
    
    /// check default
    $('input[type="radio"]:checked')
      .parents('.js-radioTrigger')
      .addClass('is-checked');
    
    function onChangeRadio () {
      var name = $(this).attr('name');
      $('input[type="radio"][name="' + name + '"]')
        .parents('.js-radioTrigger')
        .removeClass('is-checked');
      $(this)
        .parents('.js-radioTrigger')
        .addClass('is-checked');
    }
    
    $('.js-radioTrigger input').on('change', onChangeRadio);
  })();

  //checkbox
  (function checkBoxFunc() {
    /// check default
    $('input[type="checkBox"]:checked')
      .parents('.js-checkBoxTrigger,.js-tableCheck')
      .addClass('is-checked');
    
    function onChangeCheckbox () {
      $(this).parents('.js-checkBoxTrigger').toggleClass('is-checked');
      $(this).parents('.js-tableCheck').toggleClass('is-checked');
    }
    
    $('.js-checkBoxTrigger input').on('change', onChangeCheckbox);
  })();

  (function fileUpFunc() {
    //upload
    var $target = $('.js-fileArea'),
      formData = new FormData();

    $target.on('dragenter', function (e) {
      e.stopPropagation();
      e.preventDefault();
    });
    $target.on('dragover', function (e) {
      e.stopPropagation();
      e.preventDefault();
    });
    $target.on('drop', function (e) {
      e.stopPropagation();
      e.preventDefault();

      var files = e.originalEvent.dataTransfer.files;
      for (var i = 0; i < files.length; i++) {
        (function () {
          var fr = new FileReader();
          var fileName = files[i].name;

          fr.onload = function () {
            var html_before = '<li><span class="fileList__name">',
              html_after = '</span><div class="fileList__btn"><img class="fileList__icon" src="/common/images/icon-copy-bkBlack.svg" alt="コピー"><img class="fileList__icon" src="/common/images/icon-close-black.svg" alt="削除"></div><!-- fileList__btn --></li>';

            var preview = $('.fileList');
            preview.append(html_before + fileName + html_after);
          };
          fr.readAsDataURL(files[i]);
        })();

        formData.append("file", files[i]);
      }

    });

    //delete

    //copy
  })();

  (function calendar() {
    $('.js-calendar').datepicker({
      dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
      monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      yearSuffix: '.',
      dateFormat: 'yy/mm/dd',
      showMonthAfterYear: true,
      showOtherMonths: true,
      showOn: 'button',
      buttonImage: '/images/icon-calendar.svg',
      buttonImageOnly: true,
      onSelect: function() {
        // https://sukohi.blogspot.com/2017/05/vue-jquery-conflict.html
        var event = document.createEvent('HTMLEvents');
        event.initEvent('input', true, true);
        // change イベント内でネイティブ JS イベントを送出
        $(this).get(0).dispatchEvent(event);
      },
      beforeShow: function (input, inst) { 
        //GMO header
        var result = 0;
        result = parseInt($('body').css('top'));
        if (isNaN(result)) {
          result = 0;
        }

        var inputPos_Y = $(input).offset().top;
        var inputPos_X = $(input).offset().left;

        var inputH = $(input).outerHeight();
        var inputW = $(input).outerWidth();
        var calendarW = $("#ui-datepicker-div").outerWidth();
        var calendarPos_Y = inputPos_Y + inputH - result + 30;
        var calendarPos_X = inputPos_X + inputW - calendarW;
        var $calendar = inst.dpDiv;

        setTimeout(function () {
          if ($(input).hasClass('is-calendarLeft')) {
            $calendar.addClass('is-left');
            calendarPos_X = inputPos_X;
          }
          $calendar.css({'top': calendarPos_Y});
          $calendar.css({'left': calendarPos_X});
        }, 1);
      }
    });
  })();

  ((function disableSubmitButton() {
    const submitFrm = document.getElementById('preventMultiSubmitForm');
    const submitBtn = document.getElementById('preventMultiSubmitBtn');
    if (submitFrm && submitBtn) {
      submitFrm.addEventListener('submit', () => {
        submitBtn.setAttribute('disabled', true);
      });
    }
  })());

  /* ---------------------------------------------------------
   * safari :active
   * ----------------------------------------------------------*/
  document.addEventListener("touchstart", function () {}, false);

  /* ---------------------------------------------------------
   * ie  :active
   * ----------------------------------------------------------*/
  (function btnClick() {
    $('[class*="Btn"]:not([class*="is-disabled"])').mousedown( function () {
      $(this).addClass('is-click');
    }).mouseup( function () {
      $(this).removeClass('is-click');
    });
  })();


  /* ---------------------------------------------------------
  reCAPTCHA resize
  ----------------------------------------------------------*/
  function reCAPTCHA_resize() {
    var $itemW = $('.g-recaptchaWrap').width(),
      $v = ($itemW) / 302, //画面幅に対するreCAPTCHAの比率
      $h = 78 * $v + 20; //高さ
    if ($itemW <= 300) {
      $('.g-recaptcha').css('transform', 'scale(' + $v + ')');
      $('.g-recaptcha > div').css('height', $h);
    } else {
      $('.g-recaptcha').css('transform', 'scale(1)');
      $('.g-recaptcha > div').css('height', 78);
    }
  }
  /* ---------------------------------------------------------
  Show Drap Animation
  ----------------------------------------------------------*/
  function drapAnimation() {
    const dropArea = document.querySelector(".upload__content");
    if (dropArea) {
      dropArea.addEventListener("dragover", (event)=>{
        event.preventDefault();
        dropArea.classList.add("drag-active");
      });

      dropArea.addEventListener("dragleave", ()=>{
        dropArea.classList.remove("drag-active");
      });
      dropArea.addEventListener("drop", ()=>{
        dropArea.classList.remove("drag-active");
      });
    }
  }
  $(window).on('load', function () {
    reCAPTCHA_resize();
    drapAnimation();
  });

  var timer = false;
  $(window).resize(function () {
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      reCAPTCHA_resize();
    }, 200);
  });


  /* Init copy to clipboard */
  initCopyToClipboard(document.getElementById('provingEmail'),
    {'generalBtn__inner':  'localBtn__inner', 'generalBtn__txt': 'localBtn__txt'});
  initCopyToClipboard(document.getElementById('bankInfo'),
    {'generalBtn--small__inner':  'localBtn--small__inner', 'generalBtn--small__txt': 'localBtn-small__txt'});
  initCopyToClipboard(document.getElementById('invitingCode'),
    { generalBtn__inner: 'localBtn__inner', generalBtn__txt: 'localBtn__txt' });
  initCopyToClipboard(document.getElementById('paymentURL'), {});
  /* ---------------------------------------------------------
   * Browser Fingerprint
   * ----------------------------------------------------------*/ 
  (function initBrowserFingerprint() {
    if (!window.localStorage) {
      return;
    }
    if (!document.BF) {
      const fpPromise = FingerprintJS.load()
      fpPromise
        .then(fp => fp.get())
        .then(async result => {
          await ajax.post(
            '/apiInternal/visit',
            {f: result.visitorId}
          );
        });
    }
  })();

  (function popupInfo() {
    /* menu popup */
    function onClickShowMenuBtn() {
      $('#nav-popup-menu').fadeIn('fast', function() {
        $('body').addClass(' hidden-scroll');
        $('#nav-popup-menu .dialog-info').addClass('dialog-info-show');
      });
      $(document).on('keyup', function(event) {
        if (event.key == "Escape") {
          onClickHideMenuBtn();
        }
      });
    }

    function onClickHideMenuBtn() {
      $('#nav-popup-menu .dialog-info').removeClass('dialog-info-show');
      $('#nav-popup-menu').fadeOut('fast', function() {
        $('body').removeClass('hidden-scroll');
      });
      $(document).off('keyup');
    }

    $('#menu-btn').on('click', onClickShowMenuBtn);
    $('#nav-popup-menu .overlay-info').on('click', onClickHideMenuBtn);

    $('#nav-popup-menu .dialog-info-inner').on('click', function(event) {
      event.stopPropagation();
    });
    /* End menu popup */

    /* user popup */
    function onClickShowUserPopupBtn() {
      $('body').addClass(' hidden-scroll');
      $('#user-info-popup').fadeIn('fast', function() {
        $('#user-info-popup .dialog-info').addClass('dialog-info-show');
      });
      $(document).on('keyup', function(event) {
        if (event.key == "Escape") {
          onClickHideUserPopupBtn();
        }
      });
    }

    function onClickHideUserPopupBtn() {
      $('#user-info-popup .dialog-info').removeClass('dialog-info-show');
      $('#user-info-popup').fadeOut('fast', function() {
        $('body').removeClass('hidden-scroll');
      });
      $(document).off('keyup');
    }

    $('#user-info-btn').on('click', onClickShowUserPopupBtn);
    $('#user-info-popup .overlay-info').on('click', onClickHideUserPopupBtn);
    $('#user-info-popup .dialog-info-inner2').on('click', function(event) {
      event.stopPropagation();
    });
    /* End user popup */
  }());

  (function copyUserInfoBank() {
    let bankInfoTxt = '';
    let rowLength = $('#info-user-bank-tbl tr').length;

    $('#info-user-bank-tbl tr').each(function(i) {
      bankInfoTxt += $('td:eq(0)', this).text() + ' ' + $('td:eq(1)', this).text();

      if (i != rowLength - 1) {
        bankInfoTxt += '\n'
      }
    })

    $('#copy-content-user-bank').val(bankInfoTxt);
    initCopyToClipboard(document.getElementById('bank-user-info'));
  }());

  /* ---------------------------------------------------------
   * Generate Qrcode payment page
   * ----------------------------------------------------------*/ 
  (function generateQrcodePaymentPage() {
    const qrCodeElm = document.getElementById('qrcode-payment-page-url');
    if (qrCodeElm && qrCodeElm.innerHTML) return;

    const paymentPageUrlElm = document.getElementById('payment-page-url');
    if (!paymentPageUrlElm) return;

    const urlPaymentPage = paymentPageUrlElm.href;
    const QRCode = new QRCodeGenerator();
    QRCode.drawStandardQR('qrcode-payment-page-url', urlPaymentPage);
  })();

  /* ---------------------------------------------------------
   * Instrument Datadog Browser Monitoring
   * ----------------------------------------------------------*/ 
  (function initDatadogRum() {
    if (process.env.DD_RUM_ENV) { // stagingとproductionではDD_RUN_ENVを設定する
      datadogRum.init({
        applicationId: 'aa05bb61-c3b3-4637-971e-404995a7ebff',
        clientToken: 'pubb38b8dd3aa3a745d4d17d8dbcce2ea55',
        site: 'ap1.datadoghq.com',
        service: 'frnc-frontend',
        env: process.env.DD_RUM_ENV,
        // Specify a version number to identify the deployed version of your application in Datadog 
        version: process.env.DD_VERSION,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask',
        beforeSend: (event) => {
          if (event.type === 'action') {
            if (event.action.target.name) {
              // actionのターゲット名に機密情報が含まれる場合があるため、ターゲット名をマスクする
              const maskedTargetName = ''.padStart(event.action.target.name.length, '☓');
              event.action.target.name = maskedTargetName;
            }
          }
          return true;
        }
      });

      if (document.RUM_USR_ID) { // ログイン済の場合はRUMにユーザーIDを送信
        datadogRum.setUser({
          id: document.RUM_USR_ID
        });
      }
    }
  })();
}

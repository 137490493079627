var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import requiredCheck from '@lib/validator/Required';
import numberCheck from '@lib/validator/Number';
import { toNumberHalfWidth } from '@lib/Convert';
import applicationAmountCheck from '@lib/validator/ApplicationAmount';
import { Component, Vue, Watch } from 'vue-property-decorator';
var SameDayPayment = /** @class */ (function (_super) {
    __extends(SameDayPayment, _super);
    function SameDayPayment() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isConfirmedPersonInCharge = false;
        _this.isAgreePersonInCharge = false;
        _this.showContactOptionFlg = false;
        _this.showPersonInChargeConfirmFlg = false;
        return _this;
    }
    SameDayPayment.prototype.mounted = function () {
        this.isConfirmedPersonInCharge = this.invoiceInfo.isConfirmedPersonInCharge;
        this.isAgreePersonInCharge = this.invoiceInfo.isAgreePersonInCharge;
        this.showContactOptionFlg = this.invoiceInfo.isConfirmedPersonInCharge;
        this.showPersonInChargeConfirmFlg = this.invoiceInfo.isConfirmedPersonInCharge;
    };
    SameDayPayment.prototype.onConfirmedPersonChanged = function (val) {
        this.changeIsConfirmedPersonInCharge();
        if (!val) {
            this.isAgreePersonInCharge = false;
            this.changeIsAgreePersonInCharge();
        }
    };
    Object.defineProperty(SameDayPayment.prototype, "isDisplayableContactOption", {
        get: function () {
            return this.showContactOptionFlg;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SameDayPayment.prototype, "isDisplayablePersonInChargeConfirm", {
        get: function () {
            return this.showPersonInChargeConfirmFlg;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SameDayPayment.prototype, "classSort", {
        get: function () {
            return this.showContactOptionFlg ? 'billTable__sort__desc' : 'billTable__sort__asc';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SameDayPayment.prototype, "applicationAmountLabel", {
        get: function () {
            return this.$store.getters.getApplicationAmountLabel;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SameDayPayment.prototype, "applicationAmountNote", {
        get: function () {
            return this.$store.getters.getApplicationAmountNote;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SameDayPayment.prototype, "isEnabledSubmit", {
        get: function () {
            return this.validation.applicationAmount.valid && (!this.isConfirmedPersonInCharge || (this.isConfirmedPersonInCharge && this.isAgreePersonInCharge));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SameDayPayment.prototype, "validation", {
        get: function () {
            return this.$store.getters.getValidation;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SameDayPayment.prototype, "invoiceInfo", {
        get: function () {
            return this.$store.state.invoiceInfo;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SameDayPayment.prototype, "dealInfo", {
        get: function () {
            return this.$store.state.dealInfo;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SameDayPayment.prototype, "messageValidate", {
        get: function () {
            return this.isShowValidate() ? this.validation.applicationAmount.message : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SameDayPayment.prototype, "isInvoiceInfoChangedWhenDealing", {
        get: function () {
            return this.dealInfo.id > 0 && this.invoiceInfo.applicationAmount != this.dealInfo.incomingAmount;
        },
        enumerable: false,
        configurable: true
    });
    SameDayPayment.prototype.toggleOption = function () {
        this.showContactOptionFlg = !this.showContactOptionFlg;
    };
    SameDayPayment.prototype.isShowValidate = function () {
        return !this.validation.applicationAmount.valid;
    };
    SameDayPayment.prototype.changeIsAgreePersonInCharge = function () {
        this.showPersonInChargeConfirmFlg = this.isConfirmedPersonInCharge;
        this.$store.commit('setIsAgreePersonInCharge', this.isAgreePersonInCharge);
    };
    SameDayPayment.prototype.changeIsConfirmedPersonInCharge = function () {
        this.$store.commit('setIsConfirmedPersonInCharge', this.isConfirmedPersonInCharge);
    };
    SameDayPayment.prototype.nextStep = function (pathName) {
        if (!this.validation.applicationAmount.valid) {
            return;
        }
        this.$emit('nextPage', pathName);
    };
    SameDayPayment.prototype.onChangeApplicationAmount = function () {
        if (this.invoiceInfo.applicationAmount !== '') {
            this.invoiceInfo.applicationAmount = toNumberHalfWidth(this.invoiceInfo.applicationAmount);
        }
        this.checkValid('applicationAmount', '即日払い金額');
    };
    SameDayPayment.prototype.checkValid = function (field, title) {
        this.validation[field].valid = requiredCheck.check(this.invoiceInfo[field], title).valid;
        this.validation[field].message = requiredCheck.check(this.invoiceInfo[field], title).message;
        switch (field) {
            case 'applicationAmount':
                this.checkApplicationAmount();
                break;
            default:
                break;
        }
    };
    SameDayPayment.prototype.checkApplicationAmount = function () {
        if (this.invoiceInfo.amount === '') {
            if (this.invoiceInfo.applicationAmount !== '') {
                var result = numberCheck.check(this.invoiceInfo.applicationAmount);
                this.validation.applicationAmount.valid = result.valid;
                this.validation.applicationAmount.message = result.message;
                return;
            }
            this.validation.applicationAmount.valid = true;
            this.validation.applicationAmount.message = '';
            return;
        }
        if (this.invoiceInfo.applicationAmount !== '') {
            var result = numberCheck.check(this.invoiceInfo.applicationAmount);
            this.validation.applicationAmount.valid = result.valid;
            this.validation.applicationAmount.message = result.message;
            if (this.validation.applicationAmount.valid) {
                var withholdingTax = this.invoiceInfo.withholdingTax != '' ? this.invoiceInfo.withholdingTax : 0;
                var maxApplicationAmountRate = this.invoiceInfo.applyingNotifiedFactoring ?
                    parseFloat(this.invoiceInfo.notifiedFactoringOptions.maxApplicationAmountRate) || 1 : 1;
                result = applicationAmountCheck.check(parseInt(this.invoiceInfo.applicationAmount) || 0, parseInt(this.invoiceInfo.amount) || 0, parseInt(withholdingTax) || 0, maxApplicationAmountRate, this.invoiceInfo.notifiedFactoringOptions.maxApplicationAmountPerRate);
                this.validation.applicationAmount.valid = result.valid;
                this.validation.applicationAmount.message = result.message;
            }
        }
    };
    __decorate([
        Watch('isConfirmedPersonInCharge')
    ], SameDayPayment.prototype, "onConfirmedPersonChanged", null);
    SameDayPayment = __decorate([
        Component({})
    ], SameDayPayment);
    return SameDayPayment;
}(Vue));
export default SameDayPayment;

var check = function (applicationAmount, originalAmount, withholdingTax, maxApplicationAmountRate, maxApplicationAmountPerRate) {
    var maxApplicationAmount = parseInt((originalAmount * maxApplicationAmountRate).toFixed()) || 0;
    var valid = (applicationAmount > 0) && (applicationAmount <= originalAmount - withholdingTax) && (applicationAmount <= maxApplicationAmount);
    var message = valid ? '' : '請求書の額面と同じかそれ以下の申込金額を入力してください';
    if (applicationAmount > maxApplicationAmount) {
        message = "\u7533\u8ACB\u91D1\u984D\u306E\u30EC\u30FC\u30C8\u4E0A\u9650\u306F" + maxApplicationAmountPerRate + "%\u306B\u306A\u308A\u307E\u3059";
    }
    return {
        valid: valid,
        message: message
    };
};
export default {
    check: check
};

import { render, staticRenderFns } from "./item1col.vue?vue&type=template&id=73de48e7&"
import script from "./item1col.vue?vue&type=script&lang=js&"
export * from "./item1col.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "item1col.vue"
export default component.exports
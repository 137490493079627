import "@babel/polyfill"
import util from '../util';
import { halfToFullKana, fullToHalfKana, smallToBigKana } from '../../../lib/Convert';

export default {
  anshinAccountName: (state, getters) => {
    let accountName = '';
    const { bank } = state;
    if (getters.incorporated) {
      accountName = bank.corporate.accountName;
    } else {
      if (bank.individual.accountNameChoice) {
        accountName = bank.individual.otherAccountName;
      } else {
        accountName = bank.individual.accountName;
      }
    }
    accountName = fullToHalfKana(smallToBigKana(halfToFullKana(accountName)));
    return accountName;
  },

  defaultIndividualAccountName: (state, getters) => {
    const { individual } = state.userInfo;
    // 小文字には全角に存在して、半角に存在しないものがあるため
    return fullToHalfKana(smallToBigKana(halfToFullKana(individual.familyNameKana + individual.firstNameKana)));
  },

  // APIへ送信するデータを集約
  buildSendingParams: (state, getters) => {
    let params = getters.incorporated
      ? getters._buildSendingParamsOfCorporate
      : getters._buildSendingParamsOfIndividual;

    return params;
  },

  // APIへ送信するデータを集約（個人）
  _buildSendingParamsOfIndividual: (state, getters) => {
    let userInfo = state.userInfo.individual;
    let bank = state.bank.individual;

    let data = {
      //userinfo
      'incorporated':           false,  //法人として利用か否か
      'family_name':            userInfo.familyName, //姓
      'first_name':             userInfo.firstName, //名
      'family_name_kana':       userInfo.familyNameKana,  //セイ
      'first_name_kana':        userInfo.firstNameKana,  //メイ
      'pen_name':               userInfo.nickName,  //ペンネームなど通り名
      'business_name':          userInfo.storeName,  //屋号
      'business_name_kana':     userInfo.storeNameKana,//屋号カナ
      'birth_day':              userInfo.birthDateYear+'-'+userInfo.birthDateMonth+'-'+userInfo.birthDateDay,  //生年月日
      'master_gender_id':       userInfo.sex,  //性別 1:男,2:女3:その他
      'zipcode':                util.toHalfWidth(userInfo.postalCode), //郵便番号
      'master_prefecture_id':   userInfo.prefecturesId, //都道府県
      'city':                   userInfo.address, //市区町村
      'city_kana':              userInfo.addressKana, //市区町村（カナ）
      'address':                userInfo.areaDetail, //町域以下
      'address_kana':           userInfo.areaDetailKana, //町域以下（カナ）
      'building':               userInfo.apartmentName, //建物名
      'building_kana':          userInfo.apartmentNameKana, //建物名（カナ）
      'tel':                    userInfo.tel, //電話番号
      'industry_years':         util.toHalfWidth(userInfo.businessHistory),  //業歴
      'ave_invoice_sales':      util.toHalfWidth(userInfo.averageInvoiceAmount) * 10000,  //請求書1枚の平均請求額
      'ave_monthly_sales':      util.toHalfWidth(userInfo.monthlySalesAverage) * 10000, //月間売上平均
      'site_url':               userInfo.siteUrl , //サイトのURL
      //bank
      'bank_name':              bank.bankName, //銀行名
      'bank_code':              util.toHalfWidth(bank.swiftCode), //銀行コード
      'branch_name':            bank.branchName, //支店名
      'branch_code':            util.toHalfWidth(bank.branchCord), //支店コード
      'master_account_type_id': bank.accountType, //口座種別 1:普通,2:定期,3:当座 
      'account_no':             util.toHalfWidth(bank.accountNumber), //口座番号
      'account_name':           getters.mainBankAccountName, //口座名義
      'alternated_anshin_account_name': getters.alternatedAnshinAccountName, //メインバンクとは異なる名義で振込専用口座を開きたい
      'alternated_bank_account_name': bank.alternatedBankAccountName, //登録氏名と違う口座名を使いたい
      'anshin_account_name':    getters.anshinAccountName, //振込専用口座名義
    };
    // 業種
    data = Object.assign(data, state.industries);
    return data;
  },

  // APIへ送信するデータを集約（法人）
  _buildSendingParamsOfCorporate: (state, getters) => {
    let userInfo = state.userInfo.corporate;
    let bank = state.bank.corporate;

    let data = {
      //userinfo
      'incorporated':               true,  //法人として利用か否か
      'company_name':               userInfo.corporateName, //法人名
      'company_name_kana':          userInfo.corporateNameKana, //法人名カナ
      'company_no':                 util.toHalfWidth(userInfo.corporateNumber), //法人番号
      'represent_family_name':      userInfo.familyName, //代表者姓
      'represent_first_name':       userInfo.firstName, //代表者名
      'represent_family_name_kana': userInfo.familyNameKana, //代表者姓・カナ
      'represent_first_name_kana':  userInfo.firstNameKana, //代表者名・カナ
      'birth_day':                  userInfo.birthDateYear+'-'+userInfo.birthDateMonth+'-'+userInfo.birthDateDay,  //生年月日
      'master_gender_id':           userInfo.sex,  //性別 1:男,2:女3:その他
      'zipcode':                    util.toHalfWidth(userInfo.postalCode), //郵便番号
      'master_prefecture_id':       userInfo.prefecturesId, //都道府県
      'city':                       userInfo.address, //市区町村
      'city_kana':                  userInfo.addressKana, //市区町村（カナ）
      'address':                    userInfo.areaDetail, //町域以下
      'address_kana':               userInfo.areaDetailKana, //町域以下（カナ）
      'building':                   userInfo.apartmentName, //建物名
      'building_kana':              userInfo.apartmentNameKana, //建物名（カナ）
      'company_tel':                util.toHalfWidth(userInfo.tel), //法人電話番号
      'industry_years':             util.toHalfWidth(userInfo.businessHistory),  //業歴
      'established_year':           util.toHalfWidth(userInfo.establishedYear), //設立年
      'num_employee':               util.toHalfWidth(userInfo.employees), //従業員数
      'capital':                    util.toHalfWidth(userInfo.capital) * 10000, //資本金
      'sales_last_year':            util.toHalfWidth(userInfo.salesLastYear) * 10000, //前年度売上
      'in_debt':                    userInfo.borrowingPresence == '2'? true: false , //借入有無
      'in_debt_from':               userInfo.whichBank, //何行から
      'in_debt_amount':             util.toHalfWidth(userInfo.debtAmount) * 10000, //借入額合計
      'ave_invoice_sales':          util.toHalfWidth(userInfo.averageInvoiceAmount) * 10000, //請求書1枚の平均請求額
      'ave_monthly_sales':          util.toHalfWidth(userInfo.monthlySalesAverage) * 10000, //月間売上平均
      'site_url':                   userInfo.siteUrl , //サイトのURL
      //bank
      'bank_name':                  bank.bankName, //銀行名
      'bank_code':                  util.toHalfWidth(bank.swiftCode), //銀行コード
      'branch_name':                bank.branchName, //支店名
      'branch_code':                util.toHalfWidth(bank.branchCord), //支店コード
      'master_account_type_id':     bank.accountType, //口座種別 1:普通,2:定期,3:当座 
      'account_no':                 util.toHalfWidth(bank.accountNumber), //口座番号
      'account_name':               getters.mainBankAccountName, //口座名義
    };
    // 業種
    data = Object.assign(data, state.industries);

    return data;
  },

  // 「個人・法人」で「法人」を選択したか否か
  incorporated: (state, getters) => {
    return state.userChoice == 2;
  },

  // 「メインバンクとは異なる名義で振込専用口座を開きたい」にチェックがされたか否か
  alternatedAnshinAccountName: (state, getters) => {
    // 法人の場合はありえない
    if (getters.incorporated) {
      return false;
    }
    return state.bank.individual.accountNameChoice == 1;
  },

  // メインバンクの名義名
  mainBankAccountName: (state, getters) => {
    if (getters.incorporated) {
      return state.bank.corporate.accountName;
    }
    return state.bank.individual.accountName;
  },

  // 業種マスタを返す
  masterIndustries: (state, getters) => {
    return state.master_industries;
  },

  // メインバンク一覧を返す
  mainBanks: (state, getters) => state.mainBanks,

  // いずれかの業種がチェックされているか否か
  checkedAnyIndustries: (state, getters) => {
    for (let key in state.industries) {
      if (state.industries[key]) {
        return true;
      }
    }
    return false;
  },

  // 選択された業種のリストを返す
  listCheckedIndustries: (state, getters) => {
    let result = [];
    for (let key in state.industries) {
      if (state.industries[key]) {
        let r = state.master_industries.filter((item, index) => {
          return item.name === key;
        });
        if (r.length > 0) {
          result.push(r[0].label);
        }
      }
    }
    return result;
  },

  // storeのvalidation領域のオブジェクトを返す
  getBankCertValidation: (state, getters) => (sectionType, fieldName) => {
    return state['validation']['bank'][sectionType][fieldName];
  },

  // storeの保存領域のオブジェクトを返す
  getStoreObj: (state, getters) => (pane, sectionType, fieldName, certSide) => {
    if (!pane && !sectionType && !fieldName) {
      return state;
    }
    if (!sectionType && !fieldName) {
      return state[pane];
    }
    if (!fieldName) {
      return state[pane][sectionType];
    }
    if (!certSide) {
      return state[pane][sectionType][fieldName];
    }
    return state[pane][sectionType][fieldName][certSide];
  },

  isMobile: () => {
    var ua = navigator.userAgent;
    if (
      (
        ua.indexOf('iPhone') > 0
        || ua.indexOf('iPod') > 0
        || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0
      )
      || (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0)
    ) {
        return true;
    }
    return false;
  },

  getSaveTempFileUploadNeeded: (state) => {
    return state.saveTempFileUploadNeeded;
  },

  corporateNameKana: (state) => state.userInfo.corporate.corporateNameKana
};

/* eslint-disable no-param-reassign */
import moment from 'moment';
import AfterOneMonthDate from '../../../lib/AfterOneMonthDate';
import dateCheck from '../../../lib/validator/Date';

const makeFileExtensions = (allowedFileTypes) => {
  if (allowedFileTypes) {
    const allowedTypes = allowedFileTypes.split(',');
    if (allowedTypes.includes('jpg')) {
      allowedTypes.splice(allowedTypes.indexOf('jpg') + 1, 0, 'jpeg');
    }
    const result = allowedTypes.join(',');
    return result;
  }
  return 'jpg,jpeg,png,gif,pdf';
};

const makeFileUploadNote = (fileExtension) => {
  const displayFileExtension = fileExtension.replace(/,/g, ', .');
  return `振込口座の記載された請求書ファイルをアップロードしてください。（ファイル形式：.${displayFileExtension}、ファイルサイズ：10MB 以下）`
}

export default {
  updatePageTitle(state, data) {
    // ページタイトルセット
    state.page.title = data.title;
    state.page.complete = data.complete;
  },
  setBillingDate(state, billingDate) {
    state.invoiceInfo.billingDate = billingDate;
  },
  setDisableBillingDate(state, value) {
    state.invoiceInfo.disabledBillingDate = value;
  },
  setPaymentDeadline(state, date) {
    state.invoiceInfo.date = date;
  },
  updatePaymentDeadline(state, date) {
    // 請求日を入力したら振込期限を自動的にセット
    if (!dateCheck.check(date).valid) {
      // 不正な日付のときは処理しない
      return;
    }
    let afterOneMonthDate = AfterOneMonthDate(date, 'YYYY/MM/DD')
    state.invoiceInfo.date = afterOneMonthDate;
  },
  updateDefaultApplicationAmount(state, applicationAmount) {
    state.invoiceInfo.applicationAmount = applicationAmount;
  },
  updateProcessingDetectionStatus(state, status) {
    state.isProcessingDetection = status;
  },
  updateAutoDetectionMode(state, data) {
    state.autoDetectionMode = data;
  },
  updatePayProcessName(state, data) {
    // 即日払い申込モーダル表示
    if (data === 'sameDayPayment') {
      window.dataLayer.push({ event: 'content-view', contentViewPath: '/invoices/add/application-amount' });
    // 即日払いの買取金額確認モーダル表示
    } else if (data === 'applyQuickPay') {
      window.dataLayer.push({ event: 'content-view', contentViewPath: '/invoices/add/confirmation' });
    // 即日払い申込の完了モーダル表示
    } else if (data === 'complete') {
      window.dataLayer.push({ event: 'content-view', contentViewPath: '/invoices/add/complete' });
    }
    state.payProcessName = data;
  },

  updateClientProcessName(state, data) {
    state.clientProcessName = data;
  },
  setValidation(state, data) {
    state.validation = data;
  },
  updateUIFromDetectInvoice(state, data) {
    if (state.clientInfo.name === '') {
      state.clientInfo.id = data.client_info.id;
      state.clientInfo.number = data.client_info.company_no;
      state.clientInfo.name = data.client_info.name;
      state.clientInfo.representative = data.client_info.representative;
      state.clientInfo.department = data.client_info.department;
      state.clientInfo.person = data.client_info.person_in_charge;
      state.clientInfo.tel = data.client_info.tel;
      state.clientInfo.email = data.client_info.email;
      state.clientInfo.address = data.client_info.address;
      state.clientInfo.note = data.client_info.note;
      state.clientInfo.notifiedFactorings = data.client_info.notifiedFactorings;
      state.detectedClientNumber = data.client_info.company_no;
    }

    if (state.invoiceInfo.amount === '' && parseInt(data.detected_total_amount) > 0) {
      state.invoiceInfo.amount = data.detected_total_amount;
    }

    if (state.invoiceInfo.withholdingTax === '' && parseInt(data.detected_withholding_amount) >= 0) {
      state.invoiceInfo.withholdingTax = data.detected_withholding_amount;
    }
    if (state.invoiceInfo.applicationAmount === '' && parseInt(data.detected_application_amount) > 0 &&
      parseInt(data.detected_application_amount) <= parseInt(state.invoiceInfo.amount)) {
      state.invoiceInfo.applicationAmount = data.detected_application_amount;
    }

    const { isAutoCalculateDueDate, isDefaultIssuedDateToToday } = state.invoiceInfo.notifiedFactoringOptions;
    if (state.invoiceInfo.billingDate === '' && !isDefaultIssuedDateToToday) {
      state.invoiceInfo.billingDate = data.detected_invoice_issued_date;
    }
    if (state.invoiceInfo.date === '' && !isAutoCalculateDueDate) {
      state.invoiceInfo.date = data.detected_transfer_deadline;
    }

    state.invoiceDetectedData.userId = data.user_id;
    state.invoiceDetectedData.invoiceId = data.invoice_id;
    state.invoiceDetectedData.ocrRawText = data.ocr_raw_text;
    state.invoiceDetectedData.invoiceFilePath = data.invoice_file_path;
    state.invoiceDetectedData.detectedCompanyName = data.detected_company_name;
    state.invoiceDetectedData.detectedPersonIncharge = data.detected_person_incharge;
    state.invoiceDetectedData.detectedTotalAmount = data.detected_total_amount;
    state.invoiceDetectedData.detectedWithholdingAmount = data.detected_withholding_amount;
    state.invoiceDetectedData.detectedApplicationAmount = data.detected_application_amount;
    state.invoiceDetectedData.detectedInvoiceIssuedDate = data.detected_invoice_issued_date;
    state.invoiceDetectedData.detectedTransferDeadline = data.detected_transfer_deadline;
    state.invoiceDetectedData.detectionStartedAt = data.detection_started_at;
    state.invoiceDetectedData.detectionEndedAt = data.detection_ended_at;
  },
  clearInvoiceDetectedData(state) {
    state.invoiceDetectedData.userId = '';
    state.invoiceDetectedData.invoiceId = '';
    state.invoiceDetectedData.ocrRawText = '';
    state.invoiceDetectedData.invoiceFilePath = '';
    state.invoiceDetectedData.detectedCompanyName = '';
    state.invoiceDetectedData.detectedPersonIncharge = '';
    state.invoiceDetectedData.detectedTotalAmount = '';
    state.invoiceDetectedData.detectedWithholdingAmount = '';
    state.invoiceDetectedData.detectedApplicationAmount = '';
    state.invoiceDetectedData.detectedInvoiceIssuedDate = '';
    state.invoiceDetectedData.detectedTransferDeadline = '';
    state.invoiceDetectedData.detectionStartedAt = '';
    state.invoiceDetectedData.detectionEndedAt = '';
  },
  // 選択した取引先情報をセット
  selectClient(state, data) {
    state.clientInfo.id = data.id;
    state.clientInfo.number = data.number;
    state.clientInfo.name = data.name;
    state.clientInfo.representative = data.representative;
    state.clientInfo.department = data.department;
    state.clientInfo.person = data.person || state.clientInfo.person;
    state.clientInfo.tel = data.tel;
    state.clientInfo.email = data.email;
    state.clientInfo.address = data.address;
    state.clientInfo.note = data.note;
  },
  // 取引先情報クリア
  clearClient(state) {
    state.clientInfo.number = '';
    state.clientInfo.name = '';
    state.clientInfo.representative = '';
    state.clientInfo.department = '';
    state.clientInfo.person = '';
    state.clientInfo.tel = '';
    state.clientInfo.email = '';
    state.clientInfo.address = '';
    state.clientInfo.note = '';
  },
  // 選択、入力した取引先情報を請求書にセット
  updateInvoiceClient(state) {
    state.invoiceInfo.client = state.clientInfo.name;
    state.invoiceInfo.clientNumber = state.clientInfo.number;
    state.invoiceInfo.personInCharge = state.clientInfo.person;
    state.invoiceInfo.isSpecified = true;
  },
  // 入力した請求書情報を一覧に登録して買取合計金額計算
  addSelectInvoice(state) {
    state.invoiceList = [];
    state.invoiceInfo.status = 'paid';
    state.invoiceList.push(state.invoiceInfo);
    //state.termsConditions.negotiatedAmount = 0
    //state.invoiceList.forEach(function(invoiceObj) {
    //  state.termsConditions.amountBilledTotal += +invoiceObj.amountBilled;
    //  state.termsConditions.withholdingTaxTotal += +invoiceObj.withholdingTax;
    //});
  },
  // 請求書一覧から選択してapiから取得した請求書情報をセット
  addSelectFromInvoiceList(state, data) {
    state.invoiceList = [];
    data.forEach(function (v) {
      v.isSelected = false;
      state.invoiceList.push(v);
    });
  },
  // 選択した請求書にフラグ立てる
  selectInvoice(state, id) {
    state.invoiceList.forEach(function (invoiceObj) {
      if (invoiceObj.id === id) {
        invoiceObj.isSelected = invoiceObj.isSelected ? false : true;
      }
    });
  },
  // 取引先特定が必要な請求書を決める
  getTargetUnSpecifyInvoice: (state, targetIndex) => {
    let invoices = state.invoiceList.filter(function (element, index, array) {
      return !element.isSpecified
    });
    state.unSpecifyInvoiceList = invoices;

    state.unSpecifyInvoiceList.some(function (v, index) {
      if (targetIndex == index) {
        state.targetUnSpecifyInvoice = v;
        state.targetUnSpecifyInvoice.no = index + 1;
        return true
      }
    });
  },
  // 請求書新規登録か
  updateModeAddInvoice: (state, status) => {
    state.status.modeAddInvoice = status;
  },
  // 取引先選択方法表示済みか
  howToChooseClientsinitialized: (state, status) => {
    state.status.howToChooseClientsinitialized = status;
  },
  // API通信状態をセットする
  updateProcessingApiRequest: (state, status) => {
    state.status.processingApiRequest = status;
  },
  // API通信状態をセットする
  updateProcessingScreenMove: (state, status) => {
    state.status.processingScreenMove = status;
  },
  // 初期化を完了とする
  finishInitialization: (state) => {
    state.initialized = true;
  },
  // 一時的に特定済みの取引先を退避
  backUpClientInfo: (state) => {
    state.clientInfoBackUp = Object.assign({}, state.clientInfo);
  },
  // 一時的に退避してある特定済みの取引先を戻す
  restoreClientInfo: (state) => {
    state.clientInfo = Object.assign({}, state.clientInfoBackUp);
    state.invoiceInfo.client = state.clientInfo.name;
    state.invoiceInfo.personInCharge = state.clientInfo.person;
    state.invoiceInfo.clientNumber = state.clientInfo.number;
  },
  // Clear client info for creating new client
  clearClientInfo: (state) => {
    state.clientInfo = Object.assign({}, state.emptyClientInfo);
    state.invoiceInfo.client = '';
    state.invoiceInfo.personInCharge = '';
    state.invoiceInfo.clientNumber = '';
    state.isNewClientAdded = false;
  },
  load(state, data) {
    const f = new Date(data.company_number_api_maintenance_start),
      t = new Date(data.company_number_api_maintenance_end);
    state.under_maintenance_company_number_api = moment().isBetween(f, t);
    state.clients = data.clients ? data.clients : [];
    // Sort by id
    state.clients.sort((cl1, cl2) => {
      return cl1.id - cl2.id;
    });
    state.emptyClientInfo = Object.assign({}, state.clientInfo);
    state.incorporated = data.incorporated;
    state.userName = data.user_name;
    state.userEmail = data.user_email;
    state.userTel = data.user_tel;
    state.isBlockStatusPublic = data.is_block_status_public;
    // OAuth API経由で発行された請求書登録リンク内の
    // トークンをデコードしたJSONがdata.deserialized_estimation_token
    //
    // このJSONからセットされたinputはdisabledにして編集できないようにする
    if (data.deserialized_estimation_token) {
      const det = data.deserialized_estimation_token;
      state.invoiceInfo.amount = det.tax_included_amount.toString();
      state.invoiceInfo.withholdingTax = det.withholding_tax;
      state.invoiceInfo.billingDate = moment(det.issued_at).format('YYYY/MM/DD');
      state.invoiceInfo.date = moment(det.due_at).format('YYYY/MM/DD');
      state.apiConsumer.clientIdOfKong = det.client_id_of_kong;
      state.apiConsumer.invoiceId = det.invoice_id;
      state.apiConsumer.redirectUrl = det.redirect_url;
      state.invoiceInfo.isSeleted = true;
      state.invoiceInfo.disabledAmount = true;
      state.invoiceInfo.applicationAmountRequired = false;
      state.invoiceInfo.disabledWithholdingTax = true;
      state.invoiceInfo.disabledBillingDate = true;
      state.invoiceInfo.disabledDate = true;
      state.invoiceInfo.modifiable = det.modifiable;
      state.invoiceInfo.isConfirmedPersonInCharge = !!det.isConfirmedPersonInCharge;
      if (det.client) {
        state.clientInfo.disabledClientEditing = true;
        state.invoiceInfo.clientNumber = det.company_no;
        state.clientInfo.number = det.company_no;
        state.invoiceInfo.client = det.client.name;
        state.clientInfo.name = det.client.name;
        state.invoiceInfo.personInCharge = det.client.person_in_charge;
        state.clientInfo.person = det.client.person_in_charge;
        state.clientInfo.representative = det.client.representative;
        state.clientInfo.department = det.client.department;
        state.clientInfo.tel = det.client.tel;
        state.clientInfo.email = det.client.email;
        state.clientInfo.address = det.client.address;
        state.client = det.client.name;
        state.invoiceInfo.isSpecified = true;
        state.isNewClientAdded = true;
        if (det.client.tomodachi_code) {
          state.invoiceInfo.codeForNotifiedFactoring = det.client.tomodachi_code;
          state.invoiceInfo.identificationID = det.client.tomodachi_invoice_id;
        }
      }
      // トークンから渡されたデータをアップロードする請求書ファイルの情報で置き換えられないように設定
      state.autoDetectionMode = false;
    }
  },
  setClientSearchTerm(state, data) {
    state.clientSearchTerm = data;
  },
  clearSearchTerm(state) {
    state.clientSearchTerm = '';
  },
  resetNotifiedFactoring(state) {
    state.invoiceInfo.identificationID = "";
    state.invoiceInfo.notifiedFactoringOptions.identificationIDLabel = "";
    state.invoiceInfo.applyingNotifiedFactoring = false;
    state.invoiceInfo.codeForNotifiedFactoring = '';
    state.invoiceInfo.notifiedFactoringOptions.identificationIDRequired = false;
    state.invoiceInfo.notifiedFactoringOptions.fileUploadRequired = true;
  },
  updateClientForInvoice(state, data) {
    //Set state.clientInfo to the newest clientInfo
    state.clientInfo.id = data.isNew ? 0 : data.id;
    state.clientInfo.address = data.address;
    state.clientInfo.number = data.company_no || data.number;
    state.clientInfo.department = data.department;
    state.clientInfo.email = data.email;
    state.clientInfo.name = data.name;
    state.clientInfo.person = data.person_in_charge;
    state.clientInfo.representative = data.representative;
    state.clientInfo.tel = data.tel;
    state.clientInfo.notifiedFactorings = data.notifiedFactorings;
    state.invoiceInfo.canApplyNotifiedFactoring = data.notifiedFactorings && data.notifiedFactorings.length > 0 ? true : false;
  },
  updateClientNotifiedFactorings(state, notifiedFactorings) {
    state.clientInfo.notifiedFactorings = notifiedFactorings;
  },
  updateNotifiedFactorings(state, data) {
    state.clientInfo.notifiedFactorings = data;
    state.invoiceInfo.applyingNotifiedFactoring = true;
    state.invoiceInfo.canApplyNotifiedFactoring = true;
  },
  // Push new client to clients list
  createNewClient(state, data) {
    // Set temporary client info data
    state.clientInfo.id = state.tempClientId;
    state.clientInfo.notifiedFactorings = data.notifiedFactorings || [];
    state.invoiceInfo.canApplyNotifiedFactoring = data.notifiedFactorings.length > 0;
    state.applyingNfCode = '';
    state.identificationID = '';

    // Push new client to clients list
    // tempId for new client is used for sorting only
    let clientData = {
      id: state.tempClientId,
      address: data.address,
      company_no: String(data.number),
      department: data.department,
      email: data.email,
      name: data.name,
      person_in_charge: data.person,
      representative: data.representative,
      tel: data.tel,
      notifiedFactorings: data.notifiedFactorings,
      isNew: true,
    };
    state.clients.unshift(clientData);

    // Decrease the temp ID
    state.tempClientId--;
    state.isNewClientAdded = true;
  },
  newPersonInCharge: (state) => {
    state.clientInfo.tel = '';
    state.clientInfo.email = '';
    state.clientInfo.person = '';
    state.isNewClientAdded = false;
  },
  setClientInfo: (state, data) => {
    state.clientInfo = data;
  },
  // Set 3rd service invoice data to default invoice data
  initServiceInvoiceData: (state, data) => {
    // In case data alread initiated, do nothing
    if (state.serviceInvoice) {
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    state.serviceInvoiceParam = urlParams.get('invoice_data');
    state.serviceInvoice = data;
    if (state.serviceInvoice['amount']) {
      state.serviceInvoice['amount'] = Math.floor(state.serviceInvoice['amount']);
      state.invoiceInfo.amount = state.serviceInvoice['amount'].toString();
    }

    if (state.serviceInvoice['tax_withholding']) {
      state.invoiceInfo.withholdingTax = state.serviceInvoice['tax_withholding'];
    }

    let applicationAmount = '';
    if (state.invoiceInfo.amount && state.invoiceInfo.withholdingTax) {
      applicationAmount = state.invoiceInfo.amount - state.invoiceInfo.withholdingTax;
    } else if (state.invoiceInfo.amount) {
      applicationAmount = state.invoiceInfo.amount;
    }
    state.invoiceInfo.applicationAmount = applicationAmount.toString();

    if (state.serviceInvoice['issued_date']) {
      state.invoiceInfo.billingDate = moment(state.serviceInvoice['issued_date']).format('YYYY/MM/DD');
    }

    if (state.serviceInvoice['due_date']) {
      state.invoiceInfo.date = moment(state.serviceInvoice['due_date']).format('YYYY/MM/DD');
    }

    if (state.serviceInvoice['memo']) {
      state.invoiceInfo.memo = state.serviceInvoice['memo'];
    }

    if (!state.serviceInvoice['is_freenance_bank_account']) {
      state.invoiceInfo.isUsedFrncAccount = false;
    }
  },

  setInvoiceInfoID: (state, id) => {
    state.invoiceInfo.id = id;
  },
  updateInitInvoiceInfo: (state, invoiceInfo) => {
    state.initInvoiceInfo.applicationAmount = invoiceInfo.applicationAmount;
    state.initInvoiceInfo.isConfirmedPersonInCharge = invoiceInfo.isConfirmedPersonInCharge;
  },
  resetLinkingServiceID: (state) => {
    if (state.serviceInvoice) {
      state.serviceInvoice.linking_service_id = 0;
    }
  },

  loadIsShowApplyQuickPay: (state, value) => {
    state.isShowApplyQuickPay = value;
  },
  loadEditInvoiceInfo: (state, data) => {
    state.isInvoiceEdit = true;
    state.dealInfo.id = data.deal.id;
    state.dealInfo.offerPrice = data.deal.offer_price;
    state.dealInfo.clientName = data.deal.client_name;
    state.dealInfo.commissionPrice = data.deal.commission_price;
    state.dealInfo.corporationJudgeDone = data.deal.corporation_judge_done;
    state.dealInfo.createdAt = data.deal.created_at;
    state.dealInfo.createdOutcomingDate = data.deal.created_outcoming_date;
    state.dealInfo.dueDate = data.deal.due_date;
    state.dealInfo.incomingAmount = data.deal.incoming_amount;
    state.dealInfo.originalAmount = data.deal.original_amount;
    state.dealInfo.tax = data.deal.tax;
    state.dealInfo.ipAddress = data.deal.ip_address;
    state.dealInfo.judgeDone = data.deal.judge_done;
    state.dealInfo.masterDealStatus = data.deal.master_deal_status;
    state.dealInfo.sendingDate = data.deal.sending_date;
    state.dealInfo.notes = data.deal.notes;
    state.dealInfo.appliedAt = data.deal.applied_at;
    state.initInvoiceInfo.applicationAmount = String(data.application_amount);
    state.initInvoiceInfo.isConfirmedPersonInCharge = data.is_confirmed_person_in_charge;
    state.invoiceInfo.id = data.id;
    state.invoiceInfo.modifiable = data.modifiable;
    state.invoiceInfo.masterInvoiceStatusId = data.master_invoice_status_id;
    state.invoiceInfo.masterDealStatusId = data.master_deal_status_id;
    state.invoiceInfo.client = data.client.name;
    state.invoiceInfo.clientNumber = data.client.company_no;
    state.invoiceInfo.personInCharge = data.client.person_in_charge;
    state.invoiceInfo.amount = String(data.amount);
    state.invoiceInfo.withholdingTax = data.tax_withholding;
    state.invoiceInfo.applicationAmount = String(data.application_amount);
    state.invoiceInfo.billingDate = moment(data.issued_date).format('YYYY/MM/DD');
    state.invoiceInfo.date = moment(data.due_date).format('YYYY/MM/DD');
    state.invoiceInfo.isSeleted = true;
    state.invoiceInfo.isSpecified = true;
    state.invoiceInfo.linkingServiceName = data.linking_service_name;
    state.invoiceInfo.linkingServiceId = data.linking_service_id;
    state.invoiceInfo.memo = data.memo;
    state.invoiceInfo.isConfirmedPersonInCharge = data.is_confirmed_person_in_charge;
    state.invoiceInfo.isAgreePersonInCharge = data.is_confirmed_person_in_charge;
    state.invoiceInfo.isUsedFrncAccount = data.is_used_frnc_account;
    state.invoiceInfo.provingMailAddress = data.proving_mail_address;

    state.clientInfo.address = data.client.address;
    state.clientInfo.department = data.client.department;
    state.clientInfo.email = data.client.email;
    state.clientInfo.id = data.client.id;
    state.clientInfo.name = data.client.name;
    state.clientInfo.number = data.client.company_no;
    state.clientInfo.person = data.client.person_in_charge;
    state.clientInfo.representative = data.client.representative;
    state.clientInfo.tel = data.client.tel;

    // check if invoice has notifiedFactorings
    if (data.notified_factoring.ID !== 0) {
      state.clients.forEach((client) => {
        if (client.id === data.client.id) {
          state.clientInfo.notifiedFactorings = client.notifiedFactorings;
        }
      });
      state.nfCodeAppliedClientId = data.client.id;
      state.invoiceInfo.canApplyNotifiedFactoring = true;
      state.invoiceInfo.applyingNotifiedFactoring = true;
      state.applyingNfCode = data.notified_factoring.Code;
      state.invoiceInfo.codeForNotifiedFactoring = data.notified_factoring.Code;
      state.invoiceInfo.identificationID = data.identification_id || '';
      state.invoiceInfo.notifiedFactoringOptions.identificationIDRequired = data.notified_factoring.IdentificationIDRequired;
      state.invoiceInfo.notifiedFactoringOptions.fileUploadRequired = data.notified_factoring.FileUploadRequired;
      state.invoiceInfo.notifiedFactoringOptions.identificationIDLabel = data.notified_factoring.IdentificationIDLabel;
      state.invoiceInfo.notifiedFactoringOptions.companyNameLabel = data.notified_factoring.CompanyNameLabel || state.defaultDisplayOptions.companyNameLabel;
      state.invoiceInfo.notifiedFactoringOptions.personInChargeLabel = data.notified_factoring.PersonInChargeLabel || state.defaultDisplayOptions.personInChargeLabel;
      state.invoiceInfo.notifiedFactoringOptions.personInChargeRequired = data.notified_factoring.PersonInChargeRequired;
      state.invoiceInfo.notifiedFactoringOptions.issuedDateLabel = data.notified_factoring.IssuedDateLabel || state.defaultDisplayOptions.issuedDateLabel;
      state.invoiceInfo.notifiedFactoringOptions.dueDateLabel = data.notified_factoring.DueDateLabel || state.defaultDisplayOptions.dueDateLabel;
      state.invoiceInfo.notifiedFactoringOptions.amountLabel = data.notified_factoring.AmountLabel || state.defaultDisplayOptions.amountLabel;
      state.invoiceInfo.notifiedFactoringOptions.amountNote = data.notified_factoring.IsDisplayableAmountNote ? data.notified_factoring.AmountNote || state.defaultDisplayOptions.amountNote : '';
      state.invoiceInfo.notifiedFactoringOptions.taxNote = data.notified_factoring.IsDisplayableTaxNote ? data.notified_factoring.TaxNote || state.defaultDisplayOptions.taxNote : '';
      state.invoiceInfo.notifiedFactoringOptions.applicationAmountNote = data.notified_factoring.IsDisplayableApplicationAmountNote ? data.notified_factoring.ApplicationAmountNote || state.defaultDisplayOptions.applicationAmountNote : '';
      state.invoiceInfo.notifiedFactoringOptions.memoNote = data.notified_factoring.IsDisplayableMemoNote ? data.notified_factoring.MemoNote || state.defaultDisplayOptions.memoNote : '';
      state.invoiceInfo.notifiedFactoringOptions.taxLabel = data.notified_factoring.TaxLabel || state.defaultDisplayOptions.taxLabel;
      state.invoiceInfo.notifiedFactoringOptions.applicationAmountLabel = data.notified_factoring.ApplicationAmountLabel || state.defaultDisplayOptions.applicationAmountLabel;
      state.invoiceInfo.notifiedFactoringOptions.taxRequired = data.notified_factoring.TaxRequired;
      state.invoiceInfo.notifiedFactoringOptions.memoLabel = data.notified_factoring.MemoLabel || state.defaultDisplayOptions.memoLabel;
      state.invoiceInfo.notifiedFactoringOptions.memoRequired = data.notified_factoring.MemoRequired;
      state.invoiceInfo.notifiedFactoringOptions.fileUploadLabel = data.notified_factoring.FileUploadLabel || state.defaultDisplayOptions.fileUploadLabel;
      state.invoiceInfo.notifiedFactoringOptions.noteUser = data.notified_factoring.NoteUser;
      state.invoiceInfo.notifiedFactoringOptions.allowedUploadFileTypes = data.notified_factoring.AllowedUploadFileTypes || '';
      state.invoiceInfo.notifiedFactoringOptions.isChoosableFrncAccount = data.notified_factoring.IsChoosableFrncAccount;

      state.invoiceInfo.fileExtensionValidation = makeFileExtensions(data.notified_factoring.AllowedUploadFileTypes);
      state.invoiceInfo.notifiedFactoringOptions.fileUploadNote = data.notified_factoring.FileUploadNote || makeFileUploadNote(state.invoiceInfo.fileExtensionValidation);
      state.invoiceInfo.notifiedFactoringOptions.maxApplicationAmountRate = data.notified_factoring.MaxApplicationAmountRate;
      state.invoiceInfo.notifiedFactoringOptions.maxApplicationAmountPerRate = data.notified_factoring.MaxApplicationAmountPerRate;
      state.invoiceInfo.notifiedFactoringOptions.isAutoCalculateDueDate = data.notified_factoring.IsAutoCalculateDueDate;
      state.invoiceInfo.notifiedFactoringOptions.isDefaultIssuedDateToToday = data.notified_factoring.IsDefaultIssuedDateToToday;
      state.invoiceInfo.notifiedFactoringOptions.isDisabledIssuedDate = data.notified_factoring.IsDisabledIssuedDate;
      state.invoiceInfo.notifiedFactoringOptions.dueDateMonthRule = data.notified_factoring.DueDateMonthRule;
      state.invoiceInfo.notifiedFactoringOptions.dueDateDayRule = data.notified_factoring.DueDateDayRule;
    }
  },

  setNfCode(state, data) {
    state.applyingNfCode = data;
  },

  setApplyingNotifiedFactoring(state, data) {
    state.invoiceInfo.applyingNotifiedFactoring = data;
  },

  setNotifiedFactoringOptions(state, data) {
    state.invoiceInfo.notifiedFactoringOptions.identificationIDRequired = data.identification_id_required;
    state.invoiceInfo.notifiedFactoringOptions.fileUploadRequired = data.file_upload_required;
    state.invoiceInfo.notifiedFactoringOptions.identificationIDLabel = data.identification_id_label;
    state.invoiceInfo.notifiedFactoringOptions.companyNameLabel = data.company_name_label || state.defaultDisplayOptions.companyNameLabel;
    state.invoiceInfo.notifiedFactoringOptions.personInChargeLabel = data.person_in_charge_label || state.defaultDisplayOptions.personInChargeLabel;
    state.invoiceInfo.notifiedFactoringOptions.personInChargeRequired = data.person_in_charge_required;
    state.invoiceInfo.notifiedFactoringOptions.issuedDateLabel = data.issued_date_label || state.defaultDisplayOptions.issuedDateLabel;
    state.invoiceInfo.notifiedFactoringOptions.dueDateLabel = data.due_date_label || state.defaultDisplayOptions.dueDateLabel;
    state.invoiceInfo.notifiedFactoringOptions.amountLabel = data.amount_label || state.defaultDisplayOptions.amountLabel;
    state.invoiceInfo.notifiedFactoringOptions.amountNote = data.is_displayable_amount_note ? data.amount_note || state.defaultDisplayOptions.amountNote : '';
    state.invoiceInfo.notifiedFactoringOptions.taxNote = data.is_displayable_tax_note ? data.tax_note || state.defaultDisplayOptions.taxNote : '';
    state.invoiceInfo.notifiedFactoringOptions.applicationAmountNote = data.is_displayable_application_amount_note ? data.application_amount_note || state.defaultDisplayOptions.applicationAmountNote : '';
    state.invoiceInfo.notifiedFactoringOptions.memoNote = data.is_displayable_memo_note ? data.memo_note || state.defaultDisplayOptions.memoNote : '';
    state.invoiceInfo.notifiedFactoringOptions.taxLabel = data.tax_label || state.defaultDisplayOptions.taxLabel;
    state.invoiceInfo.notifiedFactoringOptions.applicationAmountLabel = data.application_amount_label || state.defaultDisplayOptions.applicationAmountLabel;
    state.invoiceInfo.notifiedFactoringOptions.taxRequired = data.tax_required;
    state.invoiceInfo.notifiedFactoringOptions.memoLabel = data.memo_label || state.defaultDisplayOptions.memoLabel;
    state.invoiceInfo.notifiedFactoringOptions.memoRequired = data.memo_required;
    state.invoiceInfo.notifiedFactoringOptions.isChoosableFrncAccount = data.is_choosable_frnc_account;
    state.invoiceInfo.notifiedFactoringOptions.fileUploadLabel = data.file_upload_label || state.defaultDisplayOptions.fileUploadLabel;
    state.invoiceInfo.notifiedFactoringOptions.noteUser = data.note_user;
    state.invoiceInfo.notifiedFactoringOptions.allowedUploadFileTypes = data.allowed_upload_file_types || '';

    state.invoiceInfo.fileExtensionValidation = makeFileExtensions(data.allowed_upload_file_types);
    state.invoiceInfo.notifiedFactoringOptions.fileUploadNote = data.file_upload_note || makeFileUploadNote(state.invoiceInfo.fileExtensionValidation);
    state.invoiceInfo.notifiedFactoringOptions.maxApplicationAmountRate = data.max_application_amount_rate;
    state.invoiceInfo.notifiedFactoringOptions.maxApplicationAmountPerRate = data.max_application_amount_per_rate;
    state.invoiceInfo.notifiedFactoringOptions.isAutoCalculateDueDate = data.is_auto_calculate_due_date;
    state.invoiceInfo.notifiedFactoringOptions.isDefaultIssuedDateToToday = data.is_default_issued_date_to_today;
    state.invoiceInfo.notifiedFactoringOptions.isDisabledIssuedDate = data.is_disabled_issued_date;
    state.invoiceInfo.notifiedFactoringOptions.dueDateMonthRule = data.due_date_month_rule;
    state.invoiceInfo.notifiedFactoringOptions.dueDateDayRule = data.due_date_day_rule;
  },

  setNfApplyedClientId(state, clientId) {
    state.nfCodeAppliedClientId = clientId;
  },

  addFileUploader(state, data) {
    state.fileUploader.uploadedFile.push(data);
  },

  addFileUploaderFromTopPage(state, data) {
    state.fileUploader.uploadedFile.push(data);
    state.fileUploader.newUploadFile.push(data);
  },

  clearFileUploader(state) {
    state.fileUploader.uploadedFile = [];
    state.fileUploader.newUploadFile = [];
  },

  resetTax(state) {
    state.invoiceInfo.withholdingTax = 0;
  },

  resetMemo(state) {
    state.invoiceInfo.memo = '';
  },

  setEditingAction(state, editingAction) {
    state.status.editingAction = editingAction;
  },

  setIsAgreePersonInCharge(state, data) {
    state.invoiceInfo.isAgreePersonInCharge = data;
  },

  setIsConfirmedPersonInCharge(state, data) {
    state.invoiceInfo.isConfirmedPersonInCharge = data;
  },

  setIsUsedFrncAccount(state, data) {
    state.invoiceInfo.isUsedFrncAccount = data;
  },

  setMovingPageCalled(state, data) {
    state.status.movingPageCalled = data;
  }
};

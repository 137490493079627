const $ = require('jquery');

export default function LoginValidator(selector1, selector2) {
  function delay(callback, ms) {
    var timer = 0;
    return function() {
      var context = this, args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        callback.apply(context, args);
      }, ms || 0);
    };
  }

  // email validate
  $(selector1).on('keyup', delay(function (e) {
    validateEmail($(this));
  }, 100));

  // password validate
  $(selector2).on('keyup', delay(function (e) {
    validatePassword($(this), $(this));
  }, 100));
}

function validateEmail(elem) {
  // サーバサイドからのエラーあればチェックしない
  if ($('#preventMultiSubmitForm #loginEmailInputBlock .error-message').html()
  || $('#preventMultiSubmitForm #loginEmailInvalid .error-message').html()) {
    return;
  }

  // メールアドレス正規表現が長くて煩雑なのでcakephpの方でします
  if ($(elem).val()) {
    $('#loginEmailErrBlock > .error-message').html('');
  } else {
    $('#loginEmailErrBlock > .error-message').html('この入力欄を未入力にすることはできません');
  }

  // jsでエラーある場合全体を赤へ
  if ($('#loginEmailErrBlock > .error-message').html()) {
    $('#loginEmailBlock').addClass('is-error');
  } else {
    $('#loginEmailBlock').removeClass('is-error');
  }
};

function validatePassword(elem) {
  // 空チェック
  if ($(elem).val()) {
    $('#loginPwdErrBlock > .error-message').html('');
  } else {
    $('#loginPwdErrBlock > .error-message').html('この入力欄を未入力にすることはできません');
  }

  let errStr = '英字・数字・記号の3種類のうち2つを含む8文字以上30文字以下の半角字を入力してください';
  let errFlg = false;
  // 形式チェック
  if (!$('#loginPwdErrBlock > .error-message').html()) {
    if ($(elem).val().match(/^([a-zA-Z0-9#|$|\-|=|\?|@|[|\]|_]{8,30})$/)) {
      let cnt = 0;
      if ($(elem).val().match(/[0-9]/u)) {
        cnt++;
      }
      if ($(elem).val().match(/[a-zA-Z]/u)) {
        cnt++;
      }
      if ($(elem).val().match(/[#|$|\-|=|\?|@|[|\]|_]/u)) {
        cnt++;
      }
      if (cnt >= 2) {
        $('#loginPwdErrBlock > .error-message').html('');
        if ($('#preventMultiSubmitForm #loginPwdInputBlock .error-message').html() === errStr) {
          $('#preventMultiSubmitForm #loginPwdInputBlock .error-message').html('');
        }
      } else {
        errFlg = true;
      }
    } else {
      errFlg = true;
    }

    if (errFlg) {
      if ($('#preventMultiSubmitForm #loginPwdInputBlock .error-message').html() !== errStr) {
        $('#loginPwdErrBlock > .error-message').html(errStr);
      }
    }
  }

  // jsでエラーある場合全体を赤へ
  if ($('#loginPwdErrBlock > .error-message').html() || errFlg) {
    $('#loginPwdBlock').addClass('is-error');
  } else {
    $('#loginPwdBlock').removeClass('is-error');
  }
};

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Mutation, Action, VuexModule, getModule, Module } from 'vuex-module-decorators';
import store from '.';
import ajax from '../../../lib/Ajax';
import * as conf from "../../InvoiceAdd/config";
import { convertUsername } from '../../../lib/Helper';
var Main = /** @class */ (function (_super) {
    __extends(Main, _super);
    function Main() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.expiredUserEvidences = [];
        _this.userEvidences = [
            {
                evidence_type: 'public_recepient',
                id: 0,
                status_id: 0,
                label: '住所確認',
                title: '住所確認',
                guide_txt: ':usernameの現在の住所が確認できる公共料金の請求書や領収書1点をアップロードしてください。家族の名義の場合は、続柄を示す住民票が必要です。',
                warning_txt: '※公的身分証はアップロードしないでください',
                created_at: '',
                upload_files: []
            }, {
                evidence_type: 'passbook_business',
                id: 0,
                status_id: 0,
                label: '通帳確認（事業者としての活動履歴）',
                title: '通帳確認',
                guide_txt: ':usernameのこれまでの事業者としての活動履歴を確認するために、銀行口座の入出金履歴を3ヶ月分以上（6ヶ月推奨）ご提供ください。',
                created_at: '',
                upload_files: []
            }
        ];
        _this.userName = '';
        _this.incorporated = false;
        _this.existUserEvidenceFileKeys = [];
        _this.attachingData = {
            file: [],
            img: []
        };
        return _this;
    }
    Object.defineProperty(Main.prototype, "certificate", {
        get: function () {
            return this.attachingData;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Main.prototype, "cntAttachedFiles", {
        get: function () {
            return this.attachingData.file.length;
        },
        enumerable: false,
        configurable: true
    });
    Main.prototype.setCertificate = function (val) {
        this.attachingData = val;
    };
    Main.prototype.setExistUserEvidenceFileKeys = function (val) {
        this.existUserEvidenceFileKeys = val;
    };
    Main.prototype.loadInitialData = function (state) {
        var _this = this;
        var processFileEvidence = function (ue) {
            var result = [];
            for (var i = 1; i <= conf.NUM_USER_EVIDENCE_UPLOADS; i++) {
                var k = "upload" + i;
                var fileName = ue[k];
                if (fileName)
                    result.push(fileName);
            }
            return result;
        };
        var username = convertUsername(state.userName, state.incorporated);
        for (var i = 0; i < this.userEvidences.length; i++) {
            var type = this.userEvidences[i].evidence_type;
            var ue = state.userEvidences[type];
            this.userEvidences[i].id = ue.id;
            this.userEvidences[i].status_id = ue.status_id;
            this.userEvidences[i].created_at = ue.created_at;
            this.userEvidences[i].upload_files = processFileEvidence(ue);
            this.userEvidences[i].guide_txt = this.userEvidences[i].guide_txt.replace(/:username/g, username);
        }
        if (state.expiredUserEvidences) {
            state.expiredUserEvidences.forEach(function (ue) {
                var files = processFileEvidence(ue);
                files.forEach(function (f) {
                    _this.expiredUserEvidences.push({
                        status_id: ue.status_id,
                        created_at: ue.created_at,
                        file: f
                    });
                });
            });
        }
    };
    Main.prototype.upload = function (userEvidence) {
        return __awaiter(this, void 0, void 0, function () {
            var csrfToken, data, i, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        csrfToken = document.CSRF_TOKEN;
                        data = new FormData();
                        for (i in this.attachingData.file) {
                            data.append("user_evidence" + i, this.attachingData.file[i]);
                        }
                        data.append('evidenceType', userEvidence.evidence_type);
                        data.append('evidenceID', userEvidence.id.toString());
                        data.append('existUserEvidenceFileKeys', userEvidence.status_id == conf.MASTER_USER_EVIDENCE_STATUS_CHECKING
                            ? this.existUserEvidenceFileKeys.join(',')
                            : '');
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, ajax.post('/apiInternal/uploadUserEvidenceAtSettingPage', data, {
                                'Content-Type': 'multipart/form-data',
                                'X-CSRF-Token': csrfToken
                            })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        alert('エラーが発生しました\n大変申し訳ありませんが時間をおいてお試しください');
                        throw err_1;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        Mutation
    ], Main.prototype, "setCertificate", null);
    __decorate([
        Mutation
    ], Main.prototype, "setExistUserEvidenceFileKeys", null);
    __decorate([
        Mutation
    ], Main.prototype, "loadInitialData", null);
    __decorate([
        Action({ rawError: true })
    ], Main.prototype, "upload", null);
    Main = __decorate([
        Module({ dynamic: true, store: store, name: 'main', namespaced: true })
    ], Main);
    return Main;
}(VuexModule));
export default getModule(Main);

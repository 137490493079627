
export default {
  // Load data from inline-embeded doc on HTML 
  // document.INVOICES
  load(state, invoices) {
    let _invoices = invoices.map((inv)=>{
      inv._selected = true;
      return inv;
    });
    state.invoices = _invoices;
  },
  updatePageTitle(state, data) {
    // ページタイトルセット
    state.page.title = data.title;
    state.page.complete = data.complete;
  },
  // 選択した請求書にフラグ立てる
  selectInvoice(state, id) {
    state.invoices.forEach((inv) => {
      if (inv.id == id) {
        inv._selected = inv._selected ? false : true;
      }
    });
  },
  // API通信状態をセットする
  updateProcessingApiRequest: (state, status) => {
    state.status.processingApiRequest = status;
  },
  isBlockStatusPublic: (state, value) => {
    state.isBlockStatusPublic = value;
  },
}

import Vue from 'vue';
import App from './components/App.vue';
import mainStore from './store/main';
import store from '../signup/store';
export default function page() {
    var app = new Vue({
        el: '#editVirtualAccountName',
        store: store,
        components: {
            App: App
        },
        created: function () {
            mainStore.loadInitialData({
                cntAccountInfo: {
                    oldAccountName: document.OLD_ACCOUNT_NAME,
                    oldAccountNumber: document.OLD_ACCOUNT_NUMBER,
                    oldBranchName: document.OLD_BRANCH_NAME
                }
            });
        }
    });
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"plan-content",attrs:{"id":"regular-content"}},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"plan-body"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"plan-price"},[_c('div',{staticClass:"amount"},[_c('span',{staticClass:"price-amount"},[_vm._v("¥"+_vm._s(_vm.displayPrice))]),_vm._v(" "),_c('span',{staticClass:"price-period"},[_vm._v("/月")])]),_vm._v(" "),_c('div',{staticClass:"note"},[_c('p',[_vm._v("\n          年額 "+_vm._s(_vm.displayTotalPricePerYear)+"円"),(_vm.isYearlyPlan)?_c('span',{staticClass:"yearly-discount"},[_vm._v("\n            約20%お得！")]):_vm._e()]),_vm._v(" "),(_vm.isYearlyPlan)?_c('p',[_vm._v("\n          年1回のお支払い\n        ")]):_c('p',[_vm._v("\n          月1回のお支払い\n        ")])])]),_vm._v(" "),_c('div',{staticClass:"campaign"},[(_vm.parentComponent.trialCampaignTxt)?_c('p',{staticClass:"campaign__title"},[_vm._v("\n        "+_vm._s(_vm.parentComponent.trialCampaignTxt)+"\n      ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"plan-features"},[(!_vm.parentComponent.trialCampaignTxt)?_c('div',{staticClass:"freelance-note"},[(!_vm.isDisabledBtn)?_c('span',[_vm._v("＼フリーナンスの会員は経費にできます／")]):_c('span',[_vm._v(" ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"choose-plan-btn"},[_c('button',{staticClass:"actionBtn--full",class:{
            'is-disabled': _vm.isDisabledBtn || _vm.isOnApiRequest,
          },attrs:{"disabled":_vm.isDisabledBtn || _vm.isOnApiRequest},on:{"click":function($event){$event.preventDefault();_vm.parentComponent.chooseMembershipPlan(
              _vm.conf.MASTER_MEMBERSHIP_PLAN_REGULAR,
              _vm.parentComponent.localActivatingPeriodID
            )}}},[_c('span',{staticClass:"actionBtn--full__inner"},[_vm._v(_vm._s(_vm.parentComponent.btnTitle(
              _vm.conf.MASTER_MEMBERSHIP_PLAN_REGULAR,
              _vm.parentComponent.localActivatingPeriodID
            )))])])]),_vm._v(" "),_vm._m(2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"plan-header"},[_c('span',[_vm._v("万が一の業務トラブルもしっかりカバー")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"plan-name"},[_c('span',{staticClass:"popular-label"},[_vm._v("レギュラー")]),_vm._v(" "),_c('span',{staticClass:"popular-tag"},[_vm._v("人気No.1")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"features-list"},[_c('li',{staticClass:"feature-item"},[_vm._v("\n          屋号でも事業用口座を開設\n        ")]),_vm._v(" "),_c('li',{staticClass:"feature-item"},[_vm._v("\n          請求書を最短即日で現金化\n        ")]),_vm._v(" "),_c('li',{staticClass:"feature-item"},[_vm._v("\n          業務遂行中の事故を補償：最高5,000万円\n        ")]),_vm._v(" "),_c('li',{staticClass:"feature-item"},[_vm._v("\n          業務過誤補償：最高500万円\n          "),_c('span',{staticClass:"feature-note"},[_vm._v("情報漏洩・著作権侵害・納品物の損壊・納期遅延等に対応")])]),_vm._v(" "),_c('li',{staticClass:"feature-item"},[_vm._v("\n          所得補償（最長1年）：即加入可能\n          "),_c('span',{staticClass:"feature-note"},[_vm._v("ケガや病気による就業不能を補償")])]),_vm._v(" "),_c('li',{staticClass:"feature-item"},[_vm._v("\n          Adobe CC 15%オフ\n        ")]),_vm._v(" "),_c('li',{staticClass:"feature-item"},[_vm._v("\n          決済用リンク手数料：3.93%\n          "),_c('span',{staticClass:"feature-note"},[_vm._v("請求書にカード決済機能を追加")])])])}]

export { render, staticRenderFns }
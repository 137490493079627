var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import VueModal from 'vue-js-modal';
import * as conf from '../../config';
import mainStore from '../../store/main';
Vue.use(VueModal);
var UpcomingInvoiceModal = /** @class */ (function (_super) {
    __extends(UpcomingInvoiceModal, _super);
    function UpcomingInvoiceModal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(UpcomingInvoiceModal.prototype, "conf", {
        get: function () {
            return conf;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UpcomingInvoiceModal.prototype, "isOnApiRequest", {
        get: function () {
            return mainStore.status.processingApiRequest;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UpcomingInvoiceModal.prototype, "action", {
        get: function () {
            return mainStore.changePlanInfo.action;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UpcomingInvoiceModal.prototype, "periodEnd", {
        get: function () {
            return mainStore.periodEnd;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UpcomingInvoiceModal.prototype, "checkoutUrl", {
        get: function () {
            var _a;
            return (_a = mainStore.changePlanInfo.checkoutUrl) !== null && _a !== void 0 ? _a : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UpcomingInvoiceModal.prototype, "seletedPlan", {
        get: function () {
            return mainStore.selectedPlan;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UpcomingInvoiceModal.prototype, "popupContent", {
        get: function () {
            var selectedPlanTitle = this.getPlanTitlebyPlanId(this.seletedPlan.selectedPlanID);
            var currentActivePlanTitle = this.getPlanTitlebyPlanId(mainStore.activatingPlanID);
            if (this.seletedPlan.selectedPlanID !== conf.MASTER_MEMBERSHIP_PLAN_FREE) {
                selectedPlanTitle += "\uFF08" + this.getPeriodTitlebyPeriodId(this.seletedPlan.selectedPeriodID) + "\uFF09";
            }
            if (mainStore.activatingPlanID !== conf.MASTER_MEMBERSHIP_PLAN_FREE) {
                currentActivePlanTitle += "\uFF08" + this.getPeriodTitlebyPeriodId(mainStore.activatingPeriodID) + "\uFF09";
            }
            var currentPeriodEndStr = this.japanDateString(this.periodEnd);
            switch (this.action) {
                case conf.ACTION_MEMBERSHIP_TRIAL_CHECKOUT_SESSION: {
                    var trialEndDate = moment().add(30, 'days').format('YYYY-MM-DD');
                    var trialEndDateString = this.japanDateString(trialEndDate);
                    return "\u30D5\u30EA\u30FC\u30D7\u30E9\u30F3\u304B\u3089" + selectedPlanTitle + "\u306B\u5909\u66F4\u3057\u307E\u3059\u3002\u7121\u6599\u671F\u9593\u7D42\u4E86\uFF08" + trialEndDateString + "\uFF09\u5F8C\u3001\u8AB2\u91D1\u304C\u958B\u59CB\u3055\u308C\u307E\u3059\u3002";
                }
                case conf.ACTION_CHECKOUT_SESSION:
                case conf.ACTION_UPGRADE_SUBSCRIPTION:
                case conf.ACTION_UPGRADE_SUBSCRIPTION_WITHOUT_CASHBACK:
                    return currentActivePlanTitle + "\u304B\u3089" + selectedPlanTitle + "\u306B\u5909\u66F4\u3057\u307E\u3059\u3002\u6599\u91D1\u306E\u6C7A\u6E08\u5B8C\u4E86\u5F8C\u3001\u3059\u3050\u306B\u65B0\u3057\u3044\u30D7\u30E9\u30F3\u304C\u9069\u7528\u3055\u308C\u307E\u3059\u3002";
                case conf.ACTION_DOWNGRADE_SUBSCRIPTION:
                    return currentActivePlanTitle + "\u304B\u3089" + selectedPlanTitle + "\u306B\u5909\u66F4\u3057\u307E\u3059\u3002\u6B21\u56DE\u66F4\u65B0\u65E5\uFF08" + currentPeriodEndStr + "\uFF09\u306B"
                        + (selectedPlanTitle + "\u306E\u6599\u91D1\u3067\u6C7A\u6E08\u3055\u308C\u307E\u3059\u3002\u6C7A\u6E08\u5B8C\u4E86\u5F8C\u3001\u65B0\u3057\u3044\u30D7\u30E9\u30F3\u304C\u9069\u7528\u3055\u308C\u307E\u3059\u3002");
                case conf.ACTION_CANCEL_SUBSCRIPTION:
                    return currentActivePlanTitle + "\u304B\u3089\u30D5\u30EA\u30FC\u30D7\u30E9\u30F3\u306B\u5909\u66F4\u3057\u307E\u3059\u3002\u73FE\u5728\u306E\u30D7\u30E9\u30F3\u306F\u6B21\u56DE\u306E\u66F4\u65B0\u6642\uFF08" + currentPeriodEndStr + "\uFF09\u307E\u3067\u3054\u5229\u7528\u3044\u305F\u3060\u3051\u307E\u3059\u3002";
                default:
                    return '';
            }
        },
        enumerable: false,
        configurable: true
    });
    UpcomingInvoiceModal.prototype.show = function () {
        this.$modal.show('upcoming-invoice-modal');
    };
    UpcomingInvoiceModal.prototype.hide = function () {
        this.$modal.hide('upcoming-invoice-modal');
    };
    UpcomingInvoiceModal.prototype.japanDateString = function (dateString) {
        if (!dateString) {
            return '';
        }
        var date = new Date(dateString);
        return date.getFullYear() + "\u5E74" + (date.getMonth() + 1) + "\u6708" + date.getDate() + "\u65E5";
    };
    UpcomingInvoiceModal.prototype.getPeriodTitlebyPeriodId = function (periodID) {
        switch (periodID) {
            case conf.MASTER_MEMBERSHIP_BILLING_PERIODS_MONTHLY:
                return conf.MEMBERSHIP_PERIODS_MONTHLY_TITLE;
            case conf.MASTER_MEMBERSHIP_BILLING_PERIODS_YEARLY:
                return conf.MEMBERSHIP_PERIODS_YEARLY_TITLE;
            default:
                return '';
        }
    };
    UpcomingInvoiceModal.prototype.getPlanTitlebyPlanId = function (planID) {
        switch (planID) {
            case conf.MASTER_MEMBERSHIP_PLAN_FREE:
                return conf.MEMBERSHIP_PLAN_FREE_TITLE + "\u30D7\u30E9\u30F3";
            case conf.MASTER_MEMBERSHIP_PLAN_REGULAR:
                return conf.MEMBERSHIP_PLAN_REGULAR_TITLE + "\u30D7\u30E9\u30F3";
            case conf.MASTER_MEMBERSHIP_PLAN_PREMIUM:
                return conf.MEMBERSHIP_PLAN_PREMIUM_TITLE + "\u30D7\u30E9\u30F3";
            default:
                return '';
        }
    };
    UpcomingInvoiceModal.prototype.approve = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.action === conf.ACTION_CHECKOUT_SESSION
                            || this.action === conf.ACTION_MEMBERSHIP_TRIAL_CHECKOUT_SESSION) {
                            mainStore.updateProcessingApiRequest(true);
                            location.href = this.checkoutUrl;
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, mainStore.processChangePlan()];
                    case 1:
                        _a.sent();
                        this.hide();
                        return [2 /*return*/];
                }
            });
        });
    };
    UpcomingInvoiceModal = __decorate([
        Component({
            name: 'upcoming-invoice-modal'
        })
    ], UpcomingInvoiceModal);
    return UpcomingInvoiceModal;
}(Vue));
export default UpcomingInvoiceModal;

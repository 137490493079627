export default function page () {

  var $inDebt = $('#in-debt');
  var $inDebtFrom = $('#in-debt-from');
  var $inDebtAmount = $('#in-debt-amount');

  function isDebtDisabled () {
    $inDebtFrom.prop('disabled', true);
    $inDebtFrom.val('');
    $inDebtFrom.addClass('is-disabled');
    $inDebtAmount.prop('disabled', true);
    $inDebtAmount.val('');
    $inDebtAmount.addClass('is-disabled');
  }

  function isDebtValidited () {
    $inDebtFrom.prop('disabled', false);
    $inDebtFrom.removeClass('is-disabled');
    $inDebtAmount.prop('disabled', false);
    $inDebtAmount.removeClass('is-disabled');
  }

  function debtChack () {
    var debt = $inDebt.val();
    if (debt == 1) {
      isDebtValidited();
    } else {
      isDebtDisabled();
    }
  }

  $inDebt.on('change', debtChack);
  debtChack();
};
